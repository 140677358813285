import { createSlice } from "@reduxjs/toolkit";
import { getCustomerProductB2BScheme, getCustomerB2BScheme, getCustomerB2BSchemeByID, getProductB2BScheme, getSchemeByID, getB2BScheme, getB2BSchemeByID, postCustomerB2BScheme, schemeAdd, schemeDelete } from "./b2bschemesAction";

const b2bschemesSlice = createSlice({
  name: "b2bschemes",
  initialState: {
    error: "",
    message: null,
    loading: false,
    schemeslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    productSchemePayload: {
      retailer_id: "",
      product_id: "",
      unit_id: "",
      quantity: ""
    },
    productScheme: {
      loading: false,
      data: {},
    },
    schemeParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    SetSchemeParams: (state, action) => {
      return {
        ...state,
        schemeParams: action.payload,
      };
    },
    schemeDelete: (state) => {
      return {
        ...state,
        loading: false

      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    clearData: (state) => {
      return {
        ...state,
        error: "",
        message: null,
        loading: false,
        schemeslist: [],
        model: false,
        error_msg: null,
        rowdata: {},
        listCount: 0,
        productSchemePayload: {
          retailer_id: "",
          product_id: "",
          unit_id: "",
          quantity: ""
        },
        productScheme: {
          loading: false,
          data: {},
        },
        schemeParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
          currentSort: "code",
          sortOrder: "-",
        },
      };
    },

    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload
      };
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getB2BScheme.pending, (state, action) => {
        state.status = "getB2BScheme loading";
        state.loading = true;
      })
      .addCase(getB2BScheme.fulfilled, (state, action) => {
        var response = action.payload.response;
        state.status = "getB2BScheme succeeded";
        state.loading = false;
        var noofpages = Math.ceil(
          action.payload.count / state.schemeParams?.page_size
        );

        state.schemeslist = response.results;
        state.listCount = response.count;
        state.schemeParams = {
          ...state.schemeParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getB2BScheme.rejected, (state, action) => {
        state.status = "getB2BScheme failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(schemeAdd.pending, (state, action) => {
        state.status = "schemeAdd loading";
        state.loading = true;
      })
      .addCase(schemeAdd.fulfilled, (state, action) => {
        state.status = "schemeAdd succeeded";
        state.loading = false
        state.model = action.payload.modal;
        state.message = action.payload.response;
        state.rowdata = {};
      }
      )
      .addCase(schemeAdd.rejected, (state, action) => {
        state.status = "schemeAdd failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(schemeDelete.pending, (state, action) => {
        state.status = "schemeDelete loading";
        state.loading = true;
      })
      .addCase(schemeDelete.fulfilled, (state, action) => {
        state.status = "schemeDelete succeeded";
        state.loading = false
      }
      )
      .addCase(schemeDelete.rejected, (state, action) => {
        state.status = "schemeDelete failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getB2BSchemeByID.pending, (state, action) => {
        state.status = "getSchemeByID loading";
        state.loading = true;
      })
      .addCase(getB2BSchemeByID.fulfilled, (state, action) => {
        state.status = "getSchemeByID succeeded";
        state.loading = false
        state.model = true;
        state.rowdata = {
          ...action.payload,
        };
      }
      )
      .addCase(getB2BSchemeByID.rejected, (state, action) => {
        state.status = "getSchemeByID failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProductB2BScheme.pending, (state, action) => {
        state.status = "getProductB2BScheme loading";
        state.productScheme.loading = true;
      })
      .addCase(getProductB2BScheme.fulfilled, (state, action) => {
        state.status = "getProductB2BScheme succeeded";
        state.productScheme.loading = false
        state.model = true;
        state.productScheme.data = action.payload
      }
      )
      .addCase(getProductB2BScheme.rejected, (state, action) => {
        state.status = "getProductB2BScheme failed";
        state.productScheme.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCustomerProductB2BScheme.pending, (state, action) => {
        state.status = "getCustomerProductB2BScheme loading";
        state.productScheme.loading = true;
      })
      .addCase(getCustomerProductB2BScheme.fulfilled, (state, action) => {

        console.log(action.payload)
        state.status = "getCustomerProductB2BScheme succeeded";
        state.productScheme.loading = false
        state.model = true;
        state.productScheme.data = action.payload
      }
      )
      .addCase(getCustomerProductB2BScheme.rejected, (state, action) => {
        state.status = "getCustomerProductB2BScheme failed";
        state.productScheme.loading = false;
        state.error = action.error.message;
      })
      .addCase(postCustomerB2BScheme.pending, (state, action) => {
        state.status = "postCustomerB2BScheme loading";
        state.loading = true;
      })
      .addCase(postCustomerB2BScheme.fulfilled, (state, action) => {
        state.status = "postCustomerB2BScheme succeeded";
        state.loading = false
        state.model = action.payload.modal;
        state.message = action.payload.response;
        state.rowdata = {};
      }
      )
      .addCase(postCustomerB2BScheme.rejected, (state, action) => {
        state.status = "postCustomerB2BScheme failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCustomerB2BScheme.pending, (state, action) => {
        state.status = "getCustomerB2BScheme loading";
        state.loading = true;
      })
      .addCase(getCustomerB2BScheme.fulfilled, (state, action) => {
        state.status = "getCustomerB2BScheme succeeded";
        state.loading = false;
        var response = action.payload.response;
        // var list = [];
        // if (state.schemeParams?.page == 1) {
        //   list = response.results;
        // } else {
        //   list = [...state.schemeslist, ...response.results];
        // }
        var noofpages = Math.ceil(
          action.payload.count / state.schemeParams?.page_size
        );

        state.schemeslist = response.results;
        state.listCount = response.count;
        state.schemeParams = {
          ...state.schemeParams,
          no_of_pages: noofpages,
        };
      }
      )
      .addCase(getCustomerB2BScheme.rejected, (state, action) => {
        state.status = "getCustomerB2BScheme failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCustomerB2BSchemeByID.pending, (state, action) => {
        state.status = "getCustomerB2BSchemeByID loading";
        state.loading = true;
      })
      .addCase(getCustomerB2BSchemeByID.fulfilled, (state, action) => {
        state.status = "getCustomerB2BSchemeByID succeeded";
        state.loading = false
        state.model = true;
        state.rowdata = {
          ...action.payload,
        };
      }
      )
      .addCase(getCustomerB2BSchemeByID.rejected, (state, action) => {
        state.status = "getCustomerB2BSchemeByID failed";
        state.loading = false;
        state.error = action.error.message;
      })

  }

});
export const {
  InputChangeValue,
  apiError,
  isModelVisible,
  SetSchemeParams,
  clearData,
} = b2bschemesSlice.actions;

export default b2bschemesSlice.reducer;
