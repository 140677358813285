import React, { useEffect, useState } from "react";
import { Row, Col, Card, Typography } from "antd";
// import Chart from "react-apexcharts";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsExporting from 'highcharts/modules/exporting'

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

function AnalyticsCard(props) {
  const { Text } = Typography;
  const { data } = props

  var category = [];
  var seriesData = [];

  data.forEach((item) => {
    const seriesItem = {
      name: item.product_name,
      data: []
    };

    item.daily_data.forEach((it) => {
      seriesItem.data.push(it.quantity);
      if (!category.includes(it.date)) {
        category.push(it.date);
      }
    });

    seriesData.push(seriesItem);
  });

  const options = {
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            //  "printChart", "separator", 
            // "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG", "separator",
            // "downloadCSV", "downloadXLS"
          ]
        }
      }
    },
    legend: {
      enabled: true,
    },

    title: {
      text: '',
    },
    chart: {
      type: 'spline',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: category,
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        format: '{value}',
      },
    },
    plotOptions: {
      line: {
        marker: {
          radius: 4,
          lineColor: '#666666',
          lineWidth: 1,
        },
      },
    },
    series: seriesData
  };
  return (
    <>
      <Card>
        <Row gutter={[0, 12]} className="hp-mt-12 hp-p-0">
          <Col sm={12} span={24}>
            <Text className="h5" strong>
              {props.title}
            </Text>
          </Col>
          <Col span={24}>
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              containerProps={{ style: { height: "400px" } }}
              allowChartUpdate
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default AnalyticsCard;
