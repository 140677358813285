import { createSlice } from "@reduxjs/toolkit";
import {
	getRetailerReceiptByID,
	getRetailerReceiptItemsByOrderID,
	getRetailerReceipts,
	postRetailerReceipts,
} from "./retailerReceipts.action";

const retailerReceiptSlice = createSlice({
	name: "retailerReceiptSlice",
	initialState: {
		loading: false,
		error: "",
		status: "",
		list: [],
		selectedData: {},
		count: 0,
		filterParams: {},
		productsList: [],
		productListparams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
		pageParams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
	},
	reducers: {
		changeParams: (state, action) => {
			return {
				...state,
				pageParams: action.payload,
			};
		},
		changeProductListParams: (state, action) => {
			return {
				...state,
				productListparams: action.payload,
			};
		},
		isDrawerVisible: (state, action) => {
			return {
				...state,
				drawer: action.payload,
			};
		},
		FilterInputChangeValue: (state, action) => {
			return {
				...state,
				filterParams: {
					...state.filterParams,
					[action.payload.key]: action.payload.value,
				},
			};
		},
		changeFilterParams: (state, action) => {
			return {
				...state,
				filterParams: action.payload,
			};
		},

		setSelectedData: (state, action) => {
			return {
				...state,
				selectedData: action.payload,
			};
		},
		inputChangeValue: (state, action) => {
			return {
				...state,
				selectedData: {
					...state.selectedData,
					...action.payload,
				},
			};
		},
		addProductsToList: (state, action) => {
			return {
				...state,
				productsList: [...state.productsList, action.payload],
			};
		},
		removeProductsFromList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => {
					return e.uniqueId == action.payload.product_id
						? {
								...e,
								dodelete: true,
						  }
						: {
								...e,
						  };
				}),
			};
		},
		changeProductList: (state, action) => {
			console.log(action.payload);
			return {
				...state,
				productsList: state.productsList.map((e) => {
					if (e.id == action.payload.product_id) {
						if (action.payload.key == "received_qty") {
							const calculatedPrice =
								+action.payload.value * e.listedPrice;
							const discountAmt =
								+calculatedPrice * (+e.discount_percent / 100);
							// const gstAmt =
							// 	+calculatedPrice * (+e.gst_percentage / 100);
							const totalPrice =
								parseFloat(calculatedPrice) -
								parseFloat(discountAmt);

							return {
								...e,
								[action.payload.key]: action.payload.value,
								discountAmt,
								// gstAmt,
								price: totalPrice,
							};
						} else {
							return {
								...e,
								[action.payload.key]: action.payload.value,
							};
						}
					} else {
						return e;
					}
				}),
			};
		},
		clearData: (state, action) => {
			return {
				...state,
				loading: false,
				error: "",
				status: "",
				list: [],
				selectedData: {},
				count: 0,
				filterParams: {},
				productsList: [],
				productListparams: {
					start_date: "",
					end_date: "",
					no_of_pages: 0,
					page_size: 10,
					page: 1,
					search: "",
				},
				pageParams: {
					start_date: "",
					end_date: "",
					no_of_pages: 0,
					page_size: 10,
					page: 1,
					search: "",
				},
			};
		},
		clearProductsList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => ({
					...e,
					dodelete: true,
				})),
			};
		},
	},
	extraReducers: (builder) => {
		builder
			//get Data
			.addCase(getRetailerReceipts.pending, (state, action) => {
				state.status = "getRetailerReceipts loading";
				state.loading = true;
			})
			.addCase(getRetailerReceipts.fulfilled, (state, action) => {
				state.status = "getRetailerReceipts succeeded";
				state.loading = false;
				var noofpages = Math.ceil(
					action.payload.count / state.pageParams.page_size
				);
				state.count = action.payload.count;
				state.list = action.payload.results;
				state.pageParams = {
					...state.pageParams,
					no_of_pages: noofpages,
				};
			})
			.addCase(getRetailerReceipts.rejected, (state, action) => {
				state.status = "getRetailerReceipts failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//get Data by Id
			.addCase(getRetailerReceiptByID.pending, (state, action) => {
				state.status = "getRetailerReceiptByID loading";
				state.loading = true;
			})
			.addCase(getRetailerReceiptByID.fulfilled, (state, action) => {
				state.status = "getRetailerReceiptByID succeeded";
				state.loading = false;
				state.selectedData = action.payload;
				state.productsList =
					action.payload.distributor_receipt_items.map((e) => {
						const calculatedPrice = +e.received_qty * +e.price;
						const discountAmt =
							+calculatedPrice * (+e.discount_percent / 100);
						// const gstAmt =
						// 	+calculatedPrice * (+e.tax_percent / 100);
						// const totalPrice = +calculatedPrice + +gstAmt;
						// const taxableValue = +e?.discount_percent
						// 	? calculatedPrice /
						// 	  (1 + parseInt(e?.discount_percent))
						// 	: 0;
						const totalPrice =
							parseFloat(calculatedPrice) - discountAmt;

						return {
							...e,
							item_id: e.id,
							product_id: e.order_item?.product.id,
							image: e?.order_item?.product.image,
							name: e?.order_item?.product.name,
							listedPrice: parseFloat(e.d_price).toFixed(2),
							originalPrice: calculatedPrice,
							discountAmt,
							// gstAmt,
							// taxableValue: parseFloat(taxableValue.toFixed(2)),
							price: parseFloat(totalPrice).toFixed(2),
							size: e?.order_item?.unit?.id,
							// gst_percentage: e.d_tax_percent,
							discount_percent: e.d_discount_percent,
							quantity: e?.order_item?.quantity,
							received_qty: e.received_qty ? e.received_qty : "",
						};
					});
			})
			.addCase(getRetailerReceiptByID.rejected, (state, action) => {
				state.status = "getRetailerReceiptByID failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//post data
			.addCase(postRetailerReceipts.pending, (state, action) => {
				state.status = "postRetailerReceipts loading";
				state.loading = true;
			})
			.addCase(postRetailerReceipts.fulfilled, (state, action) => {
				state.status = "postRetailerReceipts succeeded";
				state.loading = false;
				state.selectedData = {};
			})
			.addCase(postRetailerReceipts.rejected, (state, action) => {
				state.status = "postRetailerReceipts failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//getRetailerReceiptItemByOrderID
			.addCase(
				getRetailerReceiptItemsByOrderID.pending,
				(state, action) => {
					state.status = "getRetailerReceiptItemByOrderID loading";
					state.loading = true;
				}
			)
			.addCase(
				getRetailerReceiptItemsByOrderID.fulfilled,
				(state, action) => {
					state.status = "getRetailerReceiptItemByOrderID succeeded";
					state.loading = false;
					// state.selectedData = {
					// 	...action.payload,
					// 	salesagent_id: action.payload.sales_agent?.id,
					// 	retailer_id: action.payload.retailer?.id,
					// 	location_id: action.payload.retailer?.location?.id,
					// 	location_name: action.payload.retailer?.location?.name,
					// 	billing_address_id:
					// 		action.payload?.retailer?.default_billing_address?.id,
					// 	shipping_address_id:
					// 		action.payload?.retailer?.default_shipping_address?.id,
					// 	default_billing_address:
					// 		action.payload?.retailer?.default_billing_address,
					// 	default_shipping_address:
					// 		action.payload?.retailer?.default_shipping_address,
					// };

					console.log(action.payload);
					state.productsList = action.payload.results.map((e) => {
						const calculatedPrice = +e.quantity * +e.d_price;
						const discountAmt =
							+calculatedPrice * (+e.d_discount_percent / 100);
						const gstAmt =
							+calculatedPrice * (+e.d_tax_percent / 100);
						const totalPrice = +calculatedPrice + +gstAmt;
						const taxableValue = +e?.d_discount_percent
							? calculatedPrice /
							  (1 + parseInt(e?.d_discount_percent))
							: 0;
						return {
							...e,
							item_id: e.id,
							product_id: e.product.id,
							image: e.product.image,
							name: e.product.name,
							listedPrice: parseFloat(e.d_price).toFixed(2),
							originalPrice: calculatedPrice,
							discountAmt,
							gstAmt,
							taxableValue: parseFloat(taxableValue.toFixed(2)),
							price: parseFloat(totalPrice).toFixed(2),
							size: e?.unit?.id,
							gst_percentage: e?.d_tax_percent,
							discount_percent: e?.d_discount_percent,
							quantity: e?.quantity,
							received_qty: e?.received_qty
								? e?.received_qty
								: "",
						};
					});
				}
			)
			.addCase(
				getRetailerReceiptItemsByOrderID.rejected,
				(state, action) => {
					state.status = "getRetailerReceiptItemByOrderID failed";
					state.loading = false;
					state.error = action.error.message;
				}
			);
	},
});

// Action creators are generated for each case reducer function
export const {
	changeParams,
	clearData,
	setSelectedData,
	inputChangeValue,
	addProductsToList,
	removeProductsFromList,
	changeProductList,
	isDrawerVisible,
	FilterInputChangeValue,
	changeProductListParams,
	clearProductsList,
} = retailerReceiptSlice.actions;

export default retailerReceiptSlice.reducer;
