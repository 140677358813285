import { createSlice } from "@reduxjs/toolkit";
import moment, { min } from "moment";
import {
  getCustomerOrdersCount,
  getRevenueCount,
  getCashCollectionCount,
  getMontlyOrderReport,
  getDashboardCustmOrders,
  getDashboardSubscription,
  // B2B
  getB2BRetailerIndents,
  getB2BDistributorIndents,
  getB2BDistributorReceipts,
  getB2BRevenueCount,
  getB2BDistributorOrders,
  getDistributorOrdersList
} from "./dashboardAction";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    error: "",
    status: "",
    drawer: false,
    loading: false,
    customerCount: {},
    revenueLoading: false,
    revenueCount: [],
    cashLoading: false,
    cashCount: [],
    graphLoading: false,
    customList: [],
    customCount: 0,
    customLoading: false,
    customParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    subscriptionList: [],
    subCount: 0,
    subLoading: false,
    subscriParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    monthlyReport: [],


    // B2B
    retIndentLoading: false,
    retailerIndent: {},
    destIndentLoading: false,
    distributorIndent: {},
    destReceiptLoading: false,
    distributorReceipt: {},
    distOrderLoading: false,
    distributorOrder: [],
    b2crevenueLoad: false,
    totalRevenue: 0,
    distributorOrdersList: [],
    distListLoading: false,
    listCount: 0,
    distOrderParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    }

  },
  reducers: {
    loadingStatus: (state, action) => {
      return { ...state, loading: action.payload };
    },
    isDrawerVisible: (state, action) => {
      return {
        ...state,
        drawer: action.payload,
      };
    },
    customChngParams: (state, action) => {
      return {
        ...state,
        customParams: action.payload,
      };
    },
    customSubscriParams: (state, action) => {
      return {
        ...state,
        subscriParams: action.payload,
      };
    },
    listChngParams: (state, action) => {
      return {
        ...state,
        distOrderParams: action.payload,
      };
    },
  },

  extraReducers: (builder) => {
    builder
      // total customer order count
      .addCase(getCustomerOrdersCount.pending, (state, action) => {
        state.status = "getCustomerOrdersCount loading";
        state.loading = true;
      })
      .addCase(getCustomerOrdersCount.fulfilled, (state, action) => {
        console.log(action.payload)
        state.status = "getCustomerOrdersCount success";
        state.loading = false;
        state.customerCount = {
          total: action.payload.response.total_orders,
          pending: action.payload.response.total_pending_orders,
          delivered: action.payload.response.total_delivered_orders,
          unPaid: action.payload.response.total_unpaid_orders,
        };
      })
      .addCase(getCustomerOrdersCount.rejected, (state, action) => {
        state.status = "getCustomerOrdersCount failed";
        state.loading = false;
        state.error = action.error.message;
      })

      // Revenue
      .addCase(getRevenueCount.pending, (state, action) => {
        state.status = "getRevenueCount loading";
        state.revenueLoading = true;
      })
      .addCase(getRevenueCount.fulfilled, (state, action) => {
        var value = [];
        var data = action.payload.response;
        value = [
          // {
          //   title: "Total Revenue",
          //   total: data.total_net_revenue,
          //   color: "#B473EF",
          // },
          {
            title: "Subsription",
            total: data.subscription_revenue,
            color: "#FCA15C",
          },
          {
            title: "Ala Cart",
            total: data.ala_carte_revenue,
            color: "#3B9CE3",
          },
        ];
        state.status = "getRevenueCount success";
        state.revenueLoading = false;
        state.revenueCount = value;
      })
      .addCase(getRevenueCount.rejected, (state, action) => {
        state.status = "getRevenueCount rejected";
        state.revenueLoading = false;
        state.error = action.error.message;
      })


      // cash collection count
      .addCase(getCashCollectionCount.pending, (state, action) => {
        state.status = "getCashCollectionCount loading";
        state.cashLoading = true
      })
      .addCase(getCashCollectionCount.fulfilled, (state, action) => {
        var value = [];
        var data = action.payload;
        value = [
          // {
          //   title: "Total",
          //   total: data.total_count?.total_amount,
          //   color: "#B473EF",
          // },
          {
            title: "Requested",
            total: data.total_count?.total_requested_amount,
            color: "#148DE6",
          },
          {
            title: "Collected",
            total: data.total_count?.total_collected_amount,
            color: "#5DC4A1",
          },
        ];
        state.status = "getCashCollectionCount success";
        state.cashLoading = false
        state.cashCount = value;
      })
      .addCase(getCashCollectionCount.rejected, (state, action) => {
        state.status = "getRevenueCount rejected";
        state.cashLoading = false;
        state.error = action.error.message;
      })

      // Monthly Order Report
      .addCase(getMontlyOrderReport.pending, (state, action) => {
        state.status = "getMontlyOrderReport loading";
        state.graphLoading = true;
      })
      .addCase(getMontlyOrderReport.fulfilled, (state, action) => {
        state.status = "getMontlyOrderReport successed";
        state.graphLoading = false;
        state.monthlyReport = action.payload
      })
      .addCase(getMontlyOrderReport.rejected, (state, action) => {
        state.status = "getMontlyOrderReport rejected";
        state.graphLoading = false;
        state.error = action.error.message;
      })

      // customer order list
      .addCase(getDashboardCustmOrders.pending, (state, action) => {
        state.status = "getDashboardCustmOrders loading";
        state.customLoading = true;
      })
      .addCase(getDashboardCustmOrders.fulfilled, (state, action) => {
        state.status = "getDashboardCustmOrders succeeded";
        state.customLoading = false;
        var noofpages = Math.ceil(
          action.payload.count / state.customParams.page_size
        );
        state.customCount = action.payload.count;
        state.customList = action.payload.results;
      })
      .addCase(getDashboardCustmOrders.rejected, (state, action) => {
        state.status = "getDashboardCustmOrders failed";
        state.customLoading = false;
        state.error = action.error.message;
      })

      // subscription list
      .addCase(getDashboardSubscription.pending, (state, action) => {
        state.status = "getDashboardSubscription loading";
        state.subLoading = true;
      })
      .addCase(getDashboardSubscription.fulfilled, (state, action) => {
        state.status = "getDashboardSubscription succeeded";
        state.subLoading = false;
        var noofpages = Math.ceil(
          action.payload.count / state.subscriParams.page_size
        );
        state.subCount = action.payload.count;
        state.subscriptionList = action.payload.results;
      })
      .addCase(getDashboardSubscription.rejected, (state, action) => {
        state.status = "getDashboardSubscription failed";
        state.subLoading = false;
        state.error = action.error.message;
      })

      // B2B dashboard api's

      // get B2B dashboard Retailer Indents count
      .addCase(getB2BRetailerIndents.pending, (state, action) => {
        state.status = "getB2BRetailerIndents loading";
        state.retIndentLoading = true;
      })
      .addCase(getB2BRetailerIndents.fulfilled, (state, action) => {
        const value = action.payload.response;
        console.log(value)
        const percentage = (
          value?.today_count != null &&
          value?.last_month_count != null &&
          typeof value.today_count === 'number' &&
          typeof value.last_month_count === 'number' &&
          value.last_month_count !== 0
        ) ? (value.today_count / value.last_month_count) * 100 : 0
        console.log(percentage)

        state.status = "getB2BRetailerIndents fulfilled";
        state.retIndentLoading = false;
        state.retailerIndent = {
          totalCount: value.today_count,
          last30DaysCount: value.last_month_count,
          arrowDown: percentage < 0 ? true : false,
          type: percentage < 0 ? "danger" : "success",
          percentage: percentage.toFixed(0),
        }
      })
      .addCase(getB2BRetailerIndents.rejected, (state, action) => {
        state.status = "getB2BRetailerIndents rejected";
        state.retIndentLoading = false;
        state.error = action.error.message;
      })

      // get B2B dashboard total Distributor Indents count
      .addCase(getB2BDistributorIndents.pending, (state, action) => {
        state.status = "getB2BDistributorIndents loading";
        state.destIndentLoading = true;
      })
      .addCase(getB2BDistributorIndents.fulfilled, (state, action) => {
        var value = action.payload.response;
        const percentage = (
          value?.today_count != null &&
          value?.last_month_count != null &&
          typeof value.today_count === 'number' &&
          typeof value.last_month_count === 'number' &&
          value.last_month_count !== 0
        ) ? (value.today_count / value.last_month_count) * 100 : 0

        state.status = "getB2BDistributorIndents fulfilled";
        state.destIndentLoading = false;
        state.distributorIndent = {
          totalCount: value.today_count,
          last30DaysCount: value.last_month_count,
          arrowDown: percentage < 0 ? true : false,
          type: percentage < 0 ? "danger" : "success",
          percentage: percentage.toFixed(0),
        }
      })
      .addCase(getB2BDistributorIndents.rejected, (state, action) => {
        state.status = "getB2BDistributorIndents rejected";
        state.destIndentLoading = false;
        state.error = action.error.message;
      })

      // get B2B dashboard total Distributor Receipts count
      .addCase(getB2BDistributorReceipts.pending, (state, action) => {
        state.status = "getB2BDistributorReceipts loading";
        state.destReceiptLoading = true;
      })
      .addCase(getB2BDistributorReceipts.fulfilled, (state, action) => {
        var value = action.payload.response;
        const percentage = (
          value?.today_count != null &&
          value?.last_month_count != null &&
          typeof value.today_count === 'number' &&
          typeof value.last_month_count === 'number' &&
          value.last_month_count !== 0
        ) ? (value.today_count / value.last_month_count) * 100 : 0

        state.status = "getB2BDistributorReceipts fulfilled";
        state.destReceiptLoading = false;
        state.distributorReceipt = {
          totalCount: value.today_count,
          last30DaysCount: value.last_month_count,
          arrowDown: percentage < 0 ? true : false,
          type: percentage < 0 ? "danger" : "success",
          percentage: percentage.toFixed(0),
        }
      })
      .addCase(getB2BDistributorReceipts.rejected, (state, action) => {
        state.status = "getB2BDistributorReceipts rejected";
        state.destReceiptLoading = false;
        state.error = action.error.message;
      })

      // get B2B dashboard total revenue count
      .addCase(getB2BRevenueCount.pending, (state, action) => {
        state.status = "getB2CRevenueCount loading";
        state.b2crevenueLoad = true;
      })
      .addCase(getB2BRevenueCount.fulfilled, (state, action) => {
        state.status = "getB2CRevenueCount fulfilled";
        state.b2crevenueLoad = false;
        state.totalRevenue = action.payload.response.total_net_revenue
      })
      .addCase(getB2BRevenueCount.rejected, (state, action) => {
        state.status = "getB2CRevenueCount rejected";
        state.b2crevenueLoad = false;
        state.error = action.error.message;
      })

      // get B2B dashboard total Distributor Orders count
      .addCase(getB2BDistributorOrders.pending, (state, action) => {
        state.status = "getB2BDistributorOrders loading";
        state.distOrderLoading = true
      })
      .addCase(getB2BDistributorOrders.fulfilled, (state, action) => {
        var value = [];
        var data = action.payload;
        value = [
          {
            title: "Pending",
            total: data.pending_count,
            color: "#148DE6",
          },
          {
            title: "Delivered",
            total: data.delivered_count,
            color: "#5DC4A1",
          },
        ];
        state.status = "getB2BDistributorOrders success";
        state.distOrderLoading = false
        state.distributorOrder = value;
      })
      .addCase(getB2BDistributorOrders.rejected, (state, action) => {
        state.status = "getB2BDistributorOrders rejected";
        state.distOrderLoading = false;
        state.error = action.error.message;
      })

      // get B2B dashboard Distributor Order List
      .addCase(getDistributorOrdersList.pending, (state, action) => {
        state.status = "getDistributorOrdersList loading";
        state.distListLoading = true;
      })
      .addCase(getDistributorOrdersList.fulfilled, (state, action) => {
        state.status = "getDistributorOrdersList succeeded";
        state.distListLoading = false;
        var noofpages = Math.ceil(
          action.payload.count / state.distOrderParams.page_size
        );
        state.listCount = action.payload.count;
        state.distributorOrdersList = action.payload.results;
      })
      .addCase(getDistributorOrdersList.rejected, (state, action) => {
        state.status = "getDistributorOrdersList failed";
        state.distListLoading = false;
        state.error = action.error.message;
      })
  }
});

// Action creators are generated for each case reducer function
export const {
  loadingStatus,
  isDrawerVisible,
  customChngParams,
  customSubscriParams,
  listChngParams
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
