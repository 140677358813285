import { createSlice } from "@reduxjs/toolkit";
import { getStateByID, getStates, stateAdd, stateDelete } from "./stateAction";

const stateSlice = createSlice({
  name: "states",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    stateslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    stateParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    SetStateParams: (state, action) => {
      return {
        ...state,
        stateParams: action.payload,
      };
    },
    stateDelete: (state) => {
      return {
        ...state,
        loading: false

      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },

    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload
      };
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getStates.pending, (state, action) => {
        state.status = "getStates loading";
        state.loading = true;
      })
      .addCase(getStates.fulfilled, (state, action) => {
        console.log(action.payload.response)
        state.status = "getStates succeeded";
        state.loading = false;
        var response = action.payload.response;
        var list = [];
        if (state.stateParams?.page == 1) {
          list = response.results;
        } else {
          list = [...state.stateslist, ...response.results];
        }
        var noofpages = Math.ceil(
          action.payload.count / state.stateParams?.page_size
        );

        state.stateslist = list;
        state.listCount = response.count;
        state.stateParams = {
          ...state.stateParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getStates.rejected, (state, action) => {
        state.status = "getStates failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(stateAdd.pending, (state, action) => {
        state.status = "stateAdd loading";
        state.loading = true;
      })
      .addCase(stateAdd.fulfilled, (state, action) => {
        state.status = "stateAdd succeeded";
        state.loading = false
        state.model = action.payload.modal;
        state.message = action.payload.response;
        state.rowdata = {};
      }
      )
      .addCase(stateAdd.rejected, (state, action) => {
        state.status = "stateAdd failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(stateDelete.pending, (state, action) => {
        state.status = "stateDelete loading";
        state.loading = true;
      })
      .addCase(stateDelete.fulfilled, (state, action) => {
        state.status = "stateDelete succeeded";
        state.loading = false
      }
      )
      .addCase(stateDelete.rejected, (state, action) => {
        state.status = "stateDelete failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getStateByID.pending, (state, action) => {
        state.status = "getStateByID loading";
        state.loading = true;
      })
      .addCase(getStateByID.fulfilled, (state, action) => {
        state.status = "getStateByID succeeded";
        state.loading = false
        state.model = true;
        state.rowdata = {
          ...action.payload,
        };
      }
      )
      .addCase(getStateByID.rejected, (state, action) => {
        state.status = "getStateByID failed";
        state.loading = false;
        state.error = action.error.message;
      })
  }

});
export const {
  InputChangeValue,
  apiError,
  isModelVisible,
  SetStateParams,
} = stateSlice.actions;

export default stateSlice.reducer;
