import { createSlice } from "@reduxjs/toolkit";
import {
	getDistributorOrderByID,
	getDistributorOrders,
} from "./distributorOrdersaction";

const distributorOrdersSlice = createSlice({
	name: "distributorOrders",
	initialState: {
		loading: false,
		error: "",
		status: "",
		list: [],
		selectedData: {},
		count: 0,
		filterParams: {},
		productsList: [],
		productListparams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
		pageParams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
	},
	reducers: {
		isDrawerVisible: (state, action) => {
			return {
				...state,
				drawer: action.payload,
			};
		},
		FilterInputChangeValue: (state, action) => {
			return {
				...state,
				filterParams: {
					...state.filterParams,
					[action.payload.key]: action.payload.value,
				},
			};
		},
		changeParams: (state, action) => {
			return {
				...state,
				pageParams: action.payload,
			};
		},
		changeProductListParams: (state, action) => {
			return {
				...state,
				productListparams: action.payload,
			};
		},
		changeFilterParams: (state, action) => {
			return {
				...state,
				filterParams: action.payload,
			};
		},

		setSelectedData: (state, action) => {
			return {
				...state,
				selectedData: action.payload,
			};
		},
		inputChangeValue: (state, action) => {
			return {
				...state,
				selectedData: {
					...state.selectedData,
					...action.payload,
				},
			};
		},
		addProductsToList: (state, action) => {
			return {
				...state,
				productsList: [...state.productsList, action.payload],
			};
		},
		removeProductsFromList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => {
					return e.uniqueId == action.payload.product_id
						? {
								...e,
								dodelete: true,
						  }
						: {
								...e,
						  };
				}),
			};
		},
		changeProductList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => {
					if (e.uniqueId == action.payload.product_id) {
						if (action.payload.key == "quantity") {
							const calculatedPrice =
								parseFloat(action.payload.value) *
								parseFloat(e.listedPrice) *
								parseFloat(e.unit.units);
							const discountAmt = e.discount_percent
								? +calculatedPrice * (+e.discount_percent / 100)
								: 0;
							var taxableValue = calculatedPrice - discountAmt;
							// var gstAmt = e.gst_percentage
							// 	? parseFloat(taxableValue) *
							// 	  (+e.gst_percentage / 100)
							// 	: 0;
							// var totalPrice =
							// 	parseFloat(taxableValue) + parseFloat(gstAmt);
							var totalPrice = parseFloat(taxableValue);

							// var amt_before_tax;
							// const tax_type = e?.tax_type;
							// const tax_rate = parseFloat(e.tax_percent) / 100;

							// switch (tax_type) {
							// 	case 1:
							// 		//inclusive
							// 		console.log("inclusive");
							// 		amt_before_tax =
							// 			parseFloat(taxableValue) /
							// 			(1 + tax_rate);
							// 		gstAmt = calculatedPrice - amt_before_tax;
							// 		totalPrice = taxableValue;

							// 		gstAmt = parseFloat(gstAmt).toFixed(2);
							// 		taxableValue =
							// 			parseFloat(amt_before_tax).toFixed(2);
							// 		totalPrice =
							// 			parseFloat(totalPrice).toFixed(2);
							// 		break;
							// 	case 2:
							// 		//exclusive
							// 		// taxableValue = calculatedPrice;
							// 		// tax_amount = taxableValue * tax_rate
							// 		// amt_after_tax = tax_amount + taxableValue;
							// 		// totalPrice = amt_after_tax - obj.discountAmt

							// 		// obj.gstAmt = parseFloat(tax_amount).toFixed(2);
							// 		// obj.taxableValue =
							// 		// 	parseFloat(taxableValue).toFixed(2);
							// 		// obj.price = parseFloat(totalPrice).toFixed(2);
							// 		// obj.gstAmt = parseFloat(tax_amount).toFixed(2);
							// 		break;
							// }
							return {
								...e,
								[action.payload.key]: action.payload.value,
								discountAmt: discountAmt.toFixed(2),
								price: totalPrice.toFixed(2),
								// gstAmt: gstAmt.toFixed(2),
								// taxableValue: taxableValue.toFixed(2),
								originalPrice: calculatedPrice.toFixed(2),
							};
						} else {
							const calculatedPrice =
								parseFloat(e.quantity) *
								parseFloat(e.listedPrice) *
								parseFloat(action.payload.value?.units);
							const discountAmt = e.discount_percent
								? +calculatedPrice * (+e.discount_percent / 100)
								: 0;

							var taxableValue = calculatedPrice - discountAmt;
							// var gstAmt = e.gst_percentage
							// 	? parseFloat(taxableValue) *
							// 	  (+e.gst_percentage / 100)
							// 	: 0;
							// var totalPrice =
							// 	parseFloat(taxableValue) + parseFloat(gstAmt);
							var totalPrice = parseFloat(taxableValue);

							// var amt_before_tax;
							// const tax_type = e?.tax_type;
							// const tax_rate = parseFloat(e.tax_percent) / 100;

							// switch (tax_type) {
							// 	case 1:
							// 		//inclusive
							// 		console.log("inclusive");
							// 		amt_before_tax =
							// 			parseFloat(taxableValue) /
							// 			(1 + tax_rate);
							// 		gstAmt = calculatedPrice - amt_before_tax;
							// 		totalPrice = taxableValue;
							// 		gstAmt = parseFloat(gstAmt).toFixed(2);
							// 		taxableValue =
							// 			parseFloat(amt_before_tax).toFixed(2);
							// 		totalPrice =
							// 			parseFloat(totalPrice).toFixed(2);
							// 		break;
							// 	case 2:
							// 		//exclusive
							// 		// taxableValue = calculatedPrice;
							// 		// tax_amount = taxableValue * tax_rate
							// 		// amt_after_tax = tax_amount + taxableValue;
							// 		// totalPrice = amt_after_tax - obj.discountAmt

							// 		// obj.gstAmt = parseFloat(tax_amount).toFixed(2);
							// 		// obj.taxableValue =
							// 		// 	parseFloat(taxableValue).toFixed(2);
							// 		// obj.price = parseFloat(totalPrice).toFixed(2);
							// 		// obj.gstAmt = parseFloat(tax_amount).toFixed(2);
							// 		break;
							// }
							return {
								...e,
								[action.payload.key]: action.payload.value,
								discountAmt: discountAmt.toFixed(2),
								price: totalPrice,
								// gstAmt: gstAmt.toFixed(2),
								// taxableValue: taxableValue.toFixed(2),
								originalPrice: calculatedPrice.toFixed(2),
							};
						}
					} else {
						return e;
					}
				}),
			};
		},
		clearData: (state, action) => {
			return {
				...state,
				loading: false,
				error: "",
				status: "",
				list: [],
				selectedData: {},
				count: 0,
				productsList: [],
				productListparams: {
					start_date: "",
					end_date: "",
					no_of_pages: 0,
					page_size: 10,
					page: 1,
					search: "",
				},
			};
		},
	},
	extraReducers: (builder) => {
		builder
			//get Data
			.addCase(getDistributorOrders.pending, (state, action) => {
				state.status = "getDistributorOrders loading";
				state.loading = true;
			})
			.addCase(getDistributorOrders.fulfilled, (state, action) => {
				state.status = "getDistributorOrders succeeded";
				state.loading = false;
				var noofpages = Math.ceil(
					action.payload.count / state.pageParams.page_size
				);
				state.count = action.payload.count;
				state.list = action.payload.results;
				state.selectedData = {};
			})
			.addCase(getDistributorOrders.rejected, (state, action) => {
				state.status = "getDistributorOrders failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//get Data by Id
			.addCase(getDistributorOrderByID.pending, (state, action) => {
				state.status = "getDistributorOrderByID loading";
				state.loading = true;
			})
			.addCase(getDistributorOrderByID.fulfilled, (state, action) => {
				state.status = "getDistributorOrderByID succeeded";
				state.loading = false;
				state.selectedData = {
					...action.payload,
					salesagent_id: action.payload.sales_agent?.id,
					retailer_id: action.payload.distributor?.id,
					location_id: action.payload.distributor?.location?.id,
					location_name: action.payload.distributor?.location?.name,
					billing_address_id:
						action.payload?.distributor?.default_billing_address
							?.id,
					shipping_address_id:
						action.payload?.distributor?.default_shipping_address
							?.id,
					default_billing_address:
						action.payload?.distributor?.default_billing_address,
					default_shipping_address:
						action.payload?.distributor?.default_shipping_address,
				};

				state.productsList = action.payload?.order_items?.map((e) => {
					const calculatedPrice =
						parseFloat(e.quantity) *
						parseFloat(e.d_price) *
						parseFloat(e?.unit?.units);
					const discountAmt =
						parseFloat(calculatedPrice) *
						(+e.d_discount_percent / 100);
					var taxableValue = calculatedPrice - discountAmt;
					// var gstAmt =
					// 	parseFloat(taxableValue) * (+e.d_tax_percent / 100);
					// var totalPrice =
					// 	parseFloat(taxableValue) + parseFloat(gstAmt);
					var totalPrice = parseFloat(taxableValue);
					// const taxableValue = +e?.discount_percent
					// 	? calculatedPrice /
					// 	  (1 + parseFloat(e?.discount_percent))
					// 	: calculatedPrice;

					let freeItem = e?.free_status == 2 ? true : false;

					const obj = {
						...e,
						item_id: e.id,
						product_id: e.product.id,
						image: e.product.image,
						name: e.product.name,
						listedPrice: parseFloat(e.d_price).toFixed(2),
						originalPrice: parseFloat(calculatedPrice).toFixed(2),
						discountAmt: parseFloat(discountAmt).toFixed(2),
						// gstAmt: parseFloat(gstAmt).toFixed(2),
						// taxableValue: parseFloat(taxableValue).toFixed(2),
						price: parseFloat(totalPrice).toFixed(2),
						size: e?.unit?.id,
						// gst_percentage: e?.d_tax_percent,
						discount_percent: e?.d_discount_percent,
						quantity: e?.quantity,
						received_qty: e?.received_qty ? e?.received_qty : "",
						unit: e?.unit,
						uniqueId: e.id,
						accepted_units: e?.accepted_units,
						freeItem: freeItem,
						dodelete: false,
						mrp: parseInt(e?.mrp)
							? parseFloat(e?.mrp).toFixed(2)
							: 0,
						showCancelIcon: true,
					};

					if (freeItem) {
						obj.uniqueId = e?.applied_by?.id;
						obj.showCancelIcon = false;
					}
					// var amt_before_tax;
					// const tax_type = e?.tax_type;
					// const tax_rate = parseFloat(e.d_tax_percent) / 100;

					// switch (tax_type) {
					// 	case 1:
					// 		//inclusive
					// 		console.log("inclusive");
					// 		amt_before_tax =
					// 			parseFloat(taxableValue) / (1 + tax_rate);
					// 		gstAmt = calculatedPrice - amt_before_tax;
					// 		totalPrice = taxableValue;

					// 		obj.gstAmt = parseFloat(gstAmt).toFixed(2);
					// 		obj.taxableValue =
					// 			parseFloat(amt_before_tax).toFixed(2);
					// 		obj.price = parseFloat(totalPrice).toFixed(2);
					// 		break;
					// 	case 2:
					// 		//exclusive
					// 		// taxableValue = calculatedPrice;
					// 		// tax_amount = taxableValue * tax_rate
					// 		// amt_after_tax = tax_amount + taxableValue;
					// 		// totalPrice = amt_after_tax - obj.discountAmt

					// 		// obj.gstAmt = parseFloat(tax_amount).toFixed(2);
					// 		// obj.taxableValue =
					// 		// 	parseFloat(taxableValue).toFixed(2);
					// 		// obj.price = parseFloat(totalPrice).toFixed(2);
					// 		// obj.gstAmt = parseFloat(tax_amount).toFixed(2);
					// 		break;
					// }

					return { ...obj };
				});
			})
			.addCase(getDistributorOrderByID.rejected, (state, action) => {
				state.status = "getDistributorOrderByID failed";
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

// Action creators are generated for each case reducer function
export const {
	changeParams,
	changeProductListParams,
	clearData,
	setSelectedData,
	inputChangeValue,
	addProductsToList,
	removeProductsFromList,
	changeProductList,
	isDrawerVisible,
	FilterInputChangeValue,
	changeFilterParams,
} = distributorOrdersSlice.actions;

export default distributorOrdersSlice.reducer;
