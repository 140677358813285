import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    postAdd,
    getParamsList,
    postEdit,
    postDelete,
    getList,
    patchImg,
    putImg,
    postFormData,
    addParams,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
    RiCheckboxCircleLine,
    RiAlertLine,
    RiInformationLine,
} from "react-icons/ri";
import { serialize } from "object-to-formdata";
import { BaseURL, TOKEN_PREFIX } from "../../../helpers/AxiosHelper";

export const getProducts = createAsyncThunk(
    "/getProducts",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/masters/products/", params);
            if (response) {
                return { response };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);
export const productDelete = createAsyncThunk(
    "/productDelete",
    async (payload) => async (dispatch) => {
        console.log(dispatch)
        var productID = payload;
        try {
            const response = await postDelete("/masters/product/detail/" + productID + "/");
            if (response.status === 204) {
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Product has been deleted Successfully!
                            </p>
                        </div>
                    ),
                });
                return { response };
            } else {
                Modal.error({
                    icon: (
                        <span className="remix-icon">
                            <RiAlertLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Sorry!   Product has been not deleted!
                            </p>
                        </div>
                    ),
                });
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            throw error.message;
        }
    }
);
export const productAdd = createAsyncThunk(
    "/productAdd",
    async (payload) => {

        try {
            if (payload.id == 0) {
                const response = await postAdd("/masters/product/", payload);
                if (response.status === 201) {
                    Modal.success({
                        icon: (
                            <span className="remix-icon">
                                <RiCheckboxCircleLine />
                            </span>
                        ),
                        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                        content: (
                            <div>
                                <p className="hp-p1-body hp-text-color-black-80">
                                    Product Added Successfully.!
                                </p>
                            </div>
                        ),
                    });
                    return { response, modal: false };
                }
                else {
                    Modal.error({
                        icon: (
                            <span className="remix-icon">
                                <RiAlertLine />
                            </span>
                        ),
                        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                        content: (
                            <div>
                                <p className="hp-p1-body hp-text-color-black-80">
                                    Sorry! Unable to add Product. Please try again!
                                </p>
                            </div>
                        ),
                    });
                    throw new Error(response);
                }
            } else {
                const { stateToUpdate, dispatch, getProducts, productParams, clearuploadImage } = payload
                const formData = serialize(stateToUpdate);
                const response = await postEdit("/masters/productimage/" + payload.id, formData);
                if (response.status === 200) {
                    Modal.success({
                        icon: (
                            <span className="remix-icon">
                                <RiCheckboxCircleLine />
                            </span>
                        ),
                        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                        content: (
                            <div>
                                <p className="hp-p1-body hp-text-color-black-80">
                                    Product Edited Successfully.!
                                </p>
                            </div>
                        ),
                    });

                    dispatch(clearuploadImage());
                    dispatch(getProducts({ ...productParams }));
                    return { response, modal: false }
                } else {
                    Modal.error({
                        icon: (
                            <span className="remix-icon">
                                <RiAlertLine />
                            </span>
                        ),
                        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                        content: (
                            <div>
                                <p className="hp-p1-body hp-text-color-black-80">
                                    Sorry! Unable to Product. Please try again!
                                </p>
                            </div>
                        ),
                    });
                }
                throw new Error(response);

            }

        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            throw error.message;
        }
    }
);

export const getProductByID = createAsyncThunk(
    "/getProductByID",
    async (payload) => {
        try {
            const response = await getList("/masters/product/detail/" + payload + "/", {});
            return response.data;
        } catch (error) {
            throw error.message;
        }
    }
);

export const getProductsByRetailerID = createAsyncThunk(
    "/getProductsByRetailerID",
    async (payload) => {
        const params = addParams(payload);
        try {
            const response = await getParamsList(
                `/masters/products/retailer/${params.id}/`,
                params
            );
            if (response) {
                return { response, params };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            return error.message;
        }
    }
);
export const getProductsByDistributorID = createAsyncThunk(
    "/getProductsByDistributorID",
    async (payload) => {
        const params = addParams(payload);
        try {
            const response = await getParamsList(
                `/masters/products/distributor/${params.id}/`,
                params
            );
            if (response) {
                return { response, params };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            return error.message;
        }
    }
);
export const getProductsByCustomerID = createAsyncThunk(
    "/getProductsByCustomerID",
    async (payload) => {
        const params = addParams(payload);
        try {
            const response = await getParamsList(
                `/masters/customer_products/customer/${params.id}/`,
                params
            );
            if (response) {
                return { response, params };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            return error.message;
        }
    }
);
export const getProductsByRetailerIDAndProductId = createAsyncThunk(
    "/getProductsByRetailerIDAndProductId",
    async (payload) => {
        const params = addParams(payload);
        try {
            const response = await getParamsList(
                `/masters/retailerproduct/${params.retailer_id}/${params.product_id}`,
                params
            );
            if (response) {

                return response;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            return error.message;
        }
    }
);
export const getProductsByCustomerIDAndProductId = createAsyncThunk(
    "/getProductsByCustomerIDAndProductId",
    async (payload) => {
        const params = addParams(payload);
        try {
            const response = await getParamsList(
                `/masters/customerproduct/${params.customer_id}/${params.product_id}`,
                params
            );
            if (response) {
                return response;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            return error.message;
        }
    }
);
export const getProductsByDistributorIDAndProductId = createAsyncThunk(
    "/getProductsByDistributorIDAndProductId",
    async (payload) => {
        const params = addParams(payload);
        try {
            const response = await getParamsList(
                `/masters/distributorproduct/${params.distributor_id}/${params.product_id}`,
                params
            );
            if (response) {

                return response;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            return error.message;
        }
    }
);