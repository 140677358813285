import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
	addParams,
	getList,
	getParamsList,
	postAdd,
	postEdit,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
	RiCheckboxCircleLine,
	RiAlertLine,
	RiInformationLine,
} from "react-icons/ri";
import Swal from "sweetalert2";

export const getDistributorIndents = createAsyncThunk(
	"/getDistributorIndents",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/orders/distributor/indentslist/`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getDistributorIndentByID = createAsyncThunk(
	"/getDistributorIndentByID",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getList(
				`/orders/distributor/indentsdetail/${params.id}`
			);
			if (response) {
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const approveDistributorIndentByID = createAsyncThunk(
	"/approveDistributorIndentByID",
	async (payload, { dispatch }) => {
		try {
			const response = await postEdit(
				`/orders/distributorindentapproval/${payload.id}`,
				payload
			);
			if (response.data) {
				Swal.fire({
					title: "Success",
					text:
						response?.data?.indent_status == 2
							? "Indent Approved"
							: "Indent Rejected",
					icon: "success",
					confirmButtonColor: "#3085d6",
					// confirmButtonText: "Cool",
				});
				dispatch(getDistributorIndentByID({ id: payload.id }));
				return {
					response: response.data,
					indent_status: payload?.indent_status,
				};
			} else {
				throw new Error(response);
			}
		} catch (error) {
			Swal.fire({
				title: "Error",
				text:
					payload?.indent_status == 2
						? "Error While Approving Indent"
						: "Error While Rejecting Indent",
				icon: "error",
				confirmButtonColor: "#3085d6",
				// confirmButtonText: "Cool",
			});
			return error.message;
		}
	}
);
const delay = (ms) =>
	new Promise((resolve) => {
		setTimeout(() => {
			resolve();
		}, ms);
	});

async function checkStatus(payload) {
	const { dispatch } = payload;
	let timeout;
	timeout = await delay(1000);
	dispatch(distributorIndentProcessingStatus({}));
}
export const distributorIndentProcessingStatus = createAsyncThunk(
	"/distributorIndentProcessingStatus",
	async (payload, { dispatch }) => {
		try {
			const response = await getParamsList(
				`/orders/distributor_indent_process/status/`,
				payload
			);
			console.log(response);
			if (!response.status) {
				Swal.fire({
					title: "Success",
					text: "Process Completed",
					icon: "success",
					confirmButtonColor: "#3085d6",
					// confirmButtonText: "Cool",
				});
				return response;
			} else {
				checkStatus({ dispatch });
			}
		} catch (error) {
			Swal.fire({
				title: "Error",
				text: "Error while processing",
				icon: "error",
				confirmButtonColor: "#3085d6",
				// confirmButtonText: "Cool",
			});
			return error.message;
		}
	}
);
export const distributorIndentProcessing = createAsyncThunk(
	"/distributorIndentProcessing",
	async (payload, { dispatch }) => {
		const { product_type_id, approve_status } = payload;
		try {
			const response = await getParamsList(
				`/orders/distributor_indent_process/${approve_status}/`,
				payload
			);
			if (response) {
				checkStatus({ ...payload, dispatch });
				// dispatch(distributorIndentProcessingStatus())
			} else {
				throw new Error(response);
			}
		} catch (error) {
			Swal.fire({
				title: "Error",
				text: "Error while processing",
				icon: "error",
				confirmButtonColor: "#3085d6",
				// confirmButtonText: "Cool",
			});
			return error.message;
		}
	}
);
