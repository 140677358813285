import { Selector, Dispatch } from "../../../store/index";
import TableComponent from "../../components/components-page/TableComponent";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { customSubscriParams } from "../../../store/dashboard/dashboardSlice";
import { getDashboardSubscription } from "../../../store/dashboard/dashboardAction";
import demoImage from "../../../assets/images/demoimage.png"

import { Row, Col, Card, Spin, Space } from "antd";

const SubscriptionCard = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const subscriParams = Selector("dashboard", "subscriParams");
	const filterParamsData = Selector("dashboard", "filterParams");
	const loading = Selector("dashboard", "subLoading");
	const count = Selector("dashboard", "subCount");
	const subscriptionList = Selector("dashboard", "subscriptionList");

	const columns = [
		{
			title: <b>S.No</b>,
			dataIndex: "index",
			key: "index",
			// fixed: "left",
		},
		{
			title: <b>Customer ID</b>,
			render: (text) => (
				<span>{!text.customer_code ? "N/A" : text.customer_code}</span>
			),
			key: "code",
			// fixed: "left",
		},
		{
			title: <b>Customer</b>,
			render: (text) => (
				<span>{!text.customer_name ? "N/A" : text.customer_name}</span>
			),
			key: "code",
			// fixed: "left",
		},
		{
			title: <b>Subscription ID</b>,
			dataIndex: "code",
			key: "code",
			// fixed: "left",
		},
		{
			title: <b>Product Image</b>,
			render: (row) => (
				<Col>
					<img
						onError={(e) => {
							e.target.src = demoImage
						}}
						width={100}
						alt={row?.product?.name}
						src={row?.product?.image ? row?.product?.image : demoImage}
					// src={row?.product?.image}
					/>
				</Col>
			)
		},

		{
			title: <b>Product Details</b>,
			render: (row) => (
				<Row>
					<Col>
						<div>Product name: {row?.product?.name}</div>
						{/* <div>Free Quantity: {row?.free_quantity}</div> */}
						<div>unit: {row?.
							unit
							?.name}</div>
						<div>Quantity: {row?.quantity}</div>
					</Col>

				</Row>
			),
			key: "code",
			// fixed: "left",
		},



		{
			title: <b>Start Date</b>,
			render: (text) => (
				<span>
					{!text.start_date ? "N/A" : text.start_date}
				</span>
			),
			key: "date",
			// fixed: "left",
		},
		{
			title: <b>End Date</b>,
			render: (text) => (
				<span>
					{!text.end_date ? "N/A" : text.end_date}
				</span>
			),
			key: "date",
			// fixed: "left",
		},
		{
			title: <b>Free Days</b>,
			render: (text) => (
				<span>
					{!text.freedays ? "0" : text.freedays}
				</span>
			),
			key: "date",
			// fixed: "left",
		},
		{
			title: <b>Paid Days</b>,
			render: (text) => (
				<span>
					{!text.paiddays ? "0" : text.paiddays}
				</span>
			),
			key: "date",
			// fixed: "left",
		},
		{
			title: <b>Extended End Date</b>,
			render: (text) => (
				<span>
					{!text.vacation_end_date ? "0" : text.vacation_end_date}
				</span>
			),
			key: "date",
			// fixed: "left",
		},
		{
			title: <b>Date</b>,
			render: (text) => (
				<span>
					{!text.date ? "N/A" : text.date}
				</span>
			),
			key: "date",
			// fixed: "left",
		},
		{
			title: "Actions",
			key: "action",
			sorter: false,
			width: 100,
			render: (row) => (
				<Space size="middle">
					<Link to={`/subscription/view/` + row.id}>
						<i
							className="ri-eye-line hp-mr-2 "
							style={{ cursor: "pointer", color: "#fc6f03" }}></i>
					</Link>
				</Space>
			),
		},
	];

	const data = subscriptionList?.map((row, key) => ({
		...row,
		index: (subscriParams.page - 1) * subscriParams.page_size + (key + 1),
	}));


	function onPageChange(page, page_size) {
		Dispatch(dispatch, getDashboardSubscription, {
			...subscriParams,
			...filterParamsData,
			page: page,
			page_size: page_size,
		});
		Dispatch(dispatch, customSubscriParams, {
			...subscriParams,
			page: page,
			page_size: page_size,
		});
	}

	const customSubscriParams = (params) => {
		Dispatch(dispatch, getDashboardSubscription, { ...params, ...filterParamsData, customer: filterParamsData?.customer?.id });
		Dispatch(dispatch, customSubscriParams, { ...params });
	};

	function showTotal(total) {
		return "Total " + total + " items";
	}

	return (
		<Row gutter={[12, 0]}>
			<Col span={24} xs={24}>
				<Spin tip="Loading..." spinning={loading} delay={500}>
					<TableComponent
						page={subscriParams.page}
						count={count}
						pageSize={subscriParams.page_size}
						columns={columns}
						data={data}
						// loading={loading}
						onPageChange={onPageChange}
						showTotal={showTotal}
						scroll={false}
					/>
				</Spin>
			</Col>
		</Row>
	);
};

export default SubscriptionCard;
