import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
	addParams,
	getList,
	getParamsList,
	postAdd,
	postEdit,
} from "../../helpers/Helper";
import { Modal } from "antd";
import {
	RiCheckboxCircleLine,
	RiAlertLine,
	RiInformationLine,
} from "react-icons/ri";


// B2C dashboard
export const getCustomerOrdersCount = createAsyncThunk(
	"/getCustomerOrdersCount",
	async (payload, { dispatch }) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/b2c/admindashboard/customerorders/count`,
				params
			);
			if (response) {
				const revenueCount = await dispatch(getRevenueCount(payload)).unwrap()
				return { response, revenueCount };

			} else {
				throw new Error(response);
			}
		} catch (error) {
			const revenueCount = await dispatch(getRevenueCount(payload)).unwrap()
			return error.message, { revenueCount };
			//  revenueCount;
		}
	}
);


export const getRevenueCount = createAsyncThunk(
	"/getRevenueCount",
	async (payload, { dispatch }) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/b2c/admindashboard/revenues/count`,
				params
			);
			if (response) {
				const cashCollection = await dispatch(getCashCollectionCount(payload)).unwrap()
				return { response, cashCollection };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			const cashCollection = await dispatch(getCashCollectionCount(payload)).unwrap()
			return error.message, { cashCollection };
		}
	}
);

export const getCashCollectionCount = createAsyncThunk(
	"/getCashCollectionCount",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/b2c/admindashboard/cashcollection_requests/count`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);


export const getMontlyOrderReport = createAsyncThunk(
	"/getMontlyOrderReport",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/b2c/admindashboard/monthlyorderreport/`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);


export const getDashboardCustmOrders = createAsyncThunk(
	"/getDashboardCustmOrders",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/b2c/admindashboard/customerorders/list/`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

export const getDashboardSubscription = createAsyncThunk(
	"/getDashboardSubscription",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/b2c/admindashboard/subscriptions/list/`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

// B2B Dashboard



// get B2B dashboard Retailer Indents count
export const getB2BRetailerIndents = createAsyncThunk(
	"/getB2BRetailerIndents",
	async (payload, { dispatch }) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/orders/admindashboard/retailer/indents/count`,
				params
			);
			if (response) {
				const distributorIndents = await dispatch(getB2BDistributorIndents(payload)).unwrap()
				return { response, distributorIndents };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			const distributorIndents = await dispatch(getB2BDistributorIndents(payload)).unwrap()
			return error.message, { distributorIndents };
		}
	}
);

// get B2B dashboard total Distributor Indents count
export const getB2BDistributorIndents = createAsyncThunk(
	"/getB2BDistributorIndents",
	async (payload, { dispatch }) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/orders/admindashboard/distributor/indents/count`,
				params
			);
			if (response) {
				const distributorReceipts = await dispatch(getB2BDistributorReceipts(payload)).unwrap()
				return { response, distributorReceipts };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			const distributorReceipts = await dispatch(getB2BDistributorReceipts(payload)).unwrap()
			return error.message, { distributorReceipts };
		}
	}
);

// get B2B dashboard total Distributor Receipts count
export const getB2BDistributorReceipts = createAsyncThunk(
	"/getB2BDistributorReceipts",
	async (payload, { dispatch }) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/orders/admindashboard/distributor/receipts/count`,
				params
			);
			if (response) {
				const revenueCount = await dispatch(getB2BRevenueCount(payload)).unwrap()
				return { response, revenueCount };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			const revenueCount = await dispatch(getB2BRevenueCount(payload)).unwrap()
			return error.message, { revenueCount };
		}
	}
);

// get B2B dashboard total revenue count
export const getB2BRevenueCount = createAsyncThunk(
	"/getB2CRevenueCount",
	async (payload, { dispatch }) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/orders/admindashboard/ss/orderrevenues/count`,
				params
			);
			if (response) {
				const distributorOrders = await dispatch(getB2BDistributorOrders(payload)).unwrap()
				return { response, distributorOrders };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			const distributorOrders = await dispatch(getB2BDistributorOrders(payload)).unwrap()
			return error.message, { distributorOrders };
		}
	}
);

// get B2B dashboard total Distributor Orders count
export const getB2BDistributorOrders = createAsyncThunk(
	"/getB2BDistributorOrders",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/orders/admindashboard/distributor/orders/count`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

// get B2B dashboard Distributor Order List
export const getDistributorOrdersList = createAsyncThunk(
	"/getDistributorOrdersList",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/orders/admindashboard/distributor/orders/list/`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);