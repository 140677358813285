import { createSlice } from "@reduxjs/toolkit";
import {
	editDemandSheet,
	getDemandSheetByID,
	getDemandSheet,
	postDemandSheet,
	getDemandSheetItemsBySuperStockist,
	demandSheetExport,
	getDemandSheetItemsByRoute,
	getSchemeByDistributorIDAndProductId,
	getPriceByDistributorIDAndProductId,
	getDemandSheetProductSchemes,
} from "./demandsheet.action";
import { Input } from "antd";
import { v4 as uuidv4 } from "uuid";

const calculateTotal = (demandsheetsList, key) =>
	demandsheetsList
		.filter((item) => item.key !== "total")
		.reduce((acc, item) => acc + parseFloat(item[key] || 0), 0);

const calculateDistributorTotal = (item) => {
	const total_row_price = parseFloat(
		Object.entries(item)
			.filter(([key]) => key.includes("-price-"))
			.reduce((acc, [key, value]) => acc + parseFloat(value || 0), 0)
	).toFixed(2);

	console.log(total_row_price);

	return parseFloat(total_row_price).toFixed(2);
};

const updateDistributor = (item, payload) => {
	console.log(payload);
	const priceKey = payload.pricekey;
	return {
		...item,
		[payload.key]: payload.value,
		[priceKey]: payload.price, // Ensure priceKey is correctly set
		d_total: calculateDistributorTotal({
			...item,
			[payload.key]: payload.value,
			[priceKey]: payload.price,
		}),
	};
};
const updateTotal = (demandsheetsList, payload) => ({
	...demandsheetsList.find((item) => item.key === "total"),
	[payload.key]:
		calculateTotal(demandsheetsList, payload.key) * payload.base_units,
	d_total: demandsheetsList
		.filter((item) => item.key !== "total")
		.reduce((acc, item) => acc + parseFloat(item.d_total || 0), 0)
		.toFixed(2),
});

const demandsheetSlice = createSlice({
	name: "demandsheet",
	initialState: {
		loading: false,
		error: "",
		status: "",
		list: [],
		selectedData: {
			new_product_type: {},
		},
		distributors: [],
		products: [],
		quantities: [],
		demandsheetsList: [],
		count: 0,
		drawer: false,
		openAddressModal: false,
		openAddAddressModal: false,
		filterParams: {},
		productsList: [],
		free_quantities: [],
		productData: {},
		unitsLoading: false,
		productListparams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
		pageParams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
	},
	reducers: {
		isDrawerVisible: (state, action) => {
			return {
				...state,
				drawer: action.payload,
			};
		},
		// changeDemandSheetList: (state, action) => {
		// 	const calculateTotal = (demandsheetsList) => {
		// 		return demandsheetsList
		// 			.filter((item) => item.key !== "total")
		// 			.reduce(
		// 				(acc, item) =>
		// 					acc + parseFloat(item[action.payload.key] || 0),
		// 				0
		// 			);
		// 	};

		// 	const updatedDemandsheetsList = state.demandsheetsList.map(
		// 		(item) => {
		// 			if (item.key === action.payload.distributor_id) {
		// 				// Update the specific distributor's data
		// 				const updatedItem = {
		// 					...item,
		// 					[action.payload.key]: action.payload.value,
		// 				};
		// 				updatedItem.d_total = parseFloat(
		// 					Object.keys(updatedItem)
		// 						.filter((key) => key.includes("-qty-"))
		// 						.reduce(
		// 							(acc, key) =>
		// 								acc +
		// 								parseFloat(updatedItem[key] || 0) *
		// 									updatedItem.total_row_price,
		// 							0
		// 						)
		// 				).toFixed(2);
		// 				return updatedItem;
		// 			}
		// 			return item;
		// 		}
		// 	);

		// 	const updatedProductTotalDemandSheetsList =
		// 		updatedDemandsheetsList.map((item) => {
		// 			if (item.key == "total") {
		// 				return {
		// 					...item,
		// 					[action.payload.key]:
		// 						calculateTotal(updatedDemandsheetsList) *
		// 						action.payload.base_units,
		// 					d_total: parseFloat(
		// 						updatedDemandsheetsList.reduce((acc, value) => {
		// 							if (value.key != "total") {
		// 								console.log(value);
		// 								return acc + parseFloat(value.d_total);
		// 							}
		// 							return acc;
		// 						}, 0)
		// 					).toFixed(2),
		// 				};
		// 			}
		// 			return item;
		// 		});

		// 	return {
		// 		...state,
		// 		demandsheetsList: updatedProductTotalDemandSheetsList,
		// 	};
		// },

		changeDemandSheetList: (state, action) => {
			const updatedDemandsheetsList = state.demandsheetsList.map((item) =>
				item.key === action.payload.distributor_id
					? updateDistributor(item, action.payload)
					: item
			);

			const updatedProductTotalDemandSheetsList =
				updatedDemandsheetsList.map((item) =>
					item.key === "total"
						? updateTotal(updatedDemandsheetsList, action.payload)
						: item
				);

			return {
				...state,
				demandsheetsList: updatedProductTotalDemandSheetsList,
			};
		},
		changeDemandSheetFreeQtyList: (state, action) => {
			const updatedDemandsheetsList = state.demandsheetsList.map((item) =>
				item.key === action.payload.distributor_id
					? {
							...item,
							[action.payload.key]: action.payload.free_quantity,
					  }
					: item
			);

			return {
				...state,
				demandsheetsList: updatedDemandsheetsList,
			};
		},
		setProducts: (state, action) => {
			return {
				...state,
				products: action.payload,
			};
		},
		setFreeQuantities: (state, action) => {
			return {
				...state,
				free_quantities: action.payload,
			};
		},
		changeDemandSheetForAnotherFreeProduct: (state, action) => {
			const { freeObj, newProducts } = action.payload;

			console.log(newProducts);
			return {
				...state,
				products: newProducts,
				demandsheetsList: freeObj
					? state.demandsheetsList.map((ds) => {
							if (ds.key == freeObj.key) {
								return {
									...ds,
									...freeObj,
								};
							}
							return ds;
					  })
					: state.demandsheetsList,
			};
		},
		removeFreeItem: (state, action) => {
			const { product_id, distributor_id } = action.payload;

			// Update the demandsheetsList
			const tempdemandsheetsList = state.demandsheetsList.map((ds) => {
				if (ds.key === distributor_id) {
					// Find the key that contains the `-free_item-` for the given product_id
					const freeItemKey = Object.keys(ds).find(
						(key) =>
							ds[key] === product_id &&
							key.includes("-free_item-")
					);

					if (freeItemKey) {
						// Extract the key prefix before `-free_item-`
						const keyPrefix = freeItemKey.split("-free_item-")[0];

						// Filter out all keys that start with the extracted keyPrefix
						const newDs = Object.keys(ds)
							.filter((key) => !key.startsWith(keyPrefix)) // Keep all keys that do not start with the keyPrefix
							.reduce((acc, key) => {
								acc[key] = ds[key]; // Build the new distributor object
								return acc;
							}, {});

						return newDs; // Return the updated distributor object
					}
				}

				return ds; // If distributor_id doesn't match, return as is
			});

			return {
				...state,
				demandsheetsList: tempdemandsheetsList, // Set the updated list to the state
			};
		},

		changeParams: (state, action) => {
			return {
				...state,
				pageParams: action.payload,
			};
		},
		changeFilterParams: (state, action) => {
			return {
				...state,
				filterParams: action.payload,
			};
		},
		FilterInputChangeValue: (state, action) => {
			return {
				...state,
				filterParams: {
					...state.filterParams,
					[action.payload.key]: action.payload.value,
				},
			};
		},

		setSelectedData: (state, action) => {
			return {
				...state,
				selectedData: action.payload,
			};
		},
		inputChangeValue: (state, action) => {
			return {
				...state,
				selectedData: {
					...state.selectedData,
					...action.payload,
				},
			};
		},
		clearData: (state, action) => {
			return {
				...state,
				loading: false,
				error: "",
				status: "",
				list: [],
				selectedData: {},
				count: 0,
				openAddressModal: false,
				openAddAddressModal: false,
				productsList: [],
				productListparams: {
					start_date: "",
					end_date: "",
					no_of_pages: 0,
					page_size: 10,
					page: 1,
					search: "",
				},
			};
		},
	},
	extraReducers: (builder) => {
		builder
			//get Data
			.addCase(getDemandSheet.pending, (state, action) => {
				state.status = "getDemandSheet loading";
				state.loading = true;
			})
			.addCase(getDemandSheet.fulfilled, (state, action) => {
				state.status = "getDemandSheet succeeded";
				state.loading = false;
				var noofpages = Math.ceil(
					action.payload.count / state.pageParams.page_size
				);
				state.count = action.payload.count;
				state.list = action.payload.results;
				state.pageParams = {
					...state.pageParams,
					no_of_pages: noofpages,
				};
			})
			.addCase(getDemandSheet.rejected, (state, action) => {
				state.status = "getDemandSheet failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//get Data by superstockist id
			.addCase(
				getDemandSheetItemsBySuperStockist.pending,
				(state, action) => {
					state.status = "getDemandSheetItemsBySuperStockist loading";
					state.loading = true;
				}
			)
			.addCase(
				getDemandSheetItemsBySuperStockist.fulfilled,
				(state, action) => {
					state.status =
						"getDemandSheetItemsBySuperStockist succeeded";
					state.loading = false;

					// Extract payload safely
					const {
						distributors = [],
						products = [],
						quantities = [],
					} = action.payload || {};

					// Add UUID to each product
					const processedProducts = products.map((product) => ({
						...product,
						uuid: uuidv4(),
					}));

					// Create a map for fast product lookup
					const productMap = new Map(
						processedProducts.map((product) => [
							product.id,
							product,
						])
					);

					// Prepare an object to aggregate quantities per distributor and product
					const quantityAggregation = {};

					quantities.forEach((quantity) => {
						const {
							distributor_id,
							product_id,
							quantity: qty,
							unit_name,
							unit_id,
							distributor_indent_items_ids,
						} = quantity;

						if (!quantityAggregation[distributor_id]) {
							quantityAggregation[distributor_id] = {};
						}

						if (!quantityAggregation[distributor_id][product_id]) {
							quantityAggregation[distributor_id][product_id] =
								[];
						}

						quantityAggregation[distributor_id][product_id].push({
							quantity: parseFloat(qty),
							unit_name,
							unit_id,
							distributor_indent_items_ids,
						});
					});

					// Generate row data for each distributor
					const tempData = distributors.map((distributor) => {
						const rowData = {
							key: distributor.id,
							fullname: distributor.fullname,
						};

						const productsForDistributor =
							quantityAggregation[distributor.id] || {};

						Object.keys(productsForDistributor).forEach(
							(product_id) => {
								const product = productMap.get(product_id);
								if (product) {
									const quantitiesForProduct =
										productsForDistributor[product_id];

									quantitiesForProduct.forEach(
										(quantity, index) => {
											rowData[
												`${product.id}-qty-${index}`
											] = quantity.quantity.toFixed(2);
											rowData[
												`${product.id}-size-${index}`
											] = quantity.unit_name;
											rowData[
												`${product.id}-unit_id-${index}`
											] = quantity.unit_id;
											rowData[
												`${product.id}-distributor_indent_items_ids-${index}`
											] =
												quantity.distributor_indent_items_ids;
										}
									);
								}
							}
						);

						return rowData;
					});

					// Update state with processed data
					state.demandsheetsList = tempData;
					state.distributors = distributors;
					state.products = processedProducts;
					state.quantities = quantities;
				}
			)
			.addCase(
				getDemandSheetItemsBySuperStockist.rejected,
				(state, action) => {
					state.status = "getDemandSheetItemsBySuperStockist failed";
					state.loading = false;
					state.error = action.error.message;
				}
			)
			//get Data by route id
			.addCase(getDemandSheetItemsByRoute.pending, (state, action) => {
				state.status = "getDemandSheetItemsByRoute loading";
				state.loading = true;
			})
			.addCase(getDemandSheetItemsByRoute.fulfilled, (state, action) => {
				state.status = "getDemandSheetItemsByRoute succeeded";
				state.loading = false;

				// Extract payload safely
				const {
					distributors = [],
					products = [],
					quantities = [],
					free_quantities = [],
				} = action.payload || {};

				// // Add UUID to each product
				// const processedProducts = products.map((product) => ({
				// 	...product,
				// 	uuid: uuidv4(),
				// }));

				// // Create a map for fast product lookup
				// const productMap = new Map(
				// 	processedProducts.map((product) => [product.id, product])
				// );

				// // Prepare an object to aggregate quantities per distributor and product
				// const quantityAggregation = {};

				// quantities.forEach((quantity) => {
				// 	const {
				// 		distributor_id,
				// 		product_id,
				// 		quantity: qty,
				// 		unit_name,
				// 		unit_id,
				// 		distributor_indent_items_ids,
				// 	} = quantity;

				// 	if (!quantityAggregation[distributor_id]) {
				// 		quantityAggregation[distributor_id] = {};
				// 	}

				// 	if (!quantityAggregation[distributor_id][product_id]) {
				// 		quantityAggregation[distributor_id][product_id] = [];
				// 	}

				// 	quantityAggregation[distributor_id][product_id].push({
				// 		...quantity,
				// 		quantity: parseFloat(qty),
				// 	});
				// });

				// const sumByProduct = {};
				// const sumFreeProduct = {};

				// // Generate row data for each distributor
				// const tempData = distributors.map((distributor) => {
				// 	const rowData = {
				// 		key: distributor.id,
				// 		fullname: distributor.fullname,
				// 		d_total: 0,
				// 		total_row_price: 0,
				// 		total_row_qty: 0,
				// 	};

				// 	const productsForDistributor =
				// 		quantityAggregation[distributor.id] || {};

				// 	Object.keys(productsForDistributor).forEach(
				// 		(product_id) => {
				// 			const product = productMap.get(product_id);
				// 			if (product) {
				// 				const quantitiesForProduct =
				// 					productsForDistributor[product_id];

				// 				quantitiesForProduct.forEach(
				// 					(quantity, index) => {
				// 						console.log(quantity);
				// 						const freeQuantity =
				// 							quantity.free_quantity
				// 								? parseFloat(
				// 										quantity.free_quantity
				// 								  )
				// 								: 0;

				// 						const baseQuantity =
				// 							parseFloat(quantity.quantity) *
				// 							parseFloat(quantity.unit_units);

				// 						const basePrice =
				// 							baseQuantity *
				// 							parseFloat(quantity.price);

				// 						rowData[
				// 							`${product.id}-qty-${quantity.unit_name}`
				// 						] = quantity.quantity.toFixed(2);
				// 						rowData[
				// 							`${product.id}-price-${quantity.unit_name}`
				// 						] = basePrice;
				// 						rowData[
				// 							`${product.id}-size-${quantity.unit_name}`
				// 						] = quantity.unit_name;
				// 						rowData[
				// 							`${product.id}-unit_id-${quantity.unit_name}`
				// 						] = quantity.unit_id;
				// 						rowData[
				// 							`${product.id}-free_quantity-${quantity.unit_name}`
				// 						] = freeQuantity;
				// 						rowData[
				// 							`${product.id}-base_unit-${quantity.unit_name}`
				// 						] = parseFloat(quantity.unit_units);
				// 						rowData[
				// 							`${product.id}-distributor_indent_items_ids-${quantity.unit_name}`
				// 						] =
				// 							quantity.distributor_indent_items_ids;
				// 						rowData.total_row_price +=
				// 							parseFloat(basePrice);
				// 						rowData.total_row_qty += parseFloat(
				// 							quantity.quantity
				// 						);
				// 						rowData.d_total +=
				// 							parseFloat(basePrice);

				// 						sumByProduct.base_unit =
				// 							quantity.base_unit;

				// 						if (
				// 							!sumByProduct[
				// 								`${product.id}-qty-${quantity.unit_name}`
				// 							]
				// 						) {
				// 							sumByProduct[
				// 								`${product.id}-qty-${quantity.unit_name}`
				// 							] = 0;
				// 						}
				// 						sumByProduct[
				// 							`${product.id}-qty-${quantity.unit_name}`
				// 						] += baseQuantity;

				// 						sumByProduct[
				// 							`${product.id}-base_units-${quantity.unit_name}`
				// 						] = quantity.unit_units;

				// 						if (
				// 							!sumFreeProduct[
				// 								`${product.id}-free_quantity-${quantity.unit_name}`
				// 							]
				// 						) {
				// 							sumFreeProduct[
				// 								`${product.id}-free_quantity-${quantity.unit_name}`
				// 							] = 0;
				// 						}
				// 						sumFreeProduct[
				// 							`${product.id}-free_quantity-${quantity.unit_name}`
				// 						] += freeQuantity;
				// 					}
				// 				);
				// 			}
				// 		}
				// 	);

				// 	return rowData;
				// });

				// console.log(sumByProduct);
				// // Append totals to tempData
				// state.demandsheetsList = [
				// 	...tempData,
				// 	{
				// 		key: "total",
				// 		fullname: "Total Quantity",
				// 		...sumByProduct,
				// 		...sumFreeProduct,
				// 		d_total: tempData.reduce((acc, value) => {
				// 			console.log(value);
				// 			return acc + parseFloat(value.d_total);
				// 		}, 0),
				// 	},
				// ];

				let new_products = products.map((product) => {
					return {
						...product,
						units: [
							...new Set([
								...free_quantities
									.filter(
										(quantity) =>
											product.id === quantity.product_id
									)
									.map((quantity) => quantity.free_unit_name),
								...quantities
									.filter(
										(quantity) =>
											product.id === quantity.product_id
									)
									.map((quantity) => quantity.unit_name),
							]),
						],
					};
				});

				let new_quantities = [];

				new_products.forEach((product) => {
					product.units.forEach((unit_name) => {
						distributors.forEach((distributor) => {
							const new_free_quantities = free_quantities
								.filter(
									(quantity) =>
										product.id === quantity.product_id &&
										unit_name === quantity.free_unit_name &&
										quantity.distributor_id ===
											distributor.id
								)
								.map((quantity) => ({
									...quantity,
									applied_by_product_id:
										quantity.applied_by_product_id
											? quantity.applied_by_product_id
											: product.id,
									applied_by_unit_id:
										quantity.applied_by_unit_id
											? quantity.applied_by_unit_id
											: quantity.free_unit_id,
								}));

							if (new_free_quantities.length != 0) {
								const sum_new_free_quantities =
									new_free_quantities.reduce((acc, value) => {
										const temp_value = value.free_quantity
											? parseInt(value.free_quantity)
											: 0;
										return acc + temp_value;
									}, 0);

								new_quantities.push({
									free_quantity: sum_new_free_quantities,
									product_id: product.id,
									distributor_id: distributor.id,
									free_unit_name: unit_name,
									applied_by: new_free_quantities,
								});
							}
						});
					});
				});

				state.distributors = distributors;
				state.products = products;
				state.quantities = quantities;
				state.free_quantities = new_quantities;
				// .map((item) => {
				// 	if (
				// 		item.free_unit_name &&
				// 		item.unit_name !== item.free_unit_name
				// 	) {
				// 		let newItem = {
				// 			...item, // Copy the original item
				// 			free_unit_name: null,
				// 			free_unit_id: null,
				// 			free_unit_units: null,
				// 			free_quantity: null,
				// 		};
				// 		return {
				// 			...item,
				// 			...newItem,
				// 		};
				// 	}
				// 	return item;
				// });
			})
			.addCase(getDemandSheetItemsByRoute.rejected, (state, action) => {
				state.status = "getDemandSheetItemsByRoute failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//post data
			.addCase(postDemandSheet.pending, (state, action) => {
				state.status = "postDemandSheet loading";
				state.loading = true;
			})
			.addCase(postDemandSheet.fulfilled, (state, action) => {
				state.status = "postDemandSheet succeeded";
				state.loading = false;
				state.selectedData = {};
			})
			.addCase(postDemandSheet.rejected, (state, action) => {
				state.status = "postDemandSheet failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//edit data
			.addCase(editDemandSheet.pending, (state, action) => {
				state.status = "editDemandSheet loading";
				state.loading = true;
			})
			.addCase(editDemandSheet.fulfilled, (state, action) => {
				state.status = "editDemandSheet succeeded";
				state.loading = false;
				state.selectedData = {};
			})
			.addCase(editDemandSheet.rejected, (state, action) => {
				state.status = "editDemandSheet failed";
				state.loading = false;
				state.error = action.error.message;
			})
			.addCase(demandSheetExport.pending, (state, action) => {
				state.status = "demandSheetExport loading";
				state.loading = true;
			})
			.addCase(demandSheetExport.fulfilled, (state, action) => {
				state.status = "demandSheetExport succeeded";
				state.loading = false;
			})
			.addCase(demandSheetExport.rejected, (state, action) => {
				state.status = "demandSheetExport failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//get bu id data
			.addCase(getDemandSheetByID.pending, (state, action) => {
				state.status = "getDemandSheetByID loading";
				state.loading = true;
			})
			.addCase(getDemandSheetByID.fulfilled, (state, action) => {
				state.status = "getDemandSheetByID succeeded";
				state.loading = false;

				// Extract distributors and initialize quantities array
				const distributors = action.payload?.orders?.map(
					(order) => order.distributor
				);
				let quantities = [];

				// Process orders and items
				action.payload?.products.forEach((product) => {
					action.payload?.orders?.forEach((order) => {
						order.order_items.forEach((item) => {
							if (product.id === item.product?.id) {
								console.log(order);
								console.log(item);
								quantities.push({
									...item,
									distributor_id: order?.distributor?.id,
									product_id: item?.product?.id,
									unit_id: item?.unit?.id,
									unit_name: item?.unit?.name,
									unit_units: item?.unit?.units,
									d_total: order?.d_net,
									free_unit_id: item?.free_unit?.id,
									free_unit_name: item?.free_unit?.name,
									free_unit_units: item?.free_unit?.units,
								});
							}
						});
					});
				});

				const sumByProduct = {};
				const sumFreeProduct = {};

				// Prepare tempData
				const tempData = distributors.map((distributor) => {
					const rowData = {
						key: distributor.id,
						fullname: distributor.fullname,
					};

					// Group quantities by product and index
					const productQuantities = {};

					// Calculate totals

					quantities.forEach((quantity) => {
						if (quantity.distributor_id === distributor.id) {
							const product = quantity.product;
							const productKey = product.id;

							if (!productQuantities[productKey]) {
								productQuantities[productKey] = [];
							}

							productQuantities[productKey].push({
								quantity: parseFloat(quantity.quantity),
								unit_name: quantity.unit?.name,
								unit_id: quantity.unit?.id,
								unit_units: quantity.unit?.units,
								free_quantity: quantity?.free_quantity,
								d_total: quantity.d_total,
							});
						}
					});

					// Flatten the product quantities into rowData
					Object.keys(productQuantities).forEach((productKey) => {
						productQuantities[productKey].forEach(
							(quant, index) => {
								rowData[
									`${productKey}-qty-${quant.unit_name}`
								] = quant.quantity;
								rowData[
									`${productKey}-size-${quant.unit_name}`
								] = quant.unit_name;
								rowData[
									`${productKey}-unit_id-${quant.unit_name}`
								] = quant.unit_id;
								rowData[
									`${productKey}-unit_units-${quant.unit_name}`
								] = quant.unit_units;
								rowData[
									`${productKey}-free_quantity-${quant.unit_name}`
								] = quant.free_quantity;
								rowData[`d_total`] = quant.d_total;

								const quantity =
									parseFloat(quant.quantity) *
									parseFloat(quant.unit_units);

								if (
									!sumByProduct[
										`${productKey}-qty-${quant.unit_name}`
									]
								) {
									sumByProduct[
										`${productKey}-qty-${quant.unit_name}`
									] = 0;
								}
								sumByProduct[
									`${productKey}-qty-${quant.unit_name}`
								] += quantity;

								//free_quantity

								const freeQuantity = parseFloat(
									quant.free_quantity
								);
								if (
									!sumFreeProduct[
										`${productKey}-free_quantity-${quant.unit_name}`
									]
								) {
									sumFreeProduct[
										`${productKey}-free_quantity-${quant.unit_name}`
									] = 0;
								}
								sumFreeProduct[
									`${productKey}-free_quantity-${quant.unit_name}`
								] += freeQuantity;
							}
						);
					});

					return rowData;
				});

				console.log(sumByProduct);
				// Append totals to tempData
				state.demandsheetsList = [
					...tempData,
					{
						key: "total",
						fullname: "Total Quantity",
						...sumByProduct,
						...sumFreeProduct,
					},
				];
				state.distributors = distributors;
				state.quantities = quantities;
				state.products = action.payload?.products;
				state.selectedData = action.payload;
			})
			.addCase(getDemandSheetByID.rejected, (state, action) => {
				state.status = "getDemandSheetByID failed";
				state.loading = false;
				state.error = action.error.message;
			})
			.addCase(getDemandSheetProductSchemes.pending, (state, action) => {
				state.status = "getDemandSheetProductSchemes loading";
				state.unitsLoading = true;
			})
			.addCase(
				getDemandSheetProductSchemes.fulfilled,
				(state, action) => {
					state.status = "getDemandSheetProductSchemes succeeded";
					var response = action.payload;

					console.log(response);
					state.productData = {
						...response,
						product: {
							id: response?.id,
							name: response?.name,
							category: response?.category,
							image: response?.image,
						},
					};
					state.unitsLoading = false;
				}
			)
			.addCase(getDemandSheetProductSchemes.rejected, (state, action) => {
				state.status = "getDemandSheetProductSchemes failed";
				state.unitsLoading = false;
				state.error = action.error.message;
			});
	},
});

// Action creators are generated for each case reducer function
export const {
	isDrawerVisible,
	changeParams,
	clearData,
	setSelectedData,
	inputChangeValue,
	changeFilterParams,
	FilterInputChangeValue,
	changeDemandSheetList,
	changeDemandSheetFreeQtyList,
	changeDemandSheetForAnotherFreeProduct,
	setProducts,
	setFreeQuantities,
	removeFreeItem,
} = demandsheetSlice.actions;

export default demandsheetSlice.reducer;
