import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
	addParams,
	getList,
	getParamsList,
	patchEdit,
	postAdd,
	postEdit,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
	RiCheckboxCircleLine,
	RiAlertLine,
	RiInformationLine,
} from "react-icons/ri";
import { object } from "prop-types";

export const getTicketManagements = createAsyncThunk(
	"/getTicketManagements",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/b2c/admin/ticket/list/`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
// export const getTicketManagementsAddress = createAsyncThunk(
// 	"/getTicketManagementsAddress",
// 	async (payload) => {
// 		const params = addParams(payload);
// 		try {
// 			const response = await getParamsList(
// 				`/users/retailersaddress/create/`,
// 				params
// 			);
// 			if (response) {
// 				return response;
// 			} else {
// 				throw new Error(response);
// 			}
// 		} catch (error) {
// 			return error.message;
// 		}
// 	}
// );

export const getTicketManagementByID = createAsyncThunk(
	"/getTicketManagementByID",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getList(
				`/b2c/admin/ticket/detail/${params.id}/`
			);
			if (response) {
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

// export const postTicketManagement = createAsyncThunk(
// 	"/postTicketManagement",
// 	async (payload) => {
// 		try {
// 			const {
// 				data,
// 				history,
// 				shopdetailsForm,
// 				billingAddressForm,
// 				shippingAddressForm,
// 				clearData,
// 				message,
// 			} = payload;
// 			const response = await postAdd(
// 				"/b2c/admin/vacationmoderequest/",
// 				data
// 			);
// 			if (response.status === 201) {
// 				Modal.success({
// 					icon: (
// 						<span className="remix-icon">
// 							<RiCheckboxCircleLine />
// 						</span>
// 					),
// 					title: (
// 						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
// 					),
// 					content: (
// 						<div>
// 							<p className="hp-p1-body hp-text-color-black-80">
// 								Vacation Mode Request Added Successfully.!
// 							</p>
// 						</div>
// 					),
// 				});
// 				history.push("/vacationmode_request");
// 				shopdetailsForm.resetFields();
// 				billingAddressForm.resetFields();
// 				shippingAddressForm.resetFields();
// 				message.success("Processing complete!");
// 				clearData();
// 				return response;
// 			} else {
// 				throw response;
// 			}
// 		} catch (error) {
// 			console.log(error);

// 			if (error?.response?.data) {
// 				const values = Object.keys(error.response.data);
// 				console.log(values);
// 				Modal.error({
// 					icon: (
// 						<span className="remix-icon">
// 							<RiAlertLine />
// 						</span>
// 					),
// 					title: (
// 						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
// 					),
// 					content: (
// 						<div>
// 							<p className="hp-p1-body hp-text-color-black-80">
// 								{error?.response?.data[values[0]][values[0]]}
// 							</p>
// 						</div>
// 					),
// 				});
// 			} else {
// 				Modal.error({
// 					icon: (
// 						<span className="remix-icon">
// 							<RiAlertLine />
// 						</span>
// 					),

// 					title: (
// 						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
// 					),
// 					content: (
// 						<div>
// 							<p className="hp-p1-body hp-text-color-black-80">
// 								Sorry! Getting from server side issue!
// 							</p>
// 						</div>
// 					),
// 				});
// 			}
// 			throw error.message;
// 		}
// 	}
// );

export const postTicketManagement = createAsyncThunk(
	"/postTicketManagement",
	async (payload, { dispatch }) => {
		try {
			const { data, handleResetInputValues, pageParams } = payload;
			const response = await postAdd(`/b2c/admin/ticket/list/`, data);
			if (response.status === 201) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Cash Collection Request Added Successfully.!
							</p>
						</div>
					),
				});
				dispatch(getTicketManagements({ ...pageParams }));
				handleResetInputValues();
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error.response) {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Unable to add cash collection request.
								Please try again!
							</p>
						</div>
					),
				});
			} else {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),

					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Getting from server side issue!
							</p>
						</div>
					),
				});
			}
			throw error.message;
		}
	}
);

// export const editTicketManagement = createAsyncThunk(
// 	"/editTicketManagement",
// 	async (payload, { dispatch }) => {
// 		try {
// 			const { data, closeModal } = payload;
// 			const response = await patchEdit(
// 				`/b2c/admin/vacationmoderequest/${data.id}/`,
// 				data
// 			);
// 			if (response.status === 200) {
// 				Modal.success({
// 					icon: (
// 						<span className="remix-icon">
// 							<RiCheckboxCircleLine />
// 						</span>
// 					),
// 					title: (
// 						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
// 					),
// 					content: (
// 						<div>
// 							<p className="hp-p1-body hp-text-color-black-80">
// 								Edited Successfully.!
// 							</p>
// 						</div>
// 					),
// 				});
// 				closeModal(response.data);
// 				return response;
// 			} else {
// 				throw new Error(response);
// 			}
// 		} catch (error) {
// 			if (error.response) {
// 				Modal.error({
// 					icon: (
// 						<span className="remix-icon">
// 							<RiAlertLine />
// 						</span>
// 					),
// 					title: (
// 						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
// 					),
// 					content: (
// 						<div>
// 							<p className="hp-p1-body hp-text-color-black-80">
// 								Sorry! Unable to edit retailer. Please try
// 								again!
// 							</p>
// 						</div>
// 					),
// 				});
// 			} else {
// 				Modal.error({
// 					icon: (
// 						<span className="remix-icon">
// 							<RiAlertLine />
// 						</span>
// 					),

// 					title: (
// 						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
// 					),
// 					content: (
// 						<div>
// 							<p className="hp-p1-body hp-text-color-black-80">
// 								Sorry! Getting from server side issue!
// 							</p>
// 						</div>
// 					),
// 				});
// 			}
// 			throw error.message;
// 		}
// 	}
// );

export const editTicketManagement = createAsyncThunk(
	"/editTicketManagement",
	async (payload, { dispatch }) => {
		try {
			const { data, handleResetInputValues, pageParams } = payload;
			const response = await postEdit(
				`/b2c/admin/ticket/update/${data.id}/`,
				data
			);
			if (response.status === 200) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Edited Successfully.!
							</p>
						</div>
					),
				});
				dispatch(getTicketManagements({ ...pageParams }));
				handleResetInputValues(response.data);
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error.response) {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Unable to edit cash collection request.
								Please try again!
							</p>
						</div>
					),
				});
			} else {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),

					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Getting from server side issue!
							</p>
						</div>
					),
				});
			}
			throw error.message;
		}
	}
);
