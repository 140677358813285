import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
	addParams,
	getList,
	getParamsList,
	postAdd,
	postEdit,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
	RiCheckboxCircleLine,
	RiAlertLine,
	RiInformationLine,
} from "react-icons/ri";

export const getSubscriptions = createAsyncThunk(
	"/getSubscriptions",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/b2c/admin/subscription/`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getSubscriptionsLogs = createAsyncThunk(
	"/getSubscriptionsLogs",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/system/subscription/log/`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const postSubscriptions = createAsyncThunk(
	"/postSubscriptions",
	async (payload) => {
		try {
			const { data, history, forms } = payload;
			const response = await postAdd("/b2c/admin/subscription/", data);
			if (response.status === 201) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Subscription Added Successfully.!
							</p>
						</div>
					),
				});
				history.push("/subscription");
				forms.form.resetFields();
				forms.RemarksForm.resetFields();
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error.response) {
				const errorValues = error.response.data;
				console.log(errorValues);

				if (
					errorValues["Insufficient Balance"]?.length &&
					errorValues["Required balance"]?.length &&
					errorValues["wallet balance"]?.length
				) {
					Modal.error({
						icon: (
							<span className="remix-icon">
								<RiAlertLine />
							</span>
						),
						title: (
							<h5 className="hp-mb-0 hp-font-weight-500">
								Insufficient Balance
							</h5>
						),
						content: (
							<div>
								<p className="hp-p1-body hp-text-color-black-80">
									{errorValues["wallet balance"][0]}
								</p>
								<p className="hp-p1-body hp-text-color-black-80">
									{errorValues["Required balance"][0]}
								</p>
								<p className="hp-p1-body hp-text-color-black-80">
									{errorValues["Insufficient Balance"][0]}
								</p>
							</div>
						),
					});
				} else {
					Modal.error({
						icon: (
							<span className="remix-icon">
								<RiAlertLine />
							</span>
						),
						title: (
							<h5 className="hp-mb-0 hp-font-weight-500">
								Error
							</h5>
						),
						content: (
							<div>
								<p className="hp-p1-body hp-text-color-black-80">
									{errorValues["detail"][0]
										? errorValues["detail"][0]
										: "Sorry! Unable to add Customer Order. Please try again!"}
								</p>
							</div>
						),
					});
				}
			} else {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),

					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Getting from server side issue!
							</p>
						</div>
					),
				});
			}
			throw error.message;
		}
	}
);
export const editSubscriptions = createAsyncThunk(
	"/editSubscriptions",
	async (payload) => {
		try {
			const { id, data, history, forms } = payload;
			const response = await postEdit(
				`/b2c/admin/subscription/update/${id}/`,
				data
			);
			if (response.status === 200) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Subscription Edited Successfully.!
							</p>
						</div>
					),
				});
				history.push("/subscription");
				forms.form.resetFields();
				forms.RemarksForm.resetFields();
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error.response) {
				const errorValues = error.response.data;
				console.log(errorValues);

				if (
					errorValues["Insufficient Balance"]?.length &&
					errorValues["Required balance"]?.length &&
					errorValues["wallet balance"]?.length
				) {
					Modal.error({
						icon: (
							<span className="remix-icon">
								<RiAlertLine />
							</span>
						),
						title: (
							<h5 className="hp-mb-0 hp-font-weight-500">
								Insufficient Balance
							</h5>
						),
						content: (
							<div>
								<p className="hp-p1-body hp-text-color-black-80">
									{errorValues["wallet balance"][0]}
								</p>
								<p className="hp-p1-body hp-text-color-black-80">
									{errorValues["Required balance"][0]}
								</p>
								<p className="hp-p1-body hp-text-color-black-80">
									{errorValues["Insufficient Balance"][0]}
								</p>
							</div>
						),
					});
				} else {
					Modal.error({
						icon: (
							<span className="remix-icon">
								<RiAlertLine />
							</span>
						),
						title: (
							<h5 className="hp-mb-0 hp-font-weight-500">
								Error
							</h5>
						),
						content: (
							<div>
								<p className="hp-p1-body hp-text-color-black-80">
									{errorValues["detail"][0]
										? errorValues["detail"][0]
										: "Sorry! Unable to add Customer Order. Please try again!"}
								</p>
							</div>
						),
					});
				}
			}
			throw error.message;
		}
	}
);
export const getSubscriptionByID = createAsyncThunk(
	"/getSubscriptionByID",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getList(
				`/b2c/admin/subscription/${params.id}/`
			);
			if (response) {
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const subscriptionActive = createAsyncThunk(
	"/subscriptionActive",
	async (payload, { dispatch }) => {
		const { id, data, params, setEditStatus } = payload;
		try {
			const response = await postEdit(
				`/b2c/admin/subscription/active/${id}/`,
				{ ...data }
			);
			if (response.status >= 200 && response.status < 300) {
				setEditStatus("");
				dispatch(getSubscriptions({ ...params }));
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			Modal.error({
				icon: (
					<span className="remix-icon">
						<RiAlertLine />
					</span>
				),
				title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
				content: (
					<div>
						<p className="hp-p1-body hp-text-color-black-80">
							{error.response
								? error.response.data.phone
								: "Sorry! Getting from server side issue!"}
						</p>
					</div>
				),
			});
			throw error.message;
		}
	}
);
export const subscriptionInActive = createAsyncThunk(
	"/subscriptionInActive",
	async (payload, { dispatch }) => {
		const { id, data, params, setEditStatus } = payload;
		try {
			const response = await postEdit(
				`/b2c/admin/subscription/inactive/${id}/`,
				{ ...data }
			);
			if (response.status >= 200 && response.status < 300) {
				setEditStatus("");
				dispatch(getSubscriptions({ ...params }));
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			Modal.error({
				icon: (
					<span className="remix-icon">
						<RiAlertLine />
					</span>
				),
				title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
				content: (
					<div>
						<p className="hp-p1-body hp-text-color-black-80">
							{error.response
								? error.response.data.phone
								: "Sorry! Getting from server side issue!"}
						</p>
					</div>
				),
			});
			throw error.message;
		}
	}
);
export const subscriptionCancel = createAsyncThunk(
	"/subscriptionCancel",
	async (payload, { dispatch }) => {
		const { id, data, params, setEditStatus } = payload;
		try {
			const response = await postEdit(
				`/b2c/admin/subscription/cancel/${id}/`,
				{ ...data }
			);
			if (response.status >= 200 && response.status < 300) {
				setEditStatus("");
				dispatch(getSubscriptions({ ...params }));
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			Modal.error({
				icon: (
					<span className="remix-icon">
						<RiAlertLine />
					</span>
				),
				title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
				content: (
					<div>
						<p className="hp-p1-body hp-text-color-black-80">
							{error.response
								? error.response.data.phone
								: "Sorry! Getting from server side issue!"}
						</p>
					</div>
				),
			});
			throw error.message;
		}
	}
);
