import { createSlice } from "@reduxjs/toolkit";
import { getCustomerPriceBookByID, getCustomerPriceBook, customer_pricebookAdd, customer_pricebookDelete } from "./customer_pricebook.action";

const customer_pricebookSlice = createSlice({
  name: "customer_pricebook",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    customer_pricebooklist: [],
    customer_priceslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    customer_priceslistCount: 0,
    customer_pricebookParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    customer_pricesParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
    },
  },
  reducers: {
    SetCustomerPriceBookParams: (state, action) => {
      return {
        ...state,
        customer_pricebookParams: action.payload,
      };
    },
    SetCustomerPricesParams: (state, action) => {
      return {
        ...state,
        customer_pricesParams: action.payload,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    setRowData: (state, action) => {
      return {
        ...state,
        rowdata: action.payload,
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerPriceBook.pending, (state, action) => {
        state.status = "getCustomerPriceBook loading";
        state.loading = true;
      })
      .addCase(getCustomerPriceBook.fulfilled, (state, action) => {
        state.status = "getCustomerPriceBook succeeded";
        state.loading = false;
        var response = action.payload.response;
        var noofpages = Math.ceil(
          action.payload.count / state.customer_pricebookParams?.page_size
        );

        state.customer_pricebooklist = response.results;
        state.listCount = response.count;
        state.customer_pricebookParams = {
          ...state.customer_pricebookParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getCustomerPriceBook.rejected, (state, action) => {
        state.status = "getCustomerPriceBook failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(customer_pricebookAdd.pending, (state, action) => {
        state.status = "customer_pricebookAdd loading";
        state.loading = true;
      })
      .addCase(customer_pricebookAdd.fulfilled, (state, action) => {
        state.status = "customer_pricebookAdd succeeded";
        state.loading = false
        state.model = action.payload.modal;
        state.message = action.payload.response;
        state.rowdata = {};
      }
      )
      .addCase(customer_pricebookAdd.rejected, (state, action) => {
        state.status = "customer_pricebookAdd failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(customer_pricebookDelete.pending, (state, action) => {
        state.status = "customer_pricebookDelete loading";
        state.loading = true;
      })
      .addCase(customer_pricebookDelete.fulfilled, (state, action) => {
        state.status = "customer_pricebookDelete succeeded";
        state.loading = false
      }
      )
      .addCase(customer_pricebookDelete.rejected, (state, action) => {
        state.status = "customer_pricebookDelete failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCustomerPriceBookByID.pending, (state, action) => {
        state.status = "getCustomerPriceBookByID loading";
        state.loading = true;
      })
      .addCase(getCustomerPriceBookByID.fulfilled, (state, action) => {
        console.log(action.payload.response)
        state.status = "getCustomerPriceBookByID succeeded";
        state.loading = false;
        var response = action.payload.response;
        var noofpages = Math.ceil(
          action.payload.count / state.customer_pricesParams?.page_size
        );
        state.customer_priceslist = response.results;
        state.customer_priceslistCount = response.count;
        state.customer_pricesParams = {
          ...state.customer_pricesParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getCustomerPriceBookByID.rejected, (state, action) => {
        state.status = "getCustomerPriceBookByID failed";
        state.loading = false;
        state.error = action.error.message;
      })
  }

});
export const {
  InputChangeValue,
  apiError,
  isModelVisible,
  setRowData,
  SetCustomerPriceBookParams,
  SetCustomerPricesParams,
} = customer_pricebookSlice.actions;

export default customer_pricebookSlice.reducer;
