import { createSlice } from "@reduxjs/toolkit";
import { demandDraftExport, getDemandDraft } from "./demanddraft.action";
import { Input } from "antd";

const demanddraftSlice = createSlice({
	name: "demanddraft",
	initialState: {
		loading: false,
		error: "",
		status: "",
		list: [],
		selectedData: {
			new_product_type: {},
		},
		distributors: [],
		products: [],
		quantities: [],
		demanddraftsList: [],
		count: 0,
		drawer: false,
		openAddressModal: false,
		openAddAddressModal: false,
		filterParams: {},
		productsList: [],
		productListparams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
		pageParams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
	},
	reducers: {
		isDrawerVisible: (state, action) => {
			return {
				...state,
				drawer: action.payload,
			};
		},
		changeDemandSheetList: (state, action) => {
			return {
				...state,
				demanddraftsList: state.demanddraftsList.map((e) => {
					return e.product_id == action.payload.product_id
						? {
								...e,
								quantity: action.payload.quantity,
						  }
						: {
								...e,
						  };
				}),
			};
		},
		changeParams: (state, action) => {
			return {
				...state,
				pageParams: action.payload,
			};
		},
		changeProductListParams: (state, action) => {
			return {
				...state,
				productListparams: action.payload,
			};
		},
		setOpenAddressModal: (state, action) => {
			return {
				...state,
				openAddressModal: action.payload,
			};
		},
		setOpenAddAddressModal: (state, action) => {
			return {
				...state,
				openAddAddressModal: action.payload,
			};
		},
		changeFilterParams: (state, action) => {
			return {
				...state,
				filterParams: action.payload,
			};
		},
		FilterInputChangeValue: (state, action) => {
			return {
				...state,
				filterParams: {
					...state.filterParams,
					[action.payload.key]: action.payload.value,
				},
			};
		},

		setSelectedData: (state, action) => {
			return {
				...state,
				selectedData: action.payload,
			};
		},
		inputChangeValue: (state, action) => {
			return {
				...state,
				selectedData: {
					...state.selectedData,
					...action.payload,
				},
			};
		},
		addProductsToList: (state, action) => {
			return {
				...state,
				productsList: [...state.productsList, action.payload],
			};
		},
		removeProductsFromList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => {
					return e.uniqueId == action.payload.product_id
						? {
								...e,
								dodelete: true,
						  }
						: {
								...e,
						  };
				}),
			};
		},
		changeProductList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => {
					if (e.id == action.payload.product_id) {
						if (action.payload.key == "quantity") {
							const calculatedPrice =
								parseFloat(action.payload.value) *
								parseFloat(e.listedPrice) *
								parseFloat(e.unit.units);
							const discountAmt = e.discount_percent
								? +calculatedPrice * (+e.discount_percent / 100)
								: 0;
							const taxableValue = calculatedPrice - discountAmt;
							const gstAmt = e.gst_percentage
								? parseFloat(taxableValue) *
								  (+e.gst_percentage / 100)
								: 0;
							const totalPrice =
								parseFloat(taxableValue) + parseFloat(gstAmt);
							return {
								...e,
								[action.payload.key]: action.payload.value,
								discountAmt: discountAmt.toFixed(2),
								gstAmt: gstAmt.toFixed(2),
								price: totalPrice.toFixed(2),
								taxableValue: taxableValue.toFixed(2),
								originalPrice: calculatedPrice.toFixed(2),
							};
						} else {
							const calculatedPrice =
								parseFloat(e.quantity) *
								parseFloat(e.listedPrice) *
								parseFloat(action.payload.value?.units);
							const discountAmt = e.discount_percent
								? +calculatedPrice * (+e.discount_percent / 100)
								: 0;
							const taxableValue = calculatedPrice - discountAmt;
							const gstAmt = e.gst_percentage
								? parseFloat(taxableValue) *
								  (+e.gst_percentage / 100)
								: 0;
							const totalPrice =
								parseFloat(taxableValue) + parseFloat(gstAmt);
							return {
								...e,
								[action.payload.key]: action.payload.value,
								discountAmt: discountAmt.toFixed(2),
								gstAmt: gstAmt.toFixed(2),
								price: totalPrice,
								taxableValue: taxableValue.toFixed(2),
								originalPrice: calculatedPrice.toFixed(2),
							};
						}
					} else {
						return e;
					}
				}),
			};
		},
		clearData: (state, action) => {
			return {
				...state,
				loading: false,
				error: "",
				status: "",
				list: [],
				selectedData: {},
				count: 0,
				openAddressModal: false,
				openAddAddressModal: false,
				productsList: [],
				productListparams: {
					start_date: "",
					end_date: "",
					no_of_pages: 0,
					page_size: 10,
					page: 1,
					search: "",
				},
			};
		},
	},
	extraReducers: (builder) => {
		builder
			//get Data by superstockist id
			.addCase(getDemandDraft.pending, (state, action) => {
				state.status = "getDemandDraft loading";
				state.loading = true;
			})
			.addCase(getDemandDraft.fulfilled, (state, action) => {
				state.status = "getDemandDraft succeeded";
				state.loading = false;

				const {
					distributors = [],
					products = [],
					quantities = [],
				} = action.payload;
				const tempData = distributors?.map((distributor) => {
					const rowData = {
						key: distributor.id,
						fullname: distributor.fullname,
					};
					// Group quantities by product and index
					const productQuantities = {};
					// Calculate totals

					quantities.forEach((quantity) => {
						if (quantity.distributor_id === distributor.id) {
							const product = products.find(
								(prod) => prod.id === quantity.product_id
							);
							const productKey = product.id;
							if (!productQuantities[productKey]) {
								productQuantities[productKey] = [];
							}
							console.log(quantity);

							productQuantities[productKey].push({
								quantity: parseFloat(quantity.quantity),
								unit_name: quantity.unit_name,
								unit_id: quantity.unit_id,
								// unit_units: quantity.unit?.units,
								free_quantity: quantity?.free_quantity,
								// d_total: quantity.d_total,
							});
						}
					});

					Object.keys(productQuantities).forEach((productKey) => {
						productQuantities[productKey].forEach(
							(quant, index) => {
								rowData[
									`${productKey}-qty-${quant.unit_name}`
								] = quant.quantity;
								rowData[
									`${productKey}-size-${quant.unit_name}`
								] = quant.unit_name;
								rowData[
									`${productKey}-unit_id-${quant.unit_name}`
								] = quant.unit_id;
								// rowData[
								// 	`${productKey}-unit_units-${index}`
								// ] = quant.unit_units;
								rowData[
									`${productKey}-free_quantity-${quant.unit_name}`
								] = quant.free_quantity;
								// rowData[`d_total`] = quant.d_total;
							}
						);
					});

					return rowData;
				});
				state.demanddraftsList = tempData;
				state.distributors = distributors;
				state.products = products;
				state.quantities = quantities;
			})
			.addCase(getDemandDraft.rejected, (state, action) => {
				state.status = "getDemandDraft failed";
				state.loading = false;
				state.error = action.error.message;
			})
			.addCase(demandDraftExport.pending, (state, action) => {
				state.status = "demandDraftExport loading";
				state.loading = true;
			})
			.addCase(demandDraftExport.fulfilled, (state, action) => {
				state.status = "demandDraftExport succeeded";
				state.loading = false;
			})
			.addCase(demandDraftExport.rejected, (state, action) => {
				state.status = "demandDraftExport failed";
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

// Action creators are generated for each case reducer function
export const {
	isDrawerVisible,
	changeParams,
	changeProductListParams,
	clearData,
	setSelectedData,
	inputChangeValue,
	addProductsToList,
	removeProductsFromList,
	changeProductList,
	setOpenAddressModal,
	setOpenAddAddressModal,
	changeFilterParams,
	FilterInputChangeValue,
	changeDemandSheetList,
} = demanddraftSlice.actions;

export default demanddraftSlice.reducer;
