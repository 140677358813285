import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    postAdd,
    getParamsList,
    postEdit,
    postDelete,
    getList,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
    RiCheckboxCircleLine,
    RiAlertLine,
    RiInformationLine,
} from "react-icons/ri";

export const getSubscriptionScheme = createAsyncThunk(
    "/getSubscriptionScheme",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/b2c/admin/subscriptionschemes/", params);
            if (response) {
                return { response };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);
export const getSubscriptionSchemeList = createAsyncThunk(
    "/getSubscriptionSchemeList",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/b2c/admin/subscriptionschemes/", params);
            if (response) {
                return { response };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);
export const SubscriptionSchemeDelete = createAsyncThunk(
    "/SubscriptionSchemeDelete",
    async (payload) => async (dispatch) => {
        console.log(dispatch)
        var SubscriptionSchemeID = payload;
        try {
            const response = await postDelete("/b2c/admin/subscriptionschemes/" + SubscriptionSchemeID + "/");
            if (response.status === 204) {
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Subscription Scheme has been deleted Successfully!
                            </p>
                        </div>
                    ),
                });
                return { response };
            } else {
                Modal.error({
                    icon: (
                        <span className="remix-icon">
                            <RiAlertLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Sorry! Subscription Scheme has been not deleted!
                            </p>
                        </div>
                    ),
                });
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            throw error.message;
        }
    }
);
export const SubscriptionSchemeAdd = createAsyncThunk(
    "/SubscriptionSchemeAdd",
    async (payload, { dispatch }) => {
        const { obj, params } = payload
        try {
            if (obj.id == 0) {
                const response = await postAdd("/b2c/admin/subscriptionschemes/", obj);
                if (response.status === 201) {
                    Modal.success({
                        icon: (
                            <span className="remix-icon">
                                <RiCheckboxCircleLine />
                            </span>
                        ),
                        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                        content: (
                            <div>
                                <p className="hp-p1-body hp-text-color-black-80">
                                    Subscription Scheme Added Successfully.!
                                </p>
                            </div>
                        ),
                    });
                    dispatch(getSubscriptionScheme({ ...params }));
                    return { response, modal: false };
                } else {
                    Modal.error({
                        icon: (
                            <span className="remix-icon">
                                <RiAlertLine />
                            </span>
                        ),
                        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                        content: (
                            <div>
                                <p className="hp-p1-body hp-text-color-black-80">
                                    Sorry! Unable to add Subscription Scheme. Please try again!
                                </p>
                            </div>
                        ),
                    });
                    throw new Error(response);
                }
            } else {
                const response = await postEdit("/b2c/admin/subscriptionschemes/" + obj.id + "/", obj);
                if (response.status === 200) {
                    Modal.success({
                        icon: (
                            <span className="remix-icon">
                                <RiCheckboxCircleLine />
                            </span>
                        ),
                        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                        content: (
                            <div>
                                <p className="hp-p1-body hp-text-color-black-80">
                                    Subscription Scheme Edited Successfully.!
                                </p>
                            </div>
                        ),
                    });
                    dispatch(getSubscriptionScheme({ ...params }));
                    return { response, modal: false }
                } else {
                    Modal.error({
                        icon: (
                            <span className="remix-icon">
                                <RiAlertLine />
                            </span>
                        ),
                        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                        content: (
                            <div>
                                <p className="hp-p1-body hp-text-color-black-80">
                                    Sorry! Unable to Subscription Scheme. Please try again!
                                </p>
                            </div>
                        ),
                    });
                }
                throw new Error(response);
            }

        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            throw error.message;
        }
    }
);

export const getSubscriptionSchemeByID = createAsyncThunk(
    "/getSubscriptionSchemeByID",
    async (payload) => {

        try {
            const response = await getParamsList(`/b2c/admin/subscriptionschemes/${payload}/`);
            if (response) {
                return { response };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);

export const subscriptionSchemeActive = createAsyncThunk("/subscriptionSchemeActive", async (payload) => {
    const { id, is_active, onSuccess, OnFailure } = payload;
    try {
        const response = await postEdit(`/b2c/admin/subscriptionschemes/active/${id}/`, { is_active });
        if (response.status >= 200 && response.status < 300) {
            onSuccess(response.data);
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        if (error.response) {
            OnFailure(error.response.data.phone);
        } else {
            OnFailure("Sorry! Getting from server side issue!");
        }
        throw error.message;
    }
});
export const subscriptionSchemeInActive = createAsyncThunk(
    "/subscriptionSchemeInActive",
    async (payload) => {
        const { id, is_active, onSuccess, onFailure } = payload;
        try {
            const response = await postEdit(`/b2c/admin/subscriptionschemes/inactive/${id}/`, { is_active });
            if (response.status >= 200 && response.status < 300) {
                onSuccess(response.data);
                return response.data;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            if (error.response) {
                onFailure(error.response.data.phone);
                throw error.response.data.phone;
            } else {
                onFailure("Sorry! Getting from server side issue!");
                throw error.message;
            }
        }
    }
);