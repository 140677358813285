import { createSlice } from "@reduxjs/toolkit";
import {
	editReturnSheet,
	getReturnSheetByID,
	getReturnSheet,
	postReturnSheet,
	getReturnSheetItemsBySuperStockist,
	returnSheetExport,
} from "./returnsheet.action";
import { Input } from "antd";

const returnsheetSlice = createSlice({
	name: "returnsheet",
	initialState: {
		loading: false,
		error: "",
		status: "",
		list: [],
		selectedData: {
			new_product_type: {},
		},
		distributors: [],
		products: [],
		quantities: [],
		returnsheetsList: [],
		count: 0,
		drawer: false,
		openAddressModal: false,
		openAddAddressModal: false,
		filterParams: {},
		productsList: [],
		productListparams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
		pageParams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
	},
	reducers: {
		isDrawerVisible: (state, action) => {
			return {
				...state,
				drawer: action.payload,
			};
		},
		changeReturnSheetList: (state, action) => {
			return {
				...state,
				returnsheetsList: state.returnsheetsList.map((e) => {
					return e.key == action.payload.distributor_id
						? {
								...e,
								[action.payload.key]: parseFloat(
									action.payload.value
								),
						  }
						: {
								...e,
						  };
				}),
			};
		},
		changeParams: (state, action) => {
			return {
				...state,
				pageParams: action.payload,
			};
		},
		changeProductListParams: (state, action) => {
			return {
				...state,
				productListparams: action.payload,
			};
		},
		setOpenAddressModal: (state, action) => {
			return {
				...state,
				openAddressModal: action.payload,
			};
		},
		setOpenAddAddressModal: (state, action) => {
			return {
				...state,
				openAddAddressModal: action.payload,
			};
		},
		changeFilterParams: (state, action) => {
			return {
				...state,
				filterParams: action.payload,
			};
		},
		FilterInputChangeValue: (state, action) => {
			return {
				...state,
				filterParams: {
					...state.filterParams,
					[action.payload.key]: action.payload.value,
				},
			};
		},

		setSelectedData: (state, action) => {
			return {
				...state,
				selectedData: action.payload,
			};
		},
		inputChangeValue: (state, action) => {
			return {
				...state,
				selectedData: {
					...state.selectedData,
					...action.payload,
				},
			};
		},
		addProductsToList: (state, action) => {
			return {
				...state,
				productsList: [...state.productsList, action.payload],
			};
		},
		removeProductsFromList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => {
					return e.uniqueId == action.payload.product_id
						? {
								...e,
								dodelete: true,
						  }
						: {
								...e,
						  };
				}),
			};
		},
		changeProductList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => {
					if (e.id == action.payload.product_id) {
						if (action.payload.key == "quantity") {
							const calculatedPrice =
								parseFloat(action.payload.value) *
								parseFloat(e.listedPrice);
							const discountAmt = e.discount_percent
								? parseFloat(calculatedPrice) *
								  (parseFloat(e.discount_percent) / 100)
								: 0;
							const gstAmt = e.gst_percentage
								? parseFloat(calculatedPrice) *
								  (parseFloat(e.gst_percentage) / 100)
								: 0;
							const totalPrice =
								parseFloat(calculatedPrice) +
								parseFloat(gstAmt);
							console.log(
								typeof +e.discount_percent,
								typeof +e.gst_percentage
							);
							return {
								...e,
								[action.payload.key]: action.payload.value,
								discountAmt: discountAmt.toFixed(2),
								gstAmt: gstAmt.toFixed(2),
								price: totalPrice,
							};
						} else {
							return {
								...e,
								[action.payload.key]: action.payload.value,
							};
						}
					} else {
						return e;
					}
				}),
			};
		},
		clearData: (state, action) => {
			return {
				...state,
				loading: false,
				error: "",
				status: "",
				list: [],
				selectedData: {},
				count: 0,
				openAddressModal: false,
				openAddAddressModal: false,
				productsList: [],
				productListparams: {
					start_date: "",
					end_date: "",
					no_of_pages: 0,
					page_size: 10,
					page: 1,
					search: "",
				},
			};
		},
	},
	extraReducers: (builder) => {
		builder
			//get Data
			.addCase(getReturnSheet.pending, (state, action) => {
				state.status = "getReturnSheet loading";
				state.loading = true;
			})
			.addCase(getReturnSheet.fulfilled, (state, action) => {
				state.status = "getReturnSheet succeeded";
				state.loading = false;
				var noofpages = Math.ceil(
					action.payload.count / state.pageParams.page_size
				);
				state.count = action.payload.count;
				state.list = action.payload.results;
				state.pageParams = {
					...state.pageParams,
					no_of_pages: noofpages,
				};
			})
			.addCase(getReturnSheet.rejected, (state, action) => {
				state.status = "getReturnSheet failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//get Data by superstockist id
			.addCase(
				getReturnSheetItemsBySuperStockist.pending,
				(state, action) => {
					state.status = "getReturnSheetItemsBySuperStockist loading";
					state.loading = true;
				}
			)
			.addCase(
				getReturnSheetItemsBySuperStockist.fulfilled,
				(state, action) => {
					state.status =
						"getReturnSheetItemsBySuperStockist succeeded";
					state.loading = false;
					state.distributors = action.payload?.distributors;
					state.products = action?.payload?.products;
					const tempData = action.payload?.distributors?.map(
						(distributor) => {
							const rowData = {
								key: distributor.id,
								fullname: distributor.fullname,
							};
							action?.payload?.quantities?.forEach((quantity) => {
								if (
									quantity.distributor_id === distributor.id
								) {
									const product =
										action?.payload?.products.find(
											(prod) =>
												prod.id === quantity.product_id
										);
									rowData[product?.id + "-qty"] = parseFloat(
										quantity.quantity
									).toFixed(2); // Using product_id as key for Qty
									rowData[product?.id + "-size"] =
										quantity.unit_name; // Using product_id as key for Size
									rowData[product?.id + "-unit_id"] =
										quantity.unit_id;
									rowData[
										product?.id +
											"-return_request_items_ids"
									] = quantity.return_request_items_ids;
								}
							});
							return rowData;
						}
					);
					state.returnsheetsList = tempData;
					state.quantities = action.payload?.quantities;
				}
			)
			.addCase(
				getReturnSheetItemsBySuperStockist.rejected,
				(state, action) => {
					state.status = "getReturnSheetItemsBySuperStockist failed";
					state.loading = false;
					state.error = action.error.message;
				}
			)
			//post data
			.addCase(postReturnSheet.pending, (state, action) => {
				state.status = "postReturnSheet loading";
				state.loading = true;
			})
			.addCase(postReturnSheet.fulfilled, (state, action) => {
				state.status = "postReturnSheet succeeded";
				state.loading = false;
				state.selectedData = {};
			})
			.addCase(postReturnSheet.rejected, (state, action) => {
				state.status = "postReturnSheet failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//edit data
			// .addCase(editReturnSheet.pending, (state, action) => {
			// 	state.status = "editReturnSheet loading";
			// 	state.loading = true;
			// })
			// .addCase(editReturnSheet.fulfilled, (state, action) => {
			// 	state.status = "editReturnSheet succeeded";
			// 	state.loading = false;
			// 	state.selectedData = {};
			// })
			// .addCase(editReturnSheet.rejected, (state, action) => {
			// 	state.status = "editReturnSheet failed";
			// 	state.loading = false;
			// 	state.error = action.error.message;
			// })
			// .addCase(returnSheetExport.pending, (state, action) => {
			// 	state.status = "returnSheetExport loading";
			// 	state.loading = true;
			// })
			// .addCase(returnSheetExport.fulfilled, (state, action) => {
			// 	state.status = "returnSheetExport succeeded";
			// 	state.loading = false;
			// })
			// .addCase(returnSheetExport.rejected, (state, action) => {
			// 	state.status = "returnSheetExport failed";
			// 	state.loading = false;
			// 	state.error = action.error.message;
			// })
			//get bu id data
			.addCase(getReturnSheetByID.pending, (state, action) => {
				state.status = "getReturnSheetByID loading";
				state.loading = true;
			})
			.addCase(getReturnSheetByID.fulfilled, (state, action) => {
				state.status = "getReturnSheetByID succeeded";
				state.loading = false;
				const distributors = action.payload?.returns?.map(
					(order) => order.distributor
				);
				let quantities = [];
				action?.payload?.products.forEach((product) => {
					action.payload?.returns?.forEach((order) => {
						order.return_items?.forEach((item) => {
							if (product.id == item.product?.id) {
								quantities.push({
									...item,
									distributor_id: order?.distributor?.id,
									d_total: order?.d_total,
								});
							}
						});
					});
				});
				const tempData = distributors?.map((distributor) => {
					const rowData = {
						key: distributor.id,
						fullname: distributor.fullname,
					};
					quantities?.forEach((quantity) => {
						if (quantity.distributor_id === distributor.id) {
							const product = quantity?.product;
							rowData[product?.id + "-qty"] = Math.round(
								quantity.quantity
							); // Using product_id as key for Qty
							rowData[product?.id + "-size"] =
								quantity.unit?.name; // Using product_id as key for Size
							rowData[product?.id + "-unit_id"] =
								quantity.unit?.id;
							rowData[product?.id + "-unit_units"] =
								quantity.unit?.units;
							rowData["d_total"] = quantity.d_total;
						}
					});
					return rowData;
				});

				const sumByProduct = {};
				tempData?.forEach((item) => {
					const total = parseFloat(item.d_total);
					if (!sumByProduct["d_total"]) {
						sumByProduct["d_total"] = 0;
					}
					sumByProduct["d_total"] += total;
					Object.keys(item).forEach((key) => {
						if (key.endsWith("-qty")) {
							const productId = key
								.split("-")
								.slice(0, -1)
								.join("-"); // Extract product ID from the key
							const quantity =
								parseFloat(item[`${productId}-qty`]) *
								parseFloat(item[`${productId}-unit_units`]); // Parse quantity as a float
							// Initialize sum for the product ID if it doesn't exist
							if (!sumByProduct[`${productId}-qty`]) {
								sumByProduct[`${productId}-qty`] = 0;
							}

							// Add quantity to the sum for the product ID
							sumByProduct[`${productId}-qty`] += quantity;
						}
					});
				});

				state.returnsheetsList = [
					...tempData,
					{
						key: "total",
						fullname: "Total Quantity",
						...sumByProduct,
					},
				];
				state.distributors = distributors;
				state.quantities = quantities;
				state.products = action?.payload?.products;
				state.selectedData = action.payload;
			})
			.addCase(getReturnSheetByID.rejected, (state, action) => {
				state.status = "getReturnSheetByID failed";
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

// Action creators are generated for each case reducer function
export const {
	isDrawerVisible,
	changeParams,
	changeProductListParams,
	clearData,
	setSelectedData,
	inputChangeValue,
	addProductsToList,
	removeProductsFromList,
	changeProductList,
	setOpenAddressModal,
	setOpenAddAddressModal,
	changeFilterParams,
	FilterInputChangeValue,
	changeReturnSheetList,
} = returnsheetSlice.actions;

export default returnsheetSlice.reducer;
