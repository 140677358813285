import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { getProductsList } from "./productsAction";

const productsSlice = createSlice({
  name: "products",
  initialState: {
    productsData: {
      loading: false,
      productsList: [],
      productsCount: 0,
      productsParams: {
        start_date: "",
        end_date: "",
        no_of_pages: 0,
        page_size: 10,
        page: 1,
        search: "",
      }
    },
    productsCategoryData: {
      loading: false,
      list: [],
      count: 0,
      productsCategoryParams: {
        start_date: "",
        end_date: "",
        no_of_pages: 0,
        page_size: 10,
        page: 1,
        search: "",
      }
    }

  },
  reducers: {
    setProductsParams: (state, action) => {
      return {
        ...state,
        productsParams: action.payload,
      };
    },
    isDrawerVisible: (state, action) => {
      return {
        ...state,
        drawer: action.payload,
      };
    },
    clearProducts: (state, action) => {
      return {
        ...state,
        loading: true,
        productsList: [],
        filterStatus: false,
        productsParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      //post Data
      .addCase(getProductsList.pending, (state, action) => {
        state.status = "getProductsList loading";
        state.loading = true;
      })
      .addCase(getProductsList.fulfilled, (state, action) => {
        state.status = "getProductsList succeeded";
        state.loading = false;
        var noofpages = Math.ceil(
          action.payload.response.count / state.productsParams.page_size
        );
        state.listCount = action.payload.response.count;
        state.productsList = action.payload.response.results;
        state.productsParams = {
          ...state.productsParams,
          no_of_pages: noofpages,
        }
      })
      .addCase(getProductsList.rejected, (state, action) => {
        state.status = "getProductsList failed";
        state.loading = false;
        state.error = action.error.message;
      })
  }
});

// Action creators are generated for each case reducer function
export const {
  setProductsParams,
  isDrawerVisible,
  clearProducts,
} = productsSlice.actions;

export default productsSlice.reducer;
