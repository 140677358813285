import { createSlice } from "@reduxjs/toolkit";
import {
	allMenus,
	getMenuItems,
	getUserPermissions,
	postAttachments,
	postHostImage,
	postImage,
	postPosterAttachment,
	postZoneAttachments,
	postNotificationAttachment,
	exportMenuItemsList,
	getExportMenuItems,
	getImportMenuItems,
	getReportsMenuItems,
} from "./systemActions";
import { act } from "react-dom/test-utils";

export const systemSlice = createSlice({
	name: "system",
	initialState: {
		masterMenuItemsList: [],
		sideMenuItemsList: [],
		reportsLoading: false,
		reportsMenuItemsList: [],
		userAccessList: [],
		quickMenuItemsList: [],
		importMenuItemsList: [],
		profileMenuItemsList: [],
		exportMenuItemsList: [],
		notificationAttachment: {},
		filterData: [],
		attachmentData: [],
		posterData: {},
		hostImageData: {},
		eventImage: {},
		zoneAttachmentsData: {},
		active: false,
		quickMenuactive: false,
		menuactive: false,
		systemFormulasList: [],
	},
	reducers: {
		clearAttachments: (state, action) => {
			state.attachmentData = [];
			state.posterData = {};
			state.hostImageData = {};
			state.eventImage = {};
			state.zoneAttachmentsData = {};
		},
		removeAttachmentData: (state, action) => {
			console.log(action.payload);
			return {
				...state,
				attachmentData: state.attachmentData.filter(
					(e) => e.id != action.payload
				),
			};
		},
		removeZoneAttachmentData: (state, action) => {
			return {
				...state,
				zoneAttachmentsData: {},
			};
		},

		removePosterData: (state, action) => {
			return {
				...state,
				posterData: {},
			};
		},
		addAttachmentDataData: (state, action) => {
			return {
				...state,
				attachmentData: action.payload,
			};
		},
		addPosterData: (state, action) => {
			return {
				...state,
				posterData: action.payload,
			};
		},
		addHostImageData: (state, action) => {
			return {
				...state,
				hostImageData: action.payload,
			};
		},
		addImageAttachment: (state, action) => {
			return {
				...state,
				eventImage: action.payload,
			};
		},
		removeHostImage: (state, action) => {
			return {
				...state,
				hostImageData: {},
			};
		},
		removeImageAttachment: (state, action) => {
			return {
				...state,
				eventImage: {},
			};
		},
		userMenuActive: (state, action) => {
			return {
				...state,
				menuactive: action.payload,
			};
		},
		menuItemSearch: (state, action) => {
			return {
				...state,
				masterMenuItemsList: {
					menuitems: action.payload,
				},
			};
		},
		searchReports: (state, action) => {
			return {
				...state,
				reportsMenuItemsList: {
					menuitems: action.payload,
				},
			};
		},
	},
	extraReducers(builder) {
		builder
			//all menus
			.addCase(allMenus.pending, (state, action) => {
				state.status = "loading";
				state.loading = true;
			})
			.addCase(allMenus.fulfilled, (state, action) => {
				const { count, results } = action.payload;
				state.status = "succeeded";
				state.loading = false;
				state.allMenus.list = results;
				state.allMenus.count = count;
			})
			.addCase(allMenus.rejected, (state, action) => {
				state.status = "failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//get menus
			.addCase(getMenuItems.pending, (state, action) => {
				state.status = "loading";
				state.loading = true;
			})
			.addCase(getMenuItems.fulfilled, (state, action) => {
				const { from, response } = action.payload;
				// action.payload.menuitems = action.payload.menuitems?.sort((a, b) =>
				// 	a.sequence > b.sequence ? 1 : -1
				//   );
				//   action.payload.submenus = action.payload.submenus?.sort((a, b) =>
				// 	a.sequence > b.sequence ? 1 : -1
				//   );
				//   action.payload.submenus?.map((profileMenu) => {
				// 	profileMenu.menuitems = profileMenu.menuitems?.sort((a, b) =>
				// 	  a.sequence > b.sequence ? 1 : -1
				// 	);
				// 	return profileMenu;
				//   });
				state.status = "succeeded";
				state.loading = false;
				switch (from) {
					case "masters":
						state.masterMenuItemsList = response;
						break;
					case "sidemenu":
						state.sideMenuItemsList = response;
						break;
					case "profilemenu":
						state.profileMenuItemsList = response;
						break;
				}
			})
			.addCase(getMenuItems.rejected, (state, action) => {
				state.status = "failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//post Attachmnets
			.addCase(postAttachments.pending, (state, action) => {
				state.status = "loading";
				state.loading = true;
			})
			.addCase(postAttachments.fulfilled, (state, action) => {
				const { response } = action.payload;
				state.status = "succeeded";
				state.loading = false;
				state.attachmentData = [...state.attachmentData, response];
			})
			.addCase(postAttachments.rejected, (state, action) => {
				state.status = "failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//post Poster Attachmnets
			.addCase(postPosterAttachment.pending, (state, action) => {
				state.status = "loading";
				state.loading = true;
			})
			.addCase(postPosterAttachment.fulfilled, (state, action) => {
				const { response } = action.payload;
				state.status = "succeeded";
				state.loading = false;
				state.posterData = response;
			})
			.addCase(postPosterAttachment.rejected, (state, action) => {
				state.status = "failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//post host Image
			.addCase(postHostImage.pending, (state, action) => {
				state.status = "loading";
				state.loading = true;
			})
			.addCase(postHostImage.fulfilled, (state, action) => {
				const { response } = action.payload;
				state.status = "succeeded";
				state.loading = false;
				state.hostImageData = response;
			})
			.addCase(postHostImage.rejected, (state, action) => {
				state.status = "failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//post single Image
			.addCase(postImage.pending, (state, action) => {
				state.status = "loading";
				state.loading = true;
			})
			.addCase(postImage.fulfilled, (state, action) => {
				const { response } = action.payload;
				state.status = "succeeded";
				state.loading = false;
				state.eventImage = response;
			})
			.addCase(postImage.rejected, (state, action) => {
				state.status = "failed";
				state.loading = false;
				state.error = action.error.message;
			})
			// zones images data
			.addCase(postZoneAttachments.pending, (state, action) => {
				state.status = "loading";
				state.loading = true;
			})
			.addCase(postZoneAttachments.fulfilled, (state, action) => {
				const { response } = action.payload;
				state.status = "succeeded";
				state.loading = false;
				state.zoneAttachmentsData = response;
			})
			.addCase(postZoneAttachments.rejected, (state, action) => {
				state.status = "failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//notificationb attachment
			.addCase(postNotificationAttachment.pending, (state, action) => {
				state.status = "loading";
				state.loading = true;
			})
			.addCase(postNotificationAttachment.fulfilled, (state, action) => {
				const { response } = action.payload;
				state.status = "succeeded";
				state.loading = false;
				state.notificationAttachment = response;
			})
			.addCase(postNotificationAttachment.rejected, (state, action) => {
				state.status = "failed";
				state.loading = false;
				state.error = action.error.message;
			})
			.addCase(getUserPermissions.pending, (state, action) => {
				state.status = "loading";
			})
			.addCase(getUserPermissions.fulfilled, (state, action) => {
				state.status = "succeeded";
				// Add any fetched posts to the array
				state.userAccessList = action.payload;
			})
			.addCase(getUserPermissions.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			})
			.addCase(getExportMenuItems.pending, (state, action) => {
				state.status = "getExportMenuItems loading";
			})
			.addCase(getExportMenuItems.fulfilled, (state, action) => {
				state.status = "getExportMenuItems succeeded";
				// Add any fetched posts to the array
				state.exportMenuItemsList = action.payload;
			})
			.addCase(getExportMenuItems.rejected, (state, action) => {
				state.status = "getExportMenuItems failed";
				state.error = action.error.message;
			})
			.addCase(getImportMenuItems.pending, (state, action) => {
				state.status = "getImportMenuItems loading";
			})
			.addCase(getImportMenuItems.fulfilled, (state, action) => {
				state.status = "getImportMenuItems succeeded";
				// Add any fetched posts to the array
				state.importMenuItemsList = action.payload;
			})
			.addCase(getImportMenuItems.rejected, (state, action) => {
				state.status = "getImportMenuItems failed";
				state.error = action.error.message;
			})
			.addCase(getReportsMenuItems.pending, (state, action) => {
				state.status = "getReportsMenuItems loading";
				state.reportsLoading = true;
			})
			.addCase(getReportsMenuItems.fulfilled, (state, action) => {
				state.status = "getReportsMenuItems succeeded";
				state.reportsLoading = false;
				action.payload.menuitems = action.payload.menuitems.sort(
					(a, b) => (a.sequence > b.sequence ? 1 : -1)
				);
				// Add any fetched posts to the array
				state.reportsMenuItemsList = action.payload;
			})
			.addCase(getReportsMenuItems.rejected, (state, action) => {
				state.status = "getReportsMenuItems failed";
				state.reportsLoading = false;
				state.error = action.error.message;
			});
	},
});

// Action creators are generated for each case reducer function
export const {
	userMenuActive,
	clearAttachments,
	menuItemSearch,
	addAttachmentDataData,
	removeAttachmentData,
	removeZoneAttachmentData,
	addPosterData,
	removePosterData,
	addHostImageData,
	removeHostImage,
	addImageAttachment,
	removeImageAttachment,
	searchReports,
} = systemSlice.actions;

export default systemSlice.reducer;
