import { Selector, Dispatch } from "../../../store/index";
import TableComponent from "../../components/components-page/TableComponent";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { customChngParams } from "../../../store/dashboard/dashboardSlice";
import { getDashboardCustmOrders } from "../../../store/dashboard/dashboardAction";

import { Row, Col, Card, Spin, Space } from "antd";

const CustomerOrderCard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const customParams = Selector("dashboard", "customParams");
  const filterParamsData = Selector("dashboard", "filterParams");
  const loading = Selector("dashboard", "customLoading");
  const count = Selector("dashboard", "customCount");
  const customList = Selector("dashboard", "customList");

  const columns = [
    {
      title: <b>S.No</b>,
      dataIndex: "index",
      key: "index",
      fixed: "left",
    },
    {
      title: <b>Order Date</b>,
      render: (text) => (
        <span>{!text.date ? "N/A" : text.date}</span>
      ),
      key: "date",
      fixed: "left",
    },
    {
      title: <b>Order ID</b>,
      dataIndex: "code",
      key: "code",
      fixed: "left",
    },
    {
      title: <b>Customer ID</b>,
      render: (text) => <span>{text.customer_code ?? "None"}</span>,
    },
    {
      title: <b>Customer</b>,
      render: (text) => <span>{text.customer_name ?? "None"}</span>,
    },
    {
      title: <b>No of Items</b>,
      dataIndex: "no_of_items",
      key: "no_of_items",
    },
    {
      title: <b>Net Price</b>,
      render: (text) => <span>{text.net == "" ? "None" : text.net}</span>,
    },

    {
      title: <b>Order Type</b>,
      dataIndex: "order_type_name",
      key: "order_type_name",
    },

    {
      title: "Actions",
      key: "action",
      sorter: false,
      width: 100,
      render: (row) => (
        <Space size="middle">
          <Link to={`/customer_orders/view/` + row.id}>
            <i
              className="ri-eye-line hp-mr-2 "
              style={{ cursor: "pointer", color: "#fc6f03" }}
            ></i>
          </Link>
        </Space>
      ),
    },
  ];

  const data = customList?.map((row, key) => ({
    ...row,
    index: (customParams.page - 1) * customParams.page_size + (key + 1),
  }));

  function onPageChange(page, page_size) {
    Dispatch(dispatch, getDashboardCustmOrders, {
      ...customParams,
      ...filterParamsData,
      page: page,
      page_size: page_size,
    });
    Dispatch(dispatch, customChngParams, {
      ...customParams,
      page: page,
      page_size: page_size,
    });
  }

  const customChngParams = (params) => {
    Dispatch(dispatch, getDashboardCustmOrders, { ...params, ...filterParamsData });
    Dispatch(dispatch, customChngParams, { ...params });
  };

  function showTotal(total) {
    return "Total " + total + " items";
  }

  return (
    <Row gutter={[12, 0]}>
      <Col span={24} xs={24}>
        <Spin tip="Loading..." spinning={loading} delay={500}>
          <TableComponent
            page={customParams.page}
            count={count}
            pageSize={customParams.page_size}
            columns={columns}
            data={data}
            // loading={loading}
            onPageChange={onPageChange}
            showTotal={showTotal}
            scroll={false}
          />
        </Spin>
      </Col>
    </Row>
  );
};

export default CustomerOrderCard;
