import { createSlice } from "@reduxjs/toolkit";
import {
	// approveSubscriptionsByID,
	// distributorReturnProcessing,
	// distributorReturnProcessingStatus,
	editSubscriptions,
	postSubscriptions,
	getSubscriptionByID,
	getSubscriptions,
	subscriptionActive,
	subscriptionInActive,
	getSubscriptionsLogs,
} from "./subscription.action";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

const subscriptionSlice = createSlice({
	name: "subscriptions",
	initialState: {
		loading: false,
		approvalLoading: false,
		rejectLoading: false,
		error: "",
		status: "",
		model: false,
		list: [],
		selectedData: {},
		count: 0,
		drawer: false,

		scheduleModal: false,
		scheduleData: {},
		checkedList: [],
		checkedQuantityValue: [],

		subscriptionLogsLoading: false,
		subscriptionLogsCount: 0,
		subscriptionLogsList: [],

		filterParams: {},
		productsList: [],
		productListparams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
		pageParams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
		subscriptionLogsParams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
	},
	reducers: {
		isDrawerVisible: (state, action) => {
			return {
				...state,
				drawer: action.payload,
			};
		},
		setCheckedList: (state, action) => {
			return {
				...state,
				checkedList: action.payload,
			};
		},
		setCheckedQuantityValue: (state, action) => {
			return {
				...state,
				checkedQuantityValue: action.payload,
			};
		},
		isModelVisible: (state, action) => {
			return {
				...state,
				model: action.payload,
			};
		},
		isSheduleModalVisible: (state, action) => {
			return {
				...state,
				scheduleModal: action.payload,
			};
		},
		FilterInputChangeValue: (state, action) => {
			return {
				...state,
				filterParams: {
					...state.filterParams,
					[action.payload.key]: action.payload.value,
				},
			};
		},
		changeParams: (state, action) => {
			return {
				...state,
				pageParams: action.payload,
			};
		},
		changeProductListParams: (state, action) => {
			return {
				...state,
				productListparams: action.payload,
			};
		},
		changeSubscriptionLogsParams: (state, action) => {
			return {
				...state,
				subscriptionLogsParams: action.payload,
			};
		},
		changeFilterParams: (state, action) => {
			return {
				...state,
				filterParams: action.payload,
			};
		},

		setSelectedData: (state, action) => {
			return {
				...state,
				selectedData: action.payload,
			};
		},
		setScheduleData: (state, action) => {
			return {
				...state,
				scheduleData: action.payload,
			};
		},
		inputChangeValue: (state, action) => {
			return {
				...state,
				selectedData: {
					...state.selectedData,
					...action.payload,
				},
			};
		},
		addProductsToList: (state, action) => {
			return {
				...state,
				productsList: [...state.productsList, action.payload],
			};
		},
		removeProductsFromList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => {
					return e.uniqueId == action.payload.product_id
						? {
								...e,
								dodelete: true,
						  }
						: {
								...e,
						  };
				}),
				selectedData: {
					...state.selectedData,
					start_date: "",
					end_date: "",
					subscriptionschedules: [],
				},
			};
		},
		clearProductsList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => ({
					...e,
					dodelete: true,
				})),
			};
		},
		changeProductList: (state, action) => {
			console.log(action.payload);
			return {
				...state,
				productsList: state.productsList.map((e) => {
					if (e.uniqueId == action.payload.product_id) {
						if (action.payload.key == "quantity") {
							const calculatedPrice =
								parseFloat(action.payload.value) *
								parseFloat(e.listedPrice) *
								parseFloat(e.unit.units);
							const discountAmt = e.discount_percent
								? +calculatedPrice * (+e.discount_percent / 100)
								: 0;
							const taxableValue = calculatedPrice - discountAmt;
							// const gstAmt = e.gst_percentage
							// 	? parseFloat(taxableValue) *
							// 	  (+e.gst_percentage / 100)
							// 	: 0;
							// const totalPrice =
							// 	parseFloat(taxableValue) + parseFloat(gstAmt);
							const totalPrice = parseFloat(taxableValue);
							return {
								...e,
								[action.payload.key]: action.payload.value,
								discountAmt: discountAmt.toFixed(2),
								// gstAmt: gstAmt.toFixed(2),
								price: totalPrice.toFixed(2),
								// taxableValue: taxableValue.toFixed(2),
								originalPrice: calculatedPrice.toFixed(2),
								dodelete: action.payload.dodelete,
							};
						} else {
							const calculatedPrice =
								parseFloat(e.quantity) *
								parseFloat(e.listedPrice) *
								parseFloat(action.payload.value?.units);
							const discountAmt = e.discount_percent
								? +calculatedPrice * (+e.discount_percent / 100)
								: 0;
							const taxableValue = calculatedPrice - discountAmt;
							// const gstAmt = e.gst_percentage
							// 	? parseFloat(taxableValue) *
							// 	  (+e.gst_percentage / 100)
							// 	: 0;
							// const totalPrice =
							// 	parseFloat(taxableValue) + parseFloat(gstAmt);
							const totalPrice = parseFloat(taxableValue);
							return {
								...e,
								[action.payload.key]: action.payload.value,
								discountAmt: discountAmt.toFixed(2),
								// gstAmt: gstAmt.toFixed(2),
								price: totalPrice.toFixed(2),
								// taxableValue: taxableValue.toFixed(2),
								originalPrice: calculatedPrice.toFixed(2),
								dodelete: action.payload.dodelete,
							};
						}
					} else {
						return e;
					}
				}),
			};
		},
		clearData: (state, action) => {
			return {
				...state,
				loading: false,
				approvalLoading: false,
				rejectLoading: false,
				error: "",
				status: "",
				model: false,
				list: [],
				selectedData: {},
				count: 0,

				scheduleModal: false,
				scheduleData: {},
				checkedList: [],
				checkedQuantityValue: [],

				filterParams: {},
				productsList: [],
				productListparams: {
					start_date: "",
					end_date: "",
					no_of_pages: 0,
					page_size: 10,
					page: 1,
					search: "",
				},
				pageParams: {
					start_date: "",
					end_date: "",
					no_of_pages: 0,
					page_size: 10,
					page: 1,
					search: "",
				},
			};
		},
	},
	extraReducers: (builder) => {
		builder
			//get Data
			.addCase(getSubscriptions.pending, (state, action) => {
				state.status = "getSubscriptions loading";
				state.loading = true;
			})
			.addCase(getSubscriptions.fulfilled, (state, action) => {
				state.status = "getSubscriptions succeeded";
				state.loading = false;
				var noofpages = Math.ceil(
					action.payload.count / state.pageParams.page_size
				);
				state.count = action.payload.count;
				state.list = action.payload.results;
				state.selectedData = {};
			})
			.addCase(getSubscriptions.rejected, (state, action) => {
				state.status = "getSubscriptions failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//get Logs Data
			.addCase(getSubscriptionsLogs.pending, (state, action) => {
				state.status = "getSubscriptionsLogs loading";
				state.subscriptionLogsLoading = true;
			})
			.addCase(getSubscriptionsLogs.fulfilled, (state, action) => {
				state.status = "getSubscriptionsLogs succeeded";
				state.subscriptionLogsLoading = false;
				var list = [];
				if (state.subscriptionLogsParams?.page == 1) {
					list = action.payload.results;
				} else {
					list = [
						...state.subscriptionLogsList,
						...action.payload.results,
					];
				}
				var noofpages = Math.ceil(
					action.payload.count /
						state.subscriptionLogsParams.page_size
				);
				state.subscriptionLogsCount = action.payload.count;
				state.subscriptionLogsList = list;
				state.subscriptionLogsParams = {
					...state.subscriptionLogsParams,
					no_of_pages: noofpages,
				};
			})
			.addCase(getSubscriptionsLogs.rejected, (state, action) => {
				state.status = "getSubscriptionsLogs failed";
				state.subscriptionLogsLoading = false;
				state.error = action.error.message;
			})
			// .addCase(approveSubscriptionsByID.pending, (state, action) => {
			// 	state.status = "approveSubscriptionsByID loading";
			// 	action.meta.arg.indent_status == 2
			// 		? (state.approvalLoading = true)
			// 		: (state.rejectLoading = true);
			// })
			// .addCase(
			// 	approveSubscriptionsByID.fulfilled,
			// 	(state, action) => {
			// 		state.status = "approveSubscriptionsByID succeeded";
			// 		action.payload.indent_status == 2
			// 			? (state.approvalLoading = false)
			// 			: (state.rejectLoading = false);
			// 	}
			// )
			// .addCase(approveSubscriptionsByID.rejected, (state, action) => {
			// 	state.status = "approveSubscriptionsByID failed";
			// 	state.approvalLoading = false;
			// 	state.rejectLoading = false;
			// 	state.error = action.error.message;
			// })
			// .addCase(distributorReturnProcessing.pending, (state, action) => {
			// 	state.status = "distributorReturnProcessing loading";
			// 	state.loading = true;
			// })
			// .addCase(distributorReturnProcessing.fulfilled, (state, action) => {
			// 	state.status = "distributorReturnProcessing succeeded";
			// 	state.loading = true;
			// })
			// .addCase(distributorReturnProcessing.rejected, (state, action) => {
			// 	state.status = "distributorReturnProcessing failed";
			// 	state.loading = false;
			// 	state.error = action.error.message;
			// })
			// .addCase(
			// 	distributorReturnProcessingStatus.pending,
			// 	(state, action) => {
			// 		state.status = "distributorReturnProcessingStatus loading";
			// 		state.loading = true;
			// 	}
			// )
			// .addCase(
			// 	distributorReturnProcessingStatus.fulfilled,
			// 	(state, action) => {
			// 		state.status =
			// 			"distributorReturnProcessingStatus succeeded";
			// 		state.loading = false;
			// 	}
			// )
			// .addCase(
			// 	distributorReturnProcessingStatus.rejected,
			// 	(state, action) => {
			// 		state.status = "distributorReturnProcessingStatus failed";
			// 		state.loading = false;
			// 		state.error = action.error.message;
			// 	}
			// )
			//get Data by Id
			.addCase(getSubscriptionByID.pending, (state, action) => {
				state.status = "getSubscriptionByID loading";
				state.loading = true;
			})
			.addCase(getSubscriptionByID.fulfilled, (state, action) => {
				state.status = "getSubscriptionByID succeeded";
				state.loading = false;
				state.selectedData = {
					...action.payload,
					description: action.payload.description,
					location: action.payload.customer?.location,
					product_id: action.payload.product?.id,
					unit_id: action.payload.unit?.id,
				};

				const plainOptions = [
					"Sunday",
					"Monday",
					"Tuesday",
					"Wednesday",
					"Thursday",
					"Friday",
					"Saturday",
				];
				const subscriptionschedules =
					action.payload?.subscriptionschedules;

				const scheduleData = {
					start_date: action.payload?.start_date
						? moment(action.payload?.start_date)?.clone()
						: null,
					end_date: action.payload?.end_date
						? moment(action.payload?.end_date)?.clone()
						: null,
					type: subscriptionschedules[0]?.type,
				};

				if (subscriptionschedules.length != 0) {
					if (subscriptionschedules[0].type == 3) {
						let arr = [...state.checkedList];
						let checkedValues = [...state.checkedQuantityValue];

						plainOptions.forEach((data, index) => {
							if (
								subscriptionschedules.find(
									(schedule) => schedule.day_name == data
								)
							) {
								arr[index] = data;
								checkedValues[index] =
									subscriptionschedules.find(
										(schedule) => schedule.day_name == data
									).quantity;
							} else {
								arr[index] = null;
								const checkedValues = [
									...state.checkedQuantityValue,
								];
								checkedValues[index] = "";
							}
						});
						state.checkedList = arr;
						state.checkedQuantityValue = checkedValues;
					} else {
						scheduleData.noofdays =
							subscriptionschedules[0].noofdays;
					}
					state.scheduleData = scheduleData;
				}
				const response = action.payload;

				const calculatedPrice = parseFloat(response?.total);
				var discountAmt = parseFloat(response?.discount_amount);
				var taxableValue = parseFloat(response?.gross);
				var gstAmt = parseFloat(response?.tax_amount);
				var totalPrice = parseFloat(response?.net);

				const productsList = [];

				const obj = {
					...response,
					item_id: response.id,
					product_id: response.product.id,
					image: response.product.image,
					name: response.product.name,
					listedPrice: parseFloat(response.price).toFixed(2),
					originalPrice: parseFloat(calculatedPrice).toFixed(2),
					discountAmt: parseFloat(discountAmt).toFixed(2),
					gstAmt: parseFloat(gstAmt).toFixed(2),
					taxableValue: parseFloat(taxableValue).toFixed(2),
					price: parseFloat(totalPrice).toFixed(2),
					size: response?.unit?.id,
					gst_percentage: response?.tax_percent,
					discount_percent: response?.discount_percent,
					quantity: parseInt(response?.quantity),
					unit: response?.unit,
					uniqueId: uuidv4(),
					mrp: response.mrp,
					showCancelIcon: true,
					dodelete: false,
					// accepted_units: response?.accepted_units,
				};
				productsList.push({ ...obj });
				const productScheme = response?.customerscheme;
				const schemetype = productScheme?.scheme_type;
				if (schemetype == 2) {
					const freeProduct = {
						...productScheme?.free_product,
						product: productScheme?.product,
						freeItem: true,
						discount_percent: 0,
						free_quantity: productScheme?.free_quantity,
						free_unit: productScheme?.free_unit,
						uniqueId: obj.uniqueId,
						dodelete: false,
						showCancelIcon: false,
					};
					productsList.push({ ...freeProduct });
				}
				state.productsList = productsList;
			})
			.addCase(getSubscriptionByID.rejected, (state, action) => {
				state.status = "getSubscriptionByID failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//post data
			.addCase(postSubscriptions.pending, (state, action) => {
				state.status = "postSubscriptions loading";
				state.loading = true;
			})
			.addCase(postSubscriptions.fulfilled, (state, action) => {
				state.status = "postSubscriptions succeeded";
				state.loading = false;
				state.selectedData = {};
			})
			.addCase(postSubscriptions.rejected, (state, action) => {
				state.status = "postSubscriptions failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//edit data
			.addCase(editSubscriptions.pending, (state, action) => {
				state.status = "editSubscriptions loading";
				state.loading = true;
			})
			.addCase(editSubscriptions.fulfilled, (state, action) => {
				state.status = "editSubscriptions succeeded";
				state.loading = false;
				state.selectedData = {};
			})
			.addCase(editSubscriptions.rejected, (state, action) => {
				state.status = "editSubscriptions failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//Active data
			.addCase(subscriptionActive.pending, (state, action) => {
				state.status = "subscriptionActive loading";
				state.loading = true;
			})
			.addCase(subscriptionActive.fulfilled, (state, action) => {
				state.status = "subscriptionActive succeeded";
				state.loading = false;
			})
			.addCase(subscriptionActive.rejected, (state, action) => {
				state.status = "subscriptionActive failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//Inactive data
			.addCase(subscriptionInActive.pending, (state, action) => {
				state.status = "subscriptionInActive loading";
				state.loading = true;
			})
			.addCase(subscriptionInActive.fulfilled, (state, action) => {
				state.status = "subscriptionInActive succeeded";
				state.loading = false;
			})
			.addCase(subscriptionInActive.rejected, (state, action) => {
				state.status = "subscriptionInActive failed";
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

// Action creators are generated for each case reducer function
export const {
	changeParams,
	changeProductListParams,
	clearData,
	setSelectedData,
	inputChangeValue,
	addProductsToList,
	removeProductsFromList,
	changeProductList,
	isDrawerVisible,
	FilterInputChangeValue,
	changeFilterParams,
	clearProductsList,
	isModelVisible,
	isSheduleModalVisible,
	setScheduleData,
	setCheckedList,
	setCheckedQuantityValue,
	changeSubscriptionLogsParams,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
