import { createSlice } from "@reduxjs/toolkit";
import {
	editRetailer,
	editRetailerput,
	getRetailerByID,
	getRetailers,
	getRetailersAddress,
	postRetailer,
	postRetailersAddress,
} from "./retailers.action";

const retailersSlice = createSlice({
	name: "retailers",
	initialState: {
		loading: false,
		loadingAddresses: false,
		error: "",
		status: "",
		list: [],
		selectedData: {
			default_billing_address: {},
			default_shipping_address: {},
		},
		addressData: {
			default_billing_address: {},
			default_shipping_address: {},
		},
		addressList: [],
		drawer: false,
		openModal: false,
		openUpdateModal: false,
		openAddressModal: false,
		openAddAddressModal: false,
		count: 0,
		addressCount: 0,
		filterParams: {},
		pageParams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
		addressPageParams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
	},
	reducers: {
		isDrawerVisible: (state, action) => {
			return {
				...state,
				drawer: action.payload,
			};
		},
		setOpenModel: (state, action) => {
			return {
				...state,
				openModal: action.payload,
			};
		},
		setUpdateOpenModel: (state, action) => {
			return {
				...state,
				openUpdateModal: action.payload,
			};
		},
		changeParams: (state, action) => {
			return {
				...state,
				pageParams: action.payload,
			};
		},
		setOpenAddressModal: (state, action) => {
			return {
				...state,
				openAddressModal: action.payload,
			};
		},
		setOpenAddAddressModal: (state, action) => {
			return {
				...state,
				openAddAddressModal: action.payload,
			};
		},
		changeAddressParams: (state, action) => {
			return {
				...state,
				addressPageParams: action.payload,
			};
		},
		changeFilterParams: (state, action) => {
			return {
				...state,
				filterParams: action.payload,
			};
		},
		// clearFilterParamsData: (state, action) => {
		// 	return {
		// 		...state,
		// 		filterParams: {},
		// 	};
		// },
		FilterInputChangeValue: (state, action) => {
			return {
				...state,
				filterParams: {
					...state.filterParams,
					[action.payload.key]: action.payload.value,
				},
			};
		},
		setSelectedData: (state, action) => {
			return {
				...state,
				selectedData: action.payload,
			};
		},
		inputChangeValue: (state, action) => {
			console.log(action.payload);
			return {
				...state,
				selectedData: {
					...state.selectedData,
					...action.payload,
				},
			};
		},
		changeShippingAddress: (state, action) => {
			return {
				...state,
				addressData: {
					...state.addressData,
					...action.payload,
				},
			};
		},
		clearShippingAddress: (state, action) => {
			return {
				...state,
				addressData: {},
			};
		},
		clearData: (state, action) => {
			return {
				...state,
				loading: false,
				error: "",
				status: "",
				list: [],
				selectedData: {},
				count: 0,
				pageParams: {
					start_date: "",
					end_date: "",
					no_of_pages: 0,
					page_size: 10,
					page: 1,
					search: "",
				},
			};
		},
	},
	extraReducers: (builder) => {
		builder
			//get Data
			.addCase(getRetailers.pending, (state, action) => {
				state.status = "getRetailers loading";
				state.loading = true;
			})
			.addCase(getRetailers.fulfilled, (state, action) => {
				state.status = "getRetailers succeeded";
				state.loading = false;
				var noofpages = Math.ceil(
					action.payload.count / state.pageParams.page_size
				);
				state.count = action.payload.count;
				state.list = action.payload.results;
				state.pageParams = {
					...state.pageParams,
					no_of_pages: noofpages,
				};
			})
			.addCase(getRetailers.rejected, (state, action) => {
				state.status = "getRetailers failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//get retaailer addresses
			.addCase(getRetailersAddress.pending, (state, action) => {
				state.status = "getRetailersAddress loading";
				state.loadingAddresses = true;
			})
			.addCase(getRetailersAddress.fulfilled, (state, action) => {
				state.status = "getRetailersAddress succeeded";
				state.loadingAddresses = false;
				var noofpages = Math.ceil(
					action.payload.count / state.addressPageParams.page_size
				);
				state.addressCount = action.payload.count;
				state.addressList = action.payload.results;
				state.addressPageParams = {
					...state.addressPageParams,
					no_of_pages: noofpages,
				};
			})
			.addCase(getRetailersAddress.rejected, (state, action) => {
				state.status = "getRetailersAddress failed";
				state.loadingAddresses = false;
				state.error = action.error.message;
			})
			//post retaailer addresses
			.addCase(postRetailersAddress.pending, (state, action) => {
				state.status = "postRetailersAddress loading";
				state.loadingAddresses = true;
			})
			.addCase(postRetailersAddress.fulfilled, (state, action) => {
				state.status = "postRetailersAddress succeeded";
				state.loadingAddresses = false;
				state.selectedData = {
					default_billing_address: {},
					default_shipping_address: {},
				};
			})
			.addCase(postRetailersAddress.rejected, (state, action) => {
				state.status = "postRetailersAddress failed";
				state.loadingAddresses = false;
				state.error = action.error.message;
			})
			//edit retailer
			.addCase(editRetailer.pending, (state, action) => {
				state.status = "editRetailer loading";
				state.loadingAddresses = true;
			})
			.addCase(editRetailer.fulfilled, (state, action) => {
				state.status = "editRetailer succeeded";
				state.loadingAddresses = false;
				// state.selectedData = {
				// 	...action.payload.data,
				// 	// salesagent_id: action.payload?.data?.salesagent?.id,
				// 	distributor_id: action.payload?.data?.distributor?.id,
				// 	location_id: action.payload?.data?.location?.id,
				// };
			})
			.addCase(editRetailer.rejected, (state, action) => {
				state.status = "editRetailer failed";
				state.loadingAddresses = false;
				state.error = action.error.message;
			})
			//edit put retailer
			.addCase(editRetailerput.pending, (state, action) => {
				state.status = "editRetailerput loading";
				state.loadingAddresses = true;
			})
			.addCase(editRetailerput.fulfilled, (state, action) => {
				state.status = "editRetailerput succeeded";
				state.loadingAddresses = false;
				state.selectedData = {
					...action.payload,
					salesagent_id: action.payload?.salesagent?.id,
					location_id: action.payload?.location?.id,
				};
			})
			.addCase(editRetailerput.rejected, (state, action) => {
				state.status = "editRetailerput failed";
				state.loadingAddresses = false;
				state.error = action.error.message;
			})
			//get Data by Id
			.addCase(getRetailerByID.pending, (state, action) => {
				state.status = "getRetailerByID loading";
				state.loading = true;
			})
			.addCase(getRetailerByID.fulfilled, (state, action) => {
				state.status = "getRetailerByID succeeded";
				state.loading = false;
				state.selectedData = {
					...action.payload,
					// salesagent_id: action.payload?.salesagent?.id,
					distributor_id: action.payload?.distributor?.id,
					location_id: action.payload?.location?.id,
				};
			})
			.addCase(getRetailerByID.rejected, (state, action) => {
				state.status = "getRetailerByID failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//post data
			.addCase(postRetailer.pending, (state, action) => {
				state.status = "postRetailer loading";
				state.loading = true;
			})
			.addCase(postRetailer.fulfilled, (state, action) => {
				state.status = "postRetailer succeeded";
				state.loading = false;
				state.selectedData = {
					default_billing_address: {},
					default_shipping_address: {},
				};
			})
			.addCase(postRetailer.rejected, (state, action) => {
				state.status = "postRetailer failed";
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

// Action creators are generated for each case reducer function
export const {
	setOpenAddressModal,
	setOpenAddAddressModal,
	isDrawerVisible,
	changeParams,
	changeFilterParams,
	clearData,
	setSelectedData,
	inputChangeValue,
	setOpenModel,
	setUpdateOpenModel,
	FilterInputChangeValue,
	changeAddressParams,
	changeShippingAddress,
	clearShippingAddress,
} = retailersSlice.actions;

export default retailersSlice.reducer;
