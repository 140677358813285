import { createSlice } from "@reduxjs/toolkit";
import {
	editCashCollectionRequest,
	// editCashCollectionRequestput,
	getCashCollectionRequestByID,
	getCashCollectionRequests,
	// getCashCollectionRequestsAddress,
	postCashCollectionRequest,
	// postCashCollectionRequestsAddress,
} from "./cashCollectionRequest.action";

const cashCollectionRequestSlice = createSlice({
	name: "cashCollectionRequest",
	initialState: {
		loading: false,
		loadingAddresses: false,
		error: "",
		status: "",
		list: [],
		selectedData: {},
		addressData: {
			default_billing_address: {},
			default_shipping_address: {},
		},
		addressList: [],
		drawer: false,
		openModal: false,
		openUpdateModal: false,
		openAddressModal: false,
		openAddAddressModal: false,
		count: 0,
		addressCount: 0,
		filterParams: {},
		pageParams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
		addressPageParams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
	},
	reducers: {
		isDrawerVisible: (state, action) => {
			return {
				...state,
				drawer: action.payload,
			};
		},
		setOpenModel: (state, action) => {
			return {
				...state,
				openModal: action.payload,
			};
		},
		setUpdateOpenModel: (state, action) => {
			return {
				...state,
				openUpdateModal: action.payload,
			};
		},
		changeParams: (state, action) => {
			return {
				...state,
				pageParams: action.payload,
			};
		},
		setOpenAddressModal: (state, action) => {
			return {
				...state,
				openAddressModal: action.payload,
			};
		},
		setOpenAddAddressModal: (state, action) => {
			return {
				...state,
				openAddAddressModal: action.payload,
			};
		},
		changeAddressParams: (state, action) => {
			return {
				...state,
				addressPageParams: action.payload,
			};
		},
		changeFilterParams: (state, action) => {
			return {
				...state,
				filterParams: action.payload,
			};
		},
		// clearFilterParamsData: (state, action) => {
		// 	return {
		// 		...state,
		// 		filterParams: {},
		// 	};
		// },
		FilterInputChangeValue: (state, action) => {
			return {
				...state,
				filterParams: {
					...state.filterParams,
					[action.payload.key]: action.payload.value,
				},
			};
		},
		setSelectedData: (state, action) => {
			return {
				...state,
				selectedData: action.payload,
			};
		},
		inputChangeValue: (state, action) => {
			console.log(action.payload);
			return {
				...state,
				selectedData: {
					...state.selectedData,
					...action.payload,
				},
			};
		},
		changeShippingAddress: (state, action) => {
			return {
				...state,
				addressData: {
					...state.addressData,
					...action.payload,
				},
			};
		},
		clearShippingAddress: (state, action) => {
			return {
				...state,
				addressData: {},
			};
		},
		clearData: (state, action) => {
			return {
				...state,
				loading: false,
				error: "",
				status: "",
				list: [],
				selectedData: {},
				count: 0,
				pageParams: {
					start_date: "",
					end_date: "",
					no_of_pages: 0,
					page_size: 10,
					page: 1,
					search: "",
				},
			};
		},
	},
	extraReducers: (builder) => {
		builder
			//get Data
			.addCase(getCashCollectionRequests.pending, (state, action) => {
				state.status = "getCashCollectionRequests loading";
				state.loading = true;
			})
			.addCase(getCashCollectionRequests.fulfilled, (state, action) => {
				state.status = "getCashCollectionRequests succeeded";
				state.loading = false;
				var noofpages = Math.ceil(
					action.payload.count / state.pageParams.page_size
				);
				state.count = action.payload.count;
				state.list = action.payload.results;
				state.pageParams = {
					...state.pageParams,
					no_of_pages: noofpages,
				};
			})
			.addCase(getCashCollectionRequests.rejected, (state, action) => {
				state.status = "getCashCollectionRequests failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//get retaailer addresses
			// .addCase(
			// 	getCashCollectionRequestsAddress.pending,
			// 	(state, action) => {
			// 		state.status = "getCashCollectionRequestsAddress loading";
			// 		state.loadingAddresses = true;
			// 	}
			// )
			// .addCase(
			// 	getCashCollectionRequestsAddress.fulfilled,
			// 	(state, action) => {
			// 		state.status = "getCashCollectionRequestsAddress succeeded";
			// 		state.loadingAddresses = false;
			// 		var noofpages = Math.ceil(
			// 			action.payload.count / state.addressPageParams.page_size
			// 		);
			// 		state.addressCount = action.payload.count;
			// 		state.addressList = action.payload.results;
			// 		state.addressPageParams = {
			// 			...state.addressPageParams,
			// 			no_of_pages: noofpages,
			// 		};
			// 	}
			// )
			// .addCase(
			// 	getCashCollectionRequestsAddress.rejected,
			// 	(state, action) => {
			// 		state.status = "getCashCollectionRequestsAddress failed";
			// 		state.loadingAddresses = false;
			// 		state.error = action.error.message;
			// 	}
			// )
			//post retaailer addresses
			// .addCase(
			// 	postCashCollectionRequestsAddress.pending,
			// 	(state, action) => {
			// 		state.status = "postCashCollectionRequestsAddress loading";
			// 		state.loadingAddresses = true;
			// 	}
			// )
			// .addCase(
			// 	postCashCollectionRequestsAddress.fulfilled,
			// 	(state, action) => {
			// 		state.status = "postCashCollectionRequestsAddress succeeded";
			// 		state.loadingAddresses = false;
			// 		state.selectedData = {
			// 			default_billing_address: {},
			// 			default_shipping_address: {},
			// 		};
			// 	}
			// )
			// .addCase(
			// 	postCashCollectionRequestsAddress.rejected,
			// 	(state, action) => {
			// 		state.status = "postCashCollectionRequestsAddress failed";
			// 		state.loadingAddresses = false;
			// 		state.error = action.error.message;
			// 	}
			// )
			//edit retailer
			.addCase(editCashCollectionRequest.pending, (state, action) => {
				state.status = "editCashCollectionRequest loading";
				state.loadingAddresses = true;
			})
			.addCase(editCashCollectionRequest.fulfilled, (state, action) => {
				state.status = "editCashCollectionRequest succeeded";
				state.loadingAddresses = false;
				// state.selectedData = {
				// 	...action.payload.data,
				// 	// salesagent_id: action.payload?.data?.salesagent?.id,
				// 	distributor_id: action.payload?.data?.distributor?.id,
				// 	location_id: action.payload?.data?.location?.id,
				// };
			})
			.addCase(editCashCollectionRequest.rejected, (state, action) => {
				state.status = "editCashCollectionRequest failed";
				state.loadingAddresses = false;
				state.error = action.error.message;
			})
			//edit put retailer
			// .addCase(editCashCollectionRequestput.pending, (state, action) => {
			// 	state.status = "editCashCollectionRequestput loading";
			// 	state.loadingAddresses = true;
			// })
			// .addCase(editCashCollectionRequestput.fulfilled, (state, action) => {
			// 	state.status = "editCashCollectionRequestput succeeded";
			// 	state.loadingAddresses = false;
			// 	state.selectedData = {
			// 		...action.payload,
			// 		salesagent_id: action.payload?.salesagent?.id,
			// 		location_id: action.payload?.location?.id,
			// 	};
			// })
			// .addCase(editCashCollectionRequestput.rejected, (state, action) => {
			// 	state.status = "editCashCollectionRequestput failed";
			// 	state.loadingAddresses = false;
			// 	state.error = action.error.message;
			// })
			//get Data by Id
			.addCase(getCashCollectionRequestByID.pending, (state, action) => {
				state.status = "getCashCollectionRequestByID loading";
				state.loading = true;
			})
			.addCase(
				getCashCollectionRequestByID.fulfilled,
				(state, action) => {
					state.status = "getCashCollectionRequestByID succeeded";
					state.loading = false;
					state.selectedData = {
						...action.payload,
						// salesagent_id: action.payload?.salesagent?.id,
						distributor_id: action.payload?.distributor?.id,
						location_id: action.payload?.location?.id,
					};
				}
			)
			.addCase(getCashCollectionRequestByID.rejected, (state, action) => {
				state.status = "getCashCollectionRequestByID failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//post data
			.addCase(postCashCollectionRequest.pending, (state, action) => {
				state.status = "postCashCollectionRequest loading";
				state.loading = true;
			})
			.addCase(postCashCollectionRequest.fulfilled, (state, action) => {
				state.status = "postCashCollectionRequest succeeded";
				state.loading = false;
				state.selectedData = {
					default_billing_address: {},
					default_shipping_address: {},
				};
			})
			.addCase(postCashCollectionRequest.rejected, (state, action) => {
				state.status = "postCashCollectionRequest failed";
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

// Action creators are generated for each case reducer function
export const {
	setOpenAddressModal,
	setOpenAddAddressModal,
	isDrawerVisible,
	changeParams,
	changeFilterParams,
	clearData,
	setSelectedData,
	inputChangeValue,
	setOpenModel,
	setUpdateOpenModel,
	FilterInputChangeValue,
	changeAddressParams,
	changeShippingAddress,
	clearShippingAddress,
} = cashCollectionRequestSlice.actions;

export default cashCollectionRequestSlice.reducer;
