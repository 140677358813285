import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Select, Card, Progress, Space, Typography, Divider, Button } from "antd";
import { RiBarChart2Line } from "react-icons/ri";
import saleImage from "../../../assets/images/app/Revenue_Card.svg";



function RevenueCard(props) {
  const { Text, Link } = Typography;
  return (
    <>
      <div className="hp-p-36 hp-border-radius-xl hp-card-1 hp-overflow-hidden hp-w-100 hp-h-100">
        <div
          className="hp-position-absolute-top-left hp-nft-dashboard-own-nft-bg hp-w-100 hp-h-100"
          style={{ backgroundImage: "url(" + saleImage + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}
        >
        </div>

        <Row align="middle" >
          <Col className="hp-statistic-icon-bg hp-bg-black-0 hp-bg-color-dark-primary">
            <RiBarChart2Line
              className="hp-text-color-primary-1 hp-text-color-dark remix-icon"
              size={25}
            />

          </Col>
          <Col >
            <Text className="h4 hp-d-block hp-text-color-black-0 hp-mb-6" strong>
              {props.title}
            </Text>
            <Text className="h2 hp-text-color-black-0" strong>
              {props.total ? props.total : 0}
            </Text>
          </Col>

        </Row>
      </div>
    </>
  );
}


export default RevenueCard;
