import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
	addParams,
	getList,
	getParamsList,
	patchEdit,
	postAdd,
	postEdit,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
	RiCheckboxCircleLine,
	RiAlertLine,
	RiInformationLine,
} from "react-icons/ri";

export const getRetailers = createAsyncThunk(
	"/getRetailers",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/users/retailer/create/`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getRetailersAddress = createAsyncThunk(
	"/getRetailersAddress",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/users/retailersaddress/create/`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

export const getRetailerByID = createAsyncThunk(
	"/getRetailerByID",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getList(
				`/users/retailer/details/${params.id}/`
			);
			if (response) {
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

export const postRetailer = createAsyncThunk(
	"/postRetailer",
	async (payload) => {
		try {
			const {
				data,
				history,
				shopdetailsForm,
				billingAddressForm,
				shippingAddressForm,
				clearData,
				message,
			} = payload;
			const response = await postAdd("/users/retailer/create/", data);
			if (response.status === 201) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Retailer Added Successfully.!
							</p>
						</div>
					),
				});
				history.push("/retailers");
				shopdetailsForm.resetFields();
				billingAddressForm.resetFields();
				shippingAddressForm.resetFields();
				message.success("Processing complete!");
				clearData();
				return response;
			} else {
				throw response;
			}
		} catch (error) {
			console.log(error);

			if (error?.response?.data) {
				const values = Object.keys(error.response.data);
				console.log(values);
				console.log(error?.response?.data[values[0]]);
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								{error.message}
							</p>
						</div>
					),
				});
			} else {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),

					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Getting from server side issue!
							</p>
						</div>
					),
				});
			}
			throw error.message;
		}
	}
);

export const postRetailersAddress = createAsyncThunk(
	"/postRetailersAddress",
	async (payload) => {
		try {
			const { data, closeModal } = payload;
			const response = await postAdd(
				`/users/retailersaddress/create/`,
				data
			);
			if (response.status === 201) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Address Added Successfully.!
							</p>
						</div>
					),
				});
				closeModal();
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error.response) {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Unable to add Address. Please try again!
							</p>
						</div>
					),
				});
			} else {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),

					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Getting from server side issue!
							</p>
						</div>
					),
				});
			}
			throw error.message;
		}
	}
);
export const editRetailer = createAsyncThunk(
	"/editRetailer",
	async (payload, { dispatch }) => {
		try {
			const { data, closeModal } = payload;
			const response = await patchEdit(
				`/users/retailer/details/${data.id}/`,
				data
			);
			if (response.status === 200) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Edited Successfully.!
							</p>
						</div>
					),
				});
				closeModal(response.data);
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error.response) {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Unable to edit retailer. Please try
								again!
							</p>
						</div>
					),
				});
			} else {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),

					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Getting from server side issue!
							</p>
						</div>
					),
				});
			}
			throw error.message;
		}
	}
);

export const editRetailerput = createAsyncThunk(
	"/editRetailerput",
	async (payload, { dispatch }) => {
		try {
			const { data, handleResetInputValues, pageParams } = payload;
			const response = await postEdit(
				`/users/retailer/details/${data.id}/`,
				data
			);
			if (response.status === 200) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Edited Successfully.!
							</p>
						</div>
					),
				});
				dispatch(getRetailers({ ...pageParams }));
				handleResetInputValues(response.data);
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error.response) {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Unable to edit retailer. Please try
								again!
							</p>
						</div>
					),
				});
			} else {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),

					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Getting from server side issue!
							</p>
						</div>
					),
				});
			}
			throw error.message;
		}
	}
);
