import { createSlice } from "@reduxjs/toolkit";
import { getPriceBookByID, getPriceBook, pricebookAdd, pricebookDelete } from "./pricebook.action";

const pricebookSlice = createSlice({
  name: "pricebook",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    pricebooklist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    pricebookParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    SetPriceBookParams: (state, action) => {
      return {
        ...state,
        pricebookParams: action.payload,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getPriceBook.pending, (state, action) => {
        state.status = "getPriceBook loading";
        state.loading = true;
      })
      .addCase(getPriceBook.fulfilled, (state, action) => {
        var response = action.payload.response;
        state.status = "getPriceBook succeeded";
        state.loading = false;
        var response = action.payload.response;
        var noofpages = Math.ceil(
          action.payload.count / state.pricebookParams?.page_size
        );

        state.pricebooklist = response.results;
        state.listCount = response.count;
        state.pricebookParams = {
          ...state.pricebookParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getPriceBook.rejected, (state, action) => {
        state.status = "getPriceBook failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(pricebookAdd.pending, (state, action) => {
        state.status = "pricebookAdd loading";
        state.loading = true;
      })
      .addCase(pricebookAdd.fulfilled, (state, action) => {
        state.status = "pricebookAdd succeeded";
        state.loading = false
        state.model = action.payload.modal;
        state.message = action.payload.response;
        state.rowdata = {};
      }
      )
      .addCase(pricebookAdd.rejected, (state, action) => {
        state.status = "pricebookAdd failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(pricebookDelete.pending, (state, action) => {
        state.status = "pricebookDelete loading";
        state.loading = true;
      })
      .addCase(pricebookDelete.fulfilled, (state, action) => {
        state.status = "pricebookDelete succeeded";
        state.loading = false
      }
      )
      .addCase(pricebookDelete.rejected, (state, action) => {
        state.status = "pricebookDelete failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getPriceBookByID.pending, (state, action) => {
        state.status = "getPriceBookByID loading";
        state.loading = true;
      })
      .addCase(getPriceBookByID.fulfilled, (state, action) => {
        state.status = "getPriceBookByID succeeded";
        state.loading = false
        state.model = true;
        state.rowdata = {
          ...action.payload,
        };
      }
      )
      .addCase(getPriceBookByID.rejected, (state, action) => {
        state.status = "getPriceBookByID failed";
        state.loading = false;
        state.error = action.error.message;
      })
  }

});
export const {
  InputChangeValue,
  apiError,
  isModelVisible,
  SetPriceBookParams,
} = pricebookSlice.actions;

export default pricebookSlice.reducer;
