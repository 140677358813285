import { createSlice } from "@reduxjs/toolkit";
import {
	// approveWalletsByID,
	// distributorReturnProcessing,
	// distributorReturnProcessingStatus,
	editWallets,
	postWallets,
	getWalletsByID,
	getWallets,
	getWalletTransactions,
} from "./wallets.action";
import { v4 as uuidv4 } from "uuid";

const walletsSlice = createSlice({
	name: "wallets",
	initialState: {
		loading: false,
		transactionLoading: false,
		approvalLoading: false,
		rejectLoading: false,
		error: "",
		status: "",
		list: [],
		transactionsCount: 0,
		transactionsList: [],
		selectedData: {},
		count: 0,
		openModal: false,
		filterParams: {},
		transactionsParams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
		},
		pageParams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
	},
	reducers: {
		isDrawerVisible: (state, action) => {
			return {
				...state,
				drawer: action.payload,
			};
		},
		FilterInputChangeValue: (state, action) => {
			return {
				...state,
				filterParams: {
					...state.filterParams,
					[action.payload.key]: action.payload.value,
				},
			};
		},
		setTransactionParams: (state, action) => {
			return {
				...state,
				transactionsParams: action.payload,
			};
		},
		changeTransactionsParams: (state, action) => {
			return {
				...state,
				transactionsParams: {
					...state.transactionsParams,
					[action.payload.key]: action.payload.value,
				},
			};
		},
		changeParams: (state, action) => {
			return {
				...state,
				pageParams: action.payload,
			};
		},
		changeProductListParams: (state, action) => {
			return {
				...state,
				productListparams: action.payload,
			};
		},
		changeFilterParams: (state, action) => {
			return {
				...state,
				filterParams: action.payload,
			};
		},

		setSelectedData: (state, action) => {
			return {
				...state,
				selectedData: action.payload,
			};
		},
		inputChangeValue: (state, action) => {
			return {
				...state,
				selectedData: {
					...state.selectedData,
					...action.payload,
				},
			};
		},
		addProductsToList: (state, action) => {
			return {
				...state,
				productsList: [...state.productsList, action.payload],
			};
		},
		removeProductsFromList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => {
					return e.uniqueId == action.payload.product_id
						? {
								...e,
								dodelete: true,
						  }
						: {
								...e,
						  };
				}),
			};
		},
		clearProductsList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => ({
					...e,
					dodelete: true,
				})),
			};
		},
		changeProductList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => {
					if (e.uniqueId == action.payload.product_id) {
						if (action.payload.key == "quantity") {
							const calculatedPrice =
								parseFloat(action.payload.value) *
								parseFloat(e.listedPrice) *
								parseFloat(e.unit.units);
							const discountAmt = e.discount_percent
								? +calculatedPrice * (+e.discount_percent / 100)
								: 0;
							const taxableValue = calculatedPrice - discountAmt;
							const gstAmt = e.gst_percentage
								? parseFloat(taxableValue) *
								  (+e.gst_percentage / 100)
								: 0;
							const totalPrice =
								parseFloat(taxableValue) + parseFloat(gstAmt);
							return {
								...e,
								[action.payload.key]: action.payload.value,
								discountAmt: discountAmt.toFixed(2),
								gstAmt: gstAmt.toFixed(2),
								price: totalPrice,
								taxableValue: taxableValue.toFixed(2),
								originalPrice: calculatedPrice.toFixed(2),
							};
						} else {
							const calculatedPrice =
								parseFloat(e.quantity) *
								parseFloat(e.listedPrice) *
								parseFloat(action.payload.value?.units);
							const discountAmt = e.discount_percent
								? +calculatedPrice * (+e.discount_percent / 100)
								: 0;
							const taxableValue = calculatedPrice - discountAmt;
							const gstAmt = e.gst_percentage
								? parseFloat(taxableValue) *
								  (+e.gst_percentage / 100)
								: 0;
							const totalPrice =
								parseFloat(taxableValue) + parseFloat(gstAmt);
							return {
								...e,
								[action.payload.key]: action.payload.value,
								discountAmt: discountAmt.toFixed(2),
								gstAmt: gstAmt.toFixed(2),
								price: totalPrice,
								taxableValue: taxableValue.toFixed(2),
								originalPrice: calculatedPrice.toFixed(2),
							};
						}
					} else {
						return e;
					}
				}),
			};
		},
		setOpenModel: (state, action) => {
			return {
				...state,
				openModal: action.payload,
			};
		},
		clearData: (state, action) => {
			return {
				...state,
				loading: false,
				error: "",
				status: "",
				list: [],
				selectedData: {},
				count: 0,
				productsList: [],
				productListparams: {
					start_date: "",
					end_date: "",
					no_of_pages: 0,
					page_size: 10,
					page: 1,
					search: "",
				},
			};
		},
	},
	extraReducers: (builder) => {
		builder
			//get Data
			.addCase(getWallets.pending, (state, action) => {
				state.status = "getWallets loading";
				state.loading = true;
			})
			.addCase(getWallets.fulfilled, (state, action) => {
				state.status = "getWallets succeeded";

				console.log(action.payload.results);
				state.loading = false;
				state.count = action.payload.count;
				state.list = action.payload.results;
				// state.list = action.payload;
				state.selectedData = {};
			})
			.addCase(getWallets.rejected, (state, action) => {
				state.status = "getWallets failed";
				state.loading = false;
				state.error = action.error.message;
			})
			.addCase(getWalletTransactions.pending, (state, action) => {
				state.status = "getWalletTransactions loading";
				state.transactionLoading = true;
			})
			.addCase(getWalletTransactions.fulfilled, (state, action) => {
				state.status = "getWalletTransactions succeeded";
				state.transactionLoading = false;
				state.transactionsCount = action.payload.count;
				state.transactionsList = action.payload.results;
			})
			.addCase(getWalletTransactions.rejected, (state, action) => {
				state.status = "getWalletTransactions failed";
				state.transactionLoading = false;
				state.error = action.error.message;
			})

			//get Data by Id
			.addCase(getWalletsByID.pending, (state, action) => {
				state.status = "getWalletsByID loading";
				state.loading = true;
			})
			.addCase(getWalletsByID.fulfilled, (state, action) => {
				state.status = "getWalletsByID succeeded";
				state.loading = false;
				state.selectedData = {
					...action.payload,
					distributor_id: action.payload.distributor?.id,
					description: action.payload.description,
				};

				state.productsList = action.payload?.return_request_items?.map(
					(e) => {
						const calculatedPrice =
							parseFloat(e?.unit?.units) *
							parseFloat(e?.quantity) *
							parseFloat(e?.price);
						const discountAmt =
							parseFloat(calculatedPrice) *
							(+e.discount_percent / 100);
						const taxableValue = calculatedPrice - discountAmt;
						const gstAmt =
							parseFloat(taxableValue) * (+e.tax_percent / 100);
						const totalPrice =
							parseFloat(taxableValue) + parseFloat(gstAmt);
						// const taxableValue = +e?.discount_percent
						// 	? calculatedPrice /
						// 	  (1 + parseFloat(e?.discount_percent))
						// 	: calculatedPrice;

						return {
							...e,
							item_id: e.id,
							product_id: e.product.id,
							image: e.product.image,
							name: e.product.name,
							listedPrice: parseFloat(e.price).toFixed(2),
							originalPrice:
								parseFloat(calculatedPrice).toFixed(2),
							discountAmt: parseFloat(discountAmt).toFixed(2),
							gstAmt: parseFloat(gstAmt).toFixed(2),
							taxableValue: parseFloat(taxableValue).toFixed(2),
							price: parseFloat(totalPrice).toFixed(2),
							size: e?.unit?.id,
							gst_percentage: e?.tax_percent,
							discount_percent: e?.discount_percent,
							quantity: parseInt(e?.quantity),
							unit: e?.unit,
							uniqueId: uuidv4(),
							retailer_id: action.payload.retailer?.id,
							accepted_units: e?.accepted_units,
						};
					}
				);
			})
			.addCase(getWalletsByID.rejected, (state, action) => {
				state.status = "getWalletsByID failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//post data
			.addCase(postWallets.pending, (state, action) => {
				state.status = "postWallets loading";
				state.loading = true;
			})
			.addCase(postWallets.fulfilled, (state, action) => {
				state.status = "postWallets succeeded";
				state.loading = false;
				state.selectedData = {};
			})
			.addCase(postWallets.rejected, (state, action) => {
				state.status = "postWallets failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//edit data
			.addCase(editWallets.pending, (state, action) => {
				state.status = "editWallets loading";
				state.loading = true;
			})
			.addCase(editWallets.fulfilled, (state, action) => {
				state.status = "editWallets succeeded";
				state.loading = false;
				state.selectedData = {};
			})
			.addCase(editWallets.rejected, (state, action) => {
				state.status = "editWallets failed";
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

// Action creators are generated for each case reducer function
export const {
	changeParams,
	changeProductListParams,
	changeTransactionsParams,
	clearData,
	setSelectedData,
	inputChangeValue,
	addProductsToList,
	removeProductsFromList,
	changeProductList,
	isDrawerVisible,
	FilterInputChangeValue,
	changeFilterParams,
	clearProductsList,
	setOpenModel,
	setTransactionParams,
} = walletsSlice.actions;

export default walletsSlice.reducer;
