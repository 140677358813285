import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    postAdd,
    getParamsList,
    postEdit,
    postDelete,
    getList,
    patchImg,
    putImg,
    postFormData,
    addParams,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
    RiCheckboxCircleLine,
    RiAlertLine,
    RiInformationLine,
} from "react-icons/ri";
import { serialize } from "object-to-formdata";
import { BaseURL, TOKEN_PREFIX } from "../../../helpers/AxiosHelper";

export const getCategories = createAsyncThunk(
    "/getCategories",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/masters/categories/list/", params);
            if (response) {
                return { response };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);
export const categoryDelete = createAsyncThunk(
    "/categoryDelete",
    async (payload) => async (dispatch) => {
        console.log(dispatch)
        var categoryID = payload;
        try {
            const response = await postDelete("/masters/category/detail/" + categoryID + "/");
            if (response.status === 204) {
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Category has been deleted Successfully!
                            </p>
                        </div>
                    ),
                });
                return { response };
            } else {
                Modal.error({
                    icon: (
                        <span className="remix-icon">
                            <RiAlertLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Sorry!   Category has been not deleted!
                            </p>
                        </div>
                    ),
                });
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            throw error.message;
        }
    }
);
export const categoryAdd = createAsyncThunk(
    "/categoryAdd",
    async (payload) => {

        try {
            if (payload.id == 0) {
                const response = await postAdd("/masters/categories/list/", payload);
                if (response.status === 201) {
                    Modal.success({
                        icon: (
                            <span className="remix-icon">
                                <RiCheckboxCircleLine />
                            </span>
                        ),
                        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                        content: (
                            <div>
                                <p className="hp-p1-body hp-text-color-black-80">
                                    Category Added Successfully.!
                                </p>
                            </div>
                        ),
                    });
                    return { response, modal: false };
                }
                else {
                    Modal.error({
                        icon: (
                            <span className="remix-icon">
                                <RiAlertLine />
                            </span>
                        ),
                        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                        content: (
                            <div>
                                <p className="hp-p1-body hp-text-color-black-80">
                                    Sorry! Unable to add Category. Please try again!
                                </p>
                            </div>
                        ),
                    });
                    throw new Error(response);
                }
            } else {
                const { stateToUpdate, dispatch, getCategories, categoryParams, clearuploadImage } = payload
                const formData = serialize(stateToUpdate);
                const response = await postEdit(`/masters/category_image/update/${payload.id}/`, formData);
                if (response.status === 200) {
                    Modal.success({
                        icon: (
                            <span className="remix-icon">
                                <RiCheckboxCircleLine />
                            </span>
                        ),
                        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                        content: (
                            <div>
                                <p className="hp-p1-body hp-text-color-black-80">
                                    Category Edited Successfully.!
                                </p>
                            </div>
                        ),
                    });

                    dispatch(clearuploadImage());
                    dispatch(getCategories({ ...categoryParams }));
                    return { response, modal: false }
                } else {
                    Modal.error({
                        icon: (
                            <span className="remix-icon">
                                <RiAlertLine />
                            </span>
                        ),
                        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                        content: (
                            <div>
                                <p className="hp-p1-body hp-text-color-black-80">
                                    Sorry! Unable to Category. Please try again!
                                </p>
                            </div>
                        ),
                    });
                }
                throw new Error(response);

            }

        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            throw error.message;
        }
    }
);

export const getCategoryByID = createAsyncThunk(
    "/getCategoryByID",
    async (payload) => {
        try {
            const response = await getList("/masters/categories/" + payload + "/", {});
            return response.data;
        } catch (error) {
            throw error.message;
        }
    }
);