import { createSlice } from "@reduxjs/toolkit";
import {
	// approveReturnRequestByID,
	// distributorReturnProcessing,
	// distributorReturnProcessingStatus,
	editReturnRequest,
	postReturnRequest,
	getReturnRequestByID,
	getReturnRequest,
} from "./returnRequest.action";
import { v4 as uuidv4 } from "uuid";

const returnRequestSlice = createSlice({
	name: "distributorReturns",
	initialState: {
		loading: false,
		approvalLoading: false,
		rejectLoading: false,
		error: "",
		status: "",
		list: [],
		selectedData: {},
		count: 0,
		filterParams: {},
		productsList: [],
		productListparams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
		pageParams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
	},
	reducers: {
		isDrawerVisible: (state, action) => {
			return {
				...state,
				drawer: action.payload,
			};
		},
		FilterInputChangeValue: (state, action) => {
			return {
				...state,
				filterParams: {
					...state.filterParams,
					[action.payload.key]: action.payload.value,
				},
			};
		},
		changeParams: (state, action) => {
			return {
				...state,
				pageParams: action.payload,
			};
		},
		changeProductListParams: (state, action) => {
			return {
				...state,
				productListparams: action.payload,
			};
		},
		changeFilterParams: (state, action) => {
			return {
				...state,
				filterParams: action.payload,
			};
		},

		setSelectedData: (state, action) => {
			return {
				...state,
				selectedData: action.payload,
			};
		},
		inputChangeValue: (state, action) => {
			return {
				...state,
				selectedData: {
					...state.selectedData,
					...action.payload,
				},
			};
		},
		addProductsToList: (state, action) => {
			return {
				...state,
				productsList: [...state.productsList, action.payload],
			};
		},
		removeProductsFromList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => {
					return e.uniqueId == action.payload.product_id
						? {
								...e,
								dodelete: true,
						  }
						: {
								...e,
						  };
				}),
			};
		},
		clearProductsList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => ({
					...e,
					dodelete: true,
				})),
			};
		},
		changeProductList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => {
					if (e.uniqueId == action.payload.product_id) {
						if (action.payload.key == "quantity") {
							const calculatedPrice =
								parseFloat(action.payload.value) *
								parseFloat(e.listedPrice) *
								parseFloat(e.unit.units);
							const discountAmt = e.discount_percent
								? +calculatedPrice * (+e.discount_percent / 100)
								: 0;
							var taxableValue = calculatedPrice - discountAmt;
							// var gstAmt = e.gst_percentage
							// 	? parseFloat(taxableValue) *
							// 	  (+e.gst_percentage / 100)
							// 	: 0;
							// var totalPrice =
							// 	parseFloat(taxableValue) + parseFloat(gstAmt);
							var totalPrice = parseFloat(taxableValue);

							// var amt_before_tax;
							// const tax_type = e?.tax_type;
							// const tax_rate = parseFloat(e.tax_percent) / 100;

							// switch (tax_type) {
							// 	case 1:
							// 		//inclusive
							// 		console.log("inclusive");
							// 		amt_before_tax =
							// 			parseFloat(taxableValue) /
							// 			(1 + tax_rate);
							// 		gstAmt = calculatedPrice - amt_before_tax;
							// 		totalPrice = taxableValue;

							// 		gstAmt = parseFloat(gstAmt).toFixed(2);
							// 		taxableValue =
							// 			parseFloat(amt_before_tax).toFixed(2);
							// 		totalPrice =
							// 			parseFloat(totalPrice).toFixed(2);
							// 		break;
							// 	case 2:
							// 		//exclusive
							// 		// taxableValue = calculatedPrice;
							// 		// tax_amount = taxableValue * tax_rate
							// 		// amt_after_tax = tax_amount + taxableValue;
							// 		// totalPrice = amt_after_tax - obj.discountAmt

							// 		// obj.gstAmt = parseFloat(tax_amount).toFixed(2);
							// 		// obj.taxableValue =
							// 		// 	parseFloat(taxableValue).toFixed(2);
							// 		// obj.price = parseFloat(totalPrice).toFixed(2);
							// 		// obj.gstAmt = parseFloat(tax_amount).toFixed(2);
							// 		break;
							// }
							return {
								...e,
								[action.payload.key]: action.payload.value,
								discountAmt: discountAmt.toFixed(2),
								// gstAmt: gstAmt.toFixed(2),
								// taxableValue: taxableValue.toFixed(2),
								price: totalPrice.toFixed(2),
								originalPrice: calculatedPrice.toFixed(2),
							};
						} else {
							const calculatedPrice =
								parseFloat(e.quantity) *
								parseFloat(e.listedPrice) *
								parseFloat(action.payload.value?.units);
							const discountAmt = e.discount_percent
								? +calculatedPrice * (+e.discount_percent / 100)
								: 0;

							var taxableValue = calculatedPrice - discountAmt;
							// var gstAmt = e.gst_percentage
							// 	? parseFloat(taxableValue) *
							// 	  (+e.gst_percentage / 100)
							// 	: 0;
							// var totalPrice =
							// 	parseFloat(taxableValue) + parseFloat(gstAmt);
							var totalPrice = parseFloat(taxableValue);

							// var amt_before_tax;
							// const tax_type = e?.tax_type;
							// const tax_rate = parseFloat(e.tax_percent) / 100;

							// switch (tax_type) {
							// 	case 1:
							// 		//inclusive
							// 		console.log("inclusive");
							// 		amt_before_tax =
							// 			parseFloat(taxableValue) /
							// 			(1 + tax_rate);
							// 		gstAmt = calculatedPrice - amt_before_tax;
							// 		totalPrice = taxableValue;

							// 		gstAmt = parseFloat(gstAmt).toFixed(2);
							// 		taxableValue =
							// 			parseFloat(amt_before_tax).toFixed(2);
							// 		totalPrice =
							// 			parseFloat(totalPrice).toFixed(2);
							// 		break;
							// 	case 2:
							// 		//exclusive
							// 		// taxableValue = calculatedPrice;
							// 		// tax_amount = taxableValue * tax_rate
							// 		// amt_after_tax = tax_amount + taxableValue;
							// 		// totalPrice = amt_after_tax - obj.discountAmt

							// 		// obj.gstAmt = parseFloat(tax_amount).toFixed(2);
							// 		// obj.taxableValue =
							// 		// 	parseFloat(taxableValue).toFixed(2);
							// 		// obj.price = parseFloat(totalPrice).toFixed(2);
							// 		// obj.gstAmt = parseFloat(tax_amount).toFixed(2);
							// 		break;
							// }
							return {
								...e,
								[action.payload.key]: action.payload.value,
								discountAmt: discountAmt.toFixed(2),
								// gstAmt: gstAmt.toFixed(2),
								// taxableValue: taxableValue.toFixed(2),
								price: totalPrice,
								originalPrice: calculatedPrice.toFixed(2),
							};
						}
					} else {
						return e;
					}
				}),
			};
		},
		clearData: (state, action) => {
			return {
				...state,
				loading: false,
				error: "",
				status: "",
				list: [],
				selectedData: {},
				count: 0,
				productsList: [],
				productListparams: {
					start_date: "",
					end_date: "",
					no_of_pages: 0,
					page_size: 10,
					page: 1,
					search: "",
				},
			};
		},
	},
	extraReducers: (builder) => {
		builder
			//get Data
			.addCase(getReturnRequest.pending, (state, action) => {
				state.status = "getReturnRequest loading";
				state.loading = true;
			})
			.addCase(getReturnRequest.fulfilled, (state, action) => {
				state.status = "getReturnRequest succeeded";
				state.loading = false;
				var noofpages = Math.ceil(
					action.payload.count / state.pageParams.page_size
				);
				state.count = action.payload.count;
				state.list = action.payload.results;
				state.selectedData = {};
			})
			.addCase(getReturnRequest.rejected, (state, action) => {
				state.status = "getReturnRequest failed";
				state.loading = false;
				state.error = action.error.message;
			})
			// .addCase(approveReturnRequestByID.pending, (state, action) => {
			// 	state.status = "approveReturnRequestByID loading";
			// 	action.meta.arg.indent_status == 2
			// 		? (state.approvalLoading = true)
			// 		: (state.rejectLoading = true);
			// })
			// .addCase(
			// 	approveReturnRequestByID.fulfilled,
			// 	(state, action) => {
			// 		state.status = "approveReturnRequestByID succeeded";
			// 		action.payload.indent_status == 2
			// 			? (state.approvalLoading = false)
			// 			: (state.rejectLoading = false);
			// 	}
			// )
			// .addCase(approveReturnRequestByID.rejected, (state, action) => {
			// 	state.status = "approveReturnRequestByID failed";
			// 	state.approvalLoading = false;
			// 	state.rejectLoading = false;
			// 	state.error = action.error.message;
			// })
			// .addCase(distributorReturnProcessing.pending, (state, action) => {
			// 	state.status = "distributorReturnProcessing loading";
			// 	state.loading = true;
			// })
			// .addCase(distributorReturnProcessing.fulfilled, (state, action) => {
			// 	state.status = "distributorReturnProcessing succeeded";
			// 	state.loading = true;
			// })
			// .addCase(distributorReturnProcessing.rejected, (state, action) => {
			// 	state.status = "distributorReturnProcessing failed";
			// 	state.loading = false;
			// 	state.error = action.error.message;
			// })
			// .addCase(
			// 	distributorReturnProcessingStatus.pending,
			// 	(state, action) => {
			// 		state.status = "distributorReturnProcessingStatus loading";
			// 		state.loading = true;
			// 	}
			// )
			// .addCase(
			// 	distributorReturnProcessingStatus.fulfilled,
			// 	(state, action) => {
			// 		state.status =
			// 			"distributorReturnProcessingStatus succeeded";
			// 		state.loading = false;
			// 	}
			// )
			// .addCase(
			// 	distributorReturnProcessingStatus.rejected,
			// 	(state, action) => {
			// 		state.status = "distributorReturnProcessingStatus failed";
			// 		state.loading = false;
			// 		state.error = action.error.message;
			// 	}
			// )
			//get Data by Id
			.addCase(getReturnRequestByID.pending, (state, action) => {
				state.status = "getReturnRequestByID loading";
				state.loading = true;
			})
			.addCase(getReturnRequestByID.fulfilled, (state, action) => {
				state.status = "getReturnRequestByID succeeded";
				state.loading = false;
				state.selectedData = {
					...action.payload,
					// salesagent_id: action.payload.sales_agent?.id,
					distributor_id: action.payload.distributor?.id,
					description: action.payload.description,
					// retailer_id: action.payload.distributor?.id,
					// location_id: action.payload.distributor?.location?.id,
					// location_name: action.payload.distributor?.location?.name,
					// billing_address_id:
					// 	action.payload?.distributor?.default_billing_address
					// 		?.id,
					// shipping_address_id:
					// 	action.payload?.distributor?.default_shipping_address
					// 		?.id,
					// default_billing_address:
					// 	action.payload?.distributor?.default_billing_address,
					// default_shipping_address:
					// 	action.payload?.distributor?.default_shipping_address,
				};

				state.productsList = action.payload?.return_request_items?.map(
					(e) => {
						const calculatedPrice =
							(e?.unit?.units ? parseFloat(e?.unit?.units) : 0) *
							parseFloat(e?.quantity) *
							parseFloat(e?.price);
						const discountAmt =
							parseFloat(calculatedPrice) *
							(+e.discount_percent / 100);
						var taxableValue = calculatedPrice - discountAmt;
						// var gstAmt =
						// 	parseFloat(taxableValue) * (+e.tax_percent / 100);

						// const taxableValue = +e?.discount_percent
						// 	? calculatedPrice /
						// 	  (1 + parseFloat(e?.discount_percent))
						// 	: calculatedPrice;

						var totalPrice =
							parseFloat(calculatedPrice) -
							parseFloat(discountAmt);

						let freeItem = e?.free_status == 2 ? true : false;

						const obj = {
							...e,
							item_id: e.id,
							product_id: e.product.id,
							image: e.product.image,
							name: e.product.name,
							listedPrice: parseFloat(e.price).toFixed(2),
							originalPrice:
								parseFloat(calculatedPrice).toFixed(2),
							discountAmt: parseFloat(discountAmt).toFixed(2),
							// gstAmt: parseFloat(gstAmt).toFixed(2),
							// taxableValue: parseFloat(taxableValue).toFixed(2),
							price: parseFloat(totalPrice).toFixed(2),
							size: e?.unit?.id,
							// gst_percentage: e?.tax_percent,
							discount_percent: e?.discount_percent,
							quantity: parseInt(e?.quantity),
							unit: e?.unit,
							uniqueId: e.id,
							accepted_units: e?.accepted_units,
							freeItem: freeItem,
							dodelete: false,
							mrp: parseInt(e?.mrp)
								? parseFloat(e?.mrp).toFixed(2)
								: 0,
							showCancelIcon: true,
						};

						if (freeItem) {
							obj.uniqueId = e?.applied_by?.id;
							obj.showCancelIcon = false;
						}
						// var amt_before_tax;
						// const tax_type = e?.tax_type;
						// const tax_rate = parseFloat(e.tax_percent) / 100;

						// switch (tax_type) {
						// 	case 1:
						// 		//inclusive
						// 		console.log("inclusive");
						// 		amt_before_tax =
						// 			parseFloat(taxableValue) / (1 + tax_rate);
						// 		gstAmt = calculatedPrice - amt_before_tax;
						// 		totalPrice = taxableValue;

						// 		obj.gstAmt = parseFloat(gstAmt).toFixed(2);
						// 		obj.taxableValue =
						// 			parseFloat(amt_before_tax).toFixed(2);
						// 		obj.price = parseFloat(totalPrice).toFixed(2);
						// 		break;
						// 	case 2:
						// 		//exclusive
						// 		// taxableValue = calculatedPrice;
						// 		// tax_amount = taxableValue * tax_rate
						// 		// amt_after_tax = tax_amount + taxableValue;
						// 		// totalPrice = amt_after_tax - obj.discountAmt

						// 		// obj.gstAmt = parseFloat(tax_amount).toFixed(2);
						// 		// obj.taxableValue =
						// 		// 	parseFloat(taxableValue).toFixed(2);
						// 		// obj.price = parseFloat(totalPrice).toFixed(2);
						// 		// obj.gstAmt = parseFloat(tax_amount).toFixed(2);
						// 		break;
						// }

						return { ...obj };
					}
				);
			})
			.addCase(getReturnRequestByID.rejected, (state, action) => {
				state.status = "getReturnRequestByID failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//post data
			.addCase(postReturnRequest.pending, (state, action) => {
				state.status = "postReturnRequest loading";
				state.loading = true;
			})
			.addCase(postReturnRequest.fulfilled, (state, action) => {
				state.status = "postReturnRequest succeeded";
				state.loading = false;
				state.selectedData = {};
			})
			.addCase(postReturnRequest.rejected, (state, action) => {
				state.status = "postReturnRequest failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//edit data
			.addCase(editReturnRequest.pending, (state, action) => {
				state.status = "editReturnRequest loading";
				state.loading = true;
			})
			.addCase(editReturnRequest.fulfilled, (state, action) => {
				state.status = "editReturnRequest succeeded";
				state.loading = false;
				state.selectedData = {};
			})
			.addCase(editReturnRequest.rejected, (state, action) => {
				state.status = "editReturnRequest failed";
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

// Action creators are generated for each case reducer function
export const {
	changeParams,
	changeProductListParams,
	clearData,
	setSelectedData,
	inputChangeValue,
	addProductsToList,
	removeProductsFromList,
	changeProductList,
	isDrawerVisible,
	FilterInputChangeValue,
	changeFilterParams,
	clearProductsList,
} = returnRequestSlice.actions;

export default returnRequestSlice.reducer;
