import { createSlice } from "@reduxjs/toolkit";
import { getCategoryByID, getCategories, categoryAdd, categoryDelete } from "./category.action";

const categorySlice = createSlice({
  name: "category",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    categorylist: [],
    categoryData: {},
    unitsList: [],
    unitsLoading: false,
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    uploadImage: {
      file: {},
      imageUrl: "",
      loading: false,
    },
    categoryParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    clearCategorysListAndData: (state, action) => {
      return {
        ...state,
        categorylist: [],
        categoryData: {},
        unitsList: [],
      };
    },
    clearuploadImage: (state, action) => {
      return {
        ...state,
        uploadImage: {
          file: {},
          imageUrl: "",
          loading: false,
        },
      };
    },
    SetCategoryParams: (state, action) => {
      return {
        ...state,
        categoryParams: {
          ...state.categoryParams,
          ...action.payload
        },
      };
    },
    setUploadImage: (state, action) => {
      return {
        ...state,
        uploadImage: {
          file: action.payload.file,
          imageUrl: action.payload.imageUrl,
          loading: action.payload.loading
        },
      };
    },
    categoryDelete: (state) => {
      return {
        ...state,
        loading: false

      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    clearUnits: (state, action) => {
      return {
        ...state,
        unitsLoading: false,
        unitsList: []
      }
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state, action) => {
        state.status = "getCategories loading";
        state.loading = true;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        console.log(action.payload.response)
        state.status = "getCategories succeeded";
        state.loading = false;
        var response = action.payload.response;
        var noofpages = Math.ceil(
          action.payload.count / state.categoryParams?.page_size
        );

        state.categorylist = response.results;
        state.listCount = response.count;
        state.categoryParams = {
          ...state.categoryParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.status = "getCategories failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(categoryAdd.pending, (state, action) => {
        state.status = "categoryAdd loading";
        state.loading = true;
      })
      .addCase(categoryAdd.fulfilled, (state, action) => {
        state.status = "categoryAdd succeeded";
        state.loading = false
        state.model = action.payload.modal;
        state.message = action.payload.response;
        state.rowdata = {};
      }
      )
      .addCase(categoryAdd.rejected, (state, action) => {
        state.status = "categoryAdd failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(categoryDelete.pending, (state, action) => {
        state.status = "categoryDelete loading";
        state.loading = true;
      })
      .addCase(categoryDelete.fulfilled, (state, action) => {
        state.status = "categoryDelete succeeded";
        state.loading = false
      }
      )
      .addCase(categoryDelete.rejected, (state, action) => {
        state.status = "categoryDelete failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCategoryByID.pending, (state, action) => {
        state.status = "getCategoryByID loading";
        state.loading = true;
      })
      .addCase(getCategoryByID.fulfilled, (state, action) => {
        state.status = "getCategoryByID succeeded";
        state.loading = false
        state.model = true;
        state.rowdata = {
          ...action.payload,
        };
      })
      .addCase(getCategoryByID.rejected, (state, action) => {
        state.status = "getCategoryByID failed";
        state.loading = false;
        state.error = action.error.message;
      })
  }

});
export const {
  clearuploadImage,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetCategoryParams,
  setUploadImage,
  clearCategorysListAndData,
  clearUnits
} = categorySlice.actions;

export default categorySlice.reducer;
