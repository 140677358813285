import { Selector, Dispatch } from "../../../store/index";
import TableComponent from "../../components/components-page/TableComponent";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { listChngParams } from "../../../store/dashboard/dashboardSlice";
import { getDistributorOrdersList } from "../../../store/dashboard/dashboardAction";

import { Row, Col, Card, Spin, Space, Tag } from "antd";
import { useEffect } from "react";

const DistributorOrderList = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const count = Selector("dashboard", "listCount");
  const distOrderParams = Selector("dashboard", "distOrderParams");
  const distributorOrdersList = Selector("dashboard", "distributorOrdersList");
  const loading = Selector("dashboard", "distListLoading");


  useEffect(() => {
    dispatch(getDistributorOrdersList({ ...distOrderParams }))
  }, [])
  const columns = [
    {
      title: <b>S.No</b>,
      dataIndex: "index",
      key: "index",
      fixed: "left",
    },
    {
      title: <b>Order Date</b>,
      render: (text) => (
        <span>{!text.date ? "N/A" : text.date}</span>
      ),
      key: "date",
      fixed: "left",
    },
    {
      title: <b>Order ID</b>,
      dataIndex: "code",
      key: "code",
      fixed: "left",
    },
    {
      title: <b>Distributor</b>,
      render: (text) => <span>{text.distributor?.fullname ?? "None"}</span>,
    },
    {
      title: <b>Product Type</b>,
      render: (text) => <span>{text.product_type?.name ?? "None"}</span>,
    },
    {
      title: <b>No of Items</b>,
      dataIndex: "no_of_items",
      key: "no_of_items",
    },
    {
      title: <b>Net Price</b>,
      render: (text) => <span>{text.d_net == "" ? "None" : text.d_net}</span>,
    },

    {
      title: <b>Status</b>,
      render: (text) => (
        <span>
          {!text.indent_status_name ? (
            "None"
          ) : (
            <Tag
              color={(() => {
                let tagColor = "";
                switch (text.indent_status) {
                  case 1:
                    tagColor = "blue";
                    break;
                  case 2:
                    tagColor = "warning";
                    break;
                  case 3:
                    tagColor = "success";
                    break;
                  case 4:
                    tagColor = "error";
                    break;
                }
                return tagColor;
              })()}>
              {text.indent_status_name}
            </Tag>
          )}
        </span>
      ),
    },

    {
      title: "Actions",
      key: "action",
      sorter: false,
      width: 100,
      render: (row) => (
        <Space size="middle">
          <Link to={`/distributor_orders/view/` + row.id}>
            <i
              className="ri-eye-line hp-mr-2 "
              style={{ cursor: "pointer", color: "#fc6f03" }}
            ></i>
          </Link>
        </Space>
      ),
    },
  ];

  const data = distributorOrdersList?.map((row, key) => ({
    ...row,
    index: (distOrderParams.page - 1) * distOrderParams.page_size + (key + 1),
  }));

  function onPageChange(page, page_size) {
    Dispatch(dispatch, getDistributorOrdersList, {
      ...distOrderParams,
      page: page,
      page_size: page_size,
    });
    Dispatch(dispatch, listChngParams, {
      ...distOrderParams,
      page: page,
      page_size: page_size,
    });
  }

  const ListChngParams = (params) => {
    Dispatch(dispatch, getDistributorOrdersList, { ...params });
    Dispatch(dispatch, listChngParams, { ...params });
  };

  function showTotal(total) {
    return "Total " + total + " items";
  }

  return (
    <Row gutter={[12, 0]}>
      <Col span={24} xs={24}>
        <Spin tip="Loading..." spinning={loading} delay={500}>
          <TableComponent
            page={distOrderParams.page}
            count={count}
            pageSize={distOrderParams.page_size}
            columns={columns}
            data={data}
            // loading={loading}
            onPageChange={onPageChange}
            showTotal={showTotal}
            scroll={false}
          />
        </Spin>
      </Col>
    </Row>
  );
};

export default DistributorOrderList;
