import { createSlice } from "@reduxjs/toolkit";
import {
	// approvePaymentsByID,
	// distributorReturnProcessing,
	// distributorReturnProcessingStatus,
	editPayments,
	postPayments,
	getPaymentsByID,
	getPayments,
} from "./payments.action";
import { v4 as uuidv4 } from "uuid";

const paymentsSlice = createSlice({
	name: "payments",
	initialState: {
		loading: false,
		approvalLoading: false,
		rejectLoading: false,
		error: "",
		status: "",
		list: [],
		selectedData: {},
		count: 0,
		openModal: false,
		filterParams: {},
		productsList: [],
		productListparams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
		pageParams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
	},
	reducers: {
		isDrawerVisible: (state, action) => {
			return {
				...state,
				drawer: action.payload,
			};
		},
		FilterInputChangeValue: (state, action) => {
			return {
				...state,
				filterParams: {
					...state.filterParams,
					[action.payload.key]: action.payload.value,
				},
			};
		},
		changeParams: (state, action) => {
			return {
				...state,
				pageParams: action.payload,
			};
		},
		changeProductListParams: (state, action) => {
			return {
				...state,
				productListparams: action.payload,
			};
		},
		changeFilterParams: (state, action) => {
			return {
				...state,
				filterParams: action.payload,
			};
		},

		setSelectedData: (state, action) => {
			return {
				...state,
				selectedData: action.payload,
			};
		},
		inputChangeValue: (state, action) => {
			return {
				...state,
				selectedData: {
					...state.selectedData,
					...action.payload,
				},
			};
		},
		addProductsToList: (state, action) => {
			return {
				...state,
				productsList: [...state.productsList, action.payload],
			};
		},
		removeProductsFromList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => {
					return e.uniqueId == action.payload.product_id
						? {
								...e,
								dodelete: true,
						  }
						: {
								...e,
						  };
				}),
			};
		},
		clearProductsList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => ({
					...e,
					dodelete: true,
				})),
			};
		},
		changeProductList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => {
					if (e.uniqueId == action.payload.product_id) {
						if (action.payload.key == "quantity") {
							const calculatedPrice =
								parseFloat(action.payload.value) *
								parseFloat(e.listedPrice) *
								parseFloat(e.unit.units);
							const discountAmt = e.discount_percent
								? +calculatedPrice * (+e.discount_percent / 100)
								: 0;
							const taxableValue = calculatedPrice - discountAmt;
							const gstAmt = e.gst_percentage
								? parseFloat(taxableValue) *
								  (+e.gst_percentage / 100)
								: 0;
							const totalPrice =
								parseFloat(taxableValue) + parseFloat(gstAmt);
							return {
								...e,
								[action.payload.key]: action.payload.value,
								discountAmt: discountAmt.toFixed(2),
								gstAmt: gstAmt.toFixed(2),
								price: totalPrice,
								taxableValue: taxableValue.toFixed(2),
								originalPrice: calculatedPrice.toFixed(2),
							};
						} else {
							const calculatedPrice =
								parseFloat(e.quantity) *
								parseFloat(e.listedPrice) *
								parseFloat(action.payload.value?.units);
							const discountAmt = e.discount_percent
								? +calculatedPrice * (+e.discount_percent / 100)
								: 0;
							const taxableValue = calculatedPrice - discountAmt;
							const gstAmt = e.gst_percentage
								? parseFloat(taxableValue) *
								  (+e.gst_percentage / 100)
								: 0;
							const totalPrice =
								parseFloat(taxableValue) + parseFloat(gstAmt);
							return {
								...e,
								[action.payload.key]: action.payload.value,
								discountAmt: discountAmt.toFixed(2),
								gstAmt: gstAmt.toFixed(2),
								price: totalPrice,
								taxableValue: taxableValue.toFixed(2),
								originalPrice: calculatedPrice.toFixed(2),
							};
						}
					} else {
						return e;
					}
				}),
			};
		},
		setOpenModel: (state, action) => {
			return {
				...state,
				openModal: action.payload,
			};
		},
		clearData: (state, action) => {
			return {
				...state,
				loading: false,
				error: "",
				status: "",
				list: [],
				selectedData: {},
				count: 0,
				productsList: [],
				productListparams: {
					start_date: "",
					end_date: "",
					no_of_pages: 0,
					page_size: 10,
					page: 1,
					search: "",
				},
			};
		},
	},
	extraReducers: (builder) => {
		builder
			//get Data
			.addCase(getPayments.pending, (state, action) => {
				state.status = "getPayments loading";
				state.loading = true;
			})
			.addCase(getPayments.fulfilled, (state, action) => {
				state.status = "getPayments succeeded";
				state.loading = false;
				var noofpages = Math.ceil(
					action.payload.count / state.pageParams.page_size
				);
				state.count = action.payload.count;
				state.list = action.payload.results;
				state.selectedData = {};
			})
			.addCase(getPayments.rejected, (state, action) => {
				state.status = "getPayments failed";
				state.loading = false;
				state.error = action.error.message;
			})
			// .addCase(approvePaymentsByID.pending, (state, action) => {
			// 	state.status = "approvePaymentsByID loading";
			// 	action.meta.arg.indent_status == 2
			// 		? (state.approvalLoading = true)
			// 		: (state.rejectLoading = true);
			// })
			// .addCase(
			// 	approvePaymentsByID.fulfilled,
			// 	(state, action) => {
			// 		state.status = "approvePaymentsByID succeeded";
			// 		action.payload.indent_status == 2
			// 			? (state.approvalLoading = false)
			// 			: (state.rejectLoading = false);
			// 	}
			// )
			// .addCase(approvePaymentsByID.rejected, (state, action) => {
			// 	state.status = "approvePaymentsByID failed";
			// 	state.approvalLoading = false;
			// 	state.rejectLoading = false;
			// 	state.error = action.error.message;
			// })
			// .addCase(distributorReturnProcessing.pending, (state, action) => {
			// 	state.status = "distributorReturnProcessing loading";
			// 	state.loading = true;
			// })
			// .addCase(distributorReturnProcessing.fulfilled, (state, action) => {
			// 	state.status = "distributorReturnProcessing succeeded";
			// 	state.loading = true;
			// })
			// .addCase(distributorReturnProcessing.rejected, (state, action) => {
			// 	state.status = "distributorReturnProcessing failed";
			// 	state.loading = false;
			// 	state.error = action.error.message;
			// })
			// .addCase(
			// 	distributorReturnProcessingStatus.pending,
			// 	(state, action) => {
			// 		state.status = "distributorReturnProcessingStatus loading";
			// 		state.loading = true;
			// 	}
			// )
			// .addCase(
			// 	distributorReturnProcessingStatus.fulfilled,
			// 	(state, action) => {
			// 		state.status =
			// 			"distributorReturnProcessingStatus succeeded";
			// 		state.loading = false;
			// 	}
			// )
			// .addCase(
			// 	distributorReturnProcessingStatus.rejected,
			// 	(state, action) => {
			// 		state.status = "distributorReturnProcessingStatus failed";
			// 		state.loading = false;
			// 		state.error = action.error.message;
			// 	}
			// )
			//get Data by Id
			.addCase(getPaymentsByID.pending, (state, action) => {
				state.status = "getPaymentsByID loading";
				state.loading = true;
			})
			.addCase(getPaymentsByID.fulfilled, (state, action) => {
				state.status = "getPaymentsByID succeeded";
				state.loading = false;
				state.selectedData = {
					...action.payload,
					// salesagent_id: action.payload.sales_agent?.id,
					distributor_id: action.payload.distributor?.id,
					description: action.payload.description,
					// retailer_id: action.payload.distributor?.id,
					// location_id: action.payload.distributor?.location?.id,
					// location_name: action.payload.distributor?.location?.name,
					// billing_address_id:
					// 	action.payload?.distributor?.default_billing_address
					// 		?.id,
					// shipping_address_id:
					// 	action.payload?.distributor?.default_shipping_address
					// 		?.id,
					// default_billing_address:
					// 	action.payload?.distributor?.default_billing_address,
					// default_shipping_address:
					// 	action.payload?.distributor?.default_shipping_address,
				};

				state.productsList = action.payload?.return_request_items?.map(
					(e) => {
						const calculatedPrice =
							parseFloat(e?.unit?.units) *
							parseFloat(e?.quantity) *
							parseFloat(e?.price);
						const discountAmt =
							parseFloat(calculatedPrice) *
							(+e.discount_percent / 100);
						const taxableValue = calculatedPrice - discountAmt;
						const gstAmt =
							parseFloat(taxableValue) * (+e.tax_percent / 100);
						const totalPrice =
							parseFloat(taxableValue) + parseFloat(gstAmt);
						// const taxableValue = +e?.discount_percent
						// 	? calculatedPrice /
						// 	  (1 + parseFloat(e?.discount_percent))
						// 	: calculatedPrice;

						return {
							...e,
							item_id: e.id,
							product_id: e.product.id,
							image: e.product.image,
							name: e.product.name,
							listedPrice: parseFloat(e.price).toFixed(2),
							originalPrice:
								parseFloat(calculatedPrice).toFixed(2),
							discountAmt: parseFloat(discountAmt).toFixed(2),
							gstAmt: parseFloat(gstAmt).toFixed(2),
							taxableValue: parseFloat(taxableValue).toFixed(2),
							price: parseFloat(totalPrice).toFixed(2),
							size: e?.unit?.id,
							gst_percentage: e?.tax_percent,
							discount_percent: e?.discount_percent,
							quantity: parseInt(e?.quantity),
							unit: e?.unit,
							uniqueId: uuidv4(),
							retailer_id: action.payload.retailer?.id,
							accepted_units: e?.accepted_units,
						};
					}
				);
			})
			.addCase(getPaymentsByID.rejected, (state, action) => {
				state.status = "getPaymentsByID failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//post data
			.addCase(postPayments.pending, (state, action) => {
				state.status = "postPayments loading";
				state.loading = true;
			})
			.addCase(postPayments.fulfilled, (state, action) => {
				state.status = "postPayments succeeded";
				state.loading = false;
				state.selectedData = {};
			})
			.addCase(postPayments.rejected, (state, action) => {
				state.status = "postPayments failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//edit data
			.addCase(editPayments.pending, (state, action) => {
				state.status = "editPayments loading";
				state.loading = true;
			})
			.addCase(editPayments.fulfilled, (state, action) => {
				state.status = "editPayments succeeded";
				state.loading = false;
				state.selectedData = {};
			})
			.addCase(editPayments.rejected, (state, action) => {
				state.status = "editPayments failed";
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

// Action creators are generated for each case reducer function
export const {
	changeParams,
	changeProductListParams,
	clearData,
	setSelectedData,
	inputChangeValue,
	addProductsToList,
	removeProductsFromList,
	changeProductList,
	isDrawerVisible,
	FilterInputChangeValue,
	changeFilterParams,
	clearProductsList,
	setOpenModel,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
