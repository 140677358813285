import { combineReducers } from "@reduxjs/toolkit";
import stateSlice from "./State/stateSlice";
import locationSlice from "./Location/locationSlice";
import pricebookSlice from "./PriceBook/pricebook.slice";
import productsSlice from "./Products/products.slice";
import hubSlice from "./Hub/hubSlice";
import customer_pricebookSlice from "./CustomerPriceBook/customer_pricebook.slice";
import SubscriptionSchemeSlice from "./SubscriptionSchemes/subscriptionSchemes.slice";
import deliveryinstructionsSlice from "./CustomerDeliveryInstruction/customerDeliveryInstructionSlice";
import deliveryPersonSlice from "./DeliveryPerson/deliveryPersonSlice";
import ticketTypeSlice from "./TicketType/ticketTypeSlice";
import categorySlice from "./Category/category.slice";
import bannerSlice from "./Banners/bannersSlice";
import b2bschemesSlice from "./B2BSchemesAndOffers/b2bschemesSlice";

const Masters = combineReducers({
	states: stateSlice,
	location: locationSlice,
	pricebook: pricebookSlice,
	product: productsSlice,
	hub: hubSlice,
	customer_pricebook: customer_pricebookSlice,
	SubscriptionScheme: SubscriptionSchemeSlice,
	deliveryinstructions: deliveryinstructionsSlice,
	deliveryPerson: deliveryPersonSlice,
	ticketType: ticketTypeSlice,
	category: categorySlice,
	banner: bannerSlice,
	b2bschemes: b2bschemesSlice,
});

export default Masters;
