import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	addParams,
	getList,
	getParamsList,
	patchEdit,
	postAdd,
	postEdit,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import { RiCheckboxCircleLine, RiAlertLine } from "react-icons/ri";
import Swal from "sweetalert2";

export const getVacationmodeRequests = createAsyncThunk(
	"/getVacationmodeRequests",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/b2c/admin/vacationmoderequest/`,
				params
			);
			if (response) {
				Swal.close();
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

export const getVacationmodeRequestByID = createAsyncThunk(
	"/getVacationmodeRequestByID",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getList(
				`/b2c/admin/vacationmoderequest/${params.id}/`
			);
			if (response) {
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

export const postVacationmodeRequest = createAsyncThunk(
	"/postVacationmodeRequest",
	async (payload, { dispatch }) => {
		try {
			const { data, handleResetInputValues, pageParams } = payload;
			const response = await postAdd(
				`/b2c/admin/vacationmoderequest/`,
				data
			);
			if (response.status === 201) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Vacation Mode Request Added Successfully.!
							</p>
						</div>
					),
				});
				dispatch(getVacationmodeRequests({ ...pageParams }));
				handleResetInputValues();
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error.response) {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Unable to add vacation mode request.
								Please try again!
							</p>
						</div>
					),
				});
			} else {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),

					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Getting from server side issue!
							</p>
						</div>
					),
				});
			}
			throw error.message;
		}
	}
);

export const editVacationmodeRequest = createAsyncThunk(
	"/editVacationmodeRequest",
	async (payload, { dispatch }) => {
		try {
			const { data, handleResetInputValues, pageParams } = payload;
			const response = await postEdit(
				`/b2c/admin/vacationmoderequest/${data.id}/`,
				data
			);
			if (response.status === 200) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Edited Successfully.!
							</p>
						</div>
					),
				});
				dispatch(getVacationmodeRequests({ ...pageParams }));
				handleResetInputValues(response.data);
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error.response) {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Unable to edit vacation mode request.
								Please try again!
							</p>
						</div>
					),
				});
			} else {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),

					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Getting from server side issue!
							</p>
						</div>
					),
				});
			}
			throw error.message;
		}
	}
);

export const vacationActiveAndInactive = createAsyncThunk(
	"/vacationActiveAndInactive",
	async (payload, { dispatch }) => {
		const { id, params, body } = payload;
		try {
			Swal.fire({
				text: "Loading please wait...",
				didOpen: () => {
					Swal.showLoading();
				},
				allowOutsideClick: false,
			});
			const response = await postEdit(
				`/b2c/admin/vacationmoderequest/activeinactive/${id}/`,
				body
			);
			if (response.status >= 200 && response.status < 300) {
				dispatch(getVacationmodeRequests(params));
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			Swal.close();
			Modal.error({
				icon: (
					<span className="remix-icon">
						<RiAlertLine />
					</span>
				),
				title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
				content: (
					<div>
						<p className="hp-p1-body hp-text-color-black-80">
							Unable To Change Status
						</p>
					</div>
				),
			});
			throw error.message;
		}
	}
);
