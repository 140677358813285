import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  Row,
  Col,
  Select,
  Card,
  Progress,
  Space,
  Typography,
  Avatar,
  Skeleton,
} from "antd";
const { Text, Link } = Typography;

function TotalOrdersCard(props) {
  const { data } = props;
  const customLoading = useSelector(state => state.dashboard.customLoading)
  return (
    <>
      <Card
        actions={!customLoading && [
          <>
            <Text className="hp-d-block hp-caption hp-letter-space-1 hp-text-color-primary-1">
              Pending
            </Text>
            <Text strong className="h6">
              {data?.pending ? data?.pending : 0}
            </Text>
          </>,
          <>
            <Text
              type="success"
              className="hp-d-block hp-caption hp-letter-space-1"
            >
              Delivered
            </Text>
            <Text strong className="h6">
              {data?.delivered ? data?.delivered : 0}
            </Text>
          </>,
          <>
            <Text type="danger" className="hp-d-block hp-caption">
              Un Paid
            </Text>
            <Text strong className="h6">
              {data?.unPaid ? data?.unPaid : 0}
            </Text>
          </>,
        ]}
        bodyStyle={{ padding: "15px" }}
        className="hp-border-color-black-40 hp-transition hp-hover-border-color-primary-4
        hp-card-6 hp-eCommerceCardOne hp-eCommerceCardOne-wishlist hp-icon-card hp-transition"
      >
        {customLoading ? <Skeleton active loading={customLoading} /> :
          <Row align="middle">
            <Col span={6}>
              <Avatar
                size={45}
                icon={props.icon}
                className="hp-bg-primary-1 hp-text-color-black-0"
              />
            </Col>
            <Col span={16}>
              <Text className="h6 hp-d-block hp-mb-10" strong>
                {props?.title}
              </Text>
              <Text className="h4" strong>
                {data?.total ? data?.total : 0}
              </Text>
            </Col>
          </Row>}
      </Card>
    </>
  );
}

export default TotalOrdersCard;
