import { createSlice } from "@reduxjs/toolkit";
import { getHubByID, getHubs, hubAdd, hubDelete } from "./hubAction";

const hubSlice = createSlice({
  name: "hub",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    hublist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    hubParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    SetHubParams: (state, action) => {
      return {
        ...state,
        hubParams: action.payload,
      };
    },
    hubDelete: (state) => {
      return {
        ...state,
        loading: false

      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },


  },
  extraReducers: (builder) => {
    builder
      .addCase(getHubs.pending, (state, action) => {
        state.status = "getHubs loading";
        state.loading = true;
      })
      .addCase(getHubs.fulfilled, (state, action) => {
        var response = action.payload.response;
        state.status = "getHubs succeeded";
        state.loading = false;
        var noofpages = Math.ceil(
          action.payload.count / state.hubParams?.page_size
        );
        state.hublist = response.results;
        state.listCount = response.count;
        state.hubParams = {
          ...state.hubParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getHubs.rejected, (state, action) => {
        state.status = "getHubs failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(hubAdd.pending, (state, action) => {
        state.status = "hubAdd loading";
        state.loading = true;
      })
      .addCase(hubAdd.fulfilled, (state, action) => {
        state.status = "hubAdd succeeded";
        state.loading = false
        state.model = action.payload.modal;
        state.message = action.payload.response;
        state.rowdata = {};
      }
      )
      .addCase(hubAdd.rejected, (state, action) => {
        state.status = "hubAdd failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(hubDelete.pending, (state, action) => {
        state.status = "hubDelete loading";
        state.loading = true;
      })
      .addCase(hubDelete.fulfilled, (state, action) => {
        state.status = "hubDelete succeeded";
        state.loading = false
      }
      )
      .addCase(hubDelete.rejected, (state, action) => {
        state.status = "hubDelete failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getHubByID.pending, (state, action) => {
        state.status = "getHubByID loading";
        state.loading = true;
      })
      .addCase(getHubByID.fulfilled, (state, action) => {
        state.status = "getHubByID succeeded";
        state.loading = false
        state.model = true;
        state.rowdata = {
          ...action.payload,
        };
      }
      )
      .addCase(getHubByID.rejected, (state, action) => {
        state.status = "getHubByID failed";
        state.loading = false;
        state.error = action.error.message;
      })
  }

});
export const {
  InputChangeValue,
  apiError,
  isModelVisible,
  SetHubParams,
} = hubSlice.actions;

export default hubSlice.reducer;
