import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
    addParams,
    getList,
    getParamsList,
    postAdd,
    postEdit,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
    RiCheckboxCircleLine,
    RiAlertLine,
    RiInformationLine,
} from "react-icons/ri";
import axiosInstance from "../../../helpers/AxiosHelper";

const seralizeParams = (payload) => {
    var params = {};
    for (const k in payload) {
        if (Object.hasOwnProperty.call(payload, k)) {
            if (k === "type") {
                params.type = payload.type.join(",");
            } else {
                if (payload[k] !== "" && k !== "no_of_pages") {
                    params[k] = payload[k];
                }
            }
        }
    }
    return params;
};
const state_name = "deliveryPerson"

export const getDeliveryPersons = createAsyncThunk(state_name +
    "/getDeliveryPersons",
    async (payload) => {
        const params = addParams(payload);
        try {
            const response = await getParamsList(
                `/users/deliveryperson/create/`,
                params
            );
            if (response) {
                return response;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            return error.message;
        }
    }
);
// export const postDeliveryPersons = createAsyncThunk(
//     "/postDeliveryPersons",
//     async (payload) => {
//         try {
//             const {
//                 data,
//                 history,
//                 deliveryPersonDetailsForm,
//                 locationDetailsForm,
//                 // shippingAddressForm,
//                 clearData,
//                 message,
//             } = payload;
//             const response = await postAdd("/users/deliveryperson/create/", data);
//             if (response.status === 201) {
//                 Modal.success({
//                     icon: (
//                         <span className="remix-icon">
//                             <RiCheckboxCircleLine />
//                         </span>
//                     ),
//                     title: (
//                         <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
//                     ),
//                     content: (
//                         <div>
//                             <p className="hp-p1-body hp-text-color-black-80">
//                                 DeliveryPerson Added Successfully.!
//                             </p>
//                         </div>
//                     ),
//                 });
//                 history.push("/delivery_person");
//                 deliveryPersonDetailsForm.resetFields();
//                 locationDetailsForm.resetFields();
//                 // shippingAddressForm.resetFields();
//                 message.success("Processing complete!");
//                 clearData();
//                 return response;
//             } else {
//                 throw response;
//             }
//         } catch (error) {
//             if (error?.response?.data) {
//                 const values = Object.keys(error.response.data);
//                 console.log(values);
//                 Modal.error({
//                     icon: (
//                         <span className="remix-icon">
//                             <RiAlertLine />
//                         </span>
//                     ),
//                     title: (
//                         <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
//                     ),
//                     content: (
//                         <div>
//                             <p className="hp-p1-body hp-text-color-black-80">
//                                 {error?.response?.data[values[0]][values[0]]}
//                             </p>
//                         </div>
//                     ),
//                 });
//             } else {
//                 Modal.error({
//                     icon: (
//                         <span className="remix-icon">
//                             <RiAlertLine />
//                         </span>
//                     ),

//                     title: (
//                         <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
//                     ),
//                     content: (
//                         <div>
//                             <p className="hp-p1-body hp-text-color-black-80">
//                                 Sorry! Getting from server side issue!
//                             </p>
//                         </div>
//                     ),
//                 });
//             }
//             throw error.message;
//         }
//     }
// );
export const postDeliveryPersons = createAsyncThunk(state_name +
    "/postDeliveryPersons",
    async (payload, { dispatch }) => {
        const { data, handleAdd, deliveryPersonDetailsForm } = payload;
        try {
            const response = await postAdd(`/users/deliveryperson/create/`, { ...data });
            if (response.status >= 200 && response.status < 300) {
                handleAdd(response.data.id);
                deliveryPersonDetailsForm.resetFields();
                return response.data;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            console.log(error.response);
            throw error.message;
        }
    }
);
export const editDeliveryPersons = createAsyncThunk(state_name +
    "/editDeliveryPersons",
    async (payload) => {
        const { id, data, deliveryPersonDetailsForm, handleAdd } = payload;
        try {
            const response = await axiosInstance.put(`/users/deliveryperson/details/${id}/`, {
                ...data,
            });
            if (response.status >= 200 && response.status < 300) {
                handleAdd(id);
                deliveryPersonDetailsForm.resetFields();
                return response.data;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);
// export const editDeliveryPersons = createAsyncThunk(
//     "/editDeliveryPersons",
//     async (payload) => {
//         try {
//             const {
//                 id,
//                 data,
//                 history,
//                 deliveryPersonDetailsForm,
//                 locationDetailsForm,
//                 // shippingAddressForm,
//                 clearData,
//                 message,
//             } = payload;
//             const response = await postEdit(
//                 `/users/deliveryperson/details/${id}/`,
//                 data
//             );
//             if (response.status === 200) {
//                 Modal.success({
//                     icon: (
//                         <span className="remix-icon">
//                             <RiCheckboxCircleLine />
//                         </span>
//                     ),
//                     title: (
//                         <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
//                     ),
//                     content: (
//                         <div>
//                             <p className="hp-p1-body hp-text-color-black-80">
//                                 DeliveryPerson Edited Successfully.!
//                             </p>
//                         </div>
//                     ),
//                 });
//                 history.push("/delivery_person");
//                 deliveryPersonDetailsForm.resetFields();
//                 locationDetailsForm.resetFields();
//                 // shippingAddressForm.resetFields();
//                 message.success("Processing complete!");
//                 clearData();
//                 return response;
//             } else {
//                 throw new Error(response);
//             }
//         } catch (error) {
//             if (error.response) {
//                 Modal.error({
//                     icon: (
//                         <span className="remix-icon">
//                             <RiAlertLine />
//                         </span>
//                     ),
//                     title: (
//                         <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
//                     ),
//                     content: (
//                         <div>
//                             <p className="hp-p1-body hp-text-color-black-80">
//                                 Sorry! Unable to edit DeliveryPerson . Please try
//                                 again!
//                             </p>
//                         </div>
//                     ),
//                 });
//             } else {
//                 Modal.error({
//                     icon: (
//                         <span className="remix-icon">
//                             <RiAlertLine />
//                         </span>
//                     ),

//                     title: (
//                         <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
//                     ),
//                     content: (
//                         <div>
//                             <p className="hp-p1-body hp-text-color-black-80">
//                                 Sorry! Getting from server side issue!
//                             </p>
//                         </div>
//                     ),
//                 });
//             }
//             throw error.message;
//         }
//     }
// );
export const getDeliveryPersonByID = createAsyncThunk(state_name +
    "/getDeliveryPersonByID",
    async (payload) => {
        const params = addParams(payload);
        try {
            const response = await getList(
                `/users/deliveryperson/details/${params.id}/`
            );
            if (response) {
                return response.data;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            return error.message;
        }
    }
);

export const deliveryPersonActive = createAsyncThunk(state_name + "/deliveryPersonActive", async (payload) => {
    const { id, onSuccess, OnFailure } = payload;
    try {
        const response = await postAdd(`/users/admin/deliveryperson/active/${id}/`, {});
        if (response.status >= 200 && response.status < 300) {
            onSuccess(response.data);
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        if (error.response) {
            OnFailure(error.response.data.phone);
        } else {
            OnFailure("Sorry! Getting from server side issue!");
        }
        throw error.message;
    }
});
export const deliveryPersonInActive = createAsyncThunk(state_name +
    "/deliveryPersonInActive",
    async (payload) => {
        const { id, onSuccess, onFailure } = payload;
        try {
            const response = await postAdd(`/users/admin/deliveryperson/inactive/${id}/`, {});
            if (response.status >= 200 && response.status < 300) {
                onSuccess(response.data);
                return response.data;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            if (error.response) {
                onFailure(error.response.data.phone);
                throw error.response.data.phone;
            } else {
                onFailure("Sorry! Getting from server side issue!");
                throw error.message;
            }
        }
    }
);

export const getDeliverableOrders = createAsyncThunk(state_name +
    "/getDeliverableOrders",
    async (payload) => {
        const params = addParams(payload);
        try {
            const response = await getParamsList(
                `/b2c/admin/deliverable_orders/list/`,
                params
            );
            if (response) {
                return response;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            return error.message;
        }
    }
);
export const getDeliveryCashCollection = createAsyncThunk(state_name +
    "/getDeliveryCashCollection",
    async (payload) => {
        const params = addParams(payload);
        try {
            const response = await getParamsList(
                `/b2c/admin/delivery_cash_collection/list/`,
                params
            );
            if (response) {
                return response;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            return error.message;
        }
    }
);


export const getUserStates = createAsyncThunk(state_name +
    "/getUserStates",
    async (payload, { getState, dispatch, rejectWithValue }) => {

        var params = seralizeParams(payload);
        try {
            const firstResponse = await getParamsList(
                "/masters/states/mini/",
                params
            );

            if (firstResponse.results) {
                let page = params.page + 1;
                const page_size = 10;
                var noofpages = Math.ceil(
                    firstResponse.count / page_size
                );

                let returningValues = {
                    statesList: firstResponse.results,
                    count: firstResponse.count
                };
                while (page <= noofpages) {
                    const response = await getParamsList(
                        "/masters/states/mini/",
                        {
                            ...params,
                            page,
                            page_size
                        }
                    );
                    returningValues = {
                        statesList: [...returningValues.statesList, ...response.results],
                        count: response.count
                    };
                    page++
                }
                // if (response.results) {
                console.log(getState())
                const deliveryPersonState = getState().masters[state_name];

                var statesList = returningValues?.statesList?.map((userState) => {
                    if (deliveryPersonState.userSelectedStatesList.indexOf(userState.id) !== -1) {
                        userState.state_checked = true;
                    } else {
                        userState.state_checked = false;
                    }
                    return userState;
                });
                const configHome = statesList
                    .filter((z) => z.state_checked)
                    .map((userState) => {
                        return dispatch(getUserZones({ state: userState.id, page: 1, page_size: 10 }));
                    });

                const promise = await Promise.all(configHome);
                const promisePayload = promise.map(zone => zone.payload);
                const newStatesList = statesList.map(e => {
                    return {
                        ...e,
                        zonesCount: promisePayload.find(zone => zone.state_id == e.id)?.count,
                        zones: promisePayload.find(zone => zone.state_id == e.id)?.zoneList,
                    }
                });
                returningValues = {
                    statesList: newStatesList,
                    count: firstResponse.count
                };
                // }

                return returningValues

            } else {
                throw new Error(firstResponse);
            }
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
);
export const getUserZones = createAsyncThunk(state_name +
    "/getUserZones",
    async (payload, { getState, dispatch }) => {
        var params = seralizeParams(payload);
        try {

            const firstResponse = await getParamsList(
                "/masters/zone/mini/",
                params
            );

            if (firstResponse.results) {

                let page = params.page + 1;
                const page_size = 10;
                var noofpages = Math.ceil(
                    firstResponse.count / page_size
                );

                let returningValues = {
                    zoneList: firstResponse.results,
                    state_id: "",
                    count: firstResponse.count
                };
                while (page <= noofpages) {
                    const response = await getParamsList(
                        "/masters/zone/mini/",
                        {
                            ...params,
                            page,
                            page_size
                        }
                    );
                    returningValues = {
                        zoneList: [...returningValues.zoneList, ...response.results],
                        state_id: params.state,
                        count: response.count
                    };
                    page++
                }


                const deliveryPersonState = getState().masters[state_name];
                var zoneList = returningValues?.zoneList?.map((zone) => {
                    if (
                        deliveryPersonState.userSelectedZonesList.indexOf(zone.id) !== -1
                    ) {
                        zone.zone_checked = true;
                    } else {
                        zone.zone_checked = false;
                    }
                    zone.state_id = payload.state;
                    return zone;
                });
                const configHome = zoneList
                    .filter((d) => d.zone_checked)
                    .map((zone) => {
                        return dispatch(getUserCities({ zone: zone.id, state: payload.state, page: 1, page_size: 10 }));
                    });
                const promise = await Promise.all(configHome);
                const promisePayload = promise.map(city => city.payload);

                let newZoneList = zoneList.map(e => {
                    return {
                        ...e,
                        citiesCount: promisePayload.find(city => (city.state_id == e.state_id) && (city.zone_id == e.id))?.count,
                        cities: promisePayload.find(city => (city.state_id == e.state_id) && (city.zone_id == e.id))?.citiesList
                    }
                })
                returningValues = {
                    zoneList: newZoneList,
                    state_id: payload.state,
                    count: firstResponse.count
                }
                return returningValues;
            } else {
                throw new Error(firstResponse);
            }
        } catch (error) {
            return error.message;
        }
    }
);
export const getUserCities = createAsyncThunk(state_name +
    "/getUserCities",
    async (payload, { getState, dispatch }) => {
        var params = seralizeParams(payload);
        try {

            const firstResponse = await getParamsList(
                "/masters/citys/mini/",
                params
            );
            if (firstResponse.results) {

                let page = params.page + 1;
                const page_size = 10;
                var noofpages = Math.ceil(
                    firstResponse.count / page_size
                );

                let returningValues = {
                    citiesList: firstResponse.results,
                    state_id: params.state,
                    zone_id: params.zone,
                    count: firstResponse.count
                };
                while (page <= noofpages) {
                    const response = await getParamsList(
                        "/masters/citys/mini/",
                        {
                            ...params,
                            page,
                            page_size
                        }
                    );
                    returningValues = {
                        ...returningValues,
                        citiesList: [...returningValues.citiesList, ...response.results],
                    };
                    page++
                }

                const deliveryPersonState = getState().masters[state_name];
                var citiesList = returningValues.citiesList.map((city) => {
                    if (
                        deliveryPersonState.userSelectedCityList.indexOf(city.id) !== -1
                    ) {
                        city.city_checked = true;
                    } else {
                        city.city_checked = false;
                    }
                    city.state_id = payload.state;
                    city.zone_id = payload.zone;
                    return city;
                });
                const configHome = citiesList
                    .filter((d) => d.city_checked)
                    .map((city) => {
                        return dispatch(getUserAreas({ city: city.id, state: payload.state, zone: payload.zone, page: 1, page_size: 10 }));
                    });
                const promise = await Promise.all(configHome);
                const promisePayload = promise.map(area => area.payload);

                const newCities = citiesList?.map(e => {
                    return {
                        ...e,
                        areasCount: promisePayload.find(area => (area.state_id == e.state_id) && (area.zone_id == e.zone_id) && (area.city_id == e.id))?.
                            count,
                        areas: promisePayload.find(area => (area.state_id == e.state_id) && (area.zone_id == e.zone_id) && (area.city_id == e.id))?.
                            areasList
                    }
                });
                returningValues = {
                    ...returningValues,
                    citiesList: newCities,
                };

                return returningValues;
            } else {
                throw new Error(firstResponse);
            }
        } catch (error) {
            return error.message;
        }
    }
);
export const getUserAreas = createAsyncThunk(state_name +
    "/getUserAreas",
    async (payload, { getState, dispatch }) => {
        var params = seralizeParams(payload);
        try {

            const firstResponse = await getParamsList(
                "/masters/area/mini/",
                params
            );
            if (firstResponse.results) {

                let page = params.page + 1;
                const page_size = 10;
                var noofpages = Math.ceil(
                    firstResponse.count / page_size
                );

                let returningValues = {
                    areasList: firstResponse.results,
                    state_id: params.state,
                    zone_id: params.zone,
                    city_id: params.city,
                    count: firstResponse.count
                };
                while (page <= noofpages) {
                    const response = await getParamsList(
                        "/masters/area/mini/",
                        {
                            ...params,
                            page,
                            page_size
                        }
                    );
                    returningValues = {
                        ...returningValues,
                        areasList: [...returningValues.areasList, ...response.results],
                    };
                    page++
                }


                const deliveryPersonState = getState().masters[state_name];
                var areasList = returningValues.areasList.map((area) => {
                    if (
                        deliveryPersonState.userSelectedAreaList.indexOf(area.id) !== -1
                    ) {
                        area.area_checked = true;
                    } else {
                        area.area_checked = false;
                    }
                    area.state_id = payload.state;
                    area.zone_id = payload.zone;
                    area.city_id = payload.city;
                    return area;
                });
                const configHome = areasList
                    .filter((d) => d.area_checked)
                    .map((area) => {
                        return dispatch(getUserLocation({ area: area.id, state: payload.state, zone: payload.zone, city: payload.city, page: 1, page_size: 10 }));
                    });
                const promise = await Promise.all(configHome);
                const promisePayload = promise.map(area => area.payload);

                const newAreaList = areasList.map(e => {
                    return {
                        ...e,
                        locationsCount: promisePayload.find(location => (location.state_id == e.state_id) && (location.zone_id == e.zone_id) && (location.city_id == e.city_id) && location.area_id == e.id)?.count,
                        locations: promisePayload.find(location => (location.state_id == e.state_id) && (location.zone_id == e.zone_id) && (location.city_id == e.city_id) && location.area_id == e.id)?.locationList
                    }
                })

                returningValues = {
                    ...returningValues,
                    areasList: newAreaList,
                };

                console.log("returningValues.........", returningValues);

                return returningValues;
            } else {
                throw new Error(firstResponse);
            }
        } catch (error) {
            return error.message;
        }
    }
);
export const getUserLocation = createAsyncThunk(state_name +
    "/getUserLocation",
    async (payload, { getState, dispatch }) => {
        var params = seralizeParams(payload);
        try {

            const firstResponse = await getParamsList(
                "/masters/location/mini/",
                params
            );
            if (firstResponse.results) {

                let page = params.page + 1;
                const page_size = 10;
                var noofpages = Math.ceil(
                    firstResponse.count / page_size
                );

                let returningValues = {
                    locationList: firstResponse.results,
                    state_id: params.state,
                    zone_id: params.zone,
                    city_id: params.city,
                    area_id: params.area,
                    count: firstResponse.count
                };
                while (page <= noofpages) {
                    const response = await getParamsList(
                        "/masters/location/mini/",
                        {
                            ...params,
                            page,
                            page_size
                        }
                    );
                    returningValues = {
                        ...returningValues,
                        locationList: [...returningValues.locationList, ...response.results],
                    };
                    page++
                }

                const deliveryPersonState = getState().masters[state_name];
                var locationList = returningValues.locationList.map((location) => {
                    if (
                        deliveryPersonState.userSelectedLocationList.indexOf(location.id) !== -1
                    ) {
                        location.location_checked = true;
                    } else {
                        location.location_checked = false;
                    }
                    location.state_id = payload.state;
                    location.zone_id = payload.zone;
                    location.city_id = payload.city;
                    location.area_id = payload.area;
                    return location;
                });

                returningValues = {
                    ...returningValues,
                    locationList: locationList,
                };

                return returningValues;
            } else {
                throw new Error(firstResponse);
            }
        } catch (error) {
            return error.message;
        }
    }
);

export const putDeliveryPersonLocation = createAsyncThunk(state_name +
    "/putDeliveryPersonLocation",
    async (payload) => {
        const { id, data, history, clearData } = payload;
        try {
            const response = await postEdit(`/users/deliveryperson/locationpermission/${id}`, { ...data });
            if (response.status >= 200 && response.status < 300) {
                // onSuccess(response.data);
                history.push(`/delivery_person`);
                clearData();
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Delivery Person Added Successfully.!
                            </p>
                        </div>
                    ),
                });
                return response.data;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),
                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Unable to add delivery person location permission. Please try again!
                        </p>
                    </div>
                ),
            });
            throw error.message;
        }
    }
);