import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
	addParams,
	getDownloadFile,
	getList,
	getParamsList,
	postAdd,
	postEdit,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
	RiCheckboxCircleLine,
	RiAlertLine,
	RiInformationLine,
} from "react-icons/ri";

export const getDemandDraft = createAsyncThunk(
	"/getDemandDraft",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/orders/demanddraft/`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

export const demandDraftExport = createAsyncThunk(
	"/demandDraftExport",
	async (payload) => {
		const params = addParams(payload);
		try {
			const demand_draft_export_anchor_download = document.getElementById(
				"demand_draft_export_anchor_download"
			);

			if (demand_draft_export_anchor_download) {
				demand_draft_export_anchor_download.remove();
			}
			const response = await getParamsList(
				`/orders/demand_draft_export/`,
				params
			);
			if (response) {
				console.log(response);
				const anchorElement = document.createElement("a");
				anchorElement.id = "demand_draft_export_anchor_download";
				anchorElement.href = response;
				document.body.appendChild(anchorElement);
				anchorElement.click();
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
// export const demandDraftExport = createAsyncThunk(
// 	"/demandDraftExport",
// 	async (payload) => {
// 		const params = addParams(payload);
// 		try {
// 			const response = await getDownloadFile(
// 				`/orders/demand_draft_export/`,
// 				params
// 			);
// 			if (response) {
// 				return response;
// 			} else {
// 				throw new Error(response);
// 			}
// 		} catch (error) {
// 			return error.message;
// 		}
// 	}
// );
