import { createSlice } from "@reduxjs/toolkit";
import { getDeliveryNote, getDeliveryNoteItems } from "./deliveryNote.action";
import { v4 as uuidv4 } from "uuid";

const initalState = {
	loading: false,
	error: "",
	status: "",
	list: [],
	selectedData: {},
	count: 0,
	pageParams: {
		start_date: "",
		end_date: "",
		no_of_pages: 0,
		page_size: 10,
		page: 1,
		search: "",
	},

	itemsList: [],
	itemsCount: 0,
	itemLoading: false,
	itemsPageParams: {
		start_date: "",
		end_date: "",
		no_of_pages: 0,
		page_size: 10,
		page: 1,
		search: "",
	},

	filterParams: {},
};

const deliveryNoteSlice = createSlice({
	name: "deliveryNote",
	initialState: {
		loading: false,
		error: "",
		status: "",
		list: [],
		selectedData: {},
		count: 0,
		pageParams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
		drawer: false,

		itemsList: [],
		itemsCount: 0,
		itemLoading: false,
		itemsPageParams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
		itemsFilterDrawer: false,
		itemFilterParams: {},

		filterParams: {},
	},
	reducers: {
		isDrawerVisible: (state, action) => {
			return {
				...state,
				drawer: action.payload,
			};
		},
		isItemsDrawerVisible: (state, action) => {
			return {
				...state,
				itemsFilterDrawer: action.payload,
			};
		},
		FilterInputChangeValue: (state, action) => {
			return {
				...state,
				filterParams: {
					...state.filterParams,
					[action.payload.key]: action.payload.value,
				},
			};
		},
		changeFilterParams: (state, action) => {
			return {
				...state,
				filterParams: action.payload,
			};
		},

		FilterItemInputChangeValue: (state, action) => {
			return {
				...state,
				itemFilterParams: {
					...state.itemFilterParams,
					[action.payload.key]: action.payload.value,
				},
			};
		},
		changeItemFilterParams: (state, action) => {
			return {
				...state,
				itemFilterParams: action.payload,
			};
		},
	},
	extraReducers: (builder) => {
		builder
			//get Data
			.addCase(getDeliveryNote.pending, (state, action) => {
				state.status = "getDeliveryNote loading";
				state.loading = true;
			})
			.addCase(getDeliveryNote.fulfilled, (state, action) => {
				state.status = "getDeliveryNote succeeded";
				const { response, params } = action.payload;
				state.loading = false;
				state.count = response.count;
				state.list = response.results;

				state.pageParams = {
					...state.pageParams,
					...params,
				};
				state.selectedData = {};
			})
			.addCase(getDeliveryNote.rejected, (state, action) => {
				state.status = "getDeliveryNote failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//get Items Data
			.addCase(getDeliveryNoteItems.pending, (state, action) => {
				state.status = "getDeliveryNoteItems loading";
				state.itemLoading = true;
			})
			.addCase(getDeliveryNoteItems.fulfilled, (state, action) => {
				state.status = "getDeliveryNoteItems succeeded";
				const { response, params } = action.payload;
				state.itemLoading = false;
				state.itemsCount = response.count;
				state.itemsList = response.results;

				state.itemsPageParams = {
					...state.itemsPageParams,
					...params,
				};
			})
			.addCase(getDeliveryNoteItems.rejected, (state, action) => {
				state.status = "getDeliveryNoteItems failed";
				state.itemLoading = false;
				state.error = action.error.message;
			});
	},
});

// Action creators are generated for each case reducer function
export const {
	isDrawerVisible,
	isItemsDrawerVisible,
	FilterInputChangeValue,
	FilterItemInputChangeValue,
	changeFilterParams,
	changeItemFilterParams,
} = deliveryNoteSlice.actions;

export default deliveryNoteSlice.reducer;
