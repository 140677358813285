import { createSlice } from "@reduxjs/toolkit";
import { getBannersList, bannersAdd, bannerDelete, getBannerByID, editBannerImage } from "./bannersAction";

const bannerSlice = createSlice({
    name: "banner",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,
        bannerlist: [],
        bannerData: {},
        unitsList: [],
        unitsLoading: false,
        model: false,
        uploadmodel: false,
        error_msg: null,
        rowdata: {},
        bannerId:"",
        listCount: 0,
        uploadImage: {
            file: {},
            imageUrl: "",
            loading: false,
        },
        bannerParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
    },
    reducers: {
        clearBannersListAndData: (state, action) => {
            return {
                ...state,
                bannerlist: [],
                bannerData: {},
                unitsList: [],
            };
        },
        clearuploadImage: (state, action) => {
            return {
                ...state,
                uploadImage: {
                    file: {},
                    imageUrl: "",
                    loading: false,
                },
            };
        },
        SetBannerParams: (state, action) => {
            return {
                ...state,
                bannerParams: {
                    ...state.bannerParams,
                    ...action.payload
                },
            };
        },
        setUploadImage: (state, action) => {
            return {
                ...state,
                uploadImage: {
                    file: action.payload.file,
                    imageUrl: action.payload.imageUrl,
                    loading: action.payload.loading
                },
            };
        },
        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                    rowdata: {},
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
        isUploadModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    uploadmodel: action.payload,
                    rowdata: {},
                };
            } else {
                return {
                    ...state,
                    uploadmodel: action.payload,
                };
            }
        },
        bannersDelete: (state) => {
            return {
                ...state,
                loading: false

            };
        },
        setBannerId:(state,action) =>{
            return {
                ...state,
                bannerId: action.payload
            }
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(getBannersList.pending, (state, action) => {
                state.status = "getBannersList loading";
                state.loading = true;
            })
            .addCase(getBannersList.fulfilled, (state, action) => {
                console.log(action.payload.response)
                state.status = "getBannersList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.bannerParams?.page_size
                );

                state.bannerlist = response.results;
                state.listCount = response.count;
                state.bannerParams = {
                    ...state.bannerParams,
                    no_of_pages: noofpages,
                };
            })
            .addCase(getBannersList.rejected, (state, action) => {
                state.status = "getBannersList failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(bannersAdd.pending, (state, action) => {
                state.status = "bannersAdd loading";
                state.loading = true;
            })
            .addCase(bannersAdd.fulfilled, (state, action) => {
                state.status = "bannersAdd succeeded";
                state.loading = false
                // state.model = action.payload.modal;
                // state.message = action.payload.response;
                state.rowdata = {};
            })
            .addCase(bannersAdd.rejected, (state, action) => {
                state.status = "bannersAdd failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(bannerDelete.pending, (state, action) => {
                state.status = "bannerDelete loading";
                state.loading = true;
            })
            .addCase(bannerDelete.fulfilled, (state, action) => {
                state.status = "bannerDelete succeeded";
                state.loading = false
            }
            )
            .addCase(bannerDelete.rejected, (state, action) => {
                state.status = "bannerDelete failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getBannerByID.pending, (state, action) => {
                state.status = "getBannerByID loading";
                state.loading = true;
            })
            .addCase(getBannerByID.fulfilled, (state, action) => {
                state.status = "getBannerByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
                state.uploadImage.imageUrl = action.payload.banner_img;
            }
            )
            .addCase(getBannerByID.rejected, (state, action) => {
                state.status = "getBannerByID failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(editBannerImage.pending, (state, action) => {
                state.status = "editBannerImage loading";
                state.loading = true;
            })
            .addCase(editBannerImage.fulfilled, (state, action) => {
                state.status = "editBannerImage succeeded";
                state.loading = false
                state.rowdata = action.payload;
                state.uploadImage.imageUrl = action.payload.banner_img;
            }
            )
            .addCase(editBannerImage.rejected, (state, action) => {
                state.status = "editBannerImage failed";
                state.loading = false;
                state.error = action.error.message;
            })
    }

});
export const {
    SetBannerParams,
    setUploadImage,
    clearBannersListAndData,
    clearuploadImage,
    isModelVisible,
    isUploadModelVisible,
    InputChangeValue,
    bannersDelete,
    setBannerId
} = bannerSlice.actions;

export default bannerSlice.reducer;
