import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
	addParams,
	getList,
	getParamsList,
	postAdd,
	postEdit,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
	RiCheckboxCircleLine,
	RiAlertLine,
	RiInformationLine,
} from "react-icons/ri";

const slice_name = "";

export const getDeliveryNote = createAsyncThunk(
	"/getDeliveryNote",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/thirdparty/delivery_note/list/`,
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getDeliveryNoteItems = createAsyncThunk(
	"/getDeliveryNoteItems",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/thirdparty/delivery_note_items/list/`,
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
