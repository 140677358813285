import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
	addParams,
	getList,
	getParamsList,
	postAdd,
	postEdit,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
	RiCheckboxCircleLine,
	RiAlertLine,
	RiInformationLine,
} from "react-icons/ri";

export const getWalletTransactions = createAsyncThunk(
	"/getWalletTransactions",
	async (payload) => {
		const params = addParams(payload);
		try {
			// const response = await getParamsList(`/b2c/admin/wallet/`, params);
			const response = await getParamsList(
				`/b2c/admin/wallettransactionslist/`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getWallets = createAsyncThunk("/getWallets", async (payload) => {
	const params = addParams(payload);
	try {
		const response = await getParamsList(
			`/b2c/admin/walletConsolidatedAmount/`,
			params
		);
		if (response) {
			return response;
		} else {
			throw new Error(response);
		}
	} catch (error) {
		return error.message;
	}
});
export const postWallets = createAsyncThunk(
	"/postWallets",
	async (payload, { dispatch }) => {
		try {
			const { data, pageParams, handleResetInputValues } = payload;
			const response = await postAdd(`/b2c/admin/wallet/create/`, data);
			if (response.status === 201) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Wallet Added Successfully.!
							</p>
						</div>
					),
				});
				dispatch(getWallets({ ...pageParams }));
				handleResetInputValues();
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error.response) {
				const errorValues = error.response.data;
				console.log(errorValues);

				if (
					errorValues["Insufficient Balance"]?.length &&
					errorValues["Required balance"]?.length &&
					errorValues["wallet balance"]?.length
				) {
					Modal.error({
						icon: (
							<span className="remix-icon">
								<RiAlertLine />
							</span>
						),
						title: (
							<h5 className="hp-mb-0 hp-font-weight-500">
								Insufficient Balance
							</h5>
						),
						content: (
							<div>
								<p className="hp-p1-body hp-text-color-black-80">
									{errorValues["wallet balance"][0]}
								</p>
								<p className="hp-p1-body hp-text-color-black-80">
									{errorValues["Required balance"][0]}
								</p>
								<p className="hp-p1-body hp-text-color-black-80">
									{errorValues["Insufficient Balance"][0]}
								</p>
							</div>
						),
					});
				} else {
					Modal.error({
						icon: (
							<span className="remix-icon">
								<RiAlertLine />
							</span>
						),
						title: (
							<h5 className="hp-mb-0 hp-font-weight-500">
								Error
							</h5>
						),
						content: (
							<div>
								<p className="hp-p1-body hp-text-color-black-80">
									{errorValues["detail"][0]
										? errorValues["detail"][0]
										: "Sorry! Unable to add Customer Order. Please try again!"}
								</p>
							</div>
						),
					});
				}
			} else {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),

					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Getting from server side issue!
							</p>
						</div>
					),
				});
			}
			throw error.message;
		}
	}
);

export const editWallets = createAsyncThunk(
	"/editWallets",
	async (payload, { dispatch }) => {
		try {
			const { data, handleResetInputValues, pageParams } = payload;
			const response = await postEdit(
				`/b2c/admin/wallet/details/${data.id}/`,
				data
			);
			if (response.status === 200) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Wallet Edited Successfully.!
							</p>
						</div>
					),
				});
				dispatch(getWallets({ ...pageParams }));
				handleResetInputValues(response.data);
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error.response) {
				const errorValues = error.response.data;
				console.log(errorValues);

				if (
					errorValues["Insufficient Balance"]?.length &&
					errorValues["Required balance"]?.length &&
					errorValues["wallet balance"]?.length
				) {
					Modal.error({
						icon: (
							<span className="remix-icon">
								<RiAlertLine />
							</span>
						),
						title: (
							<h5 className="hp-mb-0 hp-font-weight-500">
								Insufficient Balance
							</h5>
						),
						content: (
							<div>
								<p className="hp-p1-body hp-text-color-black-80">
									{errorValues["wallet balance"][0]}
								</p>
								<p className="hp-p1-body hp-text-color-black-80">
									{errorValues["Required balance"][0]}
								</p>
								<p className="hp-p1-body hp-text-color-black-80">
									{errorValues["Insufficient Balance"][0]}
								</p>
							</div>
						),
					});
				} else {
					Modal.error({
						icon: (
							<span className="remix-icon">
								<RiAlertLine />
							</span>
						),
						title: (
							<h5 className="hp-mb-0 hp-font-weight-500">
								Error
							</h5>
						),
						content: (
							<div>
								<p className="hp-p1-body hp-text-color-black-80">
									{errorValues["detail"][0]
										? errorValues["detail"][0]
										: "Sorry! Unable to add Customer Order. Please try again!"}
								</p>
							</div>
						),
					});
				}
			}
			throw error.message;
		}
	}
);
export const getWalletsByID = createAsyncThunk(
	"/getWalletsByID",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getList(
				`/b2c/admin/wallet/details/${params.id}/`
			);
			if (response) {
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
