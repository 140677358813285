import { configureStore, combineReducers } from "@reduxjs/toolkit";
import customiseReducer from "./customise/customise";
import loginSlice from "./auth/login/loginSlice";
import miniSlice from "./mini/miniSlice";
import systemSlice from "./system/systemSlice";
import deleteUserSlice from "./auth/deleteAccount/deleteUserSlice";
import Masters from "./Masters/masters";
import manageUserSlice from "./settings/ManageUsers/manageUserSlice";
import manageUsersB2CSlice from "./settings/ManageUsersB2C/manageUserB2CSlice";
import manageProfileSlice from "./settings/ManageProfile/profileSlice";
import manageCompanySlice from "./settings/ManageCompany/manageCompanySlice";
import importExportSlice from "./settings/ImportExport/importExportSlice";
import appsettingsSlice from "./settings/AppSettings/appsettingsSlice";
import notificationSlice from "./notifications/notificationSlice";
import productsSlice from "./sideMenu/products/productsSlice";
import retailerIndentSlice from "./sideMenu/retailerIndents/retailerIndents.slice";
import driverSlice from "./sideMenu/driver/driverSlice";
import reportsSlice from "./reports/reportsSlice";
import userProfileSlice from "./userProfile/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import retailersSlice from "./sideMenu/retailers/retailers.slice";
import distributorIndentSlice from "./sideMenu/distributorIndents/distributorIndents.slice";
import receiptSlice from "./sideMenu/receipts/receipt.slice";
import saleordersSlice from "./sideMenu/saleorders/saleorders.slice";
import demandsheetSlice from "./sideMenu/demandsheet/demandsheet.slice";
import retailerReceiptsSlice from "./sideMenu/retailerReceipts/retailerReceipts.slice";
import distributorOrdersSlice from "./sideMenu/distributorOrders/distributorOrders.slice";
import { useCallback, useEffect, useRef } from "react";
import demanddraftSlice from "./sideMenu/demandDraft/demanddraft.slice";
import requestReturnsSlice from "./sideMenu/returnRequest/returnRequest.slice";
import distributorReturnsSlice from "./sideMenu/distributorReturns/distributorReturns.slice";
import returnsheetSlice from "./sideMenu/returnsheet/returnsheet.slice";
import superstockistReturnsSlice from "./sideMenu/superstockistsReturns/superstockistReturns.slice";
import schemesSlice from "./Masters/SchemesAndOffers/schemesSlice";
import customerOrdersSlice from "./sideMenu/customerOrder/customerOrders.slice";
import customersSlice from "./sideMenu/customers/customers.slice";
import paymentsSlice from "./sideMenu/payments/payments.slice";
import vacationmodeRequestSlice from "./sideMenu/vacationmodeRequest/vactionmodeRequest.slice";
import walletsSlice from "./sideMenu/wallet/wallets.slice";
import cashCollectionRequestSlice from "./sideMenu/cashCollectionRequest/cashCollectionRequest.slice";
import subscriptionSlice from "./sideMenu/subscription/subscription.slice";
import dashboardSlice from "./dashboard/dashboardSlice";
import synchronizationSlice from "./settings/Synchronization/synchronizationSlice";
import globalVariablesSlice from "./settings/globalVariables/globalVariablesSlice";
import ticketManagementSlice from "./sideMenu/ticketManagement/ticketManagement.slice";
import backupDatabaseSlice from "./settings/BackupDatabse/backupDatabaseSlice";
import scheduleReportSlice from "./settings/ScheduleReports/scheduleReportSlice";
import deliveryNoteSlice from "./sideMenu/deliveryNote/deliveryNote.slice";

export const store = configureStore({
	reducer: {
		dashboard: dashboardSlice,
		customise: customiseReducer,
		login: loginSlice,
		deleteuser: deleteUserSlice,
		mini: miniSlice,
		reports: reportsSlice,
		system: systemSlice,
		userProfile: userProfileSlice,
		masters: Masters,
		manageUsers: manageUserSlice,
		manageUsersB2C: manageUsersB2CSlice,
		manageProfile: manageProfileSlice,
		manageCompany: manageCompanySlice,
		importExport: importExportSlice,
		appSettings: appsettingsSlice,
		products: productsSlice,
		retailers: retailersSlice,
		retailerIndents: retailerIndentSlice,
		distributorIndents: distributorIndentSlice,
		distributorOrders: distributorOrdersSlice,
		receipt: receiptSlice,
		retailerReceipts: retailerReceiptsSlice,
		notification: notificationSlice,
		saleorders: saleordersSlice,
		demandsheet: demandsheetSlice,
		demanddraft: demanddraftSlice,
		requestReturns: requestReturnsSlice,
		distributorReturns: distributorReturnsSlice,
		superstockistReturns: superstockistReturnsSlice,
		returnsheet: returnsheetSlice,
		schemes: schemesSlice,
		customerOrders: customerOrdersSlice,
		customers: customersSlice,
		payments: paymentsSlice,
		wallets: walletsSlice,
		vacationmodeRequest: vacationmodeRequestSlice,
		cashCollectionRequest: cashCollectionRequestSlice,
		subscriptions: subscriptionSlice,
		sync: synchronizationSlice,
		globalVariables: globalVariablesSlice,
		ticketManagement: ticketManagementSlice,
		backupDatabase: backupDatabaseSlice,
		scheduleReport: scheduleReportSlice,
		driver: driverSlice,
		deliveryNote: deliveryNoteSlice,
	},
});

export const Selector = (state, value) => {
	return useSelector((root) => root[state][value]);
};
export const Dispatch = (dispatch, reducer, payload) => {
	return dispatch(reducer(payload));
};
