import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
	addParams,
	getList,
	getParamsList,
	postAdd,
	postEdit,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
	RiCheckboxCircleLine,
	RiAlertLine,
	RiInformationLine,
} from "react-icons/ri";

export const getRetailerReceipts = createAsyncThunk(
	"/getRetailerReceipts",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/orders/receipts/list/`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const postRetailerReceipts = createAsyncThunk(
	"/postRetailerReceipts",
	async (payload) => {
		try {
			const { data, history, form } = payload;
			const response = await postAdd(
				"/orders/distributorreceipts/list/",
				data
			);
			if (response.status === 201) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Retailer Receipt Added Successfully.!
							</p>
						</div>
					),
				});
				history.push("/receipts");
				form.resetFields();
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error.response) {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Unable to add Retailer Receipt. Please
								try again!
							</p>
						</div>
					),
				});
			} else {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),

					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Getting from server side issue!
							</p>
						</div>
					),
				});
			}
			throw error.message;
		}
	}
);

export const editRetailerReceipts = createAsyncThunk(
	"/editRetailerReceipts",
	async (payload) => {
		try {
			const { id, data, history, form } = payload;
			const response = await postEdit(
				`/orders/distributorreceipts/${id}`,
				data
			);
			if (response.status === 200) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Retailer Receipt Edited Successfully.!
							</p>
						</div>
					),
				});
				history.push("/retailer_indents");
				form.resetFields();
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error.response) {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Unable to edit Retailer Receipt. Please
								try again!
							</p>
						</div>
					),
				});
			} else {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),

					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Getting from server side issue!
							</p>
						</div>
					),
				});
			}
			throw error.message;
		}
	}
);

export const getRetailerReceiptByID = createAsyncThunk(
	"/getRetailerReceiptByID",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getList(
				`/orders/distributorreceipts/${params.id}/`
			);
			if (response) {
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getRetailerReceiptItemsByOrderID = createAsyncThunk(
	"/getRetailerReceiptItemsByOrderID",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/orders/orderitemminilist/`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
