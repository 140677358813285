import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    postAdd,
    getParamsList,
    addParams,
    postEdit,
    postDelete,
    getList,
    postDownloadFile,
    postFormData,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
    RiCheckboxCircleLine,
    RiAlertLine,
    RiInformationLine,
} from "react-icons/ri";
import Swal from "sweetalert2";

export const getImportModelsList = createAsyncThunk(
    "/getImportModelsList",
    async (payload) => {
        try {
            const response = await getList("/reports/generic_import_models/", {});
            if (response.data) {
                return { response: response.data }
            } else {
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            return error.message;
        }
    }
);
export const getExportModelsList = createAsyncThunk(
    "/getExportModelsList",
    async (payload) => {
        try {
            const response = await getList("/reports/generic_export_models/", {});
            if (response.data) {
                return { response: response.data }
            } else {
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            return error.message;
        }
    }
);

const delay = (ms) =>
    new Promise((resolve) => {
        setTimeout(() => {
            // here is where I want to specify my timeout logic
            resolve();
        }, ms);
    });



export const exportstatus = createAsyncThunk(
    "/exportstatus",
    async (payload, { dispatch }) => {
        // const signal = payload.signal;
        try {
            const response = await postAdd(`/reports/generic_export/status/${payload.request_id}/`, payload, {});
            if (response.data.status) {
                var objData = {
                    request_id: payload.request_id,
                    model_name: payload.model_name,
                    file_format: 0,
                };
                Swal.close();
                dispatch(exportdownloadfile(objData))
            } else {
                ExportDelay({ request_id: payload.request_id, ...payload })
                // Modal.destroyAll();
                // Modal.info({
                //     icon: (
                //         <span className="remix-icon">
                //             <RiCheckboxCircleLine />
                //         </span>
                //     ),
                //     title: <h5 className="hp-mb-0 hp-font-weight-500">Info</h5>,
                //     content: (
                //         <div>
                //             <p className="hp-p1-body hp-text-color-black-80">
                //                 Export is still running! please wait
                //             </p>
                //         </div>
                //     ),
                // });
            }
        } catch (error) {
            return error.message;
        }
    }
);
export const exportdownloadfile = createAsyncThunk(
    "/exportdownloadfile",
    async (payload, { dispatch }) => {
        // const signal = payload.signal;
        try {
            const response = await postDownloadFile(`/reports/generic_export/download/${payload.request_id}/`, payload, {});
            if (response.data) {
                Modal.destroyAll();
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Report downloaded successfully.!
                            </p>
                        </div>
                    ),
                });

                return response.data
            }
        } catch (error) {
            return error.message;
        }
    }
);

async function ExportDelay(payload) {
    const { request_id, dispatch } = payload
    // const signal = payload.signal;
    let timeout;
    timeout = await delay(10000);
    dispatch(exportstatus({ request_id: request_id, ...payload }));
}


export const downloadExportData = createAsyncThunk(
    "/downloadExportData",
    async (payload, { dispatch }) => {
        const { objData, ParamsData = {} } = payload
        // const controller = payload.controller;
        // const signal = controller.signal;
        Swal.fire({
            text: "Generating Report, please wait...",
            didOpen: () => {
                Swal.showLoading();
            },
            allowOutsideClick: false
        });
        try {
            const response = await postAdd("/reports/generic_export/", { ...objData }, { ...ParamsData });
            if (response.status == 201) {
                ExportDelay({ request_id: response.data.request_id, dispatch, ...objData, })
            } else {
                throw new Error(response);
            }
        } catch (error) {

            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            {error?.response?.data?.error ? error.response.data.error : error.message}
                        </p>
                    </div>
                ),
            });
            throw error?.response?.data?.error ? error.response.data.error : error.message;
        }
    }
);



async function ImportDelay(payload) {
    const { request_id, dispatch } = payload

    let timeout;
    timeout = await delay(10000);
    dispatch(ImportUploadStatus({ request_id: request_id, dispatch }));
}

export const ImportUploadStatus = createAsyncThunk("/ImportUploadStatus", async (payload) => {
    const { request_id, dispatch } = payload
    try {
        const response = await postAdd(`/reports/generic_import/status/${request_id}/`, {});
        if (response.status === 201) {
            return {
                response: response.data.message
            };
        } else if (response.status === 202) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),
                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Import is running
                        </p>
                    </div>
                ),
            });
            ImportDelay({ request_id: payload.request_id, dispatch });
        } else {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),
                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Import is still running
                        </p>
                    </div>
                ),
            });
        }
    } catch (error) {
        Modal.error({
            icon: (
                <span className="remix-icon">
                    <RiAlertLine />
                </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
            content: (
                <div>
                    <p className="hp-p1-body hp-text-color-black-80">
                        Sorry! Getting from server side issue!
                    </p>
                </div>
            ),
        });
        return error.message
    }
});

export const uploadImportData = createAsyncThunk(
    "/uploadImportData",
    async (payload) => {
        const { obj, onSuccess, onFailure } = payload
        try {
            const response = await postFormData("/reports/generic_import/dryrun", obj);
            if (response.status == 200) {
                onSuccess({ request_id: response.data.request_id })
                return {
                    response: response.data.request_id
                }
            } else {
                Modal.error({
                    icon: (
                        <span className="remix-icon">
                            <RiAlertLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Import is still running
                            </p>
                        </div>
                    ),
                });
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            return error.message;
        }
    }
);
export const uploadImportType2Data = createAsyncThunk(
    "/uploadImportType2Data",
    async (payload) => {
        try {
            const response = await postFormData("/reports/generic_import_2/dryrun/", payload);

            return {
                response: response.data
            }

        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            return error.message;
        }
    }
);

async function progressImportDelay(payload) {
    let timeout;
    timeout = await delay(10000);
    await progressImportStatus({ request_id: payload.request_id });
}

const progressImportStatus = async (payload) => {
    try {
        const response = await postAdd(`/reports/generic_import/status/${payload.request_id}/`, {});
        if (response.status === 201) {
            return response.data.message;
        } else if (response.status === 202) {
            progressImportDelay({ request_id: payload.request_id });
        } else {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),
                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! failed to generate the process report!
                        </p>
                    </div>
                ),
            })
        }
    } catch (error) {
        Modal.error({
            icon: (
                <span className="remix-icon">
                    <RiAlertLine />
                </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
            content: (
                <div>
                    <p className="hp-p1-body hp-text-color-black-80">{error}</p>
                </div>
            ),
        });
        return error.message
    }
}
export const submitImportData = createAsyncThunk(
    "/submitImportData",
    async (payload) => {
        try {
            const response = await postAdd("/reports/generic_import/process", payload);
            if (response.status == 200) {
                return {
                    response: progressImportStatus({
                        request_id: response.data.request_id,
                    })
                }
            } else {
                Modal.error({
                    icon: (
                        <span className="remix-icon">
                            <RiAlertLine />
                        </span>
                    ),

                    title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Sorry! Getting from server side issue!
                            </p>
                        </div>
                    ),
                });
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            return error.message;
        }
    }
);

export const submitImportType2Data = createAsyncThunk(
    "/submitImportType2Data",
    async (payload) => {
        try {
            const response = await postAdd("/reports/generic_import_2/process/", payload);

            return {
                response: response.data
            }

        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            return error.message;
        }
    }
);