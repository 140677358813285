import { Fragment } from "react";
import { Selector, Dispatch } from "../../store/index";
import B2B from "./B2B";
import B2C from "./B2C";

function Dashboard(){
  const userAccessList = Selector("system", "userAccessList");
    return (
      <Fragment>
        {userAccessList?.indexOf("System.dashboard_1") !== -1 ? (
          <B2B />
        ) : userAccessList?.indexOf("System.dashboard_2") !== -1 ? (
          <B2C />
        ) : (
          "No Permission"
        )}
      </Fragment>
    );
}

export default Dashboard
