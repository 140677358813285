import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    postAdd,
    getParamsList,
    postEdit,
    postDelete,
    getList,
    patchImg,
    putImg,
    postFormData,
    addParams,
} from "../../../helpers/Helper";
import {
    RiCheckboxCircleLine,
    RiAlertLine,
    RiInformationLine,
} from "react-icons/ri";
import { serialize } from "object-to-formdata";
import { Modal } from "antd";
import Swal from "sweetalert2";
import { clearuploadImage, isUploadModelVisible } from "./bannersSlice";

export const getBannersList = createAsyncThunk(
    "/getBannersList",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/masters/banner/list", params);
            if (response) {
                return { response };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);

export const bannersAdd = createAsyncThunk(
    "/bannersAdd",
    async (payload, { dispatch }) => {
        try {
            if (payload.id == 0) {
                const { bannerParams } = payload
                const response = await postFormData("/masters/banner/add", payload);
                if (response.status === 201) {
                    Modal.success({
                        icon: (
                            <span className="remix-icon">
                                <RiCheckboxCircleLine />
                            </span>
                        ),
                        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                        content: (
                            <div>
                                <p className="hp-p1-body hp-text-color-black-80">
                                    Banner Added Successfully.!
                                </p>
                            </div>
                        ),
                    });
                    dispatch(getBannersList({ ...bannerParams }));
                    return { response, modal: false };
                }
                else {
                    Modal.error({
                        icon: (
                            <span className="remix-icon">
                                <RiAlertLine />
                            </span>
                        ),
                        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                        content: (
                            <div>
                                <p className="hp-p1-body hp-text-color-black-80">
                                    Sorry! Unable to add Banner. Please try again!
                                </p>
                            </div>
                        ),
                    });
                    throw new Error(response);
                }
            } else {
                const { dispatch, getBannersList, bannerParams, rowupdatedata, type = "" } = payload
                // const formData = serialize(stateToUpdate);
                const response = await postEdit(`/masters/banner/${payload.id}`, rowupdatedata);
                console.log("response", response);
                if (response.status === 200) {
                    Modal.success({
                        icon: (
                            <span className="remix-icon">
                                <RiCheckboxCircleLine />
                            </span>
                        ),
                        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                        content: (
                            <div>
                                <p className="hp-p1-body hp-text-color-black-80">
                                    {type == "activeInactive" ? "Banner Status Updated SuccessFully" : "Banner Edited Successfully.!"}
                                </p>
                            </div>
                        ),
                    });

                    dispatch(getBannersList({ ...bannerParams }));
                    return { response, modal: false }
                } else {
                    Modal.error({
                        icon: (
                            <span className="remix-icon">
                                <RiAlertLine />
                            </span>
                        ),
                        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                        content: (
                            <div>
                                <p className="hp-p1-body hp-text-color-black-80">
                                    {type == "activeInactive" ? "Sorry! Unable to Update Banner Status. Please try again!" : "Sorry! Unable to Update Banner. Please try again!"}
                                </p>
                            </div>
                        ),
                    });
                }
                throw new Error(response);

            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            throw error.message;
        }
    }
);
export const bannerDelete = createAsyncThunk(
    "/bannerDelete",
    async (payload, { dispatch }) => {
        var bannerID = payload.id;
        var params = payload.params;
        console.log("bannerID", bannerID);
        try {
            const response = await postDelete("/masters/banner/" + bannerID);
            if (response.status === 204) {
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Banner has been deleted Successfully!
                            </p>
                        </div>
                    ),
                });
                console.log("response", response);
                dispatch(getBannersList({ ...params }));
                return { response };
            } else {
                Modal.error({
                    icon: (
                        <span className="remix-icon">
                            <RiAlertLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Sorry! Banner has been not deleted!
                            </p>
                        </div>
                    ),
                });
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            throw error.message;
        }
    }
);

export const getBannerByID = createAsyncThunk(
    "/getBannerByID",
    async (payload) => {
        try {
            const response = await getList("/masters/banner/" + payload, {});
            return response.data;
        } catch (error) {
            throw error.message;
        }
    }
);

export const editBannerImage = createAsyncThunk(
    "/editBannerImage",
    async (payload, { dispatch }) => {
        const { bannerParams, rowupdatedata } = payload
        const response = await putImg(`/masters/banner/${payload.id}`, rowupdatedata);
        console.log("response", response);
        if (response.status === 200) {
            Modal.success({
                icon: (
                    <span className="remix-icon">
                        <RiCheckboxCircleLine />
                    </span>
                ),
                title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Banner Image Updated Successfully.!
                        </p>
                    </div>
                ),
            });
            dispatch(isUploadModelVisible(false));
            dispatch(clearuploadImage());
            payload.form.resetFields();
            dispatch(getBannersList({ ...bannerParams }));
            return { response, modal: false }
        } else {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),
                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Unable to Update Banner Image. Please try again!
                        </p>
                    </div>
                ),
            });
        }
        throw new Error(response);
    }
);