import { createSlice } from "@reduxjs/toolkit";
import { getCustomerDeliveryInstructionByID, getCustomerDeliveryInstructions, deliveryinstructionsAdd, deliveryinstructionsDelete } from "./customerDeliveryInstructionAction";

const deliveryinstructionsSlice = createSlice({
  name: "deliveryinstructions",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    deliveryinstructionslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    deliveryinstructionsParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    SetCustomerDeliveryInstructionParams: (state, action) => {
      return {
        ...state,
        deliveryinstructionsParams: action.payload,
      };
    },
    deliveryinstructionsDelete: (state) => {
      return {
        ...state,
        loading: false

      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },


  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerDeliveryInstructions.pending, (state, action) => {
        state.status = "getCustomerDeliveryInstructions loading";
        state.loading = true;
      })
      .addCase(getCustomerDeliveryInstructions.fulfilled, (state, action) => {
        var response = action.payload.response;
        state.status = "getCustomerDeliveryInstructions succeeded";
        state.loading = false;
        var noofpages = Math.ceil(
          action.payload.count / state.deliveryinstructionsParams?.page_size
        );
        state.deliveryinstructionslist = response.results;
        state.listCount = response.count;
        state.deliveryinstructionsParams = {
          ...state.deliveryinstructionsParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getCustomerDeliveryInstructions.rejected, (state, action) => {
        state.status = "getCustomerDeliveryInstructions failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deliveryinstructionsAdd.pending, (state, action) => {
        state.status = "deliveryinstructionsAdd loading";
        state.loading = true;
      })
      .addCase(deliveryinstructionsAdd.fulfilled, (state, action) => {
        state.status = "deliveryinstructionsAdd succeeded";
        state.loading = false
        state.model = action.payload.modal;
        state.message = action.payload.response;
        state.rowdata = {};
      }
      )
      .addCase(deliveryinstructionsAdd.rejected, (state, action) => {
        state.status = "deliveryinstructionsAdd failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deliveryinstructionsDelete.pending, (state, action) => {
        state.status = "deliveryinstructionsDelete loading";
        state.loading = true;
      })
      .addCase(deliveryinstructionsDelete.fulfilled, (state, action) => {
        state.status = "deliveryinstructionsDelete succeeded";
        state.loading = false
      }
      )
      .addCase(deliveryinstructionsDelete.rejected, (state, action) => {
        state.status = "deliveryinstructionsDelete failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCustomerDeliveryInstructionByID.pending, (state, action) => {
        state.status = "getCustomerDeliveryInstructionByID loading";
        state.loading = true;
      })
      .addCase(getCustomerDeliveryInstructionByID.fulfilled, (state, action) => {
        state.status = "getCustomerDeliveryInstructionByID succeeded";
        state.loading = false
        state.model = true;
        state.rowdata = {
          ...action.payload,
        };
      }
      )
      .addCase(getCustomerDeliveryInstructionByID.rejected, (state, action) => {
        state.status = "getCustomerDeliveryInstructionByID failed";
        state.loading = false;
        state.error = action.error.message;
      })
  }

});
export const {
  InputChangeValue,
  apiError,
  isModelVisible,
  SetCustomerDeliveryInstructionParams,
} = deliveryinstructionsSlice.actions;

export default deliveryinstructionsSlice.reducer;
