import { createSlice } from "@reduxjs/toolkit";
import { ChangePasswordSubmit, getActivityLog, getUser, getUserDeviceList } from "./profileAction";

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    userData: {},
    adminData: {},
    activityLogList: [],
    passwordData: {},
    apiError: {},
    listCount: 0,
    activityLogParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    logCount: 0,
    
    userDeviceList: [],
    UserDeviceParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    deviceCount: 0,
  },
  reducers: {
    clearData: (state, action) => {
      return {
        ...state,
        passwordData: {},
      }
    },
    changePasswordModalShow: (state, action) => {
      return {
        ...state,
        loading: false,
        model: action.payload,
        passwordData: {},
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        passwordData: {
          ...state.passwordData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    SetActivityLogParams: (state, action) => {
      return {
        ...state,
        activityLogParams: action.payload,
      };
    },

    // getUserDeviceList: (state) => {
    //   return {
    //     ...state,
    //     loading: true,
    //     userDeviceList: [],
    //   };
    // },
    // getUserDeviceListSuccessful: (state, action) => {
    //   var noofpages = Math.ceil(
    //     action.payload.response.count / state.UserDeviceParams.page_size
    //   );

    //   return {
    //     ...state,
    //     listCount: action.payload.response.count,
    //     // userDeviceList: action.payload.response.results,
    //     userDeviceList: [
    //       ...state.userDeviceList,
    //       ...action.payload.response.results,
    //     ],
    //     loading: false,
    //     UserDeviceParams: {
    //       ...state.UserDeviceParams,
    //       no_of_pages: noofpages,
    //     },
    //   };
    // },
    setDeviceParams: (state, action) => {
      return {
        ...state,
        UserDeviceParams: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      //getUser
      .addCase(getUser.pending, (state, action) => {
        state.status = "getUser loading";
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.status = "getUser succeeded";
        state.loading = false;
        state.userData = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.status = "getUser failed";
        state.loading = false;
        state.error = action.error.message;
      })
      //ChangePasswordSubmit
      .addCase(ChangePasswordSubmit.pending, (state, action) => {
        state.status = "ChangePasswordSubmit loading";
        state.loading = true;
      })
      .addCase(ChangePasswordSubmit.fulfilled, (state, action) => {
        state.status = "ChangePasswordSubmit succeeded";
        state.loading = false;
        state.userData = action.payload;
      })
      .addCase(ChangePasswordSubmit.rejected, (state, action) => {
        state.status = "ChangePasswordSubmit failed";
        state.loading = false;
        state.error = action.error.message;
      })
      //getActivityLog
      .addCase(getActivityLog.pending, (state, action) => {
        state.status = "getActivityLog loading";
        state.loading = true;
      })
      .addCase(getActivityLog.fulfilled, (state, action) => {
        var response = action.payload.response
        state.status = "getActivityLog succeeded";
        state.loading = false;
        var noofpages = Math.ceil(
          action.payload.response.count / state.activityLogParams.page_size
        );
        state.logCount = response.count
        state.activityLogList = action.payload.response.results;
        state.activityLogParams = {
          ...state.activityLogParams,
          no_of_pages: noofpages,
        }
      })
      .addCase(getActivityLog.rejected, (state, action) => {
        state.status = "getActivityLog failed";
        state.loading = false;
        state.error = action.error.message;
      })
      
      //getUserDeviceList
      .addCase(getUserDeviceList.pending, (state, action) => {
        state.status = "getUserDeviceList loading";
        state.loading = true;
      })
      .addCase(getUserDeviceList.fulfilled, (state, action) => {
        var response = action.payload.response
        state.status = "getUserDeviceList succeeded";
        state.loading = false;
        var noofpages = Math.ceil(action.payload.count / state.UserDeviceParams.page_size);
        state.userDeviceList = action.payload.response.results;
        state.deviceCount = response.count;
        state.UserDeviceParams = {
          ...state.UserDeviceParams,
          no_of_pages: noofpages,
        }
      })
      .addCase(getUserDeviceList.rejected, (state, action) => {
        state.status = "getUserDeviceList failed";
        state.loading = false;
        state.error = action.error.message;
      })
  }
});

export const {
  changePasswordModalShow,
  InputChangeValue,
  SetActivityLogParams,
  setDeviceParams,
  clearData,
} = userProfileSlice.actions;

export default userProfileSlice.reducer;
