import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../helpers/AxiosHelper";
import { postAdd, postLogin } from "../../../helpers/Helper";
import { Modal } from "antd";
import { clearAttachments } from "../../system/systemSlice";
import {
	RiCheckboxCircleLine,
	RiAlertLine,
	RiInformationLine,
} from "react-icons/ri";

export const loginUser = createAsyncThunk("/loginUser", async (payload) => {
	const { loginObg, onSuccess, OnFailure } = payload;
	try {
		const response = await postLogin("/users/login/", loginObg);
		console.log(response);
		if (response.status >= 200 && response.status < 300) {
			const tokens = response.data.tokens;
			localStorage.setItem("access_token", tokens.access);
			localStorage.setItem("currentToken", tokens.refresh);
			localStorage.setItem("User_full_name", response.data.full_name);
			localStorage.setItem("username", response.data.username);
			localStorage.setItem("user_type", response.data.user_type);
			onSuccess(response.data);
			return response.data;
		} else {
			throw new Error(response);
		}
	} catch (error) {
		console.log(error);
		if (error.data.detail) {
			OnFailure(error.data.detail);
			throw error.data.detail;
		} else {
			OnFailure("In-correct Login Details");
			throw error.message;
		}
	}
});

export const logout = createAsyncThunk("/logout", async (payload) => {
	const { data, dispatch, history } = payload;
	try {
		const response = await postAdd("/auth/signout", { ...data });

		if (response.status >= 200 && response.status < 300) {
			localStorage.clear();
			dispatch(clearAttachments());
			history.push("/login");
			return response.data;
		} else {
			throw new Error(response);
		}
	} catch (error) {
		if (error.response.data.message) {
			Modal.error({
				icon: (
					<span className="remix-icon">
						<RiAlertLine />
					</span>
				),
				title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
				content: (
					<div>
						<p className="hp-p1-body hp-text-color-black-80">
							{error.response.data.message}
						</p>
					</div>
				),
			});
			throw error.response.data.message;
		} else {
			Modal.error({
				icon: (
					<span className="remix-icon">
						<RiAlertLine />
					</span>
				),
				title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
				content: (
					<div>
						<p className="hp-p1-body hp-text-color-black-80">
							Server Side Error, Cannot Signout.
						</p>
					</div>
				),
			});
			throw error.message;
		}
	}
});
