import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
	addParams,
	getDownloadFile,
	getList,
	getParamsList,
	postAdd,
	postEdit,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
	RiCheckboxCircleLine,
	RiAlertLine,
	RiInformationLine,
} from "react-icons/ri";

export const getReturnSheet = createAsyncThunk(
	"/getReturnSheet",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/returns/return_sheet/`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

export const postReturnSheet = createAsyncThunk(
	"/postReturnSheet",
	async (payload) => {
		try {
			const { data, history, form } = payload;
			const response = await postAdd("/returns/return_sheet/", data);
			if (response.status === 201) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Return Sheet Added Successfully.!
							</p>
						</div>
					),
				});
				history.push("/return_sheet");
				form.resetFields();
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error.response) {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Unable to add Return Sheet. Please try
								again!
							</p>
						</div>
					),
				});
			} else {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),

					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Getting from server side issue!
							</p>
						</div>
					),
				});
			}
			throw error.message;
		}
	}
);
// export const editReturnSheet = createAsyncThunk(
// 	"/editReturnSheet",
// 	async (payload) => {
// 		try {
// 			const { id, data, history, form } = payload;
// 			const response = await postEdit(`/returns/returns/${id}`, data);
// 			if (response.status === 200) {
// 				Modal.success({
// 					icon: (
// 						<span className="remix-icon">
// 							<RiCheckboxCircleLine />
// 						</span>
// 					),
// 					title: (
// 						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
// 					),
// 					content: (
// 						<div>
// 							<p className="hp-p1-body hp-text-color-black-80">
// 								Return Sheet Edited Successfully.!
// 							</p>
// 						</div>
// 					),
// 				});
// 				history.push("/retailer_indents");
// 				form.resetFields();
// 				return response;
// 			} else {
// 				throw new Error(response);
// 			}
// 		} catch (error) {
// 			if (error.response) {
// 				Modal.error({
// 					icon: (
// 						<span className="remix-icon">
// 							<RiAlertLine />
// 						</span>
// 					),
// 					title: (
// 						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
// 					),
// 					content: (
// 						<div>
// 							<p className="hp-p1-body hp-text-color-black-80">
// 								Sorry! Unable to edit Return Sheet. Please try
// 								again!
// 							</p>
// 						</div>
// 					),
// 				});
// 			} else {
// 				Modal.error({
// 					icon: (
// 						<span className="remix-icon">
// 							<RiAlertLine />
// 						</span>
// 					),

// 					title: (
// 						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
// 					),
// 					content: (
// 						<div>
// 							<p className="hp-p1-body hp-text-color-black-80">
// 								Sorry! Getting from server side issue!
// 							</p>
// 						</div>
// 					),
// 				});
// 			}
// 			throw error.message;
// 		}
// 	}
// );
export const getReturnSheetItemsBySuperStockist = createAsyncThunk(
	"/getReturnSheetItemsBySuperStockist",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/returns/returnsheet/superstockist/${params.id}`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getReturnSheetByID = createAsyncThunk(
	"/getReturnSheetByID",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/returns/return_sheet/${params.id}`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
// export const getReturnDraft = createAsyncThunk(
// 	"/getReturnDraft",
// 	async (payload) => {
// 		const params = addParams(payload);
// 		try {
// 			const response = await getParamsList(
// 				`/orders/demanddraft/`,
// 				params
// 			);
// 			if (response) {
// 				return response;
// 			} else {
// 				throw new Error(response);
// 			}
// 		} catch (error) {
// 			return error.message;
// 		}
// 	}
// );
export const returnSheetExport = createAsyncThunk(
	"/returnSheetExport",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getDownloadFile(
				`/orders/return_sheet_export/${params.id}`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
