import React, { useEffect, useState } from "react";
import { Card, Row, Col, Input, Button, DatePicker, Spin, Tabs } from "antd";
import { connect } from "react-redux";
import PageTitle from "../../../layout/components/content/page-title";
import {
  clearData,
  isDrawerVisible,
  FilterInputChangeValue,
} from "../../../store/dashboard/dashboardSlice";

import {
  BarChartOutlined,
  FilterOutlined,
  PieChartOutlined,
  ToolOutlined,
  SyncOutlined,
  DollarOutlined,
  TransactionOutlined,
  ReloadOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import TransactionCard from "./transactionCard";
import AnalyticsCard from "./analyticsCard";
import TotalOrdersCard from "./totalOrdersCard";
import CustomerOrderCard from "./customerOrderCard";
import { getCustomerOrdersCount, getDashboardCustmOrders, getDashboardSubscription, getMontlyOrderReport } from "../../../store/dashboard/dashboardAction";
import { useDispatch } from "react-redux";
import { Selector, Dispatch } from "../../../store/index";
import SubscriptionCard from "./subscriptionCard";



function B2CDashboard(props) {
  const { TabPane } = Tabs;
  const { Search } = Input;
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("1")

  const customerCount = Selector("dashboard", "customerCount");
  const revenueCount = Selector("dashboard", "revenueCount");
  const cashCount = Selector("dashboard", "cashCount");
  const monthlyReport = Selector("dashboard", "monthlyReport");

  const customParams = Selector("dashboard", "customParams");
  const subscriParams = Selector("dashboard", "subscriParams");

  useEffect(() => {
    dispatch(getDashboardCustmOrders({ ...customParams }))

    dispatch(getCustomerOrdersCount());

    dispatch(getMontlyOrderReport());
  }, [])


  function callback(key) {
    setActiveTab(key)
    if (key == 1) {
      Dispatch(dispatch, getDashboardCustmOrders, {
        ...customParams,
      });
    } else {
      Dispatch(dispatch, getDashboardSubscription, {
        ...subscriParams,
      });
    }
  }

  return (

    <>
      <Row gutter={[12, 12]} className="hp-ecommerce-app-header hp-mb-10" >
        <Col span={24} xs={18} sm={18} md={18}>
          <PageTitle pageTitle="Dashboard" goBack={false} />
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={16} xs={24} sm={12} md={8} xl={8}>
          <TotalOrdersCard
            title=" Total Customer Orders"
            data={customerCount}
            icon={<PieChartOutlined />}
          />
        </Col>

        <Col span={16} xs={24} sm={12} md={8} xl={8}>
          <TransactionCard
            title="Revenue"
            data={revenueCount}
          />
        </Col>

        <Col span={16} xs={24} sm={12} md={8} xl={8}>
          <TransactionCard
            title="Cash Collection"
            data={cashCount}
          />
        </Col>
      </Row>

      <Row gutter={[12, 12]} className="hp-mt-10">
        <Col span={16} xs={24} sm={24} md={24} xl={24}>
          <AnalyticsCard
            title="Monthly Order Report"
            data={monthlyReport}
          />
        </Col>
      </Row>

      <Row gutter={[12, 12]} className="hp-mt-10">
        <Col span={24} xs={24} sm={24} md={24} xl={24}>
          <Card bodyStyle={{ padding: "10px" }}>
            <Tabs defaultActiveKey="1" onChange={callback}>
              <TabPane tab="Customer Order" key="1">
                <CustomerOrderCard />
              </TabPane>

              <TabPane tab="Subscription" key="2">
                <SubscriptionCard />
              </TabPane>
            </Tabs>
          </Card>
        </Col>

      </Row>
    </>
  );
}

function mapStateToProps({ dashboard, system }) {
  return {
    loading: dashboard.loading,
  };
}

export default connect(mapStateToProps, {
  isDrawerVisible,
})(B2CDashboard);
