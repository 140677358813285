import { createSlice } from "@reduxjs/toolkit";
import { getLocationByID, getLocations, locationAdd, locationDelete } from "./locationAction";

const locationSlice = createSlice({
  name: "location",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    locationlist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    locationParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    SetLocationParams: (state, action) => {
      return {
        ...state,
        locationParams: action.payload,
      };
    },
    locationDelete: (state) => {
      return {
        ...state,
        loading: false

      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },


  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocations.pending, (state, action) => {
        state.status = "getLocations loading";
        state.loading = true;
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        var response = action.payload.response;
        state.status = "getLocations succeeded";
        state.loading = false;
        var noofpages = Math.ceil(
          action.payload.count / state.locationParams?.page_size
        );

        state.locationlist = response.results;
        state.listCount = response.count;
        state.locationParams = {
          ...state.locationParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getLocations.rejected, (state, action) => {
        state.status = "getLocations failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(locationAdd.pending, (state, action) => {
        state.status = "locationAdd loading";
        state.loading = true;
      })
      .addCase(locationAdd.fulfilled, (state, action) => {
        state.status = "locationAdd succeeded";
        state.loading = false
        state.model = action.payload.modal;
        state.message = action.payload.response;
        state.rowdata = {};
      }
      )
      .addCase(locationAdd.rejected, (state, action) => {
        state.status = "locationAdd failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(locationDelete.pending, (state, action) => {
        state.status = "locationDelete loading";
        state.loading = true;
      })
      .addCase(locationDelete.fulfilled, (state, action) => {
        state.status = "locationDelete succeeded";
        state.loading = false
      }
      )
      .addCase(locationDelete.rejected, (state, action) => {
        state.status = "locationDelete failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getLocationByID.pending, (state, action) => {
        state.status = "getLocationByID loading";
        state.loading = true;
      })
      .addCase(getLocationByID.fulfilled, (state, action) => {
        state.status = "getLocationByID succeeded";
        state.loading = false
        state.model = true;
        state.rowdata = {
          ...action.payload,
        };
      }
      )
      .addCase(getLocationByID.rejected, (state, action) => {
        state.status = "getLocationByID failed";
        state.loading = false;
        state.error = action.error.message;
      })
  }

});
export const {
  InputChangeValue,
  apiError,
  isModelVisible,
  SetLocationParams,
} = locationSlice.actions;

export default locationSlice.reducer;
