import { createSlice } from "@reduxjs/toolkit";
import { getTicketTypeByID, getTicketTypes, ticketTypeAdd, ticketTypeDelete } from "./ticketTypeAction";

const ticketTypeSlice = createSlice({
  name: "ticketType",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    ticketTypelist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    ticketTypeParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    SetTicketTypeParams: (state, action) => {
      return {
        ...state,
        ticketTypeParams: action.payload,
      };
    },
    ticketTypeDelete: (state) => {
      return {
        ...state,
        loading: false
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },


  },
  extraReducers: (builder) => {
    builder
      .addCase(getTicketTypes.pending, (state, action) => {
        state.status = "getTicketTypes loading";
        state.loading = true;
      })
      .addCase(getTicketTypes.fulfilled, (state, action) => {
        var response = action.payload.response;
        state.status = "getTicketTypes succeeded";
        state.loading = false;
        var noofpages = Math.ceil(
          action.payload.count / state.ticketTypeParams?.page_size
        );
        state.ticketTypelist = response.results;
        state.listCount = response.count;
        state.ticketTypeParams = {
          ...state.ticketTypeParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getTicketTypes.rejected, (state, action) => {
        state.status = "getTicketTypes failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(ticketTypeAdd.pending, (state, action) => {
        state.status = "ticketTypeAdd loading";
        state.loading = true;
      })
      .addCase(ticketTypeAdd.fulfilled, (state, action) => {
        state.status = "ticketTypeAdd succeeded";
        state.loading = false
        state.model = action.payload.modal;
        state.message = action.payload.response;
        state.rowdata = {};
      }
      )
      .addCase(ticketTypeAdd.rejected, (state, action) => {
        state.status = "ticketTypeAdd failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(ticketTypeDelete.pending, (state, action) => {
        state.status = "ticketTypeDelete loading";
        state.loading = true;
      })
      .addCase(ticketTypeDelete.fulfilled, (state, action) => {
        state.status = "ticketTypeDelete succeeded";
        state.loading = false
      }
      )
      .addCase(ticketTypeDelete.rejected, (state, action) => {
        state.status = "ticketTypeDelete failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getTicketTypeByID.pending, (state, action) => {
        state.status = "getTicketTypeByID loading";
        state.loading = true;
      })
      .addCase(getTicketTypeByID.fulfilled, (state, action) => {
        state.status = "getTicketTypeByID succeeded";
        state.loading = false
        state.model = true;
        state.rowdata = {
          ...action.payload,
        };
      }
      )
      .addCase(getTicketTypeByID.rejected, (state, action) => {
        state.status = "getTicketTypeByID failed";
        state.loading = false;
        state.error = action.error.message;
      })
  }

});
export const {
  InputChangeValue,
  apiError,
  isModelVisible,
  SetTicketTypeParams,
} = ticketTypeSlice.actions;

export default ticketTypeSlice.reducer;
