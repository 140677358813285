import { createSlice, current, isDraft, original } from "@reduxjs/toolkit";
import moment from "moment";
import {
  getMangeUsersData,
  postMangeUsersData,
  editMangeUsersDataById,
  getMangeUsersDataById,
  deleteMangeUsersDataById,
  userNameValidation,
  userEmailValidation,
  userMobileValidation,
  getUserStates,
  getUserZones,
  getUserCities,
  getUserAreas,
  getUserLocation,
  putUserLocation,
  changePassword,
  getUserLoginList,
  getUserDeviceList,
  getUserActivityList
} from "./manageUserActions";

const initialState = {
  filterStatus: false,
  loading: false,
  status: "",
  error: "",
  drawer: false,
  list: [],
  count: 0,

  userMiniStateList: [],
  userMiniStateLoading: false,
  userMiniStateCount: 0,
  userMiniStateParams: {
    no_of_pages: 0,
    page_size: 10,
    page: 1,
    search: "",
  },
  userMiniZoneList: [],
  userMiniZoneCount: 0,
  userMiniZoneLoading: false,
  userMiniZoneParams: {
    no_of_pages: 0,
    page_size: 10,
    page: 1,
    search: "",
  },
  userMiniCityList: [],
  userMiniCityCount: 0,
  userMiniCityLoading: false,
  userMiniCityParams: {
    no_of_pages: 0,
    page_size: 10,
    page: 1,
    search: "",
  },
  userMiniAreaList: [],
  userMiniAreaCount: 0,
  userMiniAreaLoading: false,
  userMiniAreaParams: {
    no_of_pages: 0,
    page_size: 10,
    page: 1,
    search: "",
  },
  userMiniLocationList: [],
  userMiniLocationCount: 0,
  userMiniLocationLoading: false,
  userMiniLocationParams: {
    no_of_pages: 0,
    page_size: 10,
    page: 1,
    search: "",
  },
  // zonesList: [],
  // citiesList: [],
  // areaList: [],
  // locationList: [],

  userSelectedStatesList: [],
  userSelectedZonesList: [],
  userSelectedCityList: [],
  userSelectedAreaList: [],
  userSelectedLocationList: [],

  selectedStateId: "",
  selectedZoneId: "",
  selectedCityId: "",
  selectedAreaId: "",
  selectedLocationId: "",

  selectedData: {},
  pageParams: {
    no_of_pages: 0,
    page_size: 10,
    page: 1,
    search: "",
  },

  userLoginList: [],
  loginCount: 0,
  userLoginParams: {
    no_of_pages: 0,
    page_size: 10,
    page: 1,
    search: "",
  },
  loginLoading: false,

  deviceLoading: false,
  userDeviceList: [],
  userDeviceParams: {
    no_of_pages: 0,
    page_size: 10,
    page: 1,
    search: "",
  },
  useDeviceCount: 0,

  activityLoading: false,
  userActivityList: [],
  useActivityCount: 0,
  userActivityParams: {
    no_of_pages: 0,
    page_size: 10,
    page: 1,
    search: "",
  },
  activeTab: "1",
};

const manageUsersSlice = createSlice({
  name: "manageUsers",
  initialState,
  reducers: {

    clearStateList: (state, action) => {
      return {
        ...state,
        selectedZoneId: '',
        zonesList: [],
        citiesList: [],
        areaList: [],
        locationList: [],
      }
    },
    changeParams: (state, action) => {
      state.pageParams = action.payload;
      // return {
      //   ...state,
      //   pageParams: {
      //     ...state.pageParams,
      //     no_of_pages: Math.floor(
      //       action.payload.count / state.pageParams.page_size
      //     ),
      //   },
      // };
    },
    clearUserData: (state, action) => {
      return initialState
    },
    setMiniStatesParams: (state, action) => {
      return {
        ...state,
        userMiniStateParams: action.payload,
      };
    },
    setMiniZoneParams: (state, action) => {
      return {
        ...state,
        userMiniZoneParams: action.payload,
      };
    },
    setMiniCityParams: (state, action) => {
      return {
        ...state,
        userMiniCityParams: action.payload,
      };
    },
    setMiniAreaParams: (state, action) => {
      return {
        ...state,
        userMiniAreaParams: action.payload,
      };
    },
    setMiniLocationParams: (state, action) => {
      return {
        ...state,
        userMiniLocationParams: action.payload,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        selectedData: {
          ...state.selectedData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isDrawerVisible: (state, action) => {
      return {
        ...state,
        drawer: action.payload,
      };
    },
    setSelectedData: (state, action) => {
      const { data } = action.payload;
      state.selectedData = data;
    },
    SelecteChangeValue: (state, action) => {
      return {
        ...state,
        selectedData: {
          ...state.selectedData,
          [action.payload.key]: {
            id: action.payload.value,
            name: action.payload.name,
          },
        },
      };
    },
    showZones: (state, action) => {
      return {
        ...state,
        selectedStateId: action.payload,
        selectedZoneId: "",
        selectedCityId: "",
        selectedAreaId: "",
        selectedLocationId: "",
      };
    },
    checkStatePermission: (state, action) => {
      const obj = {}
      const statesList = state.userMiniStateList.map((userState) => {
        if (userState.id == action.payload.state_permission_id) {
          if (action.payload.value) {
            obj.userSelectedStatesList = [...state.userSelectedStatesList, action.payload.state_permission_id]
            return {
              ...userState,
              state_checked: true
            }
          } else {
            obj.userSelectedStatesList = state.userSelectedStatesList.filter(e => {
              return e != action.payload.state_permission_id
            })
            return {
              ...userState,
              state_checked: false,
              zones: userState?.zones?.map((zone) => {
                obj.userSelectedZonesList = [];
                return {
                  ...zone,
                  zone_checked: false,
                  cities: zone?.cities?.map((city) => {
                    obj.userSelectedCityList = [];
                    return {
                      ...city,
                      city_checked: false,
                      areas: city?.areas?.map((area) => {
                        obj.userSelectedAreaList = [];
                        return {
                          ...area,
                          area_checked: false,
                          locations: area?.locations?.map(location => {
                            obj.userSelectedLocationList = [];
                            return {
                              ...location,
                              location_checked: false
                            }
                          })
                        }
                      })
                    }
                  })
                }
              })
            }

          }
        } else {
          return {
            ...userState
          }
        }
      });
      return {
        ...state,
        userMiniStateList: statesList,
        ...obj
      }
    },
    showCities: (state, action) => {
      return {
        ...state,
        selectedZoneId: action.payload,
        selectedCityId: "",
        selectedAreaId: "",
        selectedLocationId: "",
      };
    },
    checkZonesPermission: (state, action) => {
      const obj = {};
      const statesList =
        state.userMiniStateList.map((userState) => {
          if (userState.id == action.payload.state_id) {
            return {
              ...userState,
              zones: userState.zones.map((zone) => {
                if (zone.id == action.payload.zone_permission_id) {
                  if (action.payload.value) {
                    obj.userSelectedZonesList = [...state.userSelectedZonesList, action.payload.zone_permission_id]
                    return {
                      ...zone,
                      zone_checked: true
                    }
                  } else {
                    obj.userSelectedZonesList = state.userSelectedZonesList.filter(e => {
                      return e != action.payload.zone_permission_id
                    })
                    return {
                      ...zone,
                      zone_checked: false,
                      cities: zone?.cities?.map((city) => {
                        obj.userSelectedCityList = [];
                        return {
                          ...city,
                          city_checked: false,
                          areas: city?.areas?.map((area) => {
                            obj.userSelectedAreaList = [];
                            return {
                              ...area,
                              area_checked: false,
                              locations: area?.locations?.map(location => {
                                obj.userSelectedLocationList = [];
                                return {
                                  ...location,
                                  location_checked: false
                                }
                              })
                            }
                          })
                        }
                      })
                    }
                  }
                } else {
                  return {
                    ...zone
                  }
                }
              })
            }
          } else {
            return {
              ...userState
            }
          }
        });

      return {
        ...state,
        userMiniStateList: statesList,
        ...obj
      }
    },
    showAreas: (state, action) => {
      return {
        ...state,
        selectedCityId: action.payload,
        selectedAreaId: "",
        selectedLocationId: "",
      };
    },
    checkCityPermissions: (state, action) => {
      const obj = {};
      const statesList =
        state.userMiniStateList.map((userState) => {
          if (userState.id == action.payload.state_id) {
            return {
              ...userState,
              zones: userState.zones.map((zone) => {
                if (zone.id == action.payload.zone_id) {
                  return {
                    ...zone,
                    cities: zone.cities.map(city => {
                      if (city.id == action.payload.city_permission_id) {
                        if (action.payload.value) {
                          obj.userSelectedCityList = [...state.userSelectedCityList, action.payload.city_permission_id]
                          return {
                            ...city,
                            city_checked: true
                          }
                        } else {
                          obj.userSelectedCityList = state.userSelectedCityList.filter(e => {
                            return e != action.payload.city_permission_id
                          })
                          return {
                            ...city,
                            city_checked: false,
                            areas: city?.areas?.map((area) => {
                              obj.userSelectedAreaList = [];
                              return {
                                ...area,
                                area_checked: false,
                                locations: area?.locations?.map(location => {
                                  obj.userSelectedLocationList = [];
                                  return {
                                    ...location,
                                    location_checked: false
                                  }
                                })
                              }
                            })
                          }
                        }

                      } else {
                        return {
                          ...city,
                        }
                      }
                    })

                  }

                } else {
                  return {
                    ...zone
                  }
                }
              })
            }
          } else {
            return {
              ...userState
            }
          }
        });

      return {
        ...state,
        userMiniStateList: statesList,
        ...obj
      }
    },
    showLocations: (state, action) => {
      return {
        ...state,
        selectedAreaId: action.payload,
        selectedLocationId: "",
      };
    },
    checkAreaPermissions: (state, action) => {
      const obj = {};
      const statesList =
        state.userMiniStateList.map((userState) => {
          if (userState.id == action.payload.state_id) {
            return {
              ...userState,
              zones: userState.zones.map((zone) => {
                if (zone.id == action.payload.zone_id) {
                  return {
                    ...zone,
                    cities: zone.cities.map(city => {
                      if (city.id == action.payload.city_id) {
                        return {
                          ...city,
                          areas: city.areas.map(area => {
                            if (area.id == action.payload.area_id) {
                              if (action.payload.value) {
                                obj.userSelectedAreaList = [...state.userSelectedAreaList, action.payload.area_id]
                                return {
                                  ...area,
                                  area_checked: true,
                                  // locations: area?.locations?.map(location => {
                                  //   return {
                                  //     ...location,
                                  //     location_checked: false
                                  //   }
                                  // })
                                }
                              } else {
                                obj.userSelectedAreaList = state.userSelectedAreaList.filter(e => {
                                  return e != action.payload.area_id
                                })
                                return {
                                  ...area,
                                  area_checked: false,
                                  locations: area?.locations?.map(location => {
                                    obj.userSelectedLocationList = [];
                                    return {
                                      ...location,
                                      location_checked: false
                                    }
                                  })
                                }
                              }
                            } else {
                              return {
                                ...area
                              }
                            }

                          })
                        }


                      } else {
                        return {
                          ...city,
                        }
                      }
                    })

                  }

                } else {
                  return {
                    ...zone
                  }
                }
              })
            }
          } else {
            return {
              ...userState
            }
          }
        });

      return {
        ...state,
        userMiniStateList: statesList,
        ...obj
      }
    },
    checkLocationPermissions: (state, action) => {
      const statesList =
        state.userMiniStateList.map((userState) => {
          if (userState.id == action.payload.state_id) {
            return {
              ...userState,
              zones: userState.zones.map((zone) => {
                if (zone.id == action.payload.zone_id) {
                  return {
                    ...zone,
                    cities: zone.cities.map(city => {
                      if (city.id == action.payload.city_id) {
                        return {
                          ...city,
                          areas: city.areas.map(area => {
                            return {
                              ...area,
                              locations: area.locations?.map(location => {
                                if (location.id == action.payload.location_id) {
                                  return {
                                    ...location,
                                    location_checked: action.payload.value
                                  }
                                } else {
                                  return {
                                    ...location
                                  }
                                }
                              })
                            }


                          })
                        }


                      } else {
                        return {
                          ...city,
                        }
                      }
                    })

                  }

                } else {
                  return {
                    ...zone
                  }
                }
              })
            }
          } else {
            return {
              ...userState
            }
          }
        });

      return {
        ...state,
        userMiniStateList: statesList,
        userSelectedLocationList: action.payload.value ?
          [...state.userSelectedLocationList, action.payload.location_id]
          : state.userSelectedLocationList.filter(e => {
            return e != action.payload.location_id
          })
      }
    },
    userDeviceActiveTab: (state, action) => {
      return {
        ...state,
        activeTab: action.payload
      }
    },
    setUserLoginParams: (state, action) => {
      return {
        ...state,
        userLoginParams: action.payload
      }
    },
    setUserDeviceParams: (state, action) => {
      return {
        ...state,
        userDeviceParams: action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      //post Data
      .addCase(postMangeUsersData.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(postMangeUsersData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        // state.masterMenuItemsList = action.payload;
      })
      .addCase(postMangeUsersData.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      //get Data
      .addCase(getMangeUsersData.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getMangeUsersData.fulfilled, (state, action) => {
        const { response } = action.payload;
        state.status = "succeeded";
        state.loading = false;
        state.list = response.results;
        state.count = response.count;
      })
      .addCase(getMangeUsersData.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      //get Data By Id
      .addCase(getMangeUsersDataById.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getMangeUsersDataById.fulfilled, (state, action) => {
        const { response } = action.payload;
        state.status = "succeeded";
        state.loading = false;
        var state_ids = [];
        var zone_ids = [];
        var city_ids = [];
        var area_ids = [];
        var location_ids = [];

        state_ids = response?.state?.map((e) => {
          return e.id;
        });
        zone_ids = response?.zone?.map((e) => {
          return e.id;
        });
        city_ids = response?.city?.map((e) => {
          return e.id;
        });
        area_ids = response?.area?.map((e) => {
          return e.id;
        });
        location_ids = response?.location?.map((e) => {
          return e.id;
        });
        state.selectedData = {
          ...response,
          groups: response.groups?.find(e => e.id)
        };
        state.userSelectedStatesList = state_ids;
        state.userSelectedZonesList = zone_ids;
        state.userSelectedCityList = city_ids;
        state.userSelectedAreaList = area_ids;
        state.userSelectedLocationList = location_ids;
      })
      .addCase(getMangeUsersDataById.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(editMangeUsersDataById.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(editMangeUsersDataById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(editMangeUsersDataById.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(changePassword.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(putUserLocation.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(putUserLocation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(putUserLocation.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteMangeUsersDataById.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(deleteMangeUsersDataById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(deleteMangeUsersDataById.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(userNameValidation.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(userNameValidation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(userNameValidation.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(userEmailValidation.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(userEmailValidation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(userEmailValidation.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(userMobileValidation.pending, (state, action) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(userMobileValidation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(userMobileValidation.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUserStates.pending, (state, action) => {
        state.status = "getUserStates loading";
        state.userMiniStateLoading = true;
      })
      .addCase(getUserStates.fulfilled, (state, action) => {
        const { statesList, count } = action.payload;
        state.status = "getUserStates succeeded";
        state.userMiniStateLoading = false;

        var list = [];
        if (state.userMiniStateParams?.page == 1) {
          list = statesList;
        } else {
          list = [...state.userMiniStateList, ...statesList];
        }
        var noofpages = Math.ceil(
          action.payload.count / state.userMiniStateParams?.page_size
        )
        state.userMiniStateCount = count;
        state.userMiniStateList = list;
        state.userMiniStateParams = {
          ...state.userMiniStateParams,
          no_of_pages: noofpages,
        }
      })
      .addCase(getUserStates.rejected, (state, action) => {
        state.status = "getUserStates failed";
        state.userMiniStateLoading = false;
        state.error = action.error.message;
      })
      .addCase(getUserZones.pending, (state, action) => {
        state.status = "getUserZones loading";
        state.userMiniZoneLoading = true;
      })
      .addCase(getUserZones.fulfilled, (state, action) => {
        const { zoneList, state_id, count } = action.payload;

        const statesList = state.userMiniStateList?.map(userState => {
          if (userState.id == action.payload.state_id) {
            var list = [];
            if (state.userMiniZoneParams?.page == 1) {
              list = zoneList
            } else {
              list = [...userState.zones, ...zoneList];
            }
            userState.zones = list
          }
          return userState
        });
        console.log(action.payload, statesList);
        var noofpages = Math.ceil(
          action.payload.count / state.userMiniZoneParams?.page_size
        )
        state.userMiniZoneParams = {
          ...state.userMiniZoneParams,
          no_of_pages: noofpages,
        }
        state.status = "getUserZones succeeded";
        state.userMiniZoneLoading = false;
        state.userMiniZoneCount = action.payload.count;
        state.userMiniStateList = statesList;
        state.userMiniZoneList = zoneList;


      })
      .addCase(getUserZones.rejected, (state, action) => {
        state.status = "getUserZones failed";
        state.userMiniZoneLoading = false;
        state.error = action.error.message;
      })
      .addCase(getUserCities.pending, (state, action) => {
        state.status = "getUserCities loading";
        state.userMiniCityLoading = true;
      })
      .addCase(getUserCities.fulfilled, (state, action) => {
        const { citiesList } = action.payload;
        state.status = "getUserCities succeeded";
        state.userMiniCityLoading = false;
        const statesList = state.userMiniStateList.map(userState => {
          if (userState.id == action.payload.state_id) {
            return {
              ...userState,
              zones: userState.zones.map(zone => {
                if (zone.id == action.payload.zone_id) {
                  var list = [];
                  if (state.userMiniCityParams?.page == 1) {
                    list = citiesList
                  } else {
                    list = [...userState.zones, ...citiesList];
                  }
                  zone.cities = list;
                }
                return zone
              })
            }
          } else {
            return {
              ...userState
            }
          }
        });

        console.log(action.payload, statesList);
        var noofpages = Math.ceil(
          action.payload.count / state.userMiniCityParams?.page_size
        )

        state.userMiniStateList = statesList;
        state.userMiniCityParams = {
          ...state.userMiniCityParams,
          no_of_pages: noofpages,
        }
        state.userMiniCityCount = action.payload.count;
        state.userMiniCityList = citiesList;
      })
      .addCase(getUserCities.rejected, (state, action) => {
        state.status = "getUserCities failed";
        state.userMiniCityLoading = false;
        state.error = action.error.message;
      })
      .addCase(getUserAreas.pending, (state, action) => {
        state.status = "getUserAreas loading";
        state.userMiniAreaLoading = true;
      })
      .addCase(getUserAreas.fulfilled, (state, action) => {
        const { areasList } = action.payload;
        state.status = "getUserAreas succeeded";
        state.userMiniAreaLoading = false;

        const statesList = state.userMiniStateList.map(userState => {

          if (userState.id == action.payload.state_id) {
            return {
              ...userState,
              zones: userState.zones.map(zone => {
                return (zone.id == action.payload.zone_id) ? {
                  ...zone,
                  cities: zone.cities.map(city => {
                    if (city.id == action.payload.city_id) {
                      var list = [];
                      if (state.userMiniAreaParams?.page == 1) {
                        list = areasList
                      } else {
                        list = [...city.areas, ...areasList];
                      }
                      return {
                        ...city,
                        areas: list,
                      }
                    } else {
                      return {
                        ...city
                      }
                    }

                  })
                } : {
                  ...zone
                }
              })
            }
          } else {
            return {
              ...userState
            }
          }
        });

        console.log(statesList)
        // var list = [];
        // if (state.userMiniAreaParams?.page == 1) {
        //   list = statesList
        // } else {
        //   list = [...state.userMiniStateList, ...statesList];
        // }
        var noofpages = Math.ceil(
          action.payload.count / state.userMiniAreaParams?.page_size
        )

        state.userMiniStateList = statesList;
        state.userMiniAreaList = areasList;
        state.userMiniAreaParams = {
          ...state.userMiniAreaParams,
          no_of_pages: noofpages,
        }
        state.userMiniAreaCount = action.payload.count;
      })
      .addCase(getUserAreas.rejected, (state, action) => {
        state.status = "getUserAreas failed";
        state.userMiniAreaLoading = false;
        state.error = action.error.message;
      })
      .addCase(getUserLocation.pending, (state, action) => {
        state.status = "getUserLocation loading";
        state.userMiniLocationLoading = true;
      })
      .addCase(getUserLocation.fulfilled, (state, action) => {
        state.status = "getUserLocation succeeded";
        state.userMiniLocationLoading = false;

        const statesList = state.userMiniStateList.map(userState => {
          if (userState.id == action.payload.state_id) {
            return {
              ...userState,
              zones: userState.zones.map(zone => {
                return (zone.id == action.payload.zone_id) ? {
                  ...zone,
                  cities: zone.cities.map(city => {
                    return (city.id == action.payload.city_id) ? {
                      ...city,
                      areas: city.areas.map(area => {
                        if (area.id == action.payload.area_id) {
                          var list = [];
                          if (state.userMiniLocationParams?.page == 1) {
                            list = action.payload.locationList
                          } else {
                            list = [...area.locations, ...action.payload.locationList];
                          }
                          area.locations = action.payload.locationList;
                        }
                        return area
                      })
                    } : {
                      ...city
                    }
                  })
                } : {
                  ...zone
                }
              })
            }
          } else {
            return {
              ...userState
            }
          }
        });

        var noofpages = Math.ceil(
          action.payload.count / state.userMiniLocationParams?.page_size
        )

        state.userMiniStateList = statesList;
        state.userMiniLocationParams = {
          ...state.userMiniLocationParams,
          no_of_pages: noofpages,
        }
        state.userMiniLocationCount = action.payload.count;
        state.userMiniLocationList = action.payload.locationList;
      })
      .addCase(getUserLocation.rejected, (state, action) => {
        state.status = "getUserLocation failed";
        state.userMiniLocationLoading = false;
        state.error = action.error.message;
      })

      .addCase(getUserLoginList.pending, (state, action) => {
        state.status = "getUserLoginList pending"
        state.loginLoading = true
      })
      .addCase(getUserLoginList.fulfilled, (state, action) => {
        var response = action.payload.response;
        state.status = "getUserLoginList succeeded";
        state.loginLoading = false;
        var noofpages = Math.ceil(
          action.payload.count / state.userLoginParams?.page_size
        );

        state.userLoginList = response.results;
        state.loginCount = response.count;
        state.userLoginParams = {
          ...state.userLoginParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getUserLoginList.rejected, (state, action) => {
        state.status = "getUserLoginList failed";
        state.loginLoading = false;
        state.error = action.error.message;
      })

      .addCase(getUserDeviceList.pending, (state, action) => {
        state.status = "getUserDeviceList pending"
        state.deviceLoading = true
      })
      .addCase(getUserDeviceList.fulfilled, (state, action) => {
        var response = action.payload.response;
        state.status = "getUserDeviceList succeeded";
        state.deviceLoading = false;
        var noofpages = Math.ceil(
          action.payload.count / state.userDeviceParams?.page_size
        );

        state.userDeviceList = response.results;
        state.useDeviceCount = response.count;
        state.userDeviceParams = {
          ...state.userDeviceParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getUserDeviceList.rejected, (state, action) => {
        state.status = "getUserDeviceList failed";
        state.deviceLoading = false;
        state.error = action.error.message;
      })


      .addCase(getUserActivityList.pending, (state, action) => {
        state.status = "getUserActivityList pending"
        state.activityLoading = true
      })
      .addCase(getUserActivityList.fulfilled, (state, action) => {
        var response = action.payload.response;
        state.status = "getUserActivityList succeeded";
        state.activityLoading = false;
        var noofpages = Math.ceil(
          action.payload.count / state.userActivityParams?.page_size
        );

        state.userActivityList = response.results;
        state.useActivityCount = response.count;
        state.userActivityParams = {
          ...state.userActivityParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getUserActivityList.rejected, (state, action) => {
        state.status = "getUserActivityList failed";
        state.activityLoading = false;
        state.error = action.error.message;
      })
  },
});

// Action creators are generated for each case reducer function
export const {
  setData,
  clearStateList,
  InputChangeValue,
  setSelectedData,
  changeParams,
  checkPermission,
  checkAppPermission,
  isDrawerVisible,
  clearUserData,
  SelecteChangeValue,

  showZones,
  checkStatePermission,
  showCities,
  checkZonesPermission,
  showAreas,
  checkCityPermissions,
  showLocations,
  checkAreaPermissions,
  checkLocationPermissions,

  userDeviceActiveTab,
  setUserLoginParams,
  setUserDeviceParams,

  setMiniStatesParams,
  setMiniZoneParams,
  setMiniCityParams,
  setMiniAreaParams,
  setMiniLocationParams,
  setMiniStateList,
} = manageUsersSlice.actions;

export default manageUsersSlice.reducer;
