import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { getScheduleReports, startDeleteScheduleReportById } from "./scheduleReportActions";

const scheduleReportSlice = createSlice({
  name: "scheduleReport",
  initialState: {
    listCount: 0,
    scheduleReports: [],
    model: false,
    pageParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
  },
  reducers: {
    changeParams: (state, action) => {
      state.pageParams = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getScheduleReports.pending, (state, action) => {
        state.loading = true;
        state.status = "getScheduleReports pending";
      })

      .addCase(getScheduleReports.fulfilled, (state, action) => {
        state.status = "getScheduleReports succeeded";
        state.loading = false;
        state.listCount = action.payload.count;
        state.scheduleReports = action.payload.results
      })
      .addCase(getScheduleReports.rejected, (state, action) => {
        state.status = "getScheduleReports failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(startDeleteScheduleReportById.pending, (state, action) => {
        state.loading = true;
        state.status = "startDeleteScheduleReportById pending";
      })
      .addCase(startDeleteScheduleReportById.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "startDeleteScheduleReportById succeeded";
      })
      .addCase(startDeleteScheduleReportById.rejected, (state, action) => {
        state.status = "startDeleteScheduleReportById failed";
        state.loading = false;
        state.error = action.error.message
      })
  }
});

// Action creators are generated for each case reducer function
export const {
  changeParams,
} = scheduleReportSlice.actions;

export default scheduleReportSlice.reducer;
