import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    postAdd,
    getParamsList,
    postEdit,
    postDelete,
    getList,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
    RiCheckboxCircleLine,
    RiAlertLine,
    RiInformationLine,
} from "react-icons/ri";

export const getSchemes = createAsyncThunk(
    "/getSchemess",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/orders/schemes/list/", params);
            if (response) {
                return { response };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);
export const schemeDelete = createAsyncThunk(
    "/schemesDelete",
    async (payload) => async (dispatch) => {
        console.log(dispatch)
        var schemeID = payload;
        try {
            const response = await postDelete("/orders/schemes/detail/" + schemeID);
            if (response.status === 204) {
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Schemes has been deleted Successfully!
                            </p>
                        </div>
                    ),
                });
                return { response };
            } else {
                Modal.error({
                    icon: (
                        <span className="remix-icon">
                            <RiAlertLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Sorry!   Schemes has been not deleted!
                            </p>
                        </div>
                    ),
                });
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            throw error.message;
        }
    }
);
export const schemeAdd = createAsyncThunk(
    "/schemesAdd",
    async (payload) => {
        try {
            if (payload.id == 0) {
                const response = await postAdd("/orders/schemes/list/", payload);
                if (response.status === 201) {
                    Modal.success({
                        icon: (
                            <span className="remix-icon">
                                <RiCheckboxCircleLine />
                            </span>
                        ),
                        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                        content: (
                            <div>
                                <p className="hp-p1-body hp-text-color-black-80">
                                    Schemes Added Successfully.!
                                </p>
                            </div>
                        ),
                    });
                    return { response, modal: false };
                } else {
                    Modal.error({
                        icon: (
                            <span className="remix-icon">
                                <RiAlertLine />
                            </span>
                        ),
                        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                        content: (
                            <div>
                                <p className="hp-p1-body hp-text-color-black-80">
                                    Sorry! Unable to add Schemes. Please try again!
                                </p>
                            </div>
                        ),
                    });
                    throw new Error(response);
                }
            } else {
                const response = await postEdit("/orders/schemes/detail/" + payload.id, payload);
                if (response.status === 200) {
                    Modal.success({
                        icon: (
                            <span className="remix-icon">
                                <RiCheckboxCircleLine />
                            </span>
                        ),
                        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                        content: (
                            <div>
                                <p className="hp-p1-body hp-text-color-black-80">
                                    Schemes Edited Successfully.!
                                </p>
                            </div>
                        ),
                    });

                    return { response, modal: false }
                } else {
                    Modal.error({
                        icon: (
                            <span className="remix-icon">
                                <RiAlertLine />
                            </span>
                        ),
                        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                        content: (
                            <div>
                                <p className="hp-p1-body hp-text-color-black-80">
                                    Sorry! Unable to Schemes Country. Please try again!
                                </p>
                            </div>
                        ),
                    });
                }
                throw new Error(response);

            }

        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            throw error.message;
        }
    }
);
export const getSchemesByID = createAsyncThunk(
    "/getSchemesByID",
    async (payload) => {
        try {
            const response = await getList("/orders/schemes/detail/" + payload, {});
            return response.data;
        } catch (error) {
            throw error.message;
        }
    }
);
export const getProductSchemes = createAsyncThunk(
    "/getProductSchemes",
    async (payload) => {
        const { data, handleError, onSuccess } = payload;
        try {
            const response = await postAdd("/masters/products_schemes/", { ...data });
            onSuccess(response.data)
            return response.data;
        } catch (error) {
            handleError();
            const keys = Object.keys(error?.response?.data);
            const values = Object.values(error?.response?.data);
            console.log(values)
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),
                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            {(Array.isArray(keys) && keys.length) && keys[0]}: {Array.isArray(values) && values.length ? values[0][0] : error?.response?.data?.error.find(e => e) ? error?.response?.data?.error.find(e => e) : error.message}
                        </p>
                    </div>
                ),
            });
            throw error?.response?.data?.error.find(e => e) ? error?.response?.data?.error.find(e => e) : error.message;
        }
    }
);
export const getCustomerProductSchemes = createAsyncThunk(
    "/getCustomerProductSchemes",
    async (payload) => {
        const { data, handleError = () => { } } = payload;
        try {
            const response = await getParamsList("/b2c/admin/customer/schemes/", { ...data });
            payload?.productForm?.submit();
            payload?.closeModal();
            return response;
        } catch (error) {
            payload?.productForm?.submit();
            payload?.closeModal();
            handleError();
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),
                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            {error?.response?.data?.error.find(e => e) ? error?.response?.data?.error.find(e => e) : error.message}
                        </p>
                    </div>
                ),
            });
            throw error?.response?.data?.error.find(e => e) ? error?.response?.data?.error.find(e => e) : error.message;
        }
    }
);
export const getCustomerSchemes = createAsyncThunk(
    "/getCustomerSchemes",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/b2c/customer/schemes/", params);
            if (response) {
                return { response };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);
export const getCustomerSchemesByID = createAsyncThunk(
    "/getCustomerSchemesByID",
    async (payload) => {
        try {
            const response = await getList("/b2c/customer/schemes/" + payload + "/", {});
            return response.data;
        } catch (error) {
            throw error.message;
        }
    }
);
export const postCustomerSchemes = createAsyncThunk(
    "/postCustomerSchemes",
    async (payload) => {
        const { data, handleError } = payload;
        try {
            const response = await postAdd("/b2c/customer/schemes/", { ...data });
            if (response.status === 201) {
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Customer Scheme Added Successfully.!
                            </p>
                        </div>
                    ),
                });
                return response;
            } else {
                Modal.error({
                    icon: (
                        <span className="remix-icon">
                            <RiAlertLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Sorry! Unable to add Customer Scheme. Please try again!
                            </p>
                        </div>
                    ),
                });
                throw new Error(response);
            }
        } catch (error) {
            handleError();
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),
                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            {error?.response?.data?.error.find(e => e) ? error?.response?.data?.error.find(e => e) : error.message}
                        </p>
                    </div>
                ),
            });
            throw error?.response?.data?.error.find(e => e) ? error?.response?.data?.error.find(e => e) : error.message;
        }
    }
);

