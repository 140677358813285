import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
	addParams,
	getList,
	getParamsList,
	postAdd,
	postEdit,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
	RiCheckboxCircleLine,
	RiAlertLine,
	RiInformationLine,
} from "react-icons/ri";

export const getSaleOrders = createAsyncThunk(
	"/getSaleOrders",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(`/orders/orderslist/`, params);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const postSaleOrders = createAsyncThunk(
	"/postSaleOrders",
	async (payload) => {
		try {
			const { data, history, form } = payload;
			const response = await postAdd("/orders/orderslist/", data);
			if (response.status === 201) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Retailer Indent Added Successfully.!
							</p>
						</div>
					),
				});
				history.push("/retailer_indents");
				form.resetFields();
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error.response) {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Unable to add Retailer Indent. Please try
								again!
							</p>
						</div>
					),
				});
			} else {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),

					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Getting from server side issue!
							</p>
						</div>
					),
				});
			}
			throw error.message;
		}
	}
);
export const editSaleOrders = createAsyncThunk(
	"/editSaleOrders",
	async (payload) => {
		try {
			const { id, data, history, form } = payload;
			const response = await postEdit(`/orders/ordersdetail/${id}`, data);
			if (response.status === 200) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Retailer Indent Edited Successfully.!
							</p>
						</div>
					),
				});
				history.push("/retailer_indents");
				form.resetFields();
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error.response) {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Unable to edit Retailer Indent. Please
								try again!
							</p>
						</div>
					),
				});
			} else {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),

					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Getting from server side issue!
							</p>
						</div>
					),
				});
			}
			throw error.message;
		}
	}
);
export const getSaleOrderByID = createAsyncThunk(
	"/getSaleOrderByID",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getList(`/orders/ordersdetail/${params.id}`);
			if (response) {
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
