import { createSlice } from "@reduxjs/toolkit";
import {
	editCustomers,
	getCustomerByID,
	getCustomers,
	postCustomers,
} from "./customers.action";
import { v4 as uuidv4 } from "uuid";

const customersSlice = createSlice({
	name: "customers",
	initialState: {
		loading: false,
		error: "",
		status: "",
		list: [],
		selectedData: {
			new_product_type: {},
		},
		count: 0,
		drawer: false,
		openAddressModal: false,
		openAddAddressModal: false,
		filterParams: {},
		productsList: [],
		productListparams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
		pageParams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
	},
	reducers: {
		isDrawerVisible: (state, action) => {
			return {
				...state,
				drawer: action.payload,
			};
		},
		changeParams: (state, action) => {
			return {
				...state,
				pageParams: action.payload,
			};
		},
		changeProductListParams: (state, action) => {
			return {
				...state,
				productListparams: action.payload,
			};
		},
		setOpenAddressModal: (state, action) => {
			return {
				...state,
				openAddressModal: action.payload,
			};
		},
		setOpenAddAddressModal: (state, action) => {
			return {
				...state,
				openAddAddressModal: action.payload,
			};
		},
		changeFilterParams: (state, action) => {
			return {
				...state,
				filterParams: action.payload,
			};
		},
		FilterInputChangeValue: (state, action) => {
			return {
				...state,
				filterParams: {
					...state.filterParams,
					[action.payload.key]: action.payload.value,
				},
			};
		},

		setSelectedData: (state, action) => {
			return {
				...state,
				selectedData: action.payload,
			};
		},
		inputChangeValue: (state, action) => {
			return {
				...state,
				selectedData: {
					...state.selectedData,
					...action.payload,
				},
			};
		},
		addProductsToList: (state, action) => {
			return {
				...state,
				productsList: [...state.productsList, action.payload],
			};
		},
		removeProductsFromList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => {
					return e.uniqueId == action.payload.product_id
						? {
								...e,
								dodelete: true,
						  }
						: {
								...e,
						  };
				}),
			};
		},
		changeProductList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => {
					if (e.id == action.payload.product_id) {
						if (action.payload.key == "quantity") {
							const calculatedPrice =
								parseFloat(action.payload.value) *
								parseFloat(e.listedPrice) *
								parseFloat(e.unit.units);
							const discountAmt = e.discount_percent
								? +calculatedPrice * (+e.discount_percent / 100)
								: 0;
							const taxableValue = calculatedPrice - discountAmt;
							const gstAmt = e.gst_percentage
								? parseFloat(taxableValue) *
								  (+e.gst_percentage / 100)
								: 0;
							const totalPrice =
								parseFloat(taxableValue) + parseFloat(gstAmt);
							return {
								...e,
								[action.payload.key]: action.payload.value,
								discountAmt: discountAmt.toFixed(2),
								gstAmt: gstAmt.toFixed(2),
								price: totalPrice,
								taxableValue: taxableValue.toFixed(2),
								originalPrice: calculatedPrice.toFixed(2),
							};
						} else {
							return {
								...e,
								[action.payload.key]: action.payload.value,
							};
						}
					} else {
						return e;
					}
				}),
			};
		},
		clearData: (state, action) => {
			return {
				...state,
				loading: false,
				error: "",
				status: "",
				list: [],
				selectedData: {},
				count: 0,
				openAddressModal: false,
				openAddAddressModal: false,
				productsList: [],
				productListparams: {
					start_date: "",
					end_date: "",
					no_of_pages: 0,
					page_size: 10,
					page: 1,
					search: "",
				},
			};
		},
	},
	extraReducers: (builder) => {
		builder
			//get Data
			.addCase(getCustomers.pending, (state, action) => {
				state.status = "getCustomers loading";
				state.loading = true;
			})
			.addCase(getCustomers.fulfilled, (state, action) => {
				state.status = "getCustomers succeeded";
				state.loading = false;
				var noofpages = Math.ceil(
					action.payload.count / state.pageParams.page_size
				);
				state.count = action.payload.count;
				state.list = action.payload.results;
				state.pageParams = {
					...state.pageParams,
					no_of_pages: noofpages,
				};
			})
			.addCase(getCustomers.rejected, (state, action) => {
				state.status = "getCustomers failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//get Data by Id
			.addCase(getCustomerByID.pending, (state, action) => {
				state.status = "getCustomerByID loading";
				state.loading = true;
			})
			.addCase(getCustomerByID.fulfilled, (state, action) => {
				state.status = "getCustomerByID succeeded";
				state.loading = false;
				state.selectedData = {
					...action.payload,
					salesagent_id: action.payload.sales_agent?.id,
					retailer_id: action.payload.retailer?.id,
					location_id: action.payload.retailer?.location?.id,
					location_name: action.payload.retailer?.location?.name,
					billing_address_id:
						action.payload?.retailer?.default_billing_address?.id,
					shipping_address_id:
						action.payload?.retailer?.default_shipping_address?.id,
					default_billing_address:
						action.payload?.retailer?.default_billing_address,
					default_shipping_address:
						action.payload?.retailer?.default_shipping_address,
				};
				state.productsList = action.payload.return_items?.map((e) => {
					const calculatedPrice =
						parseFloat(e?.unit?.units) *
						parseFloat(e?.quantity) *
						parseFloat(e?.price);
					const discountAmt =
						parseFloat(calculatedPrice) *
						(+e.discount_percent / 100);
					const taxableValue = calculatedPrice - discountAmt;
					const gstAmt =
						parseFloat(taxableValue) * (+e.tax_percent / 100);
					const totalPrice =
						parseFloat(taxableValue) + parseFloat(gstAmt);
					return {
						...e,
						item_id: e.id,
						product_id: e.product.id,
						image: e.product.image,
						name: e.product.name,
						listedPrice: parseFloat(e.price).toFixed(2),
						originalPrice: parseFloat(calculatedPrice).toFixed(2),
						discountAmt: parseFloat(discountAmt).toFixed(2),
						gstAmt: parseFloat(gstAmt).toFixed(2),
						taxableValue: parseFloat(taxableValue).toFixed(2),
						price: parseFloat(totalPrice).toFixed(2),
						size: e?.unit?.id,
						gst_percentage: e?.tax_percent,
						discount_percent: e?.discount_percent,
						quantity: parseInt(e?.quantity),
						unit: e?.unit,
						uniqueId: uuidv4(),
					};
				});
			})
			.addCase(getCustomerByID.rejected, (state, action) => {
				state.status = "getCustomerByID failed";
				state.loading = false;
				state.error = action.error.message;
			})
			// post data
			.addCase(postCustomers.pending, (state, action) => {
				state.status = "postCustomers loading";
				state.loading = true;
			})
			.addCase(postCustomers.fulfilled, (state, action) => {
				state.status = "postCustomers succeeded";
				state.loading = false;
				state.selectedData = {};
			})
			.addCase(postCustomers.rejected, (state, action) => {
				state.status = "postCustomers failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//edit data
			.addCase(editCustomers.pending, (state, action) => {
				state.status = "editCustomers loading";
				state.loading = true;
			})
			.addCase(editCustomers.fulfilled, (state, action) => {
				state.status = "editCustomers succeeded";
				state.loading = false;
				state.selectedData = {};
			})
			.addCase(editCustomers.rejected, (state, action) => {
				state.status = "editCustomers failed";
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

// Action creators are generated for each case reducer function
export const {
	isDrawerVisible,
	changeParams,
	changeProductListParams,
	clearData,
	setSelectedData,
	inputChangeValue,
	addProductsToList,
	removeProductsFromList,
	changeProductList,
	setOpenAddressModal,
	setOpenAddAddressModal,
	changeFilterParams,
	FilterInputChangeValue,
} = customersSlice.actions;

export default customersSlice.reducer;
