import { createSlice } from "@reduxjs/toolkit";
import {
  editDeliveryPersons,
  getDeliverableOrders,
  getDeliveryCashCollection,
  getDeliveryPersonByID,
  getDeliveryPersons,
  postDeliveryPersons,
  getUserStates,
  getUserCities,
  getUserAreas,
  getUserLocation,
  getUserZones,
} from "./deliveryPersonAction";
import { v4 as uuidv4 } from "uuid";

const initState = {
  loading: false,
  error: "",
  status: "",
  list: [],
  selectedData: {
    new_product_type: {},
  },
  count: 0,
  drawer: false,
  openAddressModal: false,
  openAddAddressModal: false,
  filterParams: {},
  deliverableOrders: {
    list: [],
    count: 0,
    params: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 5,
      page: 1,
      search: "",
    },
    loading: false
  },
  deliveryCashCollection: {
    list: [],
    count: 0,
    params: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 5,
      page: 1,
      search: "",
    },
    loading: false
  },

  pageParams: {
    start_date: "",
    end_date: "",
    no_of_pages: 0,
    page_size: 10,
    page: 1,
    search: "",
  },

  //locations
  userMiniStateList: [],
  userMiniStateLoading: false,
  userMiniStateCount: 0,
  userMiniStateParams: {
    no_of_pages: 0,
    page_size: 10,
    page: 1,
    search: "",
  },
  userMiniZoneList: [],
  userMiniZoneCount: 0,
  userMiniZoneLoading: false,
  userMiniZoneParams: {
    no_of_pages: 0,
    page_size: 10,
    page: 1,
    search: "",
  },
  userMiniCityList: [],
  userMiniCityCount: 0,
  userMiniCityLoading: false,
  userMiniCityParams: {
    no_of_pages: 0,
    page_size: 10,
    page: 1,
    search: "",
  },
  userMiniAreaList: [],
  userMiniAreaCount: 0,
  userMiniAreaLoading: false,
  userMiniAreaParams: {
    no_of_pages: 0,
    page_size: 10,
    page: 1,
    search: "",
  },
  userMiniLocationList: [],
  userMiniLocationCount: 0,
  userMiniLocationLoading: false,
  userMiniLocationParams: {
    no_of_pages: 0,
    page_size: 10,
    page: 1,
    search: "",
  },

  userSelectedStatesList: [],
  userSelectedZonesList: [],
  userSelectedCityList: [],
  userSelectedAreaList: [],
  userSelectedLocationList: [],

  selectedStateId: "",
  selectedZoneId: "",
  selectedCityId: "",
  selectedAreaId: "",
  selectedLocationId: "",
}

const deliveryPersonSlice = createSlice({
  name: "deliveryPerson",
  initialState: initState,
  reducers: {
    isDrawerVisible: (state, action) => {
      return {
        ...state,
        drawer: action.payload,
      };
    },
    changeParams: (state, action) => {
      return {
        ...state,
        pageParams: action.payload,
      };
    },
    changeDeliverableOrdersParams: (state, action) => {
      return {
        ...state,
        deliverableOrders: {
          ...state.deliverableOrders,
          params: action.payload,
        },
      };
    },
    changeDeliveryCashCollectionParams: (state, action) => {
      return {
        ...state,
        deliveryCashCollection: {
          ...state.deliveryCashCollection,
          params: action.payload,
        },
      };
    },
    setOpenAddressModal: (state, action) => {
      return {
        ...state,
        openAddressModal: action.payload,
      };
    },
    setOpenAddAddressModal: (state, action) => {
      return {
        ...state,
        openAddAddressModal: action.payload,
      };
    },
    changeFilterParams: (state, action) => {
      return {
        ...state,
        filterParams: action.payload,
      };
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        filterParams: {
          ...state.filterParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    setSelectedData: (state, action) => {
      return {
        ...state,
        selectedData: action.payload,
      };
    },
    inputChangeValue: (state, action) => {
      return {
        ...state,
        selectedData: {
          ...state.selectedData,
          ...action.payload,
        },
      };
    },
    addProductsToList: (state, action) => {
      return {
        ...state,
        productsList: [...state.productsList, action.payload],
      };
    },
    removeProductsFromList: (state, action) => {
      return {
        ...state,
        productsList: state.productsList.map((e) => {
          return e.uniqueId == action.payload.product_id
            ? {
              ...e,
              dodelete: true,
            }
            : {
              ...e,
            };
        }),
      };
    },
    setMiniStatesParams: (state, action) => {
      return {
        ...state,
        userMiniStateParams: action.payload,
      };
    },
    setMiniZoneParams: (state, action) => {
      return {
        ...state,
        userMiniZoneParams: action.payload,
      };
    },
    setMiniCityParams: (state, action) => {
      return {
        ...state,
        userMiniCityParams: action.payload,
      };
    },
    setMiniAreaParams: (state, action) => {
      return {
        ...state,
        userMiniAreaParams: action.payload,
      };
    },
    setMiniLocationParams: (state, action) => {
      return {
        ...state,
        userMiniLocationParams: action.payload,
      };
    },
    showZones: (state, action) => {
      return {
        ...state,
        selectedStateId: action.payload,
        selectedZoneId: "",
        selectedCityId: "",
        selectedAreaId: "",
        selectedLocationId: "",
      };
    },
    checkStatePermission: (state, action) => {
      const obj = {}
      const statesList = state?.userMiniStateList?.map((userState) => {
        if (userState.id == action.payload.state_permission_id) {
          if (action.payload.value) {
            obj.userSelectedStatesList = [...state.userSelectedStatesList, action.payload.state_permission_id]
            return {
              ...userState,
              state_checked: true
            }
          } else {
            obj.userSelectedStatesList = state.userSelectedStatesList.filter(e => {
              return e != action.payload.state_permission_id
            })
            return {
              ...userState,
              state_checked: false,
              zones: userState?.zones?.map((zone) => {
                obj.userSelectedZonesList = [];
                return {
                  ...zone,
                  zone_checked: false,
                  cities: zone?.cities?.map((city) => {
                    obj.userSelectedCityList = [];
                    return {
                      ...city,
                      city_checked: false,
                      areas: city?.areas?.map((area) => {
                        obj.userSelectedAreaList = [];
                        return {
                          ...area,
                          area_checked: false,
                          locations: area?.locations?.map(location => {
                            obj.userSelectedLocationList = [];
                            return {
                              ...location,
                              location_checked: false
                            }
                          })
                        }
                      })
                    }
                  })
                }
              })
            }

          }
        } else {
          return {
            ...userState
          }
        }
      });
      return {
        ...state,
        userMiniStateList: statesList,
        ...obj
      }
    },
    showCities: (state, action) => {
      return {
        ...state,
        selectedZoneId: action.payload,
        selectedCityId: "",
        selectedAreaId: "",
        selectedLocationId: "",
      };
    },
    checkZonesPermission: (state, action) => {
      const obj = {};
      const statesList =
        state.userMiniStateList.map((userState) => {
          if (userState.id == action.payload.state_id) {
            return {
              ...userState,
              zones: userState.zones.map((zone) => {
                if (zone.id == action.payload.zone_permission_id) {
                  if (action.payload.value) {
                    obj.userSelectedZonesList = [...state.userSelectedZonesList, action.payload.zone_permission_id]
                    return {
                      ...zone,
                      zone_checked: true
                    }
                  } else {
                    obj.userSelectedZonesList = state.userSelectedZonesList.filter(e => {
                      return e != action.payload.zone_permission_id
                    })
                    return {
                      ...zone,
                      zone_checked: false,
                      cities: zone?.cities?.map((city) => {
                        obj.userSelectedCityList = [];
                        return {
                          ...city,
                          city_checked: false,
                          areas: city?.areas?.map((area) => {
                            obj.userSelectedAreaList = [];
                            return {
                              ...area,
                              area_checked: false,
                              locations: area?.locations?.map(location => {
                                obj.userSelectedLocationList = [];
                                return {
                                  ...location,
                                  location_checked: false
                                }
                              })
                            }
                          })
                        }
                      })
                    }
                  }
                } else {
                  return {
                    ...zone
                  }
                }
              })
            }
          } else {
            return {
              ...userState
            }
          }
        });

      return {
        ...state,
        userMiniStateList: statesList,
        ...obj
      }
    },
    showAreas: (state, action) => {
      return {
        ...state,
        selectedCityId: action.payload,
        selectedAreaId: "",
        selectedLocationId: "",
      };
    },
    checkCityPermissions: (state, action) => {
      const obj = {};
      const statesList =
        state.userMiniStateList.map((userState) => {
          if (userState.id == action.payload.state_id) {
            return {
              ...userState,
              zones: userState.zones.map((zone) => {
                if (zone.id == action.payload.zone_id) {
                  return {
                    ...zone,
                    cities: zone.cities.map(city => {
                      if (city.id == action.payload.city_permission_id) {
                        if (action.payload.value) {
                          obj.userSelectedCityList = [...state.userSelectedCityList, action.payload.city_permission_id]
                          return {
                            ...city,
                            city_checked: true
                          }
                        } else {
                          obj.userSelectedCityList = state.userSelectedCityList.filter(e => {
                            return e != action.payload.city_permission_id
                          })
                          return {
                            ...city,
                            city_checked: false,
                            areas: city?.areas?.map((area) => {
                              obj.userSelectedAreaList = [];
                              return {
                                ...area,
                                area_checked: false,
                                locations: area?.locations?.map(location => {
                                  obj.userSelectedLocationList = [];
                                  return {
                                    ...location,
                                    location_checked: false
                                  }
                                })
                              }
                            })
                          }
                        }

                      } else {
                        return {
                          ...city,
                        }
                      }
                    })

                  }

                } else {
                  return {
                    ...zone
                  }
                }
              })
            }
          } else {
            return {
              ...userState
            }
          }
        });

      return {
        ...state,
        userMiniStateList: statesList,
        ...obj
      }
    },
    showLocations: (state, action) => {
      return {
        ...state,
        selectedAreaId: action.payload,
        selectedLocationId: "",
      };
    },
    checkAreaPermissions: (state, action) => {
      const obj = {};
      const statesList =
        state.userMiniStateList.map((userState) => {
          if (userState.id == action.payload.state_id) {
            return {
              ...userState,
              zones: userState.zones.map((zone) => {
                if (zone.id == action.payload.zone_id) {
                  return {
                    ...zone,
                    cities: zone.cities.map(city => {
                      if (city.id == action.payload.city_id) {
                        return {
                          ...city,
                          areas: city.areas.map(area => {
                            if (area.id == action.payload.area_id) {
                              if (action.payload.value) {
                                obj.userSelectedAreaList = [...state.userSelectedAreaList, action.payload.area_id]
                                return {
                                  ...area,
                                  area_checked: true,
                                  // locations: area?.locations?.map(location => {
                                  //   return {
                                  //     ...location,
                                  //     location_checked: false
                                  //   }
                                  // })
                                }
                              } else {
                                obj.userSelectedAreaList = state.userSelectedAreaList.filter(e => {
                                  return e != action.payload.area_id
                                })
                                return {
                                  ...area,
                                  area_checked: false,
                                  locations: area?.locations?.map(location => {
                                    obj.userSelectedLocationList = state.userSelectedLocationList.filter(e => e != location.id);
                                    return {
                                      ...location,
                                      location_checked: false
                                    }
                                  })
                                }
                              }
                            } else {
                              return {
                                ...area
                              }
                            }

                          })
                        }


                      } else {
                        return {
                          ...city,
                        }
                      }
                    })

                  }

                } else {
                  return {
                    ...zone
                  }
                }
              })
            }
          } else {
            return {
              ...userState
            }
          }
        });

      return {
        ...state,
        userMiniStateList: statesList,
        ...obj
      }
    },
    checkLocationPermissions: (state, action) => {
      const statesList =
        state.userMiniStateList.map((userState) => {
          if (userState.id == action.payload.state_id) {
            return {
              ...userState,
              zones: userState.zones.map((zone) => {
                if (zone.id == action.payload.zone_id) {
                  return {
                    ...zone,
                    cities: zone.cities.map(city => {
                      if (city.id == action.payload.city_id) {
                        return {
                          ...city,
                          areas: city.areas.map(area => {
                            return {
                              ...area,
                              locations: area.locations?.map(location => {
                                if (location.id == action.payload.location_id) {
                                  return {
                                    ...location,
                                    location_checked: action.payload.value
                                  }
                                } else {
                                  return {
                                    ...location
                                  }
                                }
                              })
                            }


                          })
                        }


                      } else {
                        return {
                          ...city,
                        }
                      }
                    })

                  }

                } else {
                  return {
                    ...zone
                  }
                }
              })
            }
          } else {
            return {
              ...userState
            }
          }
        });

      return {
        ...state,
        userMiniStateList: statesList,
        userSelectedLocationList: action.payload.value ?
          [...state.userSelectedLocationList, action.payload.location_id]
          : state.userSelectedLocationList.filter(e => {
            return e != action.payload.location_id
          })
      }
    },

    clearData: (state, action) => {
      return initState;
    },
  },
  extraReducers: (builder) => {
    builder
      //get Data
      .addCase(getDeliveryPersons.pending, (state, action) => {
        state.status = "getDeliveryPersons loading";
        state.loading = true;
      })
      .addCase(getDeliveryPersons.fulfilled, (state, action) => {
        state.status = "getDeliveryPersons succeeded";
        state.loading = false;
        var noofpages = Math.ceil(
          action.payload.count / state.pageParams.page_size
        );
        state.count = action.payload.count;
        state.list = action.payload.results;
        state.pageParams = {
          ...state.pageParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getDeliveryPersons.rejected, (state, action) => {
        state.status = "getDeliveryPersons failed";
        state.loading = false;
        state.error = action.error.message;
      })
      //get Data by Id
      .addCase(getDeliveryPersonByID.pending, (state, action) => {
        state.status = "getDeliveryPersonByID loading";
        state.loading = true;
      })
      .addCase(getDeliveryPersonByID.fulfilled, (state, action) => {
        state.status = "getDeliveryPersonByID succeeded";
        state.loading = false;
        var state_ids = [];
        var zone_ids = [];
        var city_ids = [];
        var area_ids = [];
        var location_ids = [];

        state_ids = action.payload?.states?.map((e) => {
          return e.id;
        });
        zone_ids = action.payload?.zones?.map((e) => {
          return e.id;
        });
        city_ids = action.payload?.cities?.map((e) => {
          return e.id;
        });
        area_ids = action.payload?.areas?.map((e) => {
          return e.id;
        });
        location_ids = action.payload?.locations?.map((e) => {
          return e.id;
        });
        state.selectedData = action.payload;
        state.userSelectedStatesList = state_ids;
        state.userSelectedZonesList = zone_ids;
        state.userSelectedCityList = city_ids;
        state.userSelectedAreaList = area_ids;
        state.userSelectedLocationList = location_ids;

      })
      .addCase(getDeliveryPersonByID.rejected, (state, action) => {
        state.status = "getDeliveryPersonByID failed";
        state.loading = false;
        state.error = action.error.message;
      })
      // post data
      .addCase(postDeliveryPersons.pending, (state, action) => {
        state.status = "postDeliveryPersons loading";
        state.loading = true;
      })
      .addCase(postDeliveryPersons.fulfilled, (state, action) => {
        state.status = "postDeliveryPersons succeeded";
        state.loading = false;
      })
      .addCase(postDeliveryPersons.rejected, (state, action) => {
        state.status = "postDeliveryPersons failed";
        state.loading = false;
        state.error = action.error.message;
      })
      //edit data
      .addCase(editDeliveryPersons.pending, (state, action) => {
        state.status = "editDeliveryPersons loading";
        state.loading = true;
      })
      .addCase(editDeliveryPersons.fulfilled, (state, action) => {
        state.status = "editDeliveryPersons succeeded";
        state.loading = false;
        state.selectedData = {};
      })
      .addCase(editDeliveryPersons.rejected, (state, action) => {
        state.status = "editDeliveryPersons failed";
        state.loading = false;
        state.error = action.error.message;
      })
      //get Data
      .addCase(getDeliverableOrders.pending, (state, action) => {
        state.status = "getDeliverableOrders loading";
        state.deliverableOrders.loading = true;
      })
      .addCase(getDeliverableOrders.fulfilled, (state, action) => {
        state.status = "getDeliverableOrders succeeded";
        state.deliverableOrders.loading = false;
        var noofpages = Math.ceil(
          action.payload.count / state.deliverableOrders.params.page_size
        );
        state.deliverableOrders.count = action.payload.count;
        state.deliverableOrders.list = action.payload.results;
        state.deliverableOrders.params = {
          ...state.deliverableOrders.params,
          no_of_pages: noofpages,
        };
      })
      .addCase(getDeliverableOrders.rejected, (state, action) => {
        state.status = "getDeliverableOrders failed";
        state.deliverableOrders.loading = false;
        state.error = action.error.message;
      })
      .addCase(getDeliveryCashCollection.pending, (state, action) => {
        state.status = "getDeliveryCashCollection loading";
        state.deliveryCashCollection.loading = true;
      })
      .addCase(getDeliveryCashCollection.fulfilled, (state, action) => {
        state.status = "getDeliveryCashCollection succeeded";
        state.deliveryCashCollection.loading = false;
        var noofpages = Math.ceil(
          action.payload.count / state.deliveryCashCollection.params.page_size
        );
        state.deliveryCashCollection.count = action.payload.count;
        state.deliveryCashCollection.list = action.payload.results;
        state.deliveryCashCollection.params = {
          ...state.deliveryCashCollection.params,
          no_of_pages: noofpages,
        };
      })
      .addCase(getDeliveryCashCollection.rejected, (state, action) => {
        state.status = "getDeliveryCashCollection failed";
        state.deliveryCashCollection.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUserStates.pending, (state, action) => {
        state.status = "getUserStates loading";
        state.userMiniStateLoading = true;
      })
      .addCase(getUserStates.fulfilled, (state, action) => {
        const { statesList, count } = action.payload;
        state.status = "getUserStates succeeded";
        state.userMiniStateLoading = false;

        var list = [];
        if (state.userMiniStateParams?.page == 1) {
          list = statesList;
        } else {
          list = [...state.userMiniStateList, ...statesList];
        }
        var noofpages = Math.ceil(
          action.payload.count / state.userMiniStateParams?.page_size
        )
        state.userMiniStateCount = count;
        state.userMiniStateList = list;
        state.userMiniStateParams = {
          ...state.userMiniStateParams,
          no_of_pages: noofpages,
          count
        }
      })
      .addCase(getUserStates.rejected, (state, action) => {
        state.status = "getUserStates failed";
        state.userMiniStateLoading = false;
        state.error = action.error.message;
      })
      .addCase(getUserZones.pending, (state, action) => {
        state.status = "getUserZones loading";
        state.userMiniZoneLoading = true;
      })
      .addCase(getUserZones.fulfilled, (state, action) => {
        const { zoneList, state_id, count } = action.payload;

        const statesList = state.userMiniStateList?.map(userState => {
          if (userState.id == action.payload.state_id) {
            var list = [];
            if (state.userMiniZoneParams?.page == 1) {
              list = zoneList
            } else {
              list = [...userState.zones, ...zoneList];
            }
            userState.zones = list;
          }
          return userState
        });
        console.log(action.payload, statesList);
        var noofpages = Math.ceil(
          action.payload.count / state.userMiniZoneParams?.page_size
        )
        state.userMiniZoneParams = {
          ...state.userMiniZoneParams,
          no_of_pages: noofpages,
          count
        }
        state.status = "getUserZones succeeded";
        state.userMiniZoneLoading = false;
        state.userMiniZoneCount = action.payload.count;
        state.userMiniStateList = statesList;
        state.userMiniZoneList = zoneList;
      })
      .addCase(getUserZones.rejected, (state, action) => {
        state.status = "getUserZones failed";
        state.userMiniZoneLoading = false;
        state.error = action.error.message;
      })
      .addCase(getUserCities.pending, (state, action) => {
        state.status = "getUserCities loading";
        state.userMiniCityLoading = true;
      })
      .addCase(getUserCities.fulfilled, (state, action) => {
        const { citiesList } = action.payload;
        state.status = "getUserCities succeeded";
        state.userMiniCityLoading = false;
        const statesList = state.userMiniStateList.map(userState => {
          if (userState.id == action.payload.state_id) {
            return {
              ...userState,
              zones: userState.zones.map(zone => {
                if (zone.id == action.payload.zone_id) {
                  var list = [];
                  if (state.userMiniCityParams?.page == 1) {
                    list = citiesList
                  } else {
                    list = [...userState.zones, ...citiesList];
                  }
                  zone.cities = list;
                }
                return zone
              })
            }
          } else {
            return {
              ...userState
            }
          }
        });

        console.log(action.payload, statesList);
        var noofpages = Math.ceil(
          action.payload.count / state.userMiniCityParams?.page_size
        )

        state.userMiniStateList = statesList;
        state.userMiniCityParams = {
          ...state.userMiniCityParams,
          no_of_pages: noofpages,
        }
        state.userMiniCityCount = action.payload.count;
        state.userMiniCityList = citiesList;
      })
      .addCase(getUserCities.rejected, (state, action) => {
        state.status = "getUserCities failed";
        state.userMiniCityLoading = false;
        state.error = action.error.message;
      })
      .addCase(getUserAreas.pending, (state, action) => {
        state.status = "getUserAreas loading";
        state.userMiniAreaLoading = true;
      })
      .addCase(getUserAreas.fulfilled, (state, action) => {
        const { areasList } = action.payload;
        state.status = "getUserAreas succeeded";
        state.userMiniAreaLoading = false;

        const statesList = state.userMiniStateList.map(userState => {

          if (userState.id == action.payload.state_id) {
            return {
              ...userState,
              zones: userState.zones.map(zone => {
                return (zone.id == action.payload.zone_id) ? {
                  ...zone,
                  cities: zone.cities.map(city => {
                    if (city.id == action.payload.city_id) {
                      var list = [];
                      if (state.userMiniAreaParams?.page == 1) {
                        list = areasList
                      } else {
                        list = [...city.areas, ...areasList];
                      }
                      return {
                        ...city,
                        areas: list,
                      }
                    } else {
                      return {
                        ...city
                      }
                    }

                  })
                } : {
                  ...zone
                }
              })
            }
          } else {
            return {
              ...userState
            }
          }
        });

        console.log(statesList)
        // var list = [];
        // if (state.userMiniAreaParams?.page == 1) {
        //   list = statesList
        // } else {
        //   list = [...state.userMiniStateList, ...statesList];
        // }
        var noofpages = Math.ceil(
          action.payload.count / state.userMiniAreaParams?.page_size
        )

        state.userMiniStateList = statesList;
        state.userMiniAreaList = areasList;
        state.userMiniAreaParams = {
          ...state.userMiniAreaParams,
          no_of_pages: noofpages,
        }
        state.userMiniAreaCount = action.payload.count;
      })
      .addCase(getUserAreas.rejected, (state, action) => {
        state.status = "getUserAreas failed";
        state.userMiniAreaLoading = false;
        state.error = action.error.message;
      })
      .addCase(getUserLocation.pending, (state, action) => {
        state.status = "getUserLocation loading";
        state.userMiniLocationLoading = true;
      })
      .addCase(getUserLocation.fulfilled, (state, action) => {
        state.status = "getUserLocation succeeded";
        state.userMiniLocationLoading = false;

        console.log(action.payload)

        const statesList = state.userMiniStateList.map(userState => {
          if (userState.id == action.payload.state_id) {
            return {
              ...userState,
              zones: userState.zones.map(zone => {
                return (zone.id == action.payload.zone_id) ? {
                  ...zone,
                  cities: zone.cities.map(city => {
                    return (city.id == action.payload.city_id) ? {
                      ...city,
                      areas: city.areas.map(area => {
                        if (area.id == action.payload.area_id) {
                          var list = [];
                          if (state.userMiniLocationParams?.page == 1) {
                            list = action.payload.locationList
                          } else {
                            list = [...area.locations, ...action.payload.locationList];
                          }
                          area.locations = action.payload.locationList;
                        }
                        return area
                      })
                    } : {
                      ...city
                    }
                  })
                } : {
                  ...zone
                }
              })
            }
          } else {
            return {
              ...userState
            }
          }
        });

        var noofpages = Math.ceil(
          action.payload.count / state.userMiniLocationParams?.page_size
        )

        state.userMiniStateList = statesList;
        state.userMiniLocationParams = {
          ...state.userMiniLocationParams,
          no_of_pages: noofpages,
        }
        state.userMiniLocationCount = action.payload.count;
        state.userMiniLocationList = action.payload.locationList;
      })
      .addCase(getUserLocation.rejected, (state, action) => {
        state.status = "getUserLocation failed";
        state.userMiniLocationLoading = false;
        state.error = action.error.message;
      })
  },
});

// Action creators are generated for each case reducer function
export const {
  isDrawerVisible,
  changeParams,
  changeDeliverableOrdersParams,
  changeDeliveryCashCollectionParams,
  clearData,
  setSelectedData,
  inputChangeValue,
  addProductsToList,
  removeProductsFromList,
  setOpenAddressModal,
  setOpenAddAddressModal,
  changeFilterParams,
  FilterInputChangeValue,

  showZones,
  checkStatePermission,
  showCities,
  checkZonesPermission,
  showAreas,
  checkCityPermissions,
  showLocations,
  checkAreaPermissions,
  checkLocationPermissions,

  setMiniStatesParams,
  setMiniZoneParams,
  setMiniCityParams,
  setMiniAreaParams,
  setMiniLocationParams,
  setMiniStateList,
} = deliveryPersonSlice.actions;

export default deliveryPersonSlice.reducer;
