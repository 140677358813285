import { createSlice } from "@reduxjs/toolkit";
import { getSubscriptionSchemeByID, getSubscriptionScheme, SubscriptionSchemeAdd, SubscriptionSchemeDelete, getSubscriptionSchemeList } from "./subscriptionSchemes.action";

const SubscriptionSchemeSlice = createSlice({
  name: "SubscriptionScheme",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    list: [],
    listCount: 0,
    SubscriptionSchemeLoading: false,
    SubscriptionSchemelist: [],
    customer_priceslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    SubscriptionSchemeParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    pageParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    }
  },
  reducers: {
    SetSubscriptionSchemeParams: (state, action) => {
      return {
        ...state,
        SubscriptionSchemeParams: action.payload,
      };
    },
    changeParams: (state, action) => {
      return {
        ...state,
        pageParams: action.payload,
      };
    },
    SetCustomerPricesParams: (state, action) => {
      return {
        ...state,
        customer_pricesParams: action.payload,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    setRowData: (state, action) => {
      return {
        ...state,
        rowdata: action.payload,
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubscriptionScheme.pending, (state, action) => {
        state.status = "getSubscriptionScheme loading";
        state.SubscriptionSchemeLoading = true;
      })
      .addCase(getSubscriptionScheme.fulfilled, (state, action) => {
        console.log(action.payload.response)
        state.status = "getSubscriptionScheme succeeded";
        state.SubscriptionSchemeLoading = false;
        var response = action.payload.response;
        var list = [];
        if (state.SubscriptionSchemeParams?.page == 1) {
          list = response.results;
        } else {
          list = [...state.SubscriptionSchemelist, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.SubscriptionSchemeParams?.page_size
        );

        state.SubscriptionSchemelist = list;
        state.listCount = response.count;
        state.SubscriptionSchemeParams = {
          ...state.SubscriptionSchemeParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getSubscriptionScheme.rejected, (state, action) => {
        state.status = "getSubscriptionScheme failed";
        state.SubscriptionSchemeLoading = false;
        state.error = action.error.message;
      })
      .addCase(getSubscriptionSchemeList.pending, (state, action) => {
        state.status = "getSubscriptionSchemeList loading";
        state.loading = true;
      })
      .addCase(getSubscriptionSchemeList.fulfilled, (state, action) => {
        console.log(action.payload.response)
        state.status = "getSubscriptionSchemeList succeeded";
        state.loading = false;
        var response = action.payload.response;
        var noofpages = Math.ceil(
          response.count / state.pageParams?.page_size
        );
        state.list = response.results;
        state.listCount = response.count;
        state.pageParams = {
          ...state.pageParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getSubscriptionSchemeList.rejected, (state, action) => {
        state.status = "getSubscriptionSchemeList failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(SubscriptionSchemeAdd.pending, (state, action) => {
        state.status = "SubscriptionSchemeAdd loading";
        state.loading = true;
      })
      .addCase(SubscriptionSchemeAdd.fulfilled, (state, action) => {
        state.status = "SubscriptionSchemeAdd succeeded";
        state.loading = false
        state.model = action.payload.modal;
        state.message = action.payload.response;
        state.rowdata = {};
      }
      )
      .addCase(SubscriptionSchemeAdd.rejected, (state, action) => {
        state.status = "SubscriptionSchemeAdd failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(SubscriptionSchemeDelete.pending, (state, action) => {
        state.status = "SubscriptionSchemeDelete loading";
        state.loading = true;
      })
      .addCase(SubscriptionSchemeDelete.fulfilled, (state, action) => {
        state.status = "SubscriptionSchemeDelete succeeded";
        state.loading = false
      }
      )
      .addCase(SubscriptionSchemeDelete.rejected, (state, action) => {
        state.status = "SubscriptionSchemeDelete failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getSubscriptionSchemeByID.pending, (state, action) => {
        state.status = "getSubscriptionSchemeByID loading";
        state.loading = true;
      })
      .addCase(getSubscriptionSchemeByID.fulfilled, (state, action) => {
        state.status = "getSubscriptionSchemeByID succeeded";
        state.loading = false;
        state.rowdata = action.payload.response;
      })
      .addCase(getSubscriptionSchemeByID.rejected, (state, action) => {
        state.status = "getSubscriptionSchemeByID failed";
        state.loading = false;
        state.error = action.error.message;
      })
  }

});
export const {
  InputChangeValue,
  apiError,
  isModelVisible,
  setRowData,
  SetSubscriptionSchemeParams,
  SetCustomerPricesParams,
  changeParams
} = SubscriptionSchemeSlice.actions;

export default SubscriptionSchemeSlice.reducer;
