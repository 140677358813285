import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
	addParams,
	getList,
	getParamsList,
	postAdd,
	postEdit,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
	RiCheckboxCircleLine,
	RiAlertLine,
	RiInformationLine,
} from "react-icons/ri";

export const getCustomers = createAsyncThunk(
	"/getCustomers",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/users/CustomerCreate/`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const postCustomers = createAsyncThunk(
	"/postCustomers",
	async (payload) => {
		try {
			const {
				data,
				history,
				customerDetailsForm,
				locationDetailsForm,
				// shippingAddressForm,
				clearData,
				message,
			} = payload;
			const response = await postAdd("/users/CustomerCreate/", data);
			if (response.status === 201) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Customer Added Successfully.!
							</p>
						</div>
					),
				});
				history.push("/customers");
				customerDetailsForm.resetFields();
				locationDetailsForm.resetFields();
				// shippingAddressForm.resetFields();
				message.success("Processing complete!");
				clearData();
				return response;
			} else {
				throw response;
			}
		} catch (error) {
			if (error?.response?.data) {
				const values = Object.keys(error.response.data);
				console.log(values);
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								{error?.response?.data[values[0]][values[0]]}
							</p>
						</div>
					),
				});
			} else {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),

					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Getting from server side issue!
							</p>
						</div>
					),
				});
			}
			throw error.message;
		}
	}
);
export const editCustomers = createAsyncThunk(
	"/editCustomers",
	async (payload) => {
		try {
			const {
				id,
				data,
				history,
				customerDetailsForm,
				locationDetailsForm,
				// shippingAddressForm,
				clearData,
				message,
			} = payload;
			const response = await postEdit(
				`/users/customerdetails/${id}/`,
				data
			);
			if (response.status === 200) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Customer Edited Successfully.!
							</p>
						</div>
					),
				});
				history.push("/customers");
				customerDetailsForm.resetFields();
				locationDetailsForm.resetFields();
				// shippingAddressForm.resetFields();
				message.success("Processing complete!");
				clearData();
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error.response) {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Unable to edit Customer . Please try
								again!
							</p>
						</div>
					),
				});
			} else {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),

					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Getting from server side issue!
							</p>
						</div>
					),
				});
			}
			throw error.message;
		}
	}
);
export const getCustomerByID = createAsyncThunk(
	"/getCustomerByID",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getList(
				`/users/customerdetails/${params.id}/`
			);
			if (response) {
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
