import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../helpers/AxiosHelper";
import {
  postAdd,
  getParamsList,
  addParams,
  postEdit,
  postDelete,
} from "../../../helpers/Helper";
import { message, Modal } from "antd";
import { RiAlertLine, RiCheckboxCircleLine } from "react-icons/ri";

const seralizeParams = (payload) => {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  return params;
};

export const getMangeUsersData = createAsyncThunk(
  "users/getMangeUsersData",
  async (payload) => {
    const { params } = payload;
    try {
      const response = await getParamsList(`/users/list/`, params);
      if (response) {
        return { response };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);
export const getMangeUsersDataById = createAsyncThunk(
  "users/getMangeUsersDataById",
  async (payload, { dispatch }) => {
    const { id, onSuccess, onFailure } = payload;
    try {
      const response = await getParamsList(`/users/${id}`, {});
      if (response) {
        onSuccess(response);
        return { response };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      onFailure(error.message);
      throw error.message;
    }
  }
);

export const postMangeUsersData = createAsyncThunk(
  "users/postMangeUsersData",
  async (payload, { dispatch }) => {
    const { data, handleAdd, userDetailsForm } = payload;
    try {
      const response = await postAdd(`/users/create/`, { ...data });
      if (response.status >= 200 && response.status < 300) {
        handleAdd(response.data.id);
        userDetailsForm.resetFields();
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      console.log(error.response);
      throw error.message;
    }
  }
);
export const putUserLocation = createAsyncThunk(
  "users/putUserLocation",
  async (payload) => {
    const { id, data, history, clearData } = payload;
    try {
      const response = await postEdit(`/users/locationpermission/${id}`, { ...data });
      if (response.status >= 200 && response.status < 300) {
        // onSuccess(response.data);
        history.push(`/pages/settings/manage-users`);
        clearData();
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                User Locations Updated Successfully.!
              </p>
            </div>
          ),
        });
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      // if (error.response.data?.group_ids) {
      //   // OnFailure(error.response.data.group_ids[0]);
      // } else {
      //   // OnFailure(error.response.data.error[0]);
      // }
      // OnFailure(error.message);
      // OnFailure(error.message);
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to Added User location permission. Please try
              again!
            </p>
          </div>
        ),
      });
      throw error.message;
    }
  }
);

export const userNameValidation = createAsyncThunk(
  "users/userNameValidation",
  async (payload) => {
    const { data, onSuccess, onFailure } = payload;
    try {
      const response = await postAdd("/users/username/validation/", data);
      onSuccess(response);
    } catch (error) {
      if (error.response.status === 400) {
        onFailure(error.response.data.username[0]);
        // yield put(InputChangeValue({ key: "username", value: "" }));
      } else {
        onFailure("Sorry! Getting from server side issue!");
      }
    }
  }
);
export const userEmailValidation = createAsyncThunk(
  "users/userEmailValidation",
  async (payload) => {
    const { data, onSuccess, onFailure } = payload;
    try {
      const response = await postAdd("/users/useremail/validation/", data);
      onSuccess(response);
    } catch (error) {
      if (error.response.status === 400) {
        onFailure(error.response.data.error[0]);
      } else {
        onFailure("Sorry! Getting from server side issue!");
      }
    }
  }
);
export const userMobileValidation = createAsyncThunk(
  "users/userMobileValidation",
  async (payload) => {
    const { data, onSuccess, onFailure } = payload;
    try {
      const response = await postAdd("/users/userphone/validation/", data);
      onSuccess(response);
    } catch (error) {
      if (error.response.status === 400) {
        onFailure(error.response.data.error[0]);
      } else {
        onFailure("Sorry! Getting from server side issue!");
      }
    }
  }
);
export const userActive = createAsyncThunk("/userActive", async (payload) => {
  const { id, onSuccess, OnFailure } = payload;
  try {
    const response = await postAdd(`/users/useractive/${id}`, {});
    if (response.status >= 200 && response.status < 300) {
      onSuccess(response.data);
      return response.data;
    } else {
      throw new Error(response);
    }
  } catch (error) {
    if (error.response) {
      OnFailure(error.response.data.phone);
    } else {
      OnFailure("Sorry! Getting from server side issue!");
    }
    throw error.message;
  }
});
export const userInActive = createAsyncThunk(
  "users/userInActive",
  async (payload) => {
    const { id, onSuccess, onFailure } = payload;
    try {
      const response = await postAdd(`/users/userinactive/${id}`, {});
      if (response.status >= 200 && response.status < 300) {
        onSuccess(response.data);
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      if (error.response) {
        onFailure(error.response.data.phone);
        throw error.response.data.phone;
      } else {
        onFailure("Sorry! Getting from server side issue!");
        throw error.message;
      }
    }
  }
);
export const customerActive = createAsyncThunk("users/customerActive", async (payload) => {
  const { id, onSuccess, OnFailure } = payload;
  try {
    const response = await postAdd(`/users/admin/customer/active/${id}/`, {});
    if (response.status >= 200 && response.status < 300) {
      onSuccess(response.data);
      return response.data;
    } else {
      throw new Error(response);
    }
  } catch (error) {
    if (error.response) {
      OnFailure(error.response.data.phone);
    } else {
      OnFailure("Sorry! Getting from server side issue!");
    }
    throw error.message;
  }
});
export const customerInActive = createAsyncThunk(
  "users/customerInActive",
  async (payload) => {
    const { id, onSuccess, onFailure } = payload;
    try {
      const response = await postAdd(`/users/admin/customer/inactive/${id}/`, {});
      if (response.status >= 200 && response.status < 300) {
        onSuccess(response.data);
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      if (error.response) {
        onFailure(error.response.data.phone);
        throw error.response.data.phone;
      } else {
        onFailure("Sorry! Getting from server side issue!");
        throw error.message;
      }
    }
  }
);


// export const postMangeUsersDataById = createAsyncThunk(
//   "/postMangeUsersDataById",
//   async (payload) => {
//     try {
//       const response = await postAdd(`/masters/branch/${id}`, { ...payload });
//       if (response.status >= 200 && response.status < 300) {
//         return response.data;
//       } else {
//         throw new Error(response);
//       }
//     } catch (error) {
//       throw error.message;
//     }
//   }
// );

export const changePassword = createAsyncThunk(
  "users/changePassword",
  async (payload) => {
    const { data, onSuccess, onFailure } = payload;
    console.log(data)
    try {
      const response = await axiosInstance.put(`/users/changepassword/`, {
        ...data,
      });
      if (response.status >= 200 && response.status < 300) {
        onSuccess(response.data);
        // handleAdd(id);
        // userDetailsForm.resetFields();
        // clearData();
        // history.push(`/pages/settings/manage-users`)
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      onFailure("Sorry! Getting from server side issue!");
      throw error.message;
    }
  }
);

export const editMangeUsersDataById = createAsyncThunk(
  "users/editMangeUsersDataById",
  async (payload) => {
    const { id, data, userDetailsForm, handleAdd } = payload;
    try {
      const response = await axiosInstance.put(`/users/${id}`, {
        ...data,
      });
      if (response.status >= 200 && response.status < 300) {
        handleAdd(id);
        userDetailsForm.resetFields();
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);
export const deleteMangeUsersDataById = createAsyncThunk(
  "users/deleteMangeUsersDataById",
  async (payload) => {
    const { url } = payload;
    try {
      const response = await postDelete(url);
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);

export const getUserStates = createAsyncThunk(
  "users/getUserStates",
  async (payload, { getState, dispatch, rejectWithValue }) => {

    var params = seralizeParams(payload);
    try {
      const firstResponse = await getParamsList(
        "/masters/states/mini/",
        params
      );

      if (firstResponse.results) {
        let page = params.page + 1;
        const page_size = 10;
        var noofpages = Math.ceil(
          firstResponse.count / page_size
        );

        let returningValues = {
          statesList: firstResponse.results,
          count: firstResponse.count
        };
        while (page <= noofpages) {
          const response = await getParamsList(
            "/masters/states/mini/",
            {
              ...params,
              page,
              page_size
            }
          );
          returningValues = {
            statesList: [...returningValues.statesList, ...response.results],
            count: response.count
          };
          page++
        }
        // if (response.results) {
        const manageUserState = getState().manageUsers;
        var statesList = returningValues?.statesList?.map((userState) => {
          if (manageUserState.userSelectedStatesList.indexOf(userState.id) !== -1) {
            userState.state_checked = true;
          } else {
            userState.state_checked = false;
          }
          return userState;
        });
        const configHome = statesList
          .filter((z) => z.state_checked)
          .map((userState) => {
            return dispatch(getUserZones({ state: userState.id, page: 1, page_size: 10 }));
          });

        const promise = await Promise.all(configHome);
        const promisePayload = promise.map(zone => zone.payload);
        const newStatesList = statesList.map(e => {
          return {
            ...e,
            zonesCount: promisePayload.find(zone => zone.state_id == e.id)?.count,
            zones: promisePayload.find(zone => zone.state_id == e.id)?.zoneList,
          }
        });
        returningValues = {
          statesList: newStatesList,
          count: firstResponse.count
        };
        // }

        return returningValues

      } else {
        throw new Error(firstResponse);
      }
    } catch (error) {
      console.log(error)
      return rejectWithValue(error)
    }
  }
);
export const getUserZones = createAsyncThunk(
  "users/getUserZones",
  async (payload, { getState, dispatch }) => {
    var params = seralizeParams(payload);
    try {

      const firstResponse = await getParamsList(
        "/masters/zone/mini/",
        params
      );

      if (firstResponse.results) {

        let page = params.page + 1;
        const page_size = 10;
        var noofpages = Math.ceil(
          firstResponse.count / page_size
        );

        let returningValues = {
          zoneList: firstResponse.results,
          state_id: "",
          count: firstResponse.count
        };
        while (page <= noofpages) {
          const response = await getParamsList(
            "/masters/zone/mini/",
            {
              ...params,
              page,
              page_size
            }
          );
          returningValues = {
            zoneList: [...returningValues.zoneList, ...response.results],
            state_id: params.state,
            count: response.count
          };
          page++
        }


        const manageUserState = getState().manageUsers;
        var zoneList = returningValues?.zoneList?.map((zone) => {
          if (
            manageUserState.userSelectedZonesList.indexOf(zone.id) !== -1
          ) {
            zone.zone_checked = true;
          } else {
            zone.zone_checked = false;
          }
          zone.state_id = payload.state;
          return zone;
        });
        const configHome = zoneList
          .filter((d) => d.zone_checked)
          .map((zone) => {
            return dispatch(getUserCities({ zone: zone.id, state: payload.state, page: 1, page_size: 10 }));
          });
        const promise = await Promise.all(configHome);
        const promisePayload = promise.map(city => city.payload);

        let newZoneList = zoneList.map(e => {
          return {
            ...e,
            citiesCount: promisePayload.find(city => (city.state_id == e.state_id) && (city.zone_id == e.id))?.count,
            cities: promisePayload.find(city => (city.state_id == e.state_id) && (city.zone_id == e.id))?.citiesList
          }
        })
        returningValues = {
          zoneList: newZoneList,
          state_id: payload.state,
          count: firstResponse.count
        }
        return returningValues;
      } else {
        throw new Error(firstResponse);
      }
    } catch (error) {
      return error.message;
    }
  }
);
export const getUserCities = createAsyncThunk(
  "users/getUserCities",
  async (payload, { getState, dispatch }) => {
    var params = seralizeParams(payload);
    try {

      const firstResponse = await getParamsList(
        "/masters/citys/mini/",
        params
      );
      if (firstResponse.results) {

        let page = params.page + 1;
        const page_size = 10;
        var noofpages = Math.ceil(
          firstResponse.count / page_size
        );

        let returningValues = {
          citiesList: firstResponse.results,
          state_id: params.state,
          zone_id: params.zone,
          count: firstResponse.count
        };
        while (page <= noofpages) {
          const response = await getParamsList(
            "/masters/citys/mini/",
            {
              ...params,
              page,
              page_size
            }
          );
          returningValues = {
            ...returningValues,
            citiesList: [...returningValues.citiesList, ...response.results],
          };
          page++
        }

        const manageUserState = getState().manageUsers;
        var citiesList = returningValues.citiesList.map((city) => {
          if (
            manageUserState.userSelectedCityList.indexOf(city.id) !== -1
          ) {
            city.city_checked = true;
          } else {
            city.city_checked = false;
          }
          city.state_id = payload.state;
          city.zone_id = payload.zone;
          return city;
        });
        const configHome = citiesList
          .filter((d) => d.city_checked)
          .map((city) => {
            return dispatch(getUserAreas({ city: city.id, state: payload.state, zone: payload.zone, page: 1, page_size: 10 }));
          });
        const promise = await Promise.all(configHome);
        const promisePayload = promise.map(area => area.payload);

        const newCities = citiesList?.map(e => {
          return {
            ...e,
            areasCount: promisePayload.find(area => (area.state_id == e.state_id) && (area.zone_id == e.zone_id) && (area.city_id == e.id))?.
              count,
            areas: promisePayload.find(area => (area.state_id == e.state_id) && (area.zone_id == e.zone_id) && (area.city_id == e.id))?.
              areasList
          }
        });
        returningValues = {
          ...returningValues,
          citiesList: newCities,
        };

        return returningValues;
      } else {
        throw new Error(firstResponse);
      }
    } catch (error) {
      return error.message;
    }
  }
);
export const getUserAreas = createAsyncThunk(
  "users/getUserAreas",
  async (payload, { getState, dispatch }) => {
    var params = seralizeParams(payload);
    try {

      const firstResponse = await getParamsList(
        "/masters/area/mini/",
        params
      );
      if (firstResponse.results) {

        let page = params.page + 1;
        const page_size = 10;
        var noofpages = Math.ceil(
          firstResponse.count / page_size
        );

        let returningValues = {
          areasList: firstResponse.results,
          state_id: params.state,
          zone_id: params.zone,
          city_id: params.city,
          count: firstResponse.count
        };
        while (page <= noofpages) {
          const response = await getParamsList(
            "/masters/area/mini/",
            {
              ...params,
              page,
              page_size
            }
          );
          returningValues = {
            ...returningValues,
            areasList: [...returningValues.areasList, ...response.results],
          };
          page++
        }


        const manageUserState = getState().manageUsers;
        var areasList = returningValues.areasList.map((area) => {
          if (
            manageUserState.userSelectedAreaList.indexOf(area.id) !== -1
          ) {
            area.area_checked = true;
          } else {
            area.area_checked = false;
          }
          area.state_id = payload.state;
          area.zone_id = payload.zone;
          area.city_id = payload.city;
          return area;
        });
        const configHome = areasList
          .filter((d) => d.area_checked)
          .map((area) => {
            return dispatch(getUserLocation({ area: area.id, state: payload.state, zone: payload.zone, city: payload.city, page: 1, page_size: 10 }));
          });
        const promise = await Promise.all(configHome);
        const promisePayload = promise.map(area => area.payload);

        const newAreaList = areasList.map(e => {
          return {
            ...e,
            locationsCount: promisePayload.find(location => (location.state_id == e.state_id) && (location.zone_id == e.zone_id) && (location.city_id == e.city_id) && location.area_id == e.id)?.count,
            locations: promisePayload.find(location => (location.state_id == e.state_id) && (location.zone_id == e.zone_id) && (location.city_id == e.city_id) && location.area_id == e.id)?.locationList
          }
        })

        returningValues = {
          ...returningValues,
          areasList: newAreaList,
        };

        console.log("returningValues.........", returningValues);

        return returningValues;
      } else {
        throw new Error(firstResponse);
      }
    } catch (error) {
      return error.message;
    }
  }
);
export const getUserLocation = createAsyncThunk(
  "users/getUserLocation",
  async (payload, { getState, dispatch }) => {
    var params = seralizeParams(payload);
    try {

      const firstResponse = await getParamsList(
        "/masters/location/mini/",
        params
      );
      if (firstResponse.results) {

        let page = params.page + 1;
        const page_size = 10;
        var noofpages = Math.ceil(
          firstResponse.count / page_size
        );

        let returningValues = {
          locationList: firstResponse.results,
          state_id: params.state,
          zone_id: params.zone,
          city_id: params.city,
          area_id: params.area,
          count: firstResponse.count
        };
        while (page <= noofpages) {
          const response = await getParamsList(
            "/masters/location/mini/",
            {
              ...params,
              page,
              page_size
            }
          );
          returningValues = {
            ...returningValues,
            locationList: [...returningValues.locationList, ...response.results],
          };
          page++
        }

        const manageUserState = getState().manageUsers;
        var locationList = returningValues.locationList.map((location) => {
          if (
            manageUserState.userSelectedLocationList.indexOf(location.id) !== -1
          ) {
            location.location_checked = true;
          } else {
            location.location_checked = false;
          }
          location.state_id = payload.state;
          location.zone_id = payload.zone;
          location.city_id = payload.city;
          location.area_id = payload.area;
          return location;
        });

        returningValues = {
          ...returningValues,
          locationList: locationList,
        };

        return returningValues;
      } else {
        throw new Error(firstResponse);
      }
    } catch (error) {
      return error.message;
    }
  }
);





export const getUserLoginList = createAsyncThunk("users/getUserLoginList",
  async (payload) => {
    const { params } = payload;
    // for (const k in payload.params) {
    //   if (Object.hasOwnProperty.call(payload.params, k)) {
    //     if (k === "type") {
    //       params.type = payload.params.type.join(",");
    //     } else {
    //       if (payload.params[k] !== "" && k !== "no_of_pages") {
    //         params[k] = payload.params[k];
    //       }
    //     }
    //   }
    // }
    try {
      const response = await getParamsList("/users/devicelog/user/" + payload.username + "/" + payload.user_type, params);
      if (response) {
        return { response };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);


export const getUserDeviceList = createAsyncThunk("users/getUserDeviceList", async (payload) => {
  const { params } = payload;
  // var params = {};
  // console.log("params",params)
  // for (const k in payload.params) {
  //   if (Object.hasOwnProperty.call(payload.params, k)) {
  //     if (k === "type") {
  //       params.type = payload.params.type.join(",");
  //     } else {
  //       if (payload.params[k] !== "" && k !== "no_of_pages") {
  //         params[k] = payload.params[k];
  //       }
  //     }
  //   }
  // }
  // console.log(params)
  console.log("payloadddddd", payload)
  try {
    const response = await getParamsList("/users/userdevices/user/" + payload.username + "/" + payload.user_type, params);
    if (response) {
      return { response };
    } else {
      throw new Error(response);
    }
  } catch (error) {
    throw error.message;
  }
}
);


export const getUserActivityList = createAsyncThunk("users/getUserActivityList",
  async (payload) => {
    var data = payload;
    var params = {};
    for (const k in data) {
      if (Object.hasOwnProperty.call(data, k)) {
        if (k === "currentSort" || k === "sortOrder") {
          params.ordering = data.sortOrder + data.currentSort;
        } else {
          if (data[k] != "" && k != null && k != "no_of_pages") {
            params[k] = data[k];
          }
        }
      }
    }
    try {
      const response = await getParamsList("/system/activitylog/user/" + payload.id, params);
      if (response) {
        return { response };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);