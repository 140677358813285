import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
	addParams,
	getList,
	getParamsList,
	postAdd,
	postEdit,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
	RiCheckboxCircleLine,
	RiAlertLine,
	RiInformationLine,
} from "react-icons/ri";

export const getCustomerOrders = createAsyncThunk(
	"/getCustomerOrders",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/b2c/admin/customer_orders/`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

export const postCustomerOrders = createAsyncThunk(
	"/postCustomerOrders",
	async (payload) => {
		try {
			const { data, history, forms } = payload;
			const response = await postAdd("/b2c/admin/customer_orders/", data);
			if (response.status === 201) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Customer Order Added Successfully.!
							</p>
						</div>
					),
				});
				history.push("/customer_orders");
				forms.form.resetFields();
				forms.RemarksForm.resetFields();
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error.response) {
				const errorValues = error.response.data;
				if (
					errorValues["Insufficient Balance"].length &&
					errorValues["Required balance"].length &&
					errorValues["wallet balance"].length
				) {
					Modal.error({
						icon: (
							<span className="remix-icon">
								<RiAlertLine />
							</span>
						),
						title: (
							<h5 className="hp-mb-0 hp-font-weight-500">
								Insufficient Balance
							</h5>
						),
						content: (
							<div>
								<p className="hp-p1-body hp-text-color-black-80">
									{errorValues["wallet balance"][0]}
								</p>
								<p className="hp-p1-body hp-text-color-black-80">
									{errorValues["Required balance"][0]}
								</p>
								<p className="hp-p1-body hp-text-color-black-80">
									{errorValues["Insufficient Balance"][0]}
								</p>
							</div>
						),
					});
				} else {
					const errorMsg = errorValues.find((e) => e);
					Modal.error({
						icon: (
							<span className="remix-icon">
								<RiAlertLine />
							</span>
						),
						title: (
							<h5 className="hp-mb-0 hp-font-weight-500">
								Error
							</h5>
						),
						content: (
							<div>
								<p className="hp-p1-body hp-text-color-black-80">
									{errorMsg
										? `${errorMsg}`
										: "Sorry! Unable to add Customer Order. Please try again!"}
								</p>
							</div>
						),
					});
				}
			} else {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),

					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Getting from server side issue!
							</p>
						</div>
					),
				});
			}
			throw error.message;
		}
	}
);
export const editCustomerOrders = createAsyncThunk(
	"/editCustomerOrders",
	async (payload) => {
		try {
			const { id, data, history, forms = {} } = payload;
			const response = await postEdit(
				`/b2c/admin/order_details/${id}`,
				data
			);
			if (response.status === 200) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Customer Order Edited Successfully.!
							</p>
						</div>
					),
				});
				history.push("/customer_orders");
				forms.form.resetFields();
				forms.RemarksForm.resetFields();
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error.response) {
				const errorValues = error.response.data;
				console.log(errorValues);
				if (
					errorValues["Insufficient Balance"].length &&
					errorValues["Required balance"].length &&
					errorValues["wallet balance"].length
				) {
					Modal.error({
						icon: (
							<span className="remix-icon">
								<RiAlertLine />
							</span>
						),
						title: (
							<h5 className="hp-mb-0 hp-font-weight-500">
								Insufficient Balance
							</h5>
						),
						content: (
							<div>
								<p className="hp-p1-body hp-text-color-black-80">
									{errorValues["wallet balance"][0]}
								</p>
								<p className="hp-p1-body hp-text-color-black-80">
									{errorValues["Required balance"][0]}
								</p>
								<p className="hp-p1-body hp-text-color-black-80">
									{errorValues["Insufficient Balance"][0]}
								</p>
							</div>
						),
					});
				} else {
					const errorMsg = errorValues.find((e) => e);
					Modal.error({
						icon: (
							<span className="remix-icon">
								<RiAlertLine />
							</span>
						),
						title: (
							<h5 className="hp-mb-0 hp-font-weight-500">
								Error
							</h5>
						),
						content: (
							<div>
								<p className="hp-p1-body hp-text-color-black-80">
									{errorMsg
										? `${errorMsg}`
										: "Sorry! Unable to add Customer Order. Please try again!"}
								</p>
							</div>
						),
					});
				}
			} else {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),

					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Getting from server side issue!
							</p>
						</div>
					),
				});
			}
			throw error.message;
		}
	}
);
export const getCustomerOrderByID = createAsyncThunk(
	"/getCustomerOrderByID",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getList(
				`/b2c/admin/order_details/${params.id}`
			);
			if (response) {
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

export const editCustomerOrderStatusById = createAsyncThunk(
	"/editCustomerOrderStatusById",
	async (payload, { dispatch }) => {
		const { id, data, pageParams, setEditStatus } = payload;
		try {
			const response = await postEdit(`/b2c/admin/order_status/${id}`, {
				...data,
			});
			if (response) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Customer Order Status Updated Successfully.!
							</p>
						</div>
					),
				});
				dispatch(getCustomerOrders({ ...pageParams }));
				setEditStatus("");
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			Modal.error({
				icon: (
					<span className="remix-icon">
						<RiAlertLine />
					</span>
				),

				title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
				content: (
					<div>
						<p className="hp-p1-body hp-text-color-black-80">
							Sorry! Getting from server side issue!
						</p>
					</div>
				),
			});
			return error.message;
		}
	}
);
