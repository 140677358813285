import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { getParamsList } from "../../../helpers/Helper";

export const getProductsList = createAsyncThunk(
	"/getProductsList",
	async (payload) => {
		var data = payload;
		var params = {};

		for (const k in data) {
			if (Object.hasOwnProperty.call(data, k)) {
				if (k === "currentSort" || k === "sortOrder") {
					params.ordering = data.sortOrder + data.currentSort;
				} else {
					if (data[k] !== "" && k !== null && k != "no_of_pages") {
						if (k == "start_date") {
							params[k] = moment(data[k]).format("YYYY-MM-DD");
						} else if (k == "end_date") {
							params[k] = moment(data[k]).format("YYYY-MM-DD");
						} else {
							params[k] = data[k];
						}
					}
				}
			}
		}
		try {
			const response = await getParamsList(
				`/attendance/daily_attendance/`,
				params
			);
			if (response) {
				return { response };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
