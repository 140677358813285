import { createSlice } from "@reduxjs/toolkit";
import {
	getReceiptByID,
	getReceiptItemsByOrderID,
	getReceipts,
	postReceipts,
} from "./receipt.action";
import { v4 as uuidv4 } from "uuid";

const receiptSlice = createSlice({
	name: "receipt",
	initialState: {
		loading: false,
		error: "",
		status: "",
		list: [],
		selectedData: {},
		count: 0,
		filterParams: {},
		productsList: [],
		productListparams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
		pageParams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
	},
	reducers: {
		changeParams: (state, action) => {
			return {
				...state,
				pageParams: action.payload,
			};
		},
		changeProductListParams: (state, action) => {
			return {
				...state,
				productListparams: action.payload,
			};
		},
		isDrawerVisible: (state, action) => {
			return {
				...state,
				drawer: action.payload,
			};
		},
		FilterInputChangeValue: (state, action) => {
			return {
				...state,
				filterParams: {
					...state.filterParams,
					[action.payload.key]: action.payload.value,
				},
			};
		},
		changeFilterParams: (state, action) => {
			return {
				...state,
				filterParams: action.payload,
			};
		},

		setSelectedData: (state, action) => {
			return {
				...state,
				selectedData: action.payload,
			};
		},
		inputChangeValue: (state, action) => {
			return {
				...state,
				selectedData: {
					...state.selectedData,
					...action.payload,
				},
			};
		},
		addProductsToList: (state, action) => {
			return {
				...state,
				productsList: [...state.productsList, action.payload],
			};
		},
		removeProductsFromList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => {
					return e.uniqueId == action.payload.product_id
						? {
								...e,
								dodelete: true,
						  }
						: {
								...e,
						  };
				}),
			};
		},
		changeProductList: (state, action) => {
			console.log(action.payload);
			return {
				...state,
				productsList: state.productsList.map((e) => {
					if (e.uniqueId == action.payload.product_id) {
						if (action.payload.key == "received_qty") {
							const calculatedPrice =
								parseFloat(action.payload.value) *
								parseFloat(e.listedPrice) *
								parseFloat(e.unit.units);
							const discountAmt = e.discount_percent
								? +calculatedPrice * (+e.discount_percent / 100)
								: 0;
							var taxableValue = calculatedPrice - discountAmt;
							// var gstAmt = e.gst_percentage
							// 	? parseFloat(taxableValue) *
							// 	  (+e.gst_percentage / 100)
							// 	: 0;
							// var totalPrice =
							// 	parseFloat(taxableValue) + parseFloat(gstAmt);
							var totalPrice = parseFloat(taxableValue);

							// var amt_before_tax;
							// const tax_type = e?.tax_type;
							// const tax_rate = parseFloat(e.tax_percent) / 100;

							// switch (tax_type) {
							// 	case 1:
							// 		//inclusive
							// 		console.log("inclusive");
							// 		amt_before_tax =
							// 			parseFloat(taxableValue) /
							// 			(1 + tax_rate);
							// 		gstAmt = calculatedPrice - amt_before_tax;
							// 		totalPrice = taxableValue;

							// 		gstAmt = parseFloat(gstAmt).toFixed(2);
							// 		taxableValue =
							// 			parseFloat(amt_before_tax).toFixed(2);
							// 		totalPrice =
							// 			parseFloat(totalPrice).toFixed(2);
							// 		break;
							// 	case 2:
							// 		//exclusive
							// 		// taxableValue = calculatedPrice;
							// 		// tax_amount = taxableValue * tax_rate
							// 		// amt_after_tax = tax_amount + taxableValue;
							// 		// totalPrice = amt_after_tax - obj.discountAmt

							// 		// obj.gstAmt = parseFloat(tax_amount).toFixed(2);
							// 		// obj.taxableValue =
							// 		// 	parseFloat(taxableValue).toFixed(2);
							// 		// obj.price = parseFloat(totalPrice).toFixed(2);
							// 		// obj.gstAmt = parseFloat(tax_amount).toFixed(2);
							// 		break;
							// }
							return {
								...e,
								[action.payload.key]: action.payload.value,
								discountAmt: discountAmt.toFixed(2),
								price: totalPrice.toFixed(2),
								// gstAmt: gstAmt.toFixed(2),
								// taxableValue: taxableValue.toFixed(2),
								originalPrice: calculatedPrice.toFixed(2),
							};
						} else {
							return {
								...e,
								[action.payload.key]: action.payload.value,
							};
						}
					} else {
						return e;
					}
				}),
			};
		},
		clearData: (state, action) => {
			return {
				...state,
				loading: false,
				error: "",
				status: "",
				list: [],
				selectedData: {},
				count: 0,
				filterParams: {},
				productsList: [],
				productListparams: {
					start_date: "",
					end_date: "",
					no_of_pages: 0,
					page_size: 10,
					page: 1,
					search: "",
				},
				pageParams: {
					start_date: "",
					end_date: "",
					no_of_pages: 0,
					page_size: 10,
					page: 1,
					search: "",
				},
			};
		},
		clearProductsList: (state, action) => {
			return {
				...state,
				productsList: state.productsList.map((e) => ({
					...e,
					dodelete: true,
				})),
			};
		},
	},
	extraReducers: (builder) => {
		builder
			//get Data
			.addCase(getReceipts.pending, (state, action) => {
				state.status = "getReceipts loading";
				state.loading = true;
			})
			.addCase(getReceipts.fulfilled, (state, action) => {
				state.status = "getReceipts succeeded";
				state.loading = false;
				var noofpages = Math.ceil(
					action.payload.count / state.pageParams.page_size
				);
				state.count = action.payload.count;
				state.list = action.payload.results;
				state.pageParams = {
					...state.pageParams,
					no_of_pages: noofpages,
				};
			})
			.addCase(getReceipts.rejected, (state, action) => {
				state.status = "getReceipts failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//get Data by Id
			.addCase(getReceiptByID.pending, (state, action) => {
				state.status = "getReceiptByID loading";
				state.loading = true;
			})
			.addCase(getReceiptByID.fulfilled, (state, action) => {
				state.status = "getReceiptByID succeeded";
				state.loading = false;
				state.selectedData = action.payload;
				state.productsList =
					action.payload.distributor_receipt_items.map((e) => {
						const calculatedPrice =
							parseFloat(e.received_qty) *
							parseFloat(e?.order_item?.d_price) *
							parseFloat(e?.order_item?.unit?.units);
						const discountAmt =
							parseFloat(calculatedPrice) *
							(parseFloat(e?.order_item?.d_discount_percent) /
								100);
						const taxableValue = calculatedPrice - discountAmt;
						// const gstAmt =
						// 	parseFloat(taxableValue) *
						// 	(parseFloat(e?.order_item?.d_tax_percent) / 100);
						// const totalPrice =
						// 	parseFloat(taxableValue) + parseFloat(gstAmt);
						const totalPrice = parseFloat(taxableValue);

						return {
							...e,
							item_id: e.id,
							product_id: e.order_item?.product.id,
							image: e?.order_item?.product.image,
							name: e?.order_item?.product.name,
							listedPrice: parseFloat(
								e?.order_item?.d_price
							).toFixed(2),
							originalPrice:
								parseFloat(calculatedPrice).toFixed(2),
							discountAmt: parseFloat(discountAmt).toFixed(2),
							// gstAmt: parseFloat(gstAmt).toFixed(2),
							// taxableValue: parseFloat(taxableValue).toFixed(2),
							price: parseFloat(totalPrice).toFixed(2),
							size: e?.order_item?.unit?.id,
							// gst_percentage: e?.order_item?.d_tax_percent,
							discount_percent: e?.order_item?.d_discount_percent,
							quantity: e?.order_item?.quantity,
							received_qty: e.received_qty ? e.received_qty : "",
							unit: e?.order_item?.unit,
							uniqueId: uuidv4(),
						};
					});
			})
			.addCase(getReceiptByID.rejected, (state, action) => {
				state.status = "getReceiptByID failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//post data
			.addCase(postReceipts.pending, (state, action) => {
				state.status = "postReceipts loading";
				state.loading = true;
			})
			.addCase(postReceipts.fulfilled, (state, action) => {
				state.status = "postReceipts succeeded";
				state.loading = false;
				state.selectedData = {};
			})
			.addCase(postReceipts.rejected, (state, action) => {
				state.status = "postReceipts failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//getReceiptItemByOrderID
			.addCase(getReceiptItemsByOrderID.pending, (state, action) => {
				state.status = "getReceiptItemByOrderID loading";
				state.loading = true;
			})
			.addCase(getReceiptItemsByOrderID.fulfilled, (state, action) => {
				state.status = "getReceiptItemByOrderID succeeded";
				state.loading = false;
				state.productsList = action.payload.results.map((e) => {
					const calculatedPrice =
						parseFloat(e.quantity) *
						parseFloat(e.d_price) *
						parseFloat(e?.unit?.units);
					const discountAmt =
						parseFloat(calculatedPrice) *
						(+e.d_discount_percent / 100);
					var taxableValue = calculatedPrice - discountAmt;
					var gstAmt =
						parseFloat(taxableValue) * (+e.d_tax_percent / 100);
					var totalPrice =
						parseFloat(taxableValue) + parseFloat(gstAmt);

					const obj = {
						...e,
						item_id: e.id,
						product_id: e.product.id,
						image: e.product.image,
						name: e.product.name,
						listedPrice: parseFloat(e.d_price).toFixed(2),
						originalPrice: parseFloat(calculatedPrice).toFixed(2),
						discountAmt: parseFloat(discountAmt).toFixed(2),
						gstAmt: parseFloat(gstAmt).toFixed(2),
						taxableValue: parseFloat(taxableValue).toFixed(2),
						price: parseFloat(totalPrice).toFixed(2),
						size: e?.unit?.id,
						gst_percentage: e?.d_tax_percent,
						discount_percent: e?.d_discount_percent,
						quantity: e?.quantity,
						received_qty: e?.received_qty ? e?.received_qty : "",
						unit: e?.unit,
						uniqueId: uuidv4(),
					};

					var amt_before_tax;
					const tax_type = e?.tax_type;
					const tax_rate = parseFloat(e.tax_percent) / 100;

					switch (tax_type) {
						case 1:
							//inclusive
							console.log("inclusive");
							amt_before_tax =
								parseFloat(taxableValue) / (1 + tax_rate);
							gstAmt = calculatedPrice - amt_before_tax;
							totalPrice = taxableValue;

							obj.gstAmt = parseFloat(gstAmt).toFixed(2);
							obj.taxableValue =
								parseFloat(amt_before_tax).toFixed(2);
							obj.price = parseFloat(totalPrice).toFixed(2);
							break;
						case 2:
							//exclusive
							// taxableValue = calculatedPrice;
							// tax_amount = taxableValue * tax_rate
							// amt_after_tax = tax_amount + taxableValue;
							// totalPrice = amt_after_tax - obj.discountAmt

							// obj.gstAmt = parseFloat(tax_amount).toFixed(2);
							// obj.taxableValue =
							// 	parseFloat(taxableValue).toFixed(2);
							// obj.price = parseFloat(totalPrice).toFixed(2);
							// obj.gstAmt = parseFloat(tax_amount).toFixed(2);
							break;
					}

					return { ...obj };
				});
			})
			.addCase(getReceiptItemsByOrderID.rejected, (state, action) => {
				state.status = "getReceiptItemByOrderID failed";
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

// Action creators are generated for each case reducer function
export const {
	changeParams,
	clearData,
	setSelectedData,
	inputChangeValue,
	addProductsToList,
	removeProductsFromList,
	changeProductList,
	isDrawerVisible,
	FilterInputChangeValue,
	changeFilterParams,
	changeProductListParams,
	clearProductsList,
} = receiptSlice.actions;

export default receiptSlice.reducer;
