import React from "react";
import Chart from "react-apexcharts";
import { Card, Row, Col, Typography, Skeleton } from "antd";
import { color } from "highcharts";
import { useSelector } from "react-redux";
const { Text } = Typography;

function DistributorOrderCard(props) {
  const { data } = props;
  const distOrderLoading = useSelector(state => state.dashboard.distOrderLoading)

  const options = {
    chart: {
      id: 'earnings-donut-card',
      fontFamily: 'Manrope, sans-serif',
      type: 'donut',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: data?.map((d) => d.color),
    labels: data?.map((d) => d.title + " : " + d.total),
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '110%',  // Increased the overall size of the donut
          labels: {
            show: true,
            name: {
              fontSize: '1rem',
              formatter: function (val) {
                if (val.length > 10) {
                  return val.substring(0, 10) + '...';  // Truncate labels longer than 10 characters
                }
                return val;
              }
            },
            value: {
              fontSize: "20px",
              fontWeight: "regular",
              color: "B2BEC3",
              formatter(val) {
                return val;
              },
            },
            total: {
              show: true,
              fontSize: '14px',
              fontWeight: 'regular',
              color: '#636E72',
              label: 'Total',
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 426,
        options: {
          chart: {
            width: '100%',
          },
          legend: {
            itemMargin: {
              horizontal: 8,
              vertical: 4,
            },
          },
        },
      },
    ],
    legend: {
      itemMargin: {
        horizontal: 2,
        vertical: 5,
      },
      horizontalAlign: 'center',
      position: 'left',
      fontSize: '14px',
      inverseOrder: true,
      markers: {
        radius: 12,
      },
    },
  };


  const series = data?.map((d) => d.total) || [];

  return (
    <Card
      bodyStyle={{ padding: "10px", height: "10em" }}
      className="hp-border-color-black-40 hp-card-6 hp-p-0 hp-border-radius-xl"
    >
      {distOrderLoading ? <Skeleton active loading={distOrderLoading} avatar /> :
        <Row gutter={[12, 0]} >
          {/* <Col span={12}>
          <Text className="h6 hp-d-block" strong>
            {props.title}
          </Text>
          
          <Text className="h4 " strong>
          ₹{data.reduce((acc, d) => acc + d.total, 0)}
          </Text>
        </Col> */}

          <Col span={24} className="hp-p-0">
            <Text className="h6 hp-d-block hp-pl-10" strong>
              {props.title}
            </Text>
            <div id="earnings-donut-card" className="hp-donut-chart">
              <Chart
                options={options}
                series={series}
                type="donut"
                height={"60%"}
              />
            </div>
          </Col>

        </Row>}
    </Card>
  );
}

export default DistributorOrderCard;