import React, { useEffect, useState } from "react";
import { Card, Row, Col, Input, Button, DatePicker, Spin, Tabs } from "antd";
import { connect } from "react-redux";
import PageTitle from "../../../layout/components/content/page-title";
import {
  isDrawerVisible,
} from "../../../store/dashboard/dashboardSlice";

import {
  ProjectOutlined,
} from "@ant-design/icons";
import { getB2BRetailerIndents, getDistributorOrdersList } from "../../../store/dashboard/dashboardAction";
import { useDispatch } from "react-redux";
import { Selector, Dispatch } from "../../../store/index";
import RevenueCard from "./revenueCard";
import IndentsCard from "./indentsCard";
import DistributorOrderCard from "./distributorOrderCard";
import DistributorOrderList from "./distributorOrderList";



function B2BDashboard(props) {
  const { TabPane } = Tabs;
  const { Search } = Input;
  const dispatch = useDispatch();

  const totalRevenue = Selector("dashboard", "totalRevenue");
  const retailerIndent = Selector("dashboard", "retailerIndent");
  const distributorIndent = Selector("dashboard", "distributorIndent");
  const distributorReceipt = Selector("dashboard", "distributorReceipt");
  const distributorOrder = Selector("dashboard", "distributorOrder");




  useEffect(() => {
    dispatch(getB2BRetailerIndents());
  }, [])

  return (
    <>
      <Row gutter={[12, 12]} className="hp-ecommerce-app-header hp-mb-10">
        <Col span={24} xs={18} sm={18} md={18}>
          <PageTitle pageTitle="Dashboard" goBack={false} />
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={16} xs={24} sm={12} md={12} lg={8} xl={8}  >
          <IndentsCard title="Retailer Indents" data={retailerIndent} icon={<ProjectOutlined />} />
        </Col>
        <Col span={16} xs={24} sm={12} md={12} lg={8} xl={8} >
          <IndentsCard title="Distributor Indents" data={distributorIndent} icon={<ProjectOutlined />} />
        </Col>
        <Col span={16} xs={24} sm={12} md={12} lg={8} xl={8} >
          <IndentsCard title="Distributor Receipts" data={distributorReceipt} icon={<ProjectOutlined />} />
        </Col>

        <Col span={16} xs={24} sm={12} md={12} lg={8} xl={8} >
          <DistributorOrderCard title="Distributor Orders" data={distributorOrder} />
        </Col>
        <Col span={16} xs={24} sm={12} md={12} lg={8} xl={8}>
          <RevenueCard title="Revenue" total={totalRevenue} />
        </Col>

      </Row>

      <Row gutter={[12, 12]} className="hp-mt-10">
        <Col span={16} xs={24} sm={24} md={24} xl={24}>
          <DistributorOrderList />
        </Col>
      </Row>
    </>
  );
}

function mapStateToProps({ dashboard, system }) {
  return {
    loading: dashboard.loading,
  };
}

export default connect(mapStateToProps, {
  isDrawerVisible,
})(B2BDashboard);
