import { createAsyncThunk } from "@reduxjs/toolkit";
import { getList, postEdit, getParamsList } from "../../helpers/Helper";
import { RiCheckboxCircleLine, RiAlertLine } from "react-icons/ri";
import { Modal } from "antd";

export const getUser = createAsyncThunk("/getUser", async (payload) => {
  try {
    const response = await getList(`/users/iamuser/`, {});
    return response.data;
  } catch (err) {
    return err;
  }
});
export const ChangePasswordSubmit = createAsyncThunk(
  "/ChangePasswordSubmit",
  async (payload) => {
    try {
      const response = await postEdit(`/users/changepassword/`, payload.data);
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Password Changed Successfully.!
              </p>
            </div>
          ),
        });
        payload.history.push("/login");
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to change password. Please try again!
              </p>
            </div>
          ),
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var msg = "";
        if (error.response.data.old_password) {
          // msg = "Your old password is incorrect"
          msg = error.response.data.old_password.join(", ");
        } else if (error.response.data.Message) {
          msg = error.response.data.Message[0];
        } else {
          msg = "Please check your input";
        }
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">{msg}</p>
            </div>
          ),
        });
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),

          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Getting from server side issue!
              </p>
            </div>
          ),
        });
      }
      return error;
    }
  }
);
export const getActivityLog = createAsyncThunk(
  "/getActivityLog",
  async (payload) => {
    var data = payload;
    var params = {};
    for (const k in data) {
      if (Object.hasOwnProperty.call(data, k)) {
        if (k === "currentSort" || k === "sortOrder") {
          params.ordering = data.sortOrder + data.currentSort;
        } else {
          if (data[k] !== "" && k !== "no_of_pages") {
            params[k] = data[k];
          }
        }
      }
    }
    try {
      const response = await getParamsList(`/users/devicelog/me/`, params);
      return { response };
    } catch (error) {
      return error;
    }
  }
);
export const getUserDeviceList = createAsyncThunk(
  "/getUserDeviceList",
  async (payload) => {
    var data = payload;
    var params = {};
    for (const k in data) {
      if (Object.hasOwnProperty.call(data, k)) {
        if (k === "currentSort" || k === "sortOrder") {
          params.ordering = data.sortOrder + data.currentSort;
        } else {
          if (data[k] !== "" && k !== "no_of_pages") {
            params[k] = data[k];
          }
        }
      }
    }
    // const { params } = payload;
    try {
      const response = await getParamsList(`/users/userdevices/me`, params);
      return { response };
    } catch (error) {
      return error;
    }
  }
);
