import { createSlice } from "@reduxjs/toolkit";
import {
	editTicketManagement,
	// editTicketManagementput,
	getTicketManagementByID,
	getTicketManagements,
	// getTicketManagementsAddress,
	postTicketManagement,
	// postTicketManagementsAddress,
} from "./ticketManagement.action";

const ticketManagementSlice = createSlice({
	name: "ticketManagement",
	initialState: {
		loading: false,
		loadingAddresses: false,
		error: "",
		status: "",
		list: [],
		selectedData: {},
		addressData: {
			default_billing_address: {},
			default_shipping_address: {},
		},
		addressList: [],
		drawer: false,
		openModal: false,
		openUpdateModal: false,
		openAddressModal: false,
		openAddAddressModal: false,
		count: 0,
		addressCount: 0,
		filterParams: {},
		pageParams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
		addressPageParams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},
	},
	reducers: {
		isDrawerVisible: (state, action) => {
			return {
				...state,
				drawer: action.payload,
			};
		},
		setOpenModel: (state, action) => {
			return {
				...state,
				openModal: action.payload,
			};
		},
		setUpdateOpenModel: (state, action) => {
			return {
				...state,
				openUpdateModal: action.payload,
			};
		},
		changeParams: (state, action) => {
			return {
				...state,
				pageParams: action.payload,
			};
		},
		setOpenAddressModal: (state, action) => {
			return {
				...state,
				openAddressModal: action.payload,
			};
		},
		setOpenAddAddressModal: (state, action) => {
			return {
				...state,
				openAddAddressModal: action.payload,
			};
		},
		changeAddressParams: (state, action) => {
			return {
				...state,
				addressPageParams: action.payload,
			};
		},
		changeFilterParams: (state, action) => {
			return {
				...state,
				filterParams: action.payload,
			};
		},
		// clearFilterParamsData: (state, action) => {
		// 	return {
		// 		...state,
		// 		filterParams: {},
		// 	};
		// },
		FilterInputChangeValue: (state, action) => {
			return {
				...state,
				filterParams: {
					...state.filterParams,
					[action.payload.key]: action.payload.value,
				},
			};
		},
		setSelectedData: (state, action) => {
			return {
				...state,
				selectedData: action.payload,
			};
		},
		inputChangeValue: (state, action) => {
			console.log(action.payload);
			return {
				...state,
				selectedData: {
					...state.selectedData,
					...action.payload,
				},
			};
		},
		changeShippingAddress: (state, action) => {
			return {
				...state,
				addressData: {
					...state.addressData,
					...action.payload,
				},
			};
		},
		clearShippingAddress: (state, action) => {
			return {
				...state,
				addressData: {},
			};
		},
		clearData: (state, action) => {
			return {
				...state,
				loading: false,
				error: "",
				status: "",
				list: [],
				selectedData: {},
				count: 0,
				pageParams: {
					start_date: "",
					end_date: "",
					no_of_pages: 0,
					page_size: 10,
					page: 1,
					search: "",
				},
			};
		},
	},
	extraReducers: (builder) => {
		builder
			//get Data
			.addCase(getTicketManagements.pending, (state, action) => {
				state.status = "getTicketManagements loading";
				state.loading = true;
			})
			.addCase(getTicketManagements.fulfilled, (state, action) => {
				state.status = "getTicketManagements succeeded";
				state.loading = false;
				var noofpages = Math.ceil(
					action.payload.count / state.pageParams.page_size
				);
				state.count = action.payload.count;
				state.list = action.payload.results;
				state.pageParams = {
					...state.pageParams,
					no_of_pages: noofpages,
				};
			})
			.addCase(getTicketManagements.rejected, (state, action) => {
				state.status = "getTicketManagements failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//get retaailer addresses
			// .addCase(
			// 	getTicketManagementsAddress.pending,
			// 	(state, action) => {
			// 		state.status = "getTicketManagementsAddress loading";
			// 		state.loadingAddresses = true;
			// 	}
			// )
			// .addCase(
			// 	getTicketManagementsAddress.fulfilled,
			// 	(state, action) => {
			// 		state.status = "getTicketManagementsAddress succeeded";
			// 		state.loadingAddresses = false;
			// 		var noofpages = Math.ceil(
			// 			action.payload.count / state.addressPageParams.page_size
			// 		);
			// 		state.addressCount = action.payload.count;
			// 		state.addressList = action.payload.results;
			// 		state.addressPageParams = {
			// 			...state.addressPageParams,
			// 			no_of_pages: noofpages,
			// 		};
			// 	}
			// )
			// .addCase(
			// 	getTicketManagementsAddress.rejected,
			// 	(state, action) => {
			// 		state.status = "getTicketManagementsAddress failed";
			// 		state.loadingAddresses = false;
			// 		state.error = action.error.message;
			// 	}
			// )
			//post retaailer addresses
			// .addCase(
			// 	postTicketManagementsAddress.pending,
			// 	(state, action) => {
			// 		state.status = "postTicketManagementsAddress loading";
			// 		state.loadingAddresses = true;
			// 	}
			// )
			// .addCase(
			// 	postTicketManagementsAddress.fulfilled,
			// 	(state, action) => {
			// 		state.status = "postTicketManagementsAddress succeeded";
			// 		state.loadingAddresses = false;
			// 		state.selectedData = {
			// 			default_billing_address: {},
			// 			default_shipping_address: {},
			// 		};
			// 	}
			// )
			// .addCase(
			// 	postTicketManagementsAddress.rejected,
			// 	(state, action) => {
			// 		state.status = "postTicketManagementsAddress failed";
			// 		state.loadingAddresses = false;
			// 		state.error = action.error.message;
			// 	}
			// )
			//edit retailer
			.addCase(editTicketManagement.pending, (state, action) => {
				state.status = "editTicketManagement loading";
				state.loadingAddresses = true;
			})
			.addCase(editTicketManagement.fulfilled, (state, action) => {
				state.status = "editTicketManagement succeeded";
				state.loadingAddresses = false;
				// state.selectedData = {
				// 	...action.payload.data,
				// 	// salesagent_id: action.payload?.data?.salesagent?.id,
				// 	distributor_id: action.payload?.data?.distributor?.id,
				// 	location_id: action.payload?.data?.location?.id,
				// };
			})
			.addCase(editTicketManagement.rejected, (state, action) => {
				state.status = "editTicketManagement failed";
				state.loadingAddresses = false;
				state.error = action.error.message;
			})
			//edit put retailer
			// .addCase(editTicketManagementput.pending, (state, action) => {
			// 	state.status = "editTicketManagementput loading";
			// 	state.loadingAddresses = true;
			// })
			// .addCase(editTicketManagementput.fulfilled, (state, action) => {
			// 	state.status = "editTicketManagementput succeeded";
			// 	state.loadingAddresses = false;
			// 	state.selectedData = {
			// 		...action.payload,
			// 		salesagent_id: action.payload?.salesagent?.id,
			// 		location_id: action.payload?.location?.id,
			// 	};
			// })
			// .addCase(editTicketManagementput.rejected, (state, action) => {
			// 	state.status = "editTicketManagementput failed";
			// 	state.loadingAddresses = false;
			// 	state.error = action.error.message;
			// })
			//get Data by Id
			.addCase(getTicketManagementByID.pending, (state, action) => {
				state.status = "getTicketManagementByID loading";
				state.loading = true;
			})
			.addCase(getTicketManagementByID.fulfilled, (state, action) => {
				state.status = "getTicketManagementByID succeeded";
				state.loading = false;
				state.selectedData = {
					...action.payload,
					// salesagent_id: action.payload?.salesagent?.id,
					distributor_id: action.payload?.distributor?.id,
					location_id: action.payload?.location?.id,
				};
			})
			.addCase(getTicketManagementByID.rejected, (state, action) => {
				state.status = "getTicketManagementByID failed";
				state.loading = false;
				state.error = action.error.message;
			})
			//post data
			.addCase(postTicketManagement.pending, (state, action) => {
				state.status = "postTicketManagement loading";
				state.loading = true;
			})
			.addCase(postTicketManagement.fulfilled, (state, action) => {
				state.status = "postTicketManagement succeeded";
				state.loading = false;
				state.selectedData = {
					default_billing_address: {},
					default_shipping_address: {},
				};
			})
			.addCase(postTicketManagement.rejected, (state, action) => {
				state.status = "postTicketManagement failed";
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

// Action creators are generated for each case reducer function
export const {
	setOpenAddressModal,
	setOpenAddAddressModal,
	isDrawerVisible,
	changeParams,
	changeFilterParams,
	clearData,
	setSelectedData,
	inputChangeValue,
	setOpenModel,
	setUpdateOpenModel,
	FilterInputChangeValue,
	changeAddressParams,
	changeShippingAddress,
	clearShippingAddress,
} = ticketManagementSlice.actions;

export default ticketManagementSlice.reducer;
