import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
	addParams,
	getList,
	getParamsList,
	postAdd,
	postEdit,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
	RiCheckboxCircleLine,
	RiAlertLine,
	RiInformationLine,
} from "react-icons/ri";
import Swal from "sweetalert2";

export const getReturnRequest = createAsyncThunk(
	"/getReturnRequest",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/returns/distributor/return_request/list/`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getReturnRequestByID = createAsyncThunk(
	"/getReturnRequestByID",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getList(
				`/returns/distributor/return_request/${params.id}`
			);
			if (response) {
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

export const postReturnRequest = createAsyncThunk(
	"/postReturnRequest",
	async (payload) => {
		try {
			const { data, history, forms } = payload;
			const response = await postAdd(
				"/returns/distributor/return_request/create/",
				data
			);
			if (response.status === 201) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Return Request Added Successfully.!
							</p>
						</div>
					),
				});
				history.push("/return_request");
				forms.form.resetFields();
				forms.RemarksForm.resetFields();
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error.response) {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Unable to add Return Request. Please try
								again!
							</p>
						</div>
					),
				});
			} else {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),

					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Getting from server side issue!
							</p>
						</div>
					),
				});
			}
			throw error.message;
		}
	}
);

export const editReturnRequest = createAsyncThunk(
	"/editReturnRequest",
	async (payload) => {
		try {
			const { id, data, history, forms } = payload;
			const response = await postEdit(
				`/returns/distributor/return_request/${id}`,
				data
			);
			if (response.status === 200) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Return Request Edited Successfully.!
							</p>
						</div>
					),
				});
				history.push("/return_request");
				forms.form.resetFields();
				forms.RemarksForm.resetFields();
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error.response) {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Unable to edit Return Request. Please try
								again!
							</p>
						</div>
					),
				});
			} else {
				Modal.error({
					icon: (
						<span className="remix-icon">
							<RiAlertLine />
						</span>
					),

					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Sorry! Getting from server side issue!
							</p>
						</div>
					),
				});
			}
			throw error.message;
		}
	}
);

// export const approveReturnRequestByID = createAsyncThunk(
// 	"/approveReturnRequestByID",
// 	async (payload, { dispatch }) => {
// 		try {
// 			const response = await postEdit(
// 				`/orders/distributorindentapproval/${payload.id}`,
// 				payload
// 			);
// 			if (response.data) {
// 				Swal.fire({
// 					title: "Success",
// 					text:
// 						response?.data?.indent_status == 2
// 							? "Return Approved"
// 							: "Return Rejected",
// 					icon: "success",
// 					confirmButtonColor: "#3085d6",
// 					// confirmButtonText: "Cool",
// 				});
// 				dispatch(getReturnRequestByID({ id: payload.id }));
// 				return {
// 					response: response.data,
// 					indent_status: payload?.indent_status,
// 				};
// 			} else {
// 				throw new Error(response);
// 			}
// 		} catch (error) {
// 			Swal.fire({
// 				title: "Error",
// 				text:
// 					payload?.indent_status == 2
// 						? "Error While Approving Return"
// 						: "Error While Rejecting Return",
// 				icon: "error",
// 				confirmButtonColor: "#3085d6",
// 				// confirmButtonText: "Cool",
// 			});
// 			return error.message;
// 		}
// 	}
// );
// const delay = (ms) =>
// 	new Promise((resolve) => {
// 		setTimeout(() => {
// 			resolve();
// 		}, ms);
// 	});

// async function checkStatus(payload) {
// 	const { dispatch } = payload;
// 	let timeout;
// 	timeout = await delay(1000);
// 	dispatch(distributorReturnProcessingStatus({}));
// }
// export const distributorReturnProcessingStatus = createAsyncThunk(
// 	"/distributorReturnProcessingStatus",
// 	async (payload, { dispatch }) => {
// 		try {
// 			const response = await getParamsList(
// 				`/orders/distributor_indent_process/status/`,
// 				payload
// 			);
// 			console.log(response);
// 			if (!response.status) {
// 				Swal.fire({
// 					title: "Success",
// 					text: "Process Completed",
// 					icon: "success",
// 					confirmButtonColor: "#3085d6",
// 					// confirmButtonText: "Cool",
// 				});
// 				return response;
// 			} else {
// 				checkStatus({ dispatch });
// 			}
// 		} catch (error) {
// 			Swal.fire({
// 				title: "Error",
// 				text: "Error while processing",
// 				icon: "error",
// 				confirmButtonColor: "#3085d6",
// 				// confirmButtonText: "Cool",
// 			});
// 			return error.message;
// 		}
// 	}
// );
// export const distributorReturnProcessing = createAsyncThunk(
// 	"/distributorReturnProcessing",
// 	async (payload, { dispatch }) => {
// 		const { product_type_id, approve_status } = payload;
// 		try {
// 			const response = await getParamsList(
// 				`/orders/distributor_indent_process/${product_type_id}/${approve_status}/`,
// 				payload
// 			);
// 			if (response) {
// 				checkStatus({ ...payload, dispatch });
// 				// dispatch(distributorReturnProcessingStatus())
// 			} else {
// 				throw new Error(response);
// 			}
// 		} catch (error) {
// 			Swal.fire({
// 				title: "Error",
// 				text: "Error while processing",
// 				icon: "error",
// 				confirmButtonColor: "#3085d6",
// 				// confirmButtonText: "Cool",
// 			});
// 			return error.message;
// 		}
// 	}
// );
