import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
    addParams,
    getList,
    getParamsList,
    postAdd,
    postEdit,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
    RiCheckboxCircleLine,
    RiAlertLine,
    RiInformationLine,
} from "react-icons/ri";
import axiosInstance from "../../../helpers/AxiosHelper";

const seralizeParams = (payload) => {
    var params = {};
    for (const k in payload) {
        if (Object.hasOwnProperty.call(payload, k)) {
            if (k === "type") {
                params.type = payload.type.join(",");
            } else {
                if (payload[k] !== "" && k !== "no_of_pages") {
                    params[k] = payload[k];
                }
            }
        }
    }
    return params;
};

export const getDrivers = createAsyncThunk(
    "/getDrivers",
    async (payload) => {
        const params = addParams(payload);
        try {
            const response = await getParamsList(
                `/users/driver/create/`,
                params
            );
            if (response) {
                return response;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            return error.message;
        }
    }
);
// export const postDrivers = createAsyncThunk(
//     "/postDrivers",
//     async (payload) => {
//         try {
//             const {
//                 data,
//                 history,
//                 driverDetailsForm,
//                 locationDetailsForm,
//                 // shippingAddressForm,
//                 clearData,
//                 message,
//             } = payload;
//             const response = await postAdd("/users/deliveryperson/create/", data);
//             if (response.status === 201) {
//                 Modal.success({
//                     icon: (
//                         <span className="remix-icon">
//                             <RiCheckboxCircleLine />
//                         </span>
//                     ),
//                     title: (
//                         <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
//                     ),
//                     content: (
//                         <div>
//                             <p className="hp-p1-body hp-text-color-black-80">
//                                 Driver Added Successfully.!
//                             </p>
//                         </div>
//                     ),
//                 });
//                 history.push("/delivery_person");
//                 driverDetailsForm.resetFields();
//                 locationDetailsForm.resetFields();
//                 // shippingAddressForm.resetFields();
//                 message.success("Processing complete!");
//                 clearData();
//                 return response;
//             } else {
//                 throw response;
//             }
//         } catch (error) {
//             if (error?.response?.data) {
//                 const values = Object.keys(error.response.data);
//                 console.log(values);
//                 Modal.error({
//                     icon: (
//                         <span className="remix-icon">
//                             <RiAlertLine />
//                         </span>
//                     ),
//                     title: (
//                         <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
//                     ),
//                     content: (
//                         <div>
//                             <p className="hp-p1-body hp-text-color-black-80">
//                                 {error?.response?.data[values[0]][values[0]]}
//                             </p>
//                         </div>
//                     ),
//                 });
//             } else {
//                 Modal.error({
//                     icon: (
//                         <span className="remix-icon">
//                             <RiAlertLine />
//                         </span>
//                     ),

//                     title: (
//                         <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
//                     ),
//                     content: (
//                         <div>
//                             <p className="hp-p1-body hp-text-color-black-80">
//                                 Sorry! Getting from server side issue!
//                             </p>
//                         </div>
//                     ),
//                 });
//             }
//             throw error.message;
//         }
//     }
// );
export const postDrivers = createAsyncThunk(
    "/postDrivers",
    async (payload, { dispatch }) => {
        const { data, handleAdd, driverDetailsForm } = payload;
        try {
            const response = await postAdd(`/users/driver/create/`, { ...data });
            if (response.status >= 200 && response.status < 300) {
                handleAdd(response.data.id);
                driverDetailsForm.resetFields();
                return response.data;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            console.log(error.response);
            throw error.message;
        }
    }
);
export const editDrivers = createAsyncThunk(
    "/editDrivers",
    async (payload) => {
        const { id, data, driverDetailsForm, handleAdd } = payload;
        try {
            const response = await axiosInstance.put(`/users/driver/admin/details/${id}/`, {
                ...data,
            });
            if (response.status >= 200 && response.status < 300) {
                handleAdd(id);
                driverDetailsForm.resetFields();
                return response.data;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);
// export const editDrivers = createAsyncThunk(
//     "/editDrivers",
//     async (payload) => {
//         try {
//             const {
//                 id,
//                 data,
//                 history,
//                 driverDetailsForm,
//                 locationDetailsForm,
//                 // shippingAddressForm,
//                 clearData,
//                 message,
//             } = payload;
//             const response = await postEdit(
//                 `/users/deliveryperson/details/${id}/`,
//                 data
//             );
//             if (response.status === 200) {
//                 Modal.success({
//                     icon: (
//                         <span className="remix-icon">
//                             <RiCheckboxCircleLine />
//                         </span>
//                     ),
//                     title: (
//                         <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
//                     ),
//                     content: (
//                         <div>
//                             <p className="hp-p1-body hp-text-color-black-80">
//                                 Driver Edited Successfully.!
//                             </p>
//                         </div>
//                     ),
//                 });
//                 history.push("/delivery_person");
//                 driverDetailsForm.resetFields();
//                 locationDetailsForm.resetFields();
//                 // shippingAddressForm.resetFields();
//                 message.success("Processing complete!");
//                 clearData();
//                 return response;
//             } else {
//                 throw new Error(response);
//             }
//         } catch (error) {
//             if (error.response) {
//                 Modal.error({
//                     icon: (
//                         <span className="remix-icon">
//                             <RiAlertLine />
//                         </span>
//                     ),
//                     title: (
//                         <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
//                     ),
//                     content: (
//                         <div>
//                             <p className="hp-p1-body hp-text-color-black-80">
//                                 Sorry! Unable to edit Driver . Please try
//                                 again!
//                             </p>
//                         </div>
//                     ),
//                 });
//             } else {
//                 Modal.error({
//                     icon: (
//                         <span className="remix-icon">
//                             <RiAlertLine />
//                         </span>
//                     ),

//                     title: (
//                         <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
//                     ),
//                     content: (
//                         <div>
//                             <p className="hp-p1-body hp-text-color-black-80">
//                                 Sorry! Getting from server side issue!
//                             </p>
//                         </div>
//                     ),
//                 });
//             }
//             throw error.message;
//         }
//     }
// );
export const getDriverByID = createAsyncThunk(
    "/getDriverByID",
    async (payload) => {
        const params = addParams(payload);
        try {
            const response = await getList(
                `/users/driver/admin/details/${params.id}/`
            );
            if (response) {
                return response.data;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            return error.message;
        }
    }
);

export const driverActive = createAsyncThunk("/driverActive", async (payload) => {
    const { id, onSuccess, OnFailure } = payload;
    try {
        const response = await postAdd(`/users/admin/driver/active/${id}/`, {});
        if (response.status >= 200 && response.status < 300) {
            onSuccess(response.data);
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        if (error.response) {
            OnFailure(error.response.data.phone);
        } else {
            OnFailure("Sorry! Getting from server side issue!");
        }
        throw error.message;
    }
});
export const driverInActive = createAsyncThunk(
    "/driverInActive",
    async (payload) => {
        const { id, onSuccess, onFailure } = payload;
        try {
            const response = await postAdd(`/users/admin/driver/inactive/${id}/`, {});
            if (response.status >= 200 && response.status < 300) {
                onSuccess(response.data);
                return response.data;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            if (error.response) {
                onFailure(error.response.data.phone);
                throw error.response.data.phone;
            } else {
                onFailure("Sorry! Getting from server side issue!");
                throw error.message;
            }
        }
    }
);

export const getDeliverableOrders = createAsyncThunk(
    "/getDeliverableOrders",
    async (payload) => {
        const params = addParams(payload);
        try {
            const response = await getParamsList(
                `/b2c/admin/deliverable_orders/list/`,
                params
            );
            if (response) {
                return response;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            return error.message;
        }
    }
);
export const getDriverCashCollection = createAsyncThunk(
    "/getDriverCashCollection",
    async (payload) => {
        const params = addParams(payload);
        try {
            const response = await getParamsList(
                `/b2c/admin/delivery_cash_collection/list/`,
                params
            );
            if (response) {
                return response;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            return error.message;
        }
    }
);


export const getUserStates = createAsyncThunk(
    "/getUserStates",
    async (payload, { getState, dispatch, rejectWithValue }) => {

        var params = seralizeParams(payload);
        try {
            const response = await getParamsList(
                "/masters/states/mini/",
                params
            );
            if (response.results) {
                const manageUserState = getState().masters.driver;
                var statesList = response?.results?.map((userState) => {
                    if (manageUserState.userSelectedStatesList.indexOf(userState.id) !== -1) {
                        userState.state_checked = true;
                    } else {
                        userState.state_checked = false;
                    }
                    return userState;
                });
                const configHome = statesList
                    .filter((z) => z.state_checked)
                    .map((userState) => {
                        return dispatch(getUserZones({ state: userState.id, page: 1, page_size: 10 }));
                    });

                const promise = await Promise.all(configHome);
                const promisePayload = promise.map(zone => zone.payload);
                return {
                    statesList: statesList.map(e => {

                        return {
                            ...e,
                            zonesCount: promisePayload.find(zone => zone.state_id == e.id)?.count,
                            zones: promisePayload.find(zone => zone.state_id == e.id)?.zoneList,
                        }
                    }),
                    count: response.count
                };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);
export const getUserZones = createAsyncThunk(
    "/getUserZones",
    async (payload, { getState, dispatch }) => {
        var params = seralizeParams(payload);
        try {

            const response = await getParamsList(
                "/masters/zone/mini/",
                params
            );
            if (response.results) {
                const manageUserState = getState().masters.driver;
                var zoneList = response?.results?.map((zone) => {
                    if (
                        manageUserState.userSelectedZonesList.indexOf(zone.id) !== -1
                    ) {
                        zone.zone_checked = true;
                    } else {
                        zone.zone_checked = false;
                    }
                    zone.state_id = payload.state;
                    return zone;
                });
                const configHome = zoneList
                    .filter((d) => d.zone_checked)
                    .map((zone) => {
                        return dispatch(getUserCities({ zone: zone.id, state: payload.state, page: 1, page_size: 10 }));
                    });
                const promise = await Promise.all(configHome);
                const promisePayload = promise.map(city => city.payload);
                return {
                    zoneList: zoneList.map(e => {

                        return {
                            ...e,
                            // cities: promise.map(city => city.payload.
                            //   citiesList
                            // ).flat()
                            citiesCount: promisePayload.find(city => (city.state_id == e.state_id) && (city.zone_id == e.id))?.count,
                            cities: promisePayload.find(city => (city.state_id == e.state_id) && (city.zone_id == e.id))?.citiesList
                        }
                    }), state_id: payload.state,
                    count: response.count
                };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            return error.message;
        }
    }
);
export const getUserCities = createAsyncThunk(
    "/getUserCities",
    async (payload, { getState, dispatch }) => {
        var params = seralizeParams(payload);
        try {

            const response = await getParamsList(
                "/masters/citys/mini/",
                params
            );
            if (response) {
                const manageUserState = getState().masters.driver;
                var citiesList = response.results.map((city) => {
                    if (
                        manageUserState.userSelectedCityList.indexOf(city.id) !== -1
                    ) {
                        city.city_checked = true;
                    } else {
                        city.city_checked = false;
                    }
                    city.state_id = payload.state;
                    city.zone_id = payload.zone;
                    return city;
                });
                const configHome = citiesList
                    .filter((d) => d.city_checked)
                    .map((city) => {
                        return dispatch(getUserAreas({ city: city.id, state: payload.state, zone: payload.zone, page: 1, page_size: 10 }));
                    });
                const promise = await Promise.all(configHome);
                const promisePayload = promise.map(area => area.payload);
                return {
                    citiesList: citiesList?.map(e => {
                        return {
                            ...e,
                            areasCount: promisePayload.find(area => (area.state_id == e.state_id) && (area.zone_id == e.zone_id) && (area.city_id == e.id))?.
                                count,
                            areas: promisePayload.find(area => (area.state_id == e.state_id) && (area.zone_id == e.zone_id) && (area.city_id == e.id))?.
                                areasList
                        }
                    }), state_id: payload.state, zone_id: payload.zone, count: response.count
                };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            return error.message;
        }
    }
);
export const getUserAreas = createAsyncThunk(
    "/getUserAreas",
    async (payload, { getState, dispatch }) => {
        var params = seralizeParams(payload);
        try {

            const response = await getParamsList(
                "/masters/area/mini/",
                params
            );
            if (response) {
                const manageUserState = getState().masters.driver;
                var areasList = response.results.map((area) => {
                    if (
                        manageUserState.userSelectedAreaList.indexOf(area.id) !== -1
                    ) {
                        area.area_checked = true;
                    } else {
                        area.area_checked = false;
                    }
                    area.state_id = payload.state;
                    area.zone_id = payload.zone;
                    area.city_id = payload.city;
                    return area;
                });
                const configHome = areasList
                    .filter((d) => d.area_checked)
                    .map((area) => {
                        return dispatch(getUserLocation({ area: area.id, state: payload.state, zone: payload.zone, city: payload.city, page: 1, page_size: 10 }));
                    });
                const promise = await Promise.all(configHome);
                const promisePayload = promise.map(area => area.payload);

                return {
                    areasList: areasList.map(e => {
                        return {
                            ...e,
                            locationsCount: promisePayload.find(location => (location.state_id == e.state_id) && (location.zone_id == e.zone_id) && (location.city_id == e.city_id) && location.area_id == e.id)?.count,
                            locations: promisePayload.find(location => (location.state_id == e.state_id) && (location.zone_id == e.zone_id) && (location.city_id == e.city_id) && location.area_id == e.id)?.locationList
                        }
                    }), state_id: payload.state, zone_id: payload.zone, city_id: payload.city, count: response.count
                };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            return error.message;
        }
    }
);
export const getUserLocation = createAsyncThunk(
    "/getUserLocation",
    async (payload, { getState, dispatch }) => {
        var params = seralizeParams(payload);
        try {

            const response = await getParamsList(
                "/masters/location/mini/",
                params
            );
            if (response) {
                const manageUserState = getState().masters.driver;
                var locationList = response.results.map((location) => {
                    if (
                        manageUserState.userSelectedLocationList.indexOf(location.id) !== -1
                    ) {
                        location.location_checked = true;
                    } else {
                        location.location_checked = false;
                    }
                    location.state_id = payload.state;
                    location.zone_id = payload.zone;
                    location.city_id = payload.city;
                    location.area_id = payload.area;
                    return location;
                });
                return { locationList, state_id: payload.state, zone_id: payload.zone, city_id: payload.city, area_id: payload.area, count: response.count };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            return error.message;
        }
    }
);

export const putDriverLocation = createAsyncThunk(
    "/putDriverLocation",
    async (payload) => {
        const { id, data, history, clearData } = payload;
        try {
            const response = await postEdit(`/users/deliveryperson/locationpermission/${id}`, { ...data });
            if (response.status >= 200 && response.status < 300) {
                // onSuccess(response.data);
                history.push(`/delivery_person`);
                clearData();
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Driver Added Successfully.!
                            </p>
                        </div>
                    ),
                });
                return response.data;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),
                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Unable to add delivery person location permission. Please try again!
                        </p>
                    </div>
                ),
            });
            throw error.message;
        }
    }
);